// Libs
import React, { useEffect, useState } from "react";
import { connect, useSelector } from "react-redux";
import { bindActionCreators } from "redux";
import tinycolor from "tinycolor2";
import { css } from "emotion";
import { CloseIcon, SettingsOutlineIcon } from "mdi-react";
import { CSSTransition } from "react-transition-group";

// Redux actions
import { closeSideMenu } from "../actions/sideMenuActions";
import { showModalPage } from "../actions/uiActions";

// Styles
import colors from "../style/colors";
import common from "../style/common";

// Utilities
import pageNavigatorV2 from "../utilities/page-navigator-v2";
import linkPathCreator from "../utilities/get-link-path-from-page-object";
import iconConvertor from "../utilities/icon-converter";
import getProfilePicture from "../utilities/get-profile-picture-from-user-object";
import getAppName from "../utilities/get-app-name";
import req from "../utilities/request-utility";

// Components
import ManageApplicationOverview from "./ui/manageApplication/ManageApplicationOverview";
import Overlay from "./ui/Overlay";
import Notification from "./ui/Notification";
import ActionWrapper from "./ui/ActionWrapper";
import ButtonRounded from "./ui/ButtonRounded";

function SideMenu(props) {
  const { user, active } = props;
  const [points, setPoints] = useState(null);
  const { enableHighscore, allowInAppConfiguration } = useSelector((s) => s.appConfig);
  const lang = useSelector((s) => s.language.language);
  const [loading, setLoading] = useState(true);
  const { primaryColor } = useSelector((state) => state.appConfig);

  useEffect(() => {
    if (active === true) {
      req()("pointlog/count").then(({ data }) => {
        setPoints(data.count);
        setLoading(false);
      });
    }
  }, [active]);

  return (
    <div className={style(primaryColor)}>
      <Overlay active={active} onClick={props.closeSideMenu} />
      <CSSTransition in={active} timeout={300} mountOnEnter={true} unmountOnExit={true} classNames="side-menu">
        <div key={0} className="side-menu-container" data-test-id="side-menu">
          <div className="header-wrapper">
            <ActionWrapper onClick={props.closeSideMenu} style={{ marginBottom: "-0.75rem" }}>
              <CloseIcon className="close-icon" color={colors.white} />
            </ActionWrapper>
            <ActionWrapper style={{ width: "100%" }}>
              <div
                className="user"
                onClick={() => {
                  pageNavigatorV2({ path: `/${getAppName()}/profile/${user.id}`, direction: "forward" });
                  props.closeSideMenu();
                }}
              >
                {getProfilePicture(user, 42, { fill: colors.white })}
                <div data-test-id="side-menu-user" style={{ marginLeft: "0.75rem" }}>
                  <p className="bold">{user.name}</p>
                  <p
                    className="meta"
                    onClick={(e) => {
                      e.stopPropagation();
                      pageNavigatorV2({ path: `/${getAppName()}/profile/${user.id}/?tab=1`, direction: "forward" });
                      props.closeSideMenu();
                    }}
                  >
                    {enableHighscore && loading && `${lang.loading} ${lang.points}`}

                    {enableHighscore && !loading && `${points} ${lang.points}`}

                    {!enableHighscore && `${user.masterGroup.title}, ${user.userGroup.title}`}
                  </p>
                </div>
                <SettingsOutlineIcon
                  data-test-id="side-menu-settings"
                  className="settings-icon"
                  onClick={(e) => {
                    e.stopPropagation();
                    pageNavigatorV2({
                      path: `/${getAppName()}/profile/${user.id}/?tab=${props.enableHighscore ? 2 : 1}`,
                      direction: "forward",
                    });
                    props.closeSideMenu();
                  }}
                />
              </div>
            </ActionWrapper>
          </div>

          <div className="navigation">
            <div className="side-menu scroll-hider">
              {props.pages
                .filter((p) => p.module && p.title)
                .map((page, pageKey) => (
                  <ActionWrapper
                    key={pageKey}
                    className="pageWrapper"
                    onClick={() => {
                      props.closeSideMenu();
                      pageNavigatorV2({ path: linkPathCreator(page, "absolute"), direction: "forward", pageId: page.id });
                    }}
                  >
                    {iconConvertor(page.icon, {
                      flexShrink: 0,
                      color: colors.darkGrey,
                      marginRight: "0.25rem",
                      width: "1.25rem",
                      height: "1.25rem",
                    })}
                    <p className="page-title" key={pageKey}>
                      {page.title}
                    </p>
                    {!page.notifications ? null : (
                      <Notification notifications={page.notifications} style={{ marginRight: "0.75rem" }} />
                    )}
                  </ActionWrapper>
                ))}

              {/* if application allows configuration and user is admin */}
              {allowInAppConfiguration === true && user.adminId && (
                <ButtonRounded
                  onClick={() =>
                    props.showModalPage({
                      title: lang.manageApp,
                      content: <ManageApplicationOverview />,
                    })
                  }
                  secondary={true}
                  style={{ width: "91%", margin: "2rem 0 1rem 3.25%" }}
                >
                  {lang.manageApp}
                </ButtonRounded>
              )}
            </div>
          </div>
        </div>
      </CSSTransition>
    </div>
  );
}

const style = (primaryColor) => css`
  z-index: 8;
  position: relative;

  .side-menu-container {
    position: fixed;
    display: flex;
    top: 0;
    flex-direction: column;
    width: 300px;
    height: 100%;
    background-color: ${colors.white};
    color: ${colors.black};
    transition: background-color 120ms ease;
    transition-delay: 300ms;
    overflow: hidden;
    .page-title {
      flex-shrink: 1;
      //white-space: nowrap;
      //text-overflow: ellipsis;
      //overflow: hidden;
      word-break: break-word;
      padding: 0rem 0.5rem;
      flex-grow: 1;
    }

    .pageWrapper {
      position: relative;
      display: flex;
      align-items: center;
      width: 100%;

      justify-content: space-between;
      padding: 1rem;
    }

    &.side-menu-enter {
      opacity: 0;
      transform: translate3d(-100%, 0, 0);
    }
    &.side-menu-enter-active {
      transform: translate3d(0%, 0, 0);
      opacity: 1;
      transition: transform 190ms ease, opacity 300ms;
    }
    &.side-menu-exit {
      opacity: 1;
      transform: scale(1);
    }
    &.side-menu-exit-active {
      opacity: 0;
      transform: translate3d(-100%, 0, 0);
      transition: transform 300ms ease, opacity 300ms;
    }

    .close-icon {
      width: ${common.topBarHeight}px;
      height: ${common.topBarHeight}px;
      padding: 0.75rem;
    }

    .header-wrapper {
      background-color: ${primaryColor};
      width: 100%;
      box-shadow: 0 3px 13px rgba(0, 0, 0, 0.2), 0 1px 2px rgba(0, 0, 0, 0.1);
    }

    .user {
      position: relative;
      display: flex;
      align-items: center;
      padding: 0.75rem 0.75rem 0.75rem 0.75rem;
      color: ${colors.white};
      text-shadow: 0 1px 3px rgba(0, 0, 0, 0.2);

      .meta {
        opacity: 0.8;
      }

      .settings-icon {
        position: absolute;
        top: 0.75rem;
        right: 0.75rem;
        width: 1.25rem;
        height: 1.25rem;
        fill: ${colors.white};
      }
    }

    .navigation {
      overflow: hidden;
      flex-grow: 1;
      position: relative;

      .scroll-hider {
        position: absolute;
        height: 100%;
        width: 103%; /* Hides scrollbar */
        overflow-y: auto;
        padding: 0.25rem 0;
        -webkit-overflow-scrolling: touch;
      }
    }
  }
`;

const mapStateToProps = (state) => ({
  active: state.sideMenu.active,
  enableHighscore: state.appConfig.enableHighscore,
  pages: state.pages.pages,
  user: state.auth.user,
});

const mapDispatchToProps = (dispatch) => ({
  showModalPage: bindActionCreators(showModalPage, dispatch),
  closeSideMenu: bindActionCreators(closeSideMenu, dispatch),
});

export default connect(mapStateToProps, mapDispatchToProps)(SideMenu);
