// Libs
import React, { useState } from "react";
import { css } from "emotion";
import { useSelector } from "react-redux";

// Components
import TabBar from "../TabBar";
import TabView from "../TabView";
import ManageApplication from "./ManageApplication";
import ScrollView from "../ScrollView";

const ManageApplicationOverview = (props) => {
  // Redux state
  const { language: lang } = useSelector((state) => state.language);

  // Local state
  const [tabs] = useState([lang.theApp]);
  const [activeTab, setActiveTab] = useState(0);

  return (
    <div className={`${componentStyle(props)} ${props.className || ""}`} style={props.style && props.style}>
      <ManageApplication />
      {/*       <TabBar
        activeTabIndex={activeTab}
        tabs={tabs.map((tab, tabIndex) => ({
          title: tab,
          onClick: () => {
            setActiveTab(tabIndex);
          },
        }))}
      /> */}
      {/*   <TabView
        useScrollView={true}
        activeTabIndex={activeTab}
        tabs={[
          <ManageApplication />,
        ]}
      /> */}
    </div>
  );
};

const componentStyle = (props) => css`
  height: 100%;
  width: 100%;
  display: flex;
  flex-direction: column;
`;

export default ManageApplicationOverview;
