// Config
import contactTypes from "../config/contactsTypes";
import layoutTypes from "../config/layoutTypes";
import { dataTestIds, dataTestTypes } from "../config/dataTestIds";

// Utilities
import getLayoutType from "./getLayoutType";

function getDataTestIdForListItem({ groupBy, contactType, type = dataTestTypes.component } = {}) {
  // Component
  if (type === dataTestTypes.component) {
    if (contactType === contactTypes.phoneBook && getLayoutType({ groupBy }) === layoutTypes.contacts) {
      return dataTestIds.phoneBookContact;
    } else if (contactType === contactTypes.phoneBook && getLayoutType({ groupBy }) === layoutTypes.groups) {
      return dataTestIds.phoneBookGroup;
    } else if (contactType === contactTypes.keyPerson && getLayoutType({ groupBy }) === layoutTypes.contacts) {
      return dataTestIds.keyPersonContact;
    } else if (contactType === contactTypes.keyPerson && getLayoutType({ groupBy }) === layoutTypes.groups) {
      return dataTestIds.keyPersonGroup;
    }
  }

  if(type === dataTestTypes.title) {
    if(contactType === contactTypes.phoneBook) {
      return dataTestIds.phoneBookTitle;
    } else if (contactType === contactTypes.keyPerson) {
      return dataTestIds.keyPersonTitle;
    }
  }

  // MiddleTitle
  if (type === dataTestTypes.middleTitle) {
    if (contactType === contactTypes.phoneBook) {
      return dataTestIds.phoneBookMiddleTitle;
    } else if (contactType === contactTypes.keyPerson) {
      return dataTestIds.keyPersonMiddleTitle;
    }
  }

  // SubTitle
  if (type === dataTestTypes.subTitle) {
    if (contactType === contactTypes.phoneBook) {
      return dataTestIds.phoneBookSubTitle;
    } else if (contactType === contactTypes.keyPerson) {
      return dataTestIds.keyPersonSubTitle;
    }
  }

  // Content
  if (type === dataTestTypes.content) {
    if (contactType === contactTypes.phoneBook) {
      return dataTestIds.phoneBookContent;
    } else if (contactType === contactTypes.keyPerson) {
      return dataTestIds.keyPersonContent;
    }
  }
}

export { getDataTestIdForListItem };
