import React from "react";
import { css } from "emotion";
import hm from "../../../utilities/highlight-match";
import getProfilePicture from "../../../utilities/get-profile-picture-from-user-object";
import useUserModal from "../../../hooks/useUserModal";
import { useSelector } from "react-redux";
import ContactMenuOptions from "../../DEPRECATED_contacts/ContactMenuOptions";

const SearchResultUser = (props) => {
  const user = props.result.data;
  const userModal = useUserModal();
  const searchTerm = useSelector((s) => s.searchPage.searchTerm);

  return (
    <div
      key={`div__user-result__${user.id}`}
      className={`list-element ${componentStyles()}`}
      onClick={() => userModal(user.id)}
    >
      {getProfilePicture(user, 40)}
      <div style={{ marginLeft: "0.5rem", flexGrow: 1 }}>
        <p>{hm(user.name, searchTerm)}</p>
        <p className="meta">
          {user.masterGroup && user.masterGroup.title}, {user.userGroup.title}
        </p>
      </div>

      <ContactMenuOptions {...user} />
    </div>
  );
};

const componentStyles = (props) => css`
  display: flex;
  align-items: center;
`;

export default SearchResultUser;
