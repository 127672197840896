import DOMPurify from "dompurify";
import marked from "marked";
import cleanupEkkoUpdaterMarkdown from "./cleanup-ekko-updater-markdown";

marked.setOptions({
  breaks: true,
  gfm: true, // Enables github-flavored markdown
});
const defaultConfig = {
  ALLOWED_TAGS: ["p", "ul", "li", "strong", "b", "em", "u", "ol", "h1", "h2", "h3", "h4", "a", "br", "span"],
};

/** Takes a string og markdown, parses it as html and sanitize it before returning it  */
export default function (string = "", options = {}) {
  // string = string.replace(/\n(?=\n)/g, " &nbsp;\n");
  // string = string.replace(/\n/g, "(N)");
  // string = string.replace(/\r/g, "(R)");

  // string = string.replace(/\n/g, " &nbsp;\n");
  string = cleanupEkkoUpdaterMarkdown(string);

  let config;
  if (options.config) {
    config = options.config;
  } else {
    config = defaultConfig;
  }

  // https://reactjs.org/docs/dom-elements.html#dangerouslysetinnerhtml
  return { __html: DOMPurify.sanitize(marked(string), config) };
}
