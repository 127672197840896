// Libs
import React, { useEffect, useState, Fragment } from "react";
import queryString from "query-string";
import { useDispatch, useSelector } from "react-redux";
import { css } from "emotion";

// Utilities
import getDataSelectorOptions from "../../../utilities/get-data-selector-options";
import highlightMatch from "../../../utilities/highlight-match";

// Components
import StatusBox from "../StatusBox";
import Spinner from "../InlineSpinner";
import FloatingSearchInput from "../FloatingSearchInput";
import DataSelectorListItem from "./DataSelectorListItem";
import { ErrorOutlineIcon } from "mdi-react";

// Actions
import { addToast } from "../../../actions/uiActions";

// Config
import breakpoints from "../../../config/breakpoints";

// Styles
import colors from "../../../style/colors";

const DataSelectorModal = (props) => {
  const dispatch = useDispatch();
  const registrationId = queryString.parse(window.location.search).dataId;

  const { language: lang } = useSelector((state) => state.language);

  const [data, setData] = useState({
    options: [],
    loading: true,
    error: false,
    searchWord: "",
  });
  const { options, loading, error, searchWord } = data;

  useEffect(() => {
    (async function getDataOptions() {
      try {
        const options = await getDataSelectorOptions({
          dataIdentifier: props.dataIdentifier,
          registrationId: registrationId,
        });
        setData({ ...data, options: options, loading: false });
      } catch (error) {
        dispatch(
          addToast({
            title: lang.errorGeneral,
            content: lang.couldNotFetchOptions,
            icon: <ErrorOutlineIcon />,
            styleType: "error",
            duration: 20000,
          })
        );
        setData({ ...data, loading: false, error: true });
      }
    })();
  }, []);

  function filteredOptions({ searchWord }) {
    if (!searchWord) return options;
    return options.filter(
      (option) => option.title.toLowerCase().includes(searchWord) || option.subTitle.toLowerCase().includes(searchWord)
    );
  }

  function changeSearchWord(event) {
    setData({ ...data, searchWord: event.target.value.toLowerCase() });
  }

  function clearSearchWord() {
    setData({ ...data, searchWord: "" });
  }

  return (
    <div className={`${componentStyle()} ${props.className || ""}`} style={props.style}>
      {loading && !error && <Spinner style={{ marginTop: "2rem" }} />}
      {!loading && error && <StatusBox title={lang.oopsAnErrorOccured} content={lang.error} style={{ marginTop: "2rem" }} />}
      {!loading && !error && (
        <Fragment>
          <FloatingSearchInput
            onChange={changeSearchWord}
            value={searchWord}
            placeholder={lang.search}
            onClearSearch={clearSearchWord}
          />
          {filteredOptions({ searchWord }).map((option, index) => (
            <Fragment key={option.id}>
              <DataSelectorListItem
                onClick={() => props.onSelect(option)}
                className={`data-item ${index === 0 ? "first" : ""} ${
                  index === filteredOptions({ searchWord }).length - 1 ? "last" : ""
                }`}
                style={index === 0 ? { marginTop: "4.5rem" } : null}
                isSelected={props.selected && props.selected.id === option.id}
                title={highlightMatch(option.title, data.searchWord)}
                subTitle={highlightMatch(option.subTitle, data.searchWord)}
                image={option.image}
              />
            </Fragment>
          ))}
        </Fragment>
      )}
    </div>
  );
};

const componentStyle = () => css`
  display: flex;
  align-items: center;
  flex-direction: column;

  .data-item {
    width: 100%;
    max-width: ${breakpoints.lg}px;

    &.first {
      border-top-left-radius: 3px;
      border-top-right-radius: 3px;
    }

    &.last {
      margin-bottom: 1rem;
      border-bottom-left-radius: 3px;
      border-bottom-right-radius: 3px;
    }

    @media screen and (min-width: ${breakpoints.lg}px) {
      border: 1px solid ${colors.midGrey};
    }
  }
`;

export default DataSelectorModal;
