// LIbs
import React, { Fragment, useState } from "react";
import { css } from "emotion";
import { InformationOutlineIcon } from "mdi-react";
import { useSelector } from "react-redux";

// Config and utilities
import { registrationTypes } from "./config";
import fileDisplayFormat from "../../utilities/file-display-format";

// Components
import ImageCarousel from "../ui/ImageCarousel";

// Styles
import colors from "../../style/colors";
import TrafficLightLabel from "../ui/TrafficLightLabel";

const RegistrationPostContent = (props) => {
  const { answers } = props;

  const { language: lang } = useSelector((state) => state.language);
  const primaryColor = useSelector((state) => state.appConfig.primaryColor);

  return (
    <div className={componentStyle(primaryColor)}>
      {answers.map((a, index) =>
        // Well... These are all the found variants of not answered. Answers can be created from:
        // - Legacy app
        // - Ekko Updater
        // - The new app
        // (Which is probably why there are this many variations)
        a.title === null || a.title === "null" || a.title === "" || !a.title ? (
          <Fragment key={`answer-${a.id}`}>
            <p className="question">{a.question.title}</p>
            <p className="no-answer answer">
              <InformationOutlineIcon /> {lang.noAnswer}
            </p>
          </Fragment>
        ) : (
          <Fragment key={`answer-${a.id}`}>
            {/** FILE **/}
            {a.question.type === registrationTypes.FILE && (
              <>
                <p className="question">{a.question.title}</p>
                {fileDisplayFormat(a.title)}
              </>
            )}
            {/** CHECKBOX **/}
            {a.question.type === registrationTypes.CHECKBOX &&
              answers.findIndex((answer) => answer.question.id === a.question.id) === index && (
                <p className="question">
                  {answers.find((a) => a.question.type === registrationTypes.CHECKBOX).question.title}
                </p>
              )}
            {a.question.type === registrationTypes.CHECKBOX && <li key={`checkbox-item-${a.id}`}>{a.title}</li>}

            {/** RADIO **/}
            {/** DROPDOWN **/}
            {/** TEXT **/}
            {/** TIME **/}
            {/** DATE **/}
            {/** RADIO_HORIZONTAL **/}
            {(a.question.type === registrationTypes.RADIO ||
              a.question.type === registrationTypes.DROPDOWN ||
              a.question.type === registrationTypes.TEXT ||
              a.question.type === registrationTypes.TIME ||
              a.question.type === registrationTypes.DATE ||
              a.question.type === registrationTypes.RADIO_HORIZONTAL) && (
              <>
                <p className="question">{a.question.title}</p>
                <p className="answer">{a.title}</p>
              </>
            )}
            {/** DATA_SELECT**/}
            {a.question.type === registrationTypes.DATA_SELECT && (
              <>
                <p className="question">{a.question.title}</p>
                <p className="answer">{a.title && a.title.title}</p>
              </>
            )}
            {/** IMAGE **/}
            {a.question.type === registrationTypes.IMAGE && (
              <>
                <p className="question">{a.question.title}</p>
                <ImageCarousel images={[{ baseURL: a.baseURL, image: a.title }]} />
              </>
            )}

            {/** DATE **/}
            {a.question.type === registrationTypes.RADIO_TRAFFIC_LIGHT && (
              <>
                <p className="question">{a.question.title}</p>
                <TrafficLightLabel className="answer" value={parseInt(a.title)} />
              </>
            )}

            {/** YES_NO **/}
            {a.question.type === registrationTypes.YES_NO && (
              <>
                <p className="question">{a.question.title}</p>
                <p className="answer">
                  {a.title === "YES" ? lang.yes : ""}
                  {a.title === "NO" ? lang.no : ""}
                </p>
              </>
            )}
            {/** OTHER **/}
            {!a.question.type && (
              <>
                <p className="question">{a.question.title}</p>
                <p className="answer">{a.title}</p>
              </>
            )}
          </Fragment>
        )
      )}
    </div>
  );
};

const componentStyle = (primaryColor) => css`
  user-select: text;

  *::selection {
    background: ${primaryColor};
    color: ${colors.white};
    border-radius: 3px;
  }

  p {
    color: ${colors.black};
    white-space: break-spaces;
  }

  /* Documents amd links */
  .registration-file-attachment {
    border: 1px ${colors.midGrey} solid;
    border-radius: 3px;
    margin: 0.25rem 0rem ${0.5}rem;
    overflow: hidden;

    .attachment {
      text-decoration: none;
      display: block;
      color: ${colors.darkGrey};
      border-bottom: 1px ${colors.midGrey} solid;
      padding: 0.5rem;
      position: relative;
      transition: background-color 180ms ease;

      &:last-child {
        border-bottom: 0;
      }

      &:active {
        background-color: ${colors.lightGrey};
        transition: background-color 180ms ease;
      }

      p {
        padding-left: 1.75rem;
        text-overflow: ellipsis;
        overflow: hidden;
        white-space: nowrap;
      }

      svg {
        position: absolute;
        top: 50%;
        left: 0.6rem;
        transform: translateY(-50%);
        width: 1rem;
        height: 1rem;
        vertical-align: middle;
        fill: ${colors.darkGrey};
      }
    }
  }

  .no-answer {
    display: flex;
    align-items: center;
    color: ${colors.darkGrey};
    font-size: 0.9rem;

    svg {
      margin-right: 0.35rem;
      width: 1.15rem;
      height: 1.15rem;
      color: ${colors.darkGrey};
    }
  }

  li {
    margin-bottom: 0.25rem;

    &:last-of-type {
      margin-bottom: 1.25rem;
    }
  }

  .checkbox-container {
    margin-bottom: 1.25rem;
  }

  .question {
    font-weight: bold;
    margin-bottom: 0.25rem;
  }

  .answer {
    margin-bottom: 1.25rem;
  }
`;

export default RegistrationPostContent;
