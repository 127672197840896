// Libs
import React, { useEffect, useState } from "react";
import { useSelector } from "react-redux";

// Components
import Page from "../ui/Page";
import TopBar from "../ui/TopBar";
import Spinner from "../ui/InlineSpinner";
import StatusBox from "../ui/StatusBox";
import ScrollView from "../ui/ScrollView";
import ListItem from "../ui/ListItem";

// Utilities and config
import req from "../../utilities/request-utility";
import getPageFromId from "../../utilities/get-page-from-id";
import pageNavigatorV2 from "../../utilities/page-navigator-v2";
import breakpoints from "../../config/breakpoints";

// Styles
import { ChevronRightIcon, InfoOutlineIcon } from "mdi-react";

const ScheduleOverview = (props) => {
  // Page setup
  const { match } = props;
  const pages = useSelector((state) => state.pages.pages);
  const [page] = useState(getPageFromId(pages, match.params.pageId));

  // Local state
  const [groups, setGroups] = useState([]);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(false);

  // Redux state
  const { language: lang } = useSelector((state) => state.language);

  // Get the groups
  useEffect(() => {
    req()(`schedules/groups`)
      .then(({ data: groups }) => {
        setGroups(groups);
        setLoading(false);
      })
      .catch(() => {
        setLoading(false);
        setError(true);
      });
  }, []);

  return (
    <Page>
      <TopBar title={page.title} useDefaultBackButton={true} />

      <ScrollView style={{ marginTop: "1rem" }}>
        {loading && !error && <Spinner />}

        {!loading && error && (
          <StatusBox
            title={lang.noShiftGroupsCreatedYet}
            icon={<InfoOutlineIcon />}
            content={lang.tryAgainOrContactSupport}
          />
        )}

        {!loading && !error && groups.length === 0 && (
          <StatusBox
            title={lang.noShiftGroupsCreatedYet}
            icon={<InfoOutlineIcon />}
            content={lang.tryAgainOrContactSupport}
          />
        )}

        {!loading &&
          !error &&
          groups.length > 0 &&
          groups.map(({ id, title }) => (
            <ListItem
              clickable={true}
              dataTestId={"schedule-overview-item"}
              key={`schedule-overview-group-item-${id}`}
              maxWidth={breakpoints.md}
              title={title}
              iconRight={<ChevronRightIcon />}
              onClick={() => pageNavigatorV2({ path: `${match.url}/${id}`, direction: "forward" })}
            />
          ))}
      </ScrollView>
    </Page>
  );
};

export default ScheduleOverview;
