// State
import React from "react";
import { useSelector } from "react-redux";
import { css } from "emotion";
import { format, parse } from "date-fns";

// Config
import { feedTypes } from "../news/config";
import { postLayoutModes } from "../registration/config";

// Hooks
import useAuthorModal from "../../hooks/useUserModal";

// Utilities
import getUserLocale from "../../utilities/get-user-locale";
import getProfilePictureFromUserObject from "../../utilities/get-profile-picture-from-user-object";
import { parseDatetimeExtended } from "../../utilities/parse-date";

// Components
import Post from "../ui/Post";
import RegistrationPostContent from "../registration/RegistrationPostContent";
import InlineSpinner from "../ui/InlineSpinner";
import StatusBox from "../ui/StatusBox";
import InfoOutlineIcon from "mdi-react/InfoOutlineIcon";

// Styles
import colors from "../../style/colors";

const CheersForPeersPosts = (props) => {
  const feedType = props.feedType || feedTypes.all;

  // Redux state
  const { loading, posts, endOfFeed, error } = useSelector((state) => state.registration[feedType]);
  const { user } = useSelector((state) => state.auth);
  const { language: lang } = useSelector((state) => state.language);

  const authorModal = useAuthorModal();

  return (
    <div className={`${componentStyle(props)} ${props.className ? props.className : ""}`} style={props.style}>
      <div className="content-wrapper">
        {/* Content */}
        {posts.map((p) => (
          <Post
            useExpandCollapseLayout={props.layoutMode === postLayoutModes.accordion}
            expandCollapseToggle={
              <div style={{ display: "flex", alignItems: "center" }}>
                {p.author ? (
                  <>
                    {getProfilePictureFromUserObject(p.author)}
                    <div className="info-wrapper" style={{ marginLeft: "0.5rem" }}>
                      <p>{p.author.name}</p>
                      <p className="meta" style={{ color: colors.darkGrey }}>
                        {parseDatetimeExtended(p.date)}
                      </p>
                    </div>
                  </>
                ) : (
                  <p>{parseDatetimeExtended(p.date)}</p>
                )}
              </div>
            }
            key={`cheers-for-peers-${p.id}`}
            disableSwipe={true}
            author={p.author}
            date={format(parse(p.date, "yyyyMMddHHmmss", 0), "do MMMM yyyy", getUserLocale(user))}
            disableLikesAndComments={true}
            onAuthorClick={() => authorModal(p.author.id)}
            componentContent={<RegistrationPostContent answers={p.answers} />}
          />
        ))}

        {/* Loading */}
        {loading && !error && !endOfFeed && (
          <InlineSpinner style={{ margin: "2rem 0" }} title={`${lang.loading} ${lang.posts}...`} />
        )}

        {/* End of feed with posts */}
        {posts.length > 0 && endOfFeed && (
          <StatusBox
            style={{ marginBottom: "4rem", marginTop: "1rem" }}
            icon={<InfoOutlineIcon />}
            title={lang.noMorePosts}
            content={lang.reachedEndOfFeed}
          />
        )}

        {/* End of feed without posts */}
        {posts.length === 0 && endOfFeed && (
          <StatusBox
            style={{ marginBottom: "4rem", marginTop: "1rem" }}
            icon={<InfoOutlineIcon />}
            title={lang.noPostsHere}
            content={lang.comeBackSoon}
          />
        )}

        {/* Error message */}
        {error && !loading && (
          <StatusBox
            style={{ marginBottom: "4rem", marginTop: "1rem" }}
            icon={<InfoOutlineIcon />}
            title={lang.errorGeneral}
            content={lang.errorCouldNotGetPosts}
          />
        )}
      </div>
    </div>
  );
};

const componentStyle = () => css`
  display: flex;
  flex-direction: column;
  align-items: center;
  width: 100%;

  div.content-wrapper {
    width: 100%;
    margin-top: 0.5rem;
  }
`;

export default CheersForPeersPosts;
