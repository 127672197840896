import React from "react";
import { css } from "emotion";
import getProfilePictureFromUserObject from "../../utilities/get-profile-picture-from-user-object";
import colors from "../../style/colors";
import breakpoints from "../../config/breakpoints";

const ListItemSkeleton = (props) => {
  const { title = true, subTitle = true, elementLeft = true, elementRight = true, profilePicture = true, index = 1 } = props;
  return (
    <div className={componentStyle()}>
      {elementLeft && <div className="element-left"></div>}
      {profilePicture && (
        <div className="profile-picture" style={{ animationDelay: `${index * 1}ms` }}>
          {getProfilePictureFromUserObject({}, 36, { marginRight: "0.5rem", marginBottom: "-3px" })}
        </div>
      )}
      <div className="fake-title-container">
        {title && (
          <div className="fake-title header" style={{ animationDelay: `${index * 10}ms` }}>
            <div className="tile tile-1"></div>
            <div className="tile tile-2"></div>
            <div className="tile tile-3"></div>
          </div>
        )}
        {subTitle && (
          <div className="fake-title sub-header" style={{ animationDelay: `${index * 20}ms` }}>
            <div className="tile tile-1"></div>
            <div className="tile tile-2"></div>
            <div className="tile tile-3"></div>
          </div>
        )}
      </div>
      {elementRight && <div className="element-right"></div>}
    </div>
  );
};

const ListItemSkeletonGroup = (props) => (
  <>
    <ListItemSkeleton {...props} index={1} />
    <ListItemSkeleton {...props} index={2} />
    <ListItemSkeleton {...props} index={3} />
    <ListItemSkeleton {...props} index={4} />
    <ListItemSkeleton {...props} index={5} />
    <ListItemSkeleton {...props} index={6} />
    <ListItemSkeleton {...props} index={7} />
    <ListItemSkeleton {...props} index={8} />
    <ListItemSkeleton {...props} index={9} />
    <ListItemSkeleton {...props} index={10} />
  </>
);

const componentStyle = (props) => css`
  background-color: ${colors.white};
  margin-bottom: -1px;
  background-color: ${colors.white};
  border-top: 1px solid ${colors.midGrey};
  border-bottom: 1px solid ${colors.midGrey};
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 1rem;
  flex-shrink: 0;
  max-width: ${breakpoints.lg}px;

  @media screen and (min-width: ${breakpoints.lg}px) {
    max-width: ${breakpoints.lg}px;
    margin-left: auto !important;
    margin-right: auto !important;
    border: 1px solid ${colors.midGrey};
  }

  &:first-of-type {
    border-top-left-radius: 3px;
    border-top-right-radius: 3px;
  }
  &:last-of-type {
    border-bottom-left-radius: 3px;
    border-bottom-right-radius: 3px;
  }
  .element-left,
  .element-right {
    opacity: 0.2;
    background-color: ${colors.darkGrey};
    border-radius: 4px;
    margin-right: 0.45rem;
    width: 25px;
    height: 15px;
    animation: blink 1.8s infinite;
  }

  .element-left {
    margin-right: 0.45rem;
  }

  .profile-picture {
    opacity: 0.2;
    animation: blink 1.8s infinite;
  }

  .fake-title-container {
    flex-grow: 1;
  }

  .fake-title {
    display: flex;
    opacity: 0.2;
    animation: blink 1.8s infinite;

    .tile {
      background-color: ${colors.darkGrey};
      border-radius: 4px;
      margin-right: 0.45rem;
    }

    &.header {
      margin-bottom: 0.9rem;

      .tile-1 {
        width: 50px;
        height: 7px;
      }
      .tile-2 {
        width: 35px;
        height: 7px;
      }
      .tile-3 {
        width: 80px;
        height: 7px;
      }
    }
    &.sub-header {
      .tile-1 {
        width: 40px;
        height: 4px;
      }
      .tile-2 {
        width: 55px;
        height: 4px;
      }
      .tile-3 {
        width: 30px;
        height: 4px;
      }
    }
  }

  @keyframes blink {
    0%,
    60% {
      opacity: 0.2;
    }

    70% {
      opacity: 0.4;
    }

    100% {
      opacity: 0.2;
    }
  }
`;

export { ListItemSkeletonGroup, ListItemSkeleton };
