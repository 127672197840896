// Config
import pageConfig from "../../../config/pageConfig";

export function getSubTitle({ contact, config }) {
  let str = "";

  if (contact.application) str += `${contact.application.title}, `;

  if (((config && config[pageConfig.HIDE_USER_GROUP] !== true) || !config) && contact.userGroup) {
    str += `${contact.userGroup.title}, `;
  }

  if (((config && config[pageConfig.HIDE_MASTER_GROUP] !== true) || !config) && contact.masterGroup) {
    str += `${contact.masterGroup.title}, `;
  }

  if (contact.contactGroup) str += `${contact.contactGroup.title || contact.contactGroup.groupName || ""}, `;
  if (contact.contactPersonTitle) str += `${contact.contactPersonTitle}, `;

  // Replaces trailing ", " with ""
  str = str.replace(/, $/gm, "");

  return str;
}
