// Libs
import React, { useState } from "react";
import { useDispatch, useSelector } from "react-redux";

// Utilities
import getPageFromId from "../../../utilities/get-page-from-id";

// Components
import Page from "../../ui/Page";
import TopBar from "../../ui/TopBar";
import TabBar from "../../ui/TabBar";
import TabView from "../../ui/TabView";
import EquipmentBookingSingleDetailsTab from "./EquipmentBookingSingleDetailsTab";
import EquipmentBookingSingleHistoryTab from "./EquipmentBookingSingleHistoryTab";
import EquipmentBookingSingleTimes from "./EquipmentBookingSingleTimes";
import { ArrowLeftIcon, BookOpenVariantIcon, ClipboardTextOutlineIcon, InformationOutlineIcon } from "mdi-react";
import { disableResetStateTemporarily } from "../../../actions/equipmentBookingActions";
import ActionWrapper from "../../ui/ActionWrapper";
import pageNavigatorV2 from "../../../utilities/page-navigator-v2";

function EquipmentBookingSingle(props) {
  const dispatch = useDispatch();
  const { pages } = useSelector((state) => state.pages);
  const [page] = useState(getPageFromId(pages, props.match.params.pageId));
  const tabs = [
    <>
      <InformationOutlineIcon /> Info
    </>,
    <>
      <ClipboardTextOutlineIcon /> Bookninger
    </>,
    <>
      <BookOpenVariantIcon /> Historik
    </>,
  ];
  const [activeTabIndex, setActiveTabIndex] = useState(0);

  return (
    <Page>
      <TopBar
        actionLeft={
          <ActionWrapper
            onClick={() => {
              dispatch(disableResetStateTemporarily());
              pageNavigatorV2({ mode: "pop" });
            }}
          >
            <ArrowLeftIcon />
          </ActionWrapper>
        }
        title={page.title}
      />
      <TabBar
        tabs={tabs.map((tab, index) => ({ title: tab, onClick: () => setActiveTabIndex(index) }))}
        activeTabIndex={activeTabIndex}
      />

      <TabView
        activeTabIndex={activeTabIndex}
        tabs={[
          <EquipmentBookingSingleDetailsTab {...props} />,
          <EquipmentBookingSingleTimes {...props} />,
          <EquipmentBookingSingleHistoryTab {...props} />,
        ]}
      />
    </Page>
  );
}

export default EquipmentBookingSingle;
