import React from "react";
import { css } from "emotion";
import colors from "../../../style/colors";

const SkeletonLine = ({ width = 100, height = 8, animationDelay = 0, style = {} }) => (
  <div className={componentStyle({ width, height, animationDelay })} style={style}></div>
);

const componentStyle = ({ width, height, animationDelay }) => css`
  width: ${width}px;
  height: ${height}px;
  background-color: ${colors.darkGrey};
  border-radius: 2px;
  opacity: 0.2;
  animation: blink 1.5s infinite;
  animation-delay: ${animationDelay}ms;

  @keyframes blink {
    0%,
    60% {
      opacity: 0.2;
    }

    70% {
      opacity: 0.4;
    }

    100% {
      opacity: 0.2;
    }
  }
`;

export default SkeletonLine;
