// Libraries
import React from "react";
import { connect, useSelector, useDispatch } from "react-redux";
import { bindActionCreators } from "redux";
import { hideModalPage, addToast, updateModalPage } from "../../../actions/uiActions";
import {
  showFilters,
  hideFilters,
  clearAllFilters,
  setSelectedMasterGroup,
  setSelectedUserGroup,
} from "../../../actions/targetGroupModalActions";
import { CSSTransition } from "react-transition-group";

// Styles
import colors from "../../../style/colors";

// Components
import ActionWrapper from "../../ui/ActionWrapper";
import ScrollView from "../../ui/ScrollView";

// Utils
import { css } from "emotion";
import { CloseIcon, InfoOutlineIcon, TrashCanOutlineIcon } from "mdi-react";
import DropDownList from "../../ui/DropDownList";
import Button from "../../ui/Button";
import Overlay from "../../ui/Overlay";
import common from "../../../style/common";
import breakpoints from "../../../config/breakpoints";

function TargetGroupFilters(props) {
  const primaryColor = useSelector((s) => s.appConfig.primaryColor);
  const { showFilters, masterGroups, userGroups, selectedUserGroupId, selectedMasterGroupId, overviewUsers } = useSelector(
    (s) => s.targetGroupModal
  );
  const lang = useSelector((s) => s.language.language);
  const dispatch = useDispatch();

  return (
    <>
      <CSSTransition in={showFilters} timeout={400} mountOnEnter={true} unmountOnExit={true} classNames="social-filters">
        <div className={componentStyles(primaryColor)}>
          <div className="top-bar">
            <p className="title">{lang.filters}</p>
            <ActionWrapper onClick={props.hideFilters}>
              <CloseIcon />
            </ActionWrapper>
          </div>

          <ScrollView className="filters-content" style={{ padding: "1rem" }}>
            <div className="content-wrapper">
              <label>{lang.mainGroup}</label>
              <DropDownList
                style={{ marginBottom: "1.5rem" }}
                placeholder={`${lang.all} ${lang.mainGroups}`}
                stateless={true}
                selectedContentId={selectedMasterGroupId}
                dropDownListContent={[{ id: "", title: `${lang.all} ${lang.mainGroups}` }, ...masterGroups]}
                onChange={(e) => void dispatch(setSelectedMasterGroup(e.target.value))}
              />

              <label>{lang.subGroup}</label>
              <DropDownList
                style={{ marginBottom: "1.5rem" }}
                placeholder={`${lang.all} ${lang.subGroups}`}
                stateless={true}
                selectedContentId={selectedUserGroupId}
                dropDownListContent={[{ id: "", title: `${lang.all} ${lang.subGroups}` }, ...userGroups]}
                onChange={(e) => void dispatch(setSelectedUserGroup(e.target.value))}
              />

              <p className="meta" style={{ color: colors.darkGrey }}>
                <InfoOutlineIcon style={{ width: "1.15rem", height: "1.15rem", marginBottom: "-3px" }} />{" "}
                {overviewUsers.loading ? `${lang.loading}...` : `${overviewUsers.count} ${lang.users} ${lang.matches}`}
              </p>
              <Button buttonType="tertiary" style={{ marginTop: "2rem" }} onClick={props.hideFilters}>
                Use filters
              </Button>
              <Button buttonType="secondary" style={{ marginTop: "0.5rem" }} onClick={() => dispatch(clearAllFilters())}>
                <TrashCanOutlineIcon style={{ height: "1.25rem", width: "1.25rem", marginBottom: "-0.25rem" }} />{" "}
                {lang.clear} {lang.all.toLowerCase()} {lang.filters.toLowerCase()}
              </Button>
            </div>
          </ScrollView>
        </div>
      </CSSTransition>
      <Overlay active={showFilters} onClick={props.hideFilters} style={{ zIndex: 2 }} />
    </>
  );
}

const componentStyles = (primaryColor) => css`
  position: absolute;
  left: 0;
  top: 0;
  width: 100%;
  max-height: 100%;
  z-index: 3;
  color: ${colors.black};
  display: flex;
  flex-direction: column;
  transform-origin: top;

  @media screen and (min-width: ${breakpoints.lg}px) {
    .content-wrapper {
      max-width: ${breakpoints.lg}px;
      margin: 0 auto;
    }
  }

  .top-bar {
    display: flex;
    justify-content: space-between;
    color: ${primaryColor};
    border-bottom: 1px ${colors.lightGrey} solid;
    height: ${common.topBarHeight}px;
    background-color: ${colors.white};
    z-index: 1;

    p {
      line-height: ${common.topBarHeight}px;
      padding-left: 1rem;
    }

    svg {
      width: ${common.topBarHeight}px;
      height: ${common.topBarHeight}px;
      padding: 0.65rem;
    }
  }

  .filters-content {
    background-color: ${colors.white};
  }

  .title {
    font-weight: bold;
    margin-bottom: 1rem;
  }

  label {
    color: ${colors.darkGrey};
    margin-bottom: 0.75rem;
    display: block;
  }

  .checkbox-element {
    display: flex;
    color: ${colors.darkGrey};
    margin-bottom: 1.5rem;

    svg {
      margin-right: 0.75rem;
    }
  }

  /* Top-bar animation */
  &.social-filters-enter .top-bar {
    opacity: 0;
    transform: translateX(100%);
  }
  &.social-filters-enter-active .top-bar {
    opacity: 1;
    transform: translateX(0px);
    transition: opacity 200ms ease, transform 200ms ease;
  }
  &.social-filters-exit .top-bar {
    opacity: 1;
    transform: translateX(0px);
  }
  &.social-filters-exit-active .top-bar {
    opacity: 0;
    transform: translateX(100%);
    transition: opacity 300ms ease, transform 300ms ease;
    transition-delay: 100ms;
  }

  /* filters-content animation */
  &.social-filters-enter .filters-content {
    opacity: 0;
    transform: translateY(-100%);
  }
  &.social-filters-enter-active .filters-content {
    opacity: 1;
    transform: translateY(0);
    transition: transform 200ms ease, opacity 200ms ease;
    transition-delay: 100ms;
  }
  &.social-filters-exit .filters-content {
    opacity: 1;
    transform: translateY(0);
  }
  &.social-filters-exit-active .filters-content {
    opacity: 0;
    transform: translateY(-100%);
    transition: transform 200ms ease, opacity 200ms ease;
  }
`;

const mapDispatchToProps = (dispatch) => ({
  updateModalPage: bindActionCreators(updateModalPage, dispatch),
  hideModalPage: bindActionCreators(hideModalPage, dispatch),
  addToast: bindActionCreators(addToast, dispatch),
  showFilters: bindActionCreators(showFilters, dispatch),
  hideFilters: bindActionCreators(hideFilters, dispatch),
});

export default connect(null, mapDispatchToProps)(TargetGroupFilters);
