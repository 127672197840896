// Libs
import React from "react";
import { css } from "emotion";
import { connect, useSelector } from "react-redux";
import { bindActionCreators } from "redux";

// Components
import ActionWrapper from "./ActionWrapper";
import { ThumbsUpIcon, ThumbsUpOutlineIcon } from "mdi-react";
import InlineSpinner from "./InlineSpinner";

// styles
import colors from "../../style/colors";

// Actions
import { refreshPostCommentLikes } from "../../actions/socialActions";

const CommentLikeButton = (props) => {
  // Props
  const { onCommentLike, comment, submittingLikedOnCommentId, isCommentLiked } = props;

  // Redux state
  const { primaryColor } = useSelector((state) => state.appConfig);

  // Is the comment liked
  let isLiked = isCommentLiked(comment);

  return (
    <div className={componentStyle(isLiked, primaryColor)}>
      <ActionWrapper onClick={() => onCommentLike(comment)} disableFocusStyle={true}>
        <div className="comment-like">
          {isLiked && submittingLikedOnCommentId !== comment.id && <ThumbsUpIcon />}
          {!isLiked && submittingLikedOnCommentId !== comment.id && <ThumbsUpOutlineIcon />}
          {isLiked && submittingLikedOnCommentId === comment.id && (
            <InlineSpinner size="17" style={{ marginBottom: "-0.5rem" }} color={colors.white} />
          )}
          {!isLiked && submittingLikedOnCommentId === comment.id && (
            <InlineSpinner size="17" style={{ marginBottom: "-0.5rem" }} color={colors.darkGrey} />
          )}
          {submittingLikedOnCommentId !== comment.id && comment.likes && comment.likes.length > 99 && <p>{"99+"}</p>}
          {submittingLikedOnCommentId !== comment.id &&
            comment.likes &&
            comment.likes.length > 0 &&
            comment.likes.length <= 99 && <p>{comment.likes.length}</p>}
        </div>
      </ActionWrapper>
    </div>
  );
};

const height = 24;

const componentStyle = (liked, primaryColor) => css`
  div.comment-like {
    display: flex;
    align-items: center;
    border-radius: ${height / 2}px;
    height: ${height}px;
    padding: 0.2rem 0.2rem !important;
    border: 1px solid;
    margin: 0 0.25rem;
    background-color: ${liked ? primaryColor : colors.white};
    border-color: ${liked ? primaryColor : colors.midGrey};

    p {
      color: ${liked ? colors.white : colors.darkGrey};
      line-height: ${height}px;
      transition: color 220ms ease;
      font-size: 0.75rem;
      margin-right: 0.25rem;
      font-weight: bold;
    }

    svg {
      fill: ${liked ? colors.white : colors.darkGrey};
      transition: color 220ms ease;
      width: 0.85rem;
      height: 0.85rem;
    }
  }
`;

const mapDispatchToProps = (dispatch) => ({
  refreshPostCommentLikes: bindActionCreators(refreshPostCommentLikes, dispatch),
});

export default connect(null, mapDispatchToProps)(CommentLikeButton);
