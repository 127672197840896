import React, { useEffect } from "react";
import TextareaInput from "../ui/TextareaInput";
import req from "../../utilities/request-utility";
import DatePicker from "../ui/DatePicker";
import { css } from "emotion";
import breakpoints from "../../config/breakpoints";
import Button from "../ui/Button";
import { useState } from "react";
import { addDays, parse, format } from "date-fns/esm";
import { useDispatch } from "react-redux";
import { addToast, hideDialog, hideModalPage } from "../../actions/uiActions";
import TimePicker from "../ui/TimePicker";

function EquipmentBookingStartBookingModal({ equipmentBookingId, equipmentId, onFinishedBooking, editData = undefined }) {
  const dispatch = useDispatch();
  const [processingBooking, setProcessingBooking] = useState(false);

  let potentialParsedStartDate = false;
  let potentialParsedEndDate = false;

  try {
    potentialParsedStartDate = parse(editData.bookingStartDate, "yyyyMMddHHmm", 0);
    potentialParsedEndDate = parse(editData.bookingEndDate, "yyyyMMddHHmm", 0);
  } catch {}

  const [formData, setFormData] = useState({
    comment: editData && editData.bookingStartComment ? editData.bookingStartComment : "",
    bookingStartDate: format(potentialParsedStartDate ? potentialParsedStartDate : new Date(), "yyyy-MM-dd"),
    bookingStartTime: potentialParsedStartDate ? format(potentialParsedStartDate, "HH:mm") : format(new Date(), "HH:mm"),
    bookingEndDate: format(potentialParsedEndDate ? potentialParsedEndDate : addDays(new Date(), 1), "yyyy-MM-dd"),
    bookingEndTime: potentialParsedEndDate ? format(potentialParsedEndDate, "HH:mm") : format(new Date(), "HH:mm"),
  });

  function bookEquipment() {
    setProcessingBooking(true);

    // prettier-ignore
    let startTime = `${format(parse(formData.bookingStartDate, "yyyy-MM-dd", 0),"yyyyMMdd")}${formData.bookingStartTime.replace(":", "")}`;
    // prettier-ignore
    let endTime = `${format(parse(formData.bookingEndDate, "yyyy-MM-dd", 0),"yyyyMMdd")}${formData.bookingEndTime.replace(":", "")}`;

    let url = "";

    if (editData) {
      url = `equipment-booking/${equipmentBookingId}/equipment/${equipmentId}/bookings/${editData.id}`;
    } else {
      url = `equipment-booking/${equipmentBookingId}/equipment/${equipmentId}/bookings`;
    }

    req()
      .put(url, {
        comment: formData.comment,
        bookingStartDate: startTime,
        bookingEndDate: endTime,
      })
      .then(() => onFinishedBooking())
      .catch((err) => {
        console.log(err.response.data.code);
        if (err && err.response && err.response.data && err.response.data.code === "EQUIPMENT_ALREADY_BOOKED") {
          dispatch(
            addToast({
              duration: 10000,
              title: "Udstyret er allerede booket",
              content:
                "Udstyret er ikke tilgængeligt i de dage du prøver at booke det. Du kan finde en oversigt over eksisterende bookninger under fanen 'Tider'.",
            })
          );
        } else {
          dispatch(addToast({ template: "error" }));
        }

        setProcessingBooking(false);
      });
  }

  return (
    <div className={componentStyles()}>
      <label htmlFor="bookingStartDate">Hvornår skal du bruge udstyret?</label>

      <div className="wrapper" style={{ marginBottom: "2rem" }}>
        <DatePicker
          name="bookingStartDate"
          value={formData.bookingStartDate}
          onChange={(e) => setFormData({ ...formData, [e.target.name]: e.target.value })}
          id="bookingStartDate"
          className="date-picker"
          style={{ height: "42px", marginRight: "0.5rem" }}
        />
        <TimePicker
          className="time-picker"
          style={{ height: "42px" }}
          selectedValue={formData.bookingStartTime}
          onSelect={(e) => setFormData({ ...formData, bookingStartTime: e })}
        />
      </div>
      <label htmlFor="bookingEndDate">Hvornår afleverer du udstyret tilbage?</label>
      <div className="wrapper" style={{ marginBottom: "2rem" }}>
        <DatePicker
          className="date-picker"
          name="bookingEndDate"
          value={formData.bookingEndDate}
          onChange={(e) => setFormData({ ...formData, [e.target.name]: e.target.value })}
          id="bookingEndDate"
          style={{ height: "42px", marginRight: "0.5rem" }}
        />
        <TimePicker
          className="time-picker"
          style={{ height: "42px" }}
          selectedValue={formData.bookingEndTime}
          onSelect={(e) => setFormData({ ...formData, bookingEndTime: e })}
        />
      </div>
      <label htmlFor="bookingCommentInput">Kommentar til din booking</label>
      <TextareaInput
        name="comment"
        style={{ marginBottom: "3.5rem" }}
        value={formData.comment}
        onChange={(e) => setFormData({ ...formData, [e.target.name]: e.target.value })}
        id="bookingCommentInput"
      ></TextareaInput>

      <Button active={processingBooking} onClick={bookEquipment} style={{ marginBottom: "0.5rem" }}>
        {editData ? "Gem ændringer" : "Book udstyr"}
      </Button>
      <Button buttonType={"secondary"} onClick={() => dispatch(hideModalPage())}>
        Annuller
      </Button>
    </div>
  );
}

const componentStyles = () => css`
  max-width: ${breakpoints.md}px;
  margin: 0 auto;
  padding: 1.5rem 0.65rem 0.65rem 0.65rem;

  .wrapper {
    display: flex;
    justify-content: space-between;
    width: 100%;

    .date-picker {
      flex: 60% 1 1;
    }

    .time-picker {
      flex: 40% 1 1;
    }
  }

  & > label {
    display: inline-block;
    margin-bottom: 0.5rem;
  }
`;

export default EquipmentBookingStartBookingModal;
