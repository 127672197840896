// Side menu
export const OPEN_SIDE_MENU = "OPEN_SIDE_MENU";
export const CLOSE_SIDE_MENU = "CLOSE_SIDE_MENU";

// Language
export const GET_LANGUAGE = "GET_LANGUAGE";
export const GET_LANGUAGE_SUCCESS = "GET_LANGUAGE_SUCCESS";
export const GET_LANGUAGE_FAILURE = "GET_LANGUAGE_FAILURE";

// Pages
export const GET_PAGES = "GET_PAGES";
export const GET_PAGES_SUCCESS = "GET_PAGES_SUCCESS";
export const GET_PAGES_FAILURE = "GET_PAGES_FAILURE";
export const SET_TRANSITION_DIRECTION = "SET_TRANSITION_DIRECTION";

// Auth
export const UPDATE_USER_DATA = "UPDATE_USER_DATA";
export const SIGN_IN = "SIGN_IN";
export const SIGN_IN_SUCCESS = "SIGN_IN_SUCCESS";
export const SIGN_IN_FAILURE = "SIGN_IN_FAILURE";
export const AD_SIGN_IN_FAILURE = "AD_SIGN_IN_FAILURE";
export const AD_SIGN_IN_FAILURE_HANDLED = "AD_SIGN_IN_FAILURE_HANDLED";
export const SIGN_IN_FAILURE_HANDLED = "SIGN_IN_FAILURE_HANDLED";
export const SIGN_OUT = "SIGN_OUT";
export const CHECK_TOKEN = "CHECK_TOKEN";
export const CHECK_TOKEN_VALID = "CHECK_TOKEN_VALID";
export const CHECK_TOKEN_INVALID = "CHECK_TOKEN_INVALID";
export const CHECK_TOKEN_SERVICE_UNAVAILABLE = "CHECK_TOKEN_SERVICE_UNAVAILABLE";
export const RENEW_TOKEN = "RENEW_TOKEN";
export const RENEW_TOKEN_SUCCESS = "RENEW_TOKEN_SUCCESS";
export const RENEW_TOKEN_FAILURE = "RENEW_TOKEN_FAILURE";
export const RENEW_PASSWORD = "RENEW_PASSWORD";
export const RENEW_PASSWORD_SUCCESS = "RENEW_PASSWORD_SUCCESS";
export const RENEW_PASSWORD_FAILED = "RENEW_PASSWORD_FAILED";
export const RENEW_PASSWORD_HANDLED = "RENEW_PASSWORD_HANDLED";

// App Config
export const GET_APP_CONFIG = "GET_APP_CONFIG";
export const GET_APP_CONFIG_SUCCESS = "GET_APP_CONFIG_SUCCESS";
export const GET_APP_CONFIG_FAILURE = "GET_APP_CONFIG_FAILURE";
export const SET_APP_LANGUAGE = "SET_APP_LANGUAGE";
export const CLEAR_APP_CONFIG_LOGIN_TYPES = "CLEAR_APP_CONFIG_LOGIN_TYPES";

/******************************* News ****************************/

// Main feed functionality
export const GET_NEWS_POSTS = "GET_NEWS_POSTS";
export const GET_NEWS_POSTS_FAILURE = "GET_NEWS_POSTS_FAILURE";
export const GET_NEWS_POSTS_SUCCESS = "GET_NEWS_POSTS_SUCCESS";
export const CLEAR_NEWS_FEED = "CLEAR_NEWS_FEED";
export const RESET_NEWS_FEEDS = "RESET_NEWS_FEEDS";
export const RESET_NEWS_FEED = "RESET_NEWS_FEED";
export const SET_END_OF_NEWS_FEED = "SET_END_OF_NEWS_FEED";
export const SET_UNREAD_NEWS_POSTS_COUNT = "SET_UNREAD_NEWS_POSTS_COUNT";

// Single Post operations
export const REFETCH_NEWS_POST = "REFETCH_NEWS_POST";
export const REFETCH_NEWS_POST_FAILURE = "REFETCH_NEWS_POST_FAILURE";
export const REFETCH_NEWS_POST_SUCCESS = "REFETCH_NEWS_POST_SUCCESS";
export const REFETCH_NEWS_POST_COMMENTS = "REFETCH_NEWS_POST_COMMENTS";
export const REFETCH_NEWS_POST_COMMENTS_FAILURE = "REFETCH_NEWS_POST_COMMENTS_FAILURE";
export const REFETCH_NEWS_POST_COMMENTS_SUCCESS = "REFETCH_NEWS_POST_COMMENTS_SUCCESS";
export const REFETCH_NEWS_POST_LIKES = "REFETCH_NEWS_POST_LIKES";
export const REFETCH_NEWS_POST_LIKES_FAILURE = "REFETCH_NEWS_POST_LIKES_FAILURE";
export const REFETCH_NEWS_POST_LIKES_SUCCESS = "REFETCH_NEWS_POST_LIKES_SUCCESS";
export const REFETCH_NEWS_POST_COMMENTS_LIKES = "REFETCH_NEWS_POST_COMMENTS_LIKES";
export const SET_POST_AS_DELETED = "SET_POST_AS_DELETED";
export const UNSET_POST_AS_DELETED = "UNSET_POST_AS_DELETED";
export const REMOVE_POST_FROM_FEED = "REMOVE_POST_FROM_FEED";
export const DELETE_NEWS_POST = "DELETE_NEWS_POST";
export const DELETE_NEWS_POST_FAILURE = "DELETE_NEWS_POST_FAILURE";
export const DELETE_NEWS_POST_SUCCESS = "DELETE_NEWS_POST_SUCCESS";

export const READ_NEWS_POST = "READ_NEWS_POST";
export const READ_NEWS_POST_SUCCESS = "READ_NEWS_POST_SUCCESS";
export const READ_NEWS_POST_FAILURE = "READ_NEWS_POST_FAILURE";

// Post like actions
export const LIKE_NEWS_POST = "LIKE_NEWS_POST";
export const LIKE_NEWS_POST_FAILURE = "LIKE_NEWS_POST_FAILURE";
export const LIKE_NEWS_POST_SUCCESS = "LIKE_NEWS_POST_SUCCESS";
export const UNLIKE_NEWS_POST = "UNLIKE_NEWS_POST";
export const UNLIKE_NEWS_POST_FAILURE = "UNLIKE_NEWS_POST_FAILURE";
export const UNLIKE_NEWS_POST_SUCCESS = "UNLIKE_NEWS_POST_SUCCESS";

// Post comment actions
export const ADD_NEWS_POST_COMMENT = "ADD_NEWS_POST_COMMENT";
export const ADD_NEWS_POST_COMMENT_SUCCESS = "ADD_NEWS_POST_COMMENT_SUCCESS";
export const ADD_NEWS_POST_COMMENT_FAILURE = "ADD_NEWS_POST_COMMENT_FAILURE";

export const DELETE_NEWS_POST_COMMENT = "DELETE_NEWS_POST_COMMENT";
export const DELETE_NEWS_POST_COMMENT_FAILURE = "DELETE_NEWS_POST_COMMENT_FAILURE";
export const DELETE_NEWS_POST_COMMENT_SUCCESS = "DELETE_NEWS_POST_COMMENT_SUCCESS";

/******************************* Social  ****************************/

export const GET_SOCIAL_POSTS = "GET_SOCIAL_POSTS";
export const GET_SOCIAL_POSTS_SUCCESS = "GET_SOCIAL_POSTS_SUCCESS";
export const GET_SOCIAL_POSTS_FAILURE = "GET_SOCIAL_POSTS_FAILURE";
export const REPLACE_SOCIAL_POST = "REPLACE_SOCIAL_POST";
export const CLEAR_SOCIAL_FEED = "CLEAR_SOCIAL_FEED";
export const END_OF_SOCIAL_FEED = "END_OF_SOCIAL_FEED";
export const REFRESH_SOCIAL_POST_COMMENTS = "REFRESH_SOCIAL_POST_COMMENTS";
export const REFRESH_SOCIAL_POST_LIKES = "REFRESH_SOCIAL_POST_LIKES";
export const REFRESH_SOCIAL_POST_COMMENT_LIKES = "REFRESH_SOCIAL_POST_COMMENT_LIKES";
export const SET_SOCIAL_POST_AS_DELETED = "SET_SOCIAL_POST_AS_DELETED";
export const REMOVE_SOCIAL_POST_FROM_FEED = "REMOVE_SOCIAL_POST_FROM_FEED";
export const SET_SOCIAL_CURRENT_DATA_ID = "SET_SOCIAL_CURRENT_DATA_ID";

/******************************* UI  ****************************/
// -> Context menu
export const SHOW_CONTEXT_MENU = "SHOW_CONTEXT_MENU";
export const HIDE_CONTEXT_MENU = "HIDE_CONTEXT_MENU";
export const CLEAR_CONTEXT_MENU_ACTIONS = "CLEAR_CONTEXT_MENU_ACTIONS";

// -> Dialog
export const SHOW_DIALOG = "SHOW_DIALOG";
export const HIDE_DIALOG = "HIDE_DIALOG";
export const CLEAR_DIALOG = "CLEAR_DIALOG";

// -> Lightbox
export const SHOW_LIGHT_BOX = "SHOW_LIGHT_BOX";
export const HIDE_LIGHT_BOX = "HIDE_LIGHT_BOX";

// -> Modal page
export const SHOW_MODAL_PAGE = "SHOW_MODAL_PAGE";
export const UPDATE_MODAL_PAGE_CONTENT = "UPDATE_MODAL_PAGE_CONTENT";
export const HIDE_MODAL_PAGE = "HIDE_MODAL_PAGE";

// -> Toasts
export const ADD_TOAST = "ADD_TOAST";
export const DEACTIVATE_CURRENT_TOAST = "DEACTIVATE_CURRENT_TOAST";
export const SHOW_NEXT_TOAST = "SHOW_NEXT_TOAST";
export const REPLACE_IDENTICAL_TOAST = "REPLACE_IDENTICAL_TOAST";

/******************************* Target Group Modal  ****************************/
export const GET_TARGET_GROUP_USERS = "GET_TARGET_GROUP_USERS";
export const GET_TARGET_GROUP_USERS_SUCCESS = "GET_TARGET_GROUP_USERS_SUCCESS";
export const GET_TARGET_GROUP_USERS_FAILURE = "GET_TARGET_GROUP_USERS_FAILURE";
export const GET_MORE_OVERVIEW_USERS = "GET_MORE_OVERVIEW_USERS";
export const GET_MORE_OVERVIEW_USERS_SUCCESS = "GET_MORE_OVERVIEW_USERS_SUCCESS";
export const GET_MORE_OVERVIEW_USERS_FAILURE = "GET_MORE_OVERVIEW_USERS_FAILURE";
export const SET_OVERVIEW_USERS_END_OF_FEED = "SET_OVERVIEW_USERS_END_OF_FEED";
export const SET_OVERVIEW_USERS_COUNT = "SET_OVERVIEW_USERS_COUNT";
export const CLEAR_OVERVIEW_USERS = "CLEAR_OVERVIEW_USERS";
export const GET_USER_GROUPS = "GET_USER_GROUPS";
export const GET_USER_GROUPS_SUCCESS = "GET_USER_GROUPS_SUCCESS";
export const GET_USER_GROUPS_FAILURE = "GET_USER_GROUPS_FAILURE";
export const GET_MASTER_GROUPS = "GET_MASTER_GROUPS";
export const GET_MASTER_GROUPS_SUCCESS = "GET_MASTER_GROUPS_SUCCESS";
export const GET_MASTER_GROUPS_FAILURE = "GET_MASTER_GROUPS_FAILURE";
export const GET_ACTIVITY_GROUPS = "GET_ACTIVITY_GROUPS";
export const GET_ACTIVITY_GROUPS_SUCCESS = "GET_ACTIVITY_GROUPS_SUCCESS";
export const GET_ACTIVITY_GROUPS_FAILURE = "GET_ACTIVITY_GROUPS_FAILURE";
export const SET_SELECTED_MASTER_GROUP = "SET_SELECTED_MASTER_GROUP";
export const SET_SELECTED_USER_GROUP = "SET_SELECTED_USER_GROUP";
export const SET_SELECTED_ACTIVITY_GROUP = "SET_SELECTED_ACTIVITY_GROUP";
export const ADD_USER_TO_TARGET_GROUP = "ADD_USER_TO_TARGET_GROUP";
export const ADD_USER_TO_TARGET_GROUP_SUCCESS = "ADD_USER_TO_TARGET_GROUP_SUCCESS";
export const ADD_USER_TO_TARGET_GROUP_FAILURE = "ADD_USER_TO_TARGET_GROUP_FAILURE";
export const REMOVE_USER_FROM_TARGET_GROUP = "REMOVE_USER_FROM_TARGET_GROUP";
export const REMOVE_USER_FROM_TARGET_GROUP_SUCCESS = "REMOVE_USER_FROM_TARGET_GROUP_SUCCESS";
export const REMOVE_USER_FROM_TARGET_GROUP_FAILURE = "REMOVE_USER_FROM_TARGET_GROUP_FAILURE";
export const SET_SERCHTERM = "SET_SERCHTERM";
export const SET_CURRENT_PAGE_ID = "SET_CURRENT_PAGE_ID";
export const SHOW_FILTERS = "SHOW_FILTERS";
export const HIDE_FILTERS = "HIDE_FILTERS";
export const SHOW_TARGET_GROUP = "SHOW_TARGET_GROUP";
export const HIDE_TARGET_GROUP = "HIDE_TARGET_GROUP";

/******************************* Contacts  ****************************/

export const GET_CONTACT_GROUP_NAMES_STARTED = "GET_CONTACT_GROUP_NAMES_STARTED";
export const GET_CONTACT_GROUP_NAMES_SUCCESS = "GET_CONTACT_GROUP_NAMES_SUCCESS";
export const GET_CONTACT_GROUP_NAMES_FAILURE = "GET_CONTACT_GROUP_NAMES_FAILURE";
export const CLEAR_CONTACT_GROUP_NAMES = "CLEAR_CONTACT_GROUP_NAMES";

export const GET_CONTACTS_STARTED = "GET_CONTACTS_STARTED";
export const GET_CONTACTS_SUCCESS = "GET_CONTACTS_SUCCESS";
export const GET_CONTACTS_FAILURE = "GET_CONTACTS_FAILURE";
export const CONTACTS_END_OF_FEED = "CONTACTS_END_OF_FEED";
export const CLEAR_CONTACTS = "CLEAR_CONTACTS";
export const CLEAR_ALL_CONTACTS = "CLEAR_ALL_CONTACTS";
export const SET_RELATED_APPS_CONTACTS = "SET_RELATED_APPS_CONTACTS";
export const SET_CONTACT_FILTERS_VALUE = "SET_CONTACT_FILTERS_VALUE";
export const SET_CONTACT_FILTERS_ACTIVE_STATE = "SET_CONTACT_FILTERS_ACTIVE_STATE";
export const SET_CONTACT_PREVIOUS_STATE = "SET_CONTACT_PREVIOUS_STATE";

// -> Tab bar navigation
export const SET_ACTIVE_TAB = "SET_ACTIVE_TAB";

// UI

// SEARCH PAGE MODAL
export const SET_SEARCH_TERM = "SET_SEARCH_TERM";
export const SET_END_OF_FEED_VALUE = "SET_END_OF_FEED_VALUE";
export const SET_SEARCH_PAGE_OFFSET = "SET_SEARCH_PAGE_OFFSET";
export const GET_SEARCH_RESULTS = "GET_SEARCH_RESULTS";
export const GET_SEARCH_RESULTS_SUCCESS = "GET_SEARCH_RESULTS_SUCCESS";
export const GET_SEARCH_RESULTS_FAILURE = "GET_SEARCH_RESULTS_FAILURE";

// ACADEMY MODULE
export const GET_QUIZZES_OVERVIEW_SUCCESS = "GET_QUIZZES_OVERVIEW_SUCCESS";
export const GET_QUIZZES_OVERVIEW_FAILED = "GET_QUIZZES_OVERVIEW_FAILED";

export const GET_CHALLENGE_OVERVIEW_SUCCESS = "GET_CHALLENGE_OVERVIEW_SUCCESS";
export const GET_CHALLENGE_OVERVIEW_FAILED = "GET_CHALLENGE_OVERVIEW_FAILED";

export const GET_CERTIFICATE_OVERVIEW_SUCCESS = "GET_CERTIFICATE_OVERVIEW_SUCCESS";
export const GET_CERTIFICATE_OVERVIEW_FAILED = "GET_CERTIFICATE_OVERVIEW_FAILED";

// REGISTRATION MODULE
// --> Get registration posts
export const GET_REGISTRATION_POSTS = "GET_REGISTRATION_POSTS";
export const GET_REGISTRATION_POSTS_SUCCESS = "GET_REGISTRATION_POSTS_SUCCESS";
export const GET_REGISTRATION_POSTS_FAILURE = "GET_REGISTRATION_POSTS_FAILURE";
export const END_OF_REGISTRATIONS_POSTS = "END_OF_REGISTRATIONS_POSTS";

export const READ_REGISTRATION_POST = "READ_REGISTRATION_POST";
export const READ_REGISTRATION_POST_FAILURE = "READ_REGISTRATION_POST_FAILURE";

export const REFETCH_REGISTRATION_POST_SUCCESS = "REFETCH_REGISTRATION_POST_SUCCESS";
export const REFETCH_REGISTRATION_POST_FAILURE = "REFETCH_REGISTRATION_POST_FAILURE";

export const REFETCH_REGISTRATION_POST_LIKES_SUCCESS = "REFETCH_REGISTRATION_POST_LIKES_SUCCESS";
export const REFETCH_REGISTRATION_POST_LIKES_FAILURE = "REFETCH_REGISTRATION_POST_LIKES_FAILURE";

export const UNLIKE_REGISTRATION_POST = "UNLIKE_REGISTRATION_POST";
export const UNLIKE_REGISTRATION_POST_FAILURE = "UNLIKE_REGISTRATION_POST_FAILURE";

export const LIKE_REGISTRATION_POST = "LIKE_REGISTRATION_POST";
export const LIKE_REGISTRATION_POST_FAILURE = "LIKE_REGISTRATION_POST_FAILURE";

export const RESET_REGISTRATION = "RESET_REGISTRATION";
export const RESET_REGISTRATION_FEED = "RESET_REGISTRATION_FEED";

export const GET_REGISTRATION_POSTS_STATISTICS_SUCCESS = "GET_REGISTRATION_POSTS_STATISTICS_SUCCESS";

// UI academy
export const SET_QUIZ_LOADING = "SET_QUIZ_LOADING";
export const SET_CHALLENGE_LOADING = "SET_CHALLENGE_LOADING";
export const SET_CERTIFICATE_LOADING = "SET_CERTIFICATE_LOADING";

// ANNIVERSARY MODULE
export const GET_ANNIVERSARIES = "GET_ANNIVERSARIES";
export const GET_ANNIVERSARIES_SUCCESS = "GET_ANNIVERSARIES_SUCCESS";
export const END_OF_ANNIVERSARIES_FEED = "END_OF_ANNIVERSARIES_FEED";
export const GET_ANNIVERSARIES_FAILURE = "GET_ANNIVERSARIES_FAILURE";
export const RESET_ANNIVERSARY = "RESET_ANNIVERSARY";

// CHECKLIST
export const GET_CHECKLISTS = "GET_CHECKLISTS";
export const GET_CHECKLISTS_SUCCESS = "GET_CHECKLISTS_SUCCESS";
export const GET_CHECKLISTS_FAILURE = "GET_CHECKLISTS_FAILURE";
export const SET_CHECKLIST_TAB = "SET_CHECKLIST_TAB";

// BIRTHDAY MODULE
export const GET_BIRTHDAYS = "GET_BIRTHDAYS";
export const GET_BIRTHDAYS_SUCCESS = "GET_BIRTHDAYS_SUCCESS";
export const GET_BIRTHDAYS_FAILURE = "GET_BIRTHDAYS_FAILURE";
export const SET_NEW_BIRTHDAY_YEAR = "SET_NEW_BIRTHDAY_YEAR";
export const SET_IS_NO_BIRTHDAYS = "SET_IS_NO_BIRTHDAYS";
export const RESET_BIRTHDAYS = "RESET_BIRTHDAYS";
export const SET_RELATED_APPS_BIRTHDAYS = "SET_RELATED_APPS_BIRTHDAYS";
export const SET_BIRTHDAYS_FILTER_VALUE = "SET_BIRTHDAYS_FILTER_VALUE";

// EVENTS
export const GET_EVENTS = "GET_EVENTS";
export const GET_EVENTS_SUCCESS = "GET_EVENTS_SUCCESS";
export const GET_EVENTS_FAILURE = "GET_EVENTS_FAILURE";
export const END_OF_EVENTS = "END_OF_EVENTS";
export const RESET_EVENTS = "RESET_EVENTS";

export const GET_EVENT = "GET_EVENT";
export const GET_EVENT_SUCCESS = "GET_EVENT_SUCCESS";
export const GET_EVENT_FAILURE = "GET_EVENT_FAILURE";
export const RESET_EVENT = "RESET_EVENT";

export const ATTEND_EVENT = "ATTEND_EVENT";
export const ATTEND_EVENT_SUCCESS = "ATTEND_EVENT_SUCCESS";
export const ATTEND_EVENT_FAILURE = "ATTEND_EVENT_FAILURE";

export const DELETE_ATTEND_EVENT = "DELETE_ATTEND_EVENT";
export const DELETE_ATTEND_EVENT_SUCCESS = "DELETE_ATTEND_EVENT_SUCCESS";
export const DELETE_ATTEND_EVENT_FAILURE = "DELETE_ATTEND_EVENT_FAILURE";

export const GET_EVENT_ATTENDEES = "GET_EVENT_ATTENDEES";
export const GET_EVENT_ATTENDEES_SUCCESS = "GET_EVENT_ATTENDEES_SUCCESS";
export const GET_EVENT_ATTENDEES_FAILURE = "GET_EVENT_ATTENDEES_FAILURE";

// ThemeQuiz
export const GET_ACTIVE_THEME_QUIZZES_SUCCESS = "GET_ACTIVE_THEME_QUIZZES_SUCCESS";
export const SHOW_THEME_QUIZ_FLOATING_ACTION_BUTTON = "SHOW_THEME_QUIZ_FLOATING_ACTION_BUTTON";
export const HIDE_THEME_QUIZ_FLOATING_ACTION_BUTTON = "HIDE_THEME_QUIZ_FLOATING_ACTION_BUTTON";
export const CLEAR_THEME_QUIZ_FLOATING_ACTION_BUTTON = "CLEAR_THEME_QUIZ_FLOATING_ACTION_BUTTON";

// Equipment booking
export const GET_ALL_EQUIPMENT = "GET_ALL_EQUIPMENT";
export const GET_ALL_EQUIPMENT_SUCCESS = "GET_ALL_EQUIPMENT_SUCCESS";
export const GET_ALL_EQUIPMENT_FAILURE = "GET_ALL_EQUIPMENT_FAILURE";
export const SET_ALL_EQUIPMENT_END_OF_FEED = "SET_ALL_EQUIPMENT_END_OF_FEED";
export const RESET_EQUIPMENT_BOOKING_STATE = "RESET_EQUIPMENT_BOOKING_STATE";
export const GET_MY_EQUIPMENT = "GET_MY_EQUIPMENT";
export const GET_MY_EQUIPMENT_SUCCESS = "GET_MY_EQUIPMENT_SUCCESS";
export const GET_MY_EQUIPMENT_FAILURE = "GET_MY_EQUIPMENT_FAILURE";
export const SET_MY_EQUIPMENT_END_OF_FEED = "SET_MY_EQUIPMENT_END_OF_FEED";
export const SET_MY_EQUIPMENT_COUNT = "SET_MY_EQUIPMENT_COUNT";
export const SET_CURRENT_EQUIPMENT_BOOKING_ID = "SET_CURRENT_EQUIPMENT_BOOKING_ID";
export const SET_EQUIPMENT_CATEGORIES = "SET_EQUIPMENT_CATEGORIES";
export const SET_EQUIPMENT_FILTER_VALUE = "SET_EQUIPMENT_FILTER_VALUE";
export const REPLACE_ALL_EQUIPMENT_DATA = "REPLACE_ALL_EQUIPMENT_DATA";
export const SET_EQUIPMENT_SEARCHTERM = "SET_EQUIPMENT_SEARCHTERM";
export const SET_PREVENT_STATE_RESET = "SET_PREVENT_STATE_RESET";
