// Libraries
import React from "react";
import { useSelector } from "react-redux";
import { CSSTransition } from "react-transition-group";

// Styles
import colors from "../../style/colors";

// Components
import ActionWrapper from "../ui/ActionWrapper";
import ScrollView from "../ui/ScrollView";

// Utils
import { css } from "emotion";
import { CloseIcon, TrashCanOutlineIcon } from "mdi-react";
import DropDownList from "../ui/DropDownList";
import Button from "../ui/Button";
import Overlay from "../ui/Overlay";
import common from "../../style/common";
import breakpoints from "../../config/breakpoints";

/**
 * @param {Object} props
 * @param {Boolean} props.active - shows or hide the filters
 * @param {Function} props.hideFilters - Function to hide the filters
 * @param {Function} props.onChange - Function to handle updates. Is passed directly from inputs. Use e.target.name to identify which input updated
 * @param {Function} props.clearFilters - Function that should clear all selected values
 * @param {Object[]} props.relatedApps - A list of related apps
 * @param {Object} props.values - Object of values supplied directly to input. Ie `{ selectedAppId: 13 }`
 */
function AppFilterModal({
  active = false,
  hideFilters = () => {},
  relatedApps = [],
  onChange = () => {},
  values = {},
  clearFilters = () => {},
}) {
  const primaryColor = useSelector((s) => s.appConfig.primaryColor);
  const lang = useSelector((s) => s.language.language);
  const myApp = useSelector((s) => s.appConfig);

  return (
    <>
      <CSSTransition in={active} timeout={400} mountOnEnter={true} unmountOnExit={true} classNames="social-filters">
        <div className={componentStyles(primaryColor)}>
          <div className="top-bar">
            <p className="title">{lang.filters}</p>
            <ActionWrapper onClick={hideFilters}>
              <CloseIcon />
            </ActionWrapper>
          </div>

          <ScrollView className="filters-content" style={{ padding: "1rem" }}>
            <div className="content-wrapper">
              {/* App chooser */}
              <label htmlFor="">{lang.chooseApp}</label>
              <DropDownList
                style={{ marginBottom: "1.5rem" }}
                name="selectedAppId"
                stateless={true}
                selectedContentId={values["selectedAppId"]}
                dropDownListContent={[
                  { id: myApp.appId, title: `${lang.myApp} (${myApp.title})` },
                  { id: "ALL", title: `${lang.all}` },
                  ...relatedApps.map(({ id, name: title }) => ({ id, title })),
                ]}
                onChange={onChange}
              />

              {/* Buttons */}
              <Button buttonType="tertiary" style={{ marginTop: "2rem" }} onClick={hideFilters}>
                Use filters
              </Button>
              <Button onClick={clearFilters} buttonType="secondary" style={{ marginTop: "0.5rem" }}>
                <TrashCanOutlineIcon style={{ height: "1.25rem", width: "1.25rem", marginBottom: "-0.25rem" }} />{" "}
                {lang.clear} {lang.all.toLowerCase()} {lang.filters.toLowerCase()}
              </Button>
            </div>
          </ScrollView>
        </div>
      </CSSTransition>
      <Overlay active={active} onClick={hideFilters} style={{ zIndex: 2 }} />
    </>
  );
}

const componentStyles = (primaryColor) => css`
  position: absolute;
  left: 0;
  top: 0;
  width: 100%;
  max-height: 100%;
  z-index: 3;
  color: ${colors.black};
  display: flex;
  flex-direction: column;
  transform-origin: top;

  @media screen and (min-width: ${breakpoints.lg}px) {
    .content-wrapper {
      max-width: ${breakpoints.lg}px;
      margin: 0 auto;
    }
  }

  .top-bar {
    display: flex;
    justify-content: space-between;
    color: ${primaryColor};
    border-bottom: 1px ${colors.lightGrey} solid;
    height: ${common.topBarHeight}px;
    background-color: ${colors.white};
    z-index: 1;

    p {
      line-height: ${common.topBarHeight}px;
      padding-left: 1rem;
    }

    svg {
      width: ${common.topBarHeight}px;
      height: ${common.topBarHeight}px;
      padding: 0.65rem;
    }
  }

  .filters-content {
    background-color: ${colors.white};
  }

  .title {
    font-weight: bold;
    margin-bottom: 1rem;
  }

  label {
    color: ${colors.darkGrey};
    margin-bottom: 0.75rem;
    display: block;
  }

  .checkbox-element {
    display: flex;
    color: ${colors.darkGrey};
    margin-bottom: 1.5rem;

    svg {
      margin-right: 0.75rem;
    }
  }

  /* Top-bar animation */
  &.social-filters-enter .top-bar {
    opacity: 0;
    transform: translateX(200px);
  }
  &.social-filters-enter-active .top-bar {
    opacity: 1;
    transform: translateX(0px);
    transition: opacity 150ms ease, transform 200ms ease;
  }
  &.social-filters-exit .top-bar {
    opacity: 1;
    transform: translateX(0px);
  }
  &.social-filters-exit-active .top-bar {
    opacity: 0;
    transform: translateX(200px);
    transition: opacity 200ms ease, transform 250ms ease;
    transition-delay: 100ms;
  }

  /* filters-content animation */
  &.social-filters-enter .filters-content {
    opacity: 0;
    transform: translateY(-200px);
  }
  &.social-filters-enter-active .filters-content {
    opacity: 1;
    transform: translateY(0);
    transition: transform 200ms ease, opacity 150ms ease;
    transition-delay: 100ms;
  }
  &.social-filters-exit .filters-content {
    opacity: 1;
    transform: translateY(0);
  }
  &.social-filters-exit-active .filters-content {
    opacity: 0;
    transform: translateY(-200px);
    transition: transform 200ms ease, opacity 150ms ease;
  }
`;

export default AppFilterModal;
