import {
  GET_SOCIAL_POSTS,
  GET_SOCIAL_POSTS_SUCCESS,
  GET_SOCIAL_POSTS_FAILURE,
  CLEAR_SOCIAL_FEED,
  END_OF_SOCIAL_FEED,
  REFRESH_SOCIAL_POST_COMMENTS,
  REFRESH_SOCIAL_POST_LIKES,
  SET_SOCIAL_POST_AS_DELETED,
  REMOVE_SOCIAL_POST_FROM_FEED,
  REPLACE_SOCIAL_POST,
  SET_SOCIAL_CURRENT_DATA_ID,
  REFRESH_SOCIAL_POST_COMMENT_LIKES,
} from "../actions/actionTypes";

const initialState = {
  posts: [],
  currentDataId: undefined,
  endOfFeed: false,
  loading: false,
  error: false,
};

export default function (state = initialState, action) {
  switch (action.type) {
    case GET_SOCIAL_POSTS:
      return {
        ...state,
        loading: true,
        error: false,
      };

    case GET_SOCIAL_POSTS_FAILURE:
      return {
        ...state,
        loading: false,
        error: true,
      };

    case GET_SOCIAL_POSTS_SUCCESS:
      return {
        ...state,
        loading: false,
        error: false,
        posts: [...state.posts, ...action.payload],
      };

    case CLEAR_SOCIAL_FEED:
      return {
        ...state,
        loading: false,
        error: false,
        endOfFeed: false,
        posts: [],
      };

    case END_OF_SOCIAL_FEED:
      return {
        ...state,
        loading: false,
        error: false,
        endOfFeed: true,
      };

    case REFRESH_SOCIAL_POST_COMMENTS:
      return {
        ...state,
        posts: state.posts.map((post) =>
          post.id !== action.payload.postId
            ? post
            : {
                ...post,
                comments: action.payload.comments,
              }
        ),
      };
    case REFRESH_SOCIAL_POST_LIKES:
      return {
        ...state,
        posts: state.posts.map((post) =>
          post.id !== action.payload.postId
            ? post
            : {
                ...post,
                likes: action.payload.likes,
              }
        ),
      };

    case REFRESH_SOCIAL_POST_COMMENT_LIKES:
      return {
        ...state,
        posts: state.posts.map((post) =>
          post.id !== action.payload.postId
            ? post
            : {
                ...post,
                comments: post.comments.map((comment) =>
                  comment.id === action.payload.commentId
                    ? {
                        ...comment,
                        likes: action.payload.likes,
                      }
                    : comment
                    ? {
                        ...comment,
                        comments: comment.comments.map((childComment) =>
                          childComment.id === action.payload.commentId
                            ? {
                                ...childComment,
                                likes: action.payload.likes,
                              }
                            : childComment
                        ),
                      }
                    : comment
                ),
              }
        ),
      };

    case SET_SOCIAL_POST_AS_DELETED:
      /**
       * Finds post by id and adds property `deleted` with value `true`
       * In SocialPost.jsx the map looks for the deleted value and sets the
       * post as being visually deleted if its true (it defaults to not being defined)
       */

      return {
        ...state,
        posts: state.posts.map((post) =>
          post.id !== action.payload
            ? post
            : {
                ...post,
                deleted: true,
              }
        ),
      };

    case REMOVE_SOCIAL_POST_FROM_FEED:
      let removeIndex = state.posts.findIndex((p) => p.id === action.payload);
      return {
        ...state,
        posts: [...state.posts.slice(0, removeIndex), ...state.posts.slice(removeIndex + 1)],
      };

    case REPLACE_SOCIAL_POST:
      let replaceIndex = state.posts.findIndex((p) => p.id === action.payload.postId);
      return {
        ...state,
        posts: [...state.posts.slice(0, replaceIndex), action.payload.post, ...state.posts.slice(replaceIndex + 1)],
      };

    case SET_SOCIAL_CURRENT_DATA_ID:
      return {
        ...state,
        currentDataId: action.payload,
      };

    default:
      return state;
  }
}
