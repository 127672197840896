import React, { Component, Fragment } from "react";
import { connect } from "react-redux";
import { css } from "emotion";
import PropTypes from "prop-types";

//Style
import common from "../../style/common";
import colors from "../../style/colors";
import {
  getSelectedFromPrimaryColor,
  getHoverFromPrimaryColor,
  getActiveFromPrimaryColor,
} from "../../utilities/color-modifiers";

/** Select list for lists where only 1 element must be selected at one time
 *
 * ```
 * Options array passed to this element should be structured:
 * [ {id: XX, title: YY}]
 * ```
 */
class HorizontalRadioGroup extends Component {
  constructor(props) {
    super(props);
    this.state = {
      activeRadioId: null,
    };
  }

  onSelect = (radioId) => {
    // Emit event
    this.props.onSelect(radioId);
    this.setState({
      activeRadioId: radioId,
    });
  };

  componentDidMount() {
    if (this.props.selectedValue) this.setState({ activeRadioId: this.props.selectedValue });
  }

  render() {
    let { primaryColor } = this.props.appConfig;
    let { activeRadioId } = this.state;
    let { disabled = false } = this.props;

    return (
      <div className={`${componentStyle(primaryColor)} ${this.props.className}`} style={this.props.style}>
        {this.props.options.map((radio, index) => (
          <Fragment key={radio.id}>
            {activeRadioId === radio.id ? (
              <label
                className="radio selected"
                htmlFor={radio.id}
                onClick={!disabled ? () => this.onSelect(radio.id) : {}}
                key={`radio-group-${radio.id}-${radio.title}`}
              >
                <p>{radio.title}</p>
              </label>
            ) : (
              <label
                className="radio"
                htmlFor={radio.id}
                onClick={!disabled ? () => this.onSelect(radio.id) : {}}
                key={`radio-group-${radio.id}-${radio.title}`}
              >
                <p>{radio.title}</p>
              </label>
            )}
          </Fragment>
        ))}
      </div>
    );
  }
}
const componentStyle = (primaryColor) => css`
  display: flex;
  flex-wrap: wrap;
  box-sizing: border-box;
  border-width: 1px 0 0 1px;
  border-style: solid;
  border-color: ${colors.midGrey};
  border-radius: 3px;

  .radio {
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
    -webkit-appearance: none;
    border-width: 0 1px 1px 0;
    font-weight: 400;
    flex: 1;
    padding: 0.5rem 1rem;
    border-style: solid;
    border-color: ${colors.midGrey};
    cursor: pointer;

    p {
      white-space: nowrap;
      font-size: 1rem;
      font-family: ${common.fontStack};
    }

    &:hover {
      background-color: ${getHoverFromPrimaryColor(primaryColor)};
    }
    &:active {
      background-color: ${getActiveFromPrimaryColor(primaryColor)};
    }

    &.selected,
    &.selected {
      font-weight: 700;
      color: ${primaryColor};
      background-color: ${getSelectedFromPrimaryColor(primaryColor)};
    }

    &:last-child {
      border-radius: 0 0 3px 0;
    }
  }
`;

const mapStateToProps = (state) => ({
  appConfig: state.appConfig,
});

HorizontalRadioGroup.propTypes = {
  /** Function to be executed when an option is selected */
  onSelect: PropTypes.func,
  /** Array which holds the selectable elements */
  options: PropTypes.array,
  /** Override style of component with this */
  style: PropTypes.object,
  /** Default value or target values **/
  selectedValue: PropTypes.string,
};

export default connect(mapStateToProps, null)(HorizontalRadioGroup);
