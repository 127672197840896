import React, { useRef } from "react";
import { useSelector } from "react-redux";
import { connect } from "react-redux";
import { css } from "emotion";
import PropTypes from "prop-types";
import { SearchIcon, CloseIcon } from "mdi-react";

// Components
import InlineSpinner from "./InlineSpinner";

// Style
import colors from "../../style/colors";
import common from "../../style/common";

/** Component used for search functionality
 * ```
 * IN IMPLEMENTING FILE:
 *  filterContacts = event => {
      let filteredContacts = [...this.state.contacts];
      filteredContacts = filteredContacts.filter(
        item =>
          item.name.toLowerCase().search(event.target.value.toLowerCase()) !== -1 ||
          item.userGroup.title.toLowerCase().search(event.target.value.toLowerCase()) !== -1 ||
          item.masterGroup.title.toLowerCase().search(event.target.value.toLowerCase()) !== -1
        );
      this.setState({ filteredContacts, filterTerm: event.target.value });
    };
 *
 * <SearchInput onChange={this.filterContacts} placeholder={"Søg i kontakter"} />
 *
 * ```
 *
 */
const FloatingSearchInput = (props) => {
  const ref = useRef();
  const lang = useSelector((state) => state.language.language);

  if (props.autoFocusOnRender === true && ref.current) {
    ref.current.querySelector("input").focus({ preventScroll: false });
  }

  return (
    <div
      data-test-id={props.dataTestId}
      ref={ref}
      className={`search-input ${style(props)} ${props.className || ""}`}
      style={props.style && props.style}
    >
      {!props.searching && <SearchIcon className="main-icon" />}
      {props.searching && <InlineSpinner className="main-icon" style={{ top: "13px" }} size="20" />}
      <input
        name={props.name}
        type="text"
        onChange={props.onChange}
        value={props.value}
        placeholder={props.placeholder || lang.typeToSearch}
      />

      {props.value !== "" && <CloseIcon onClick={props.onClearSearch} className="right" />}
    </div>
  );
};

const height = 50;
const maxWidth = 1024;

const style = (props) => css`
  display: flex;
  position: fixed;
  top: ${common.topBarHeight + 10}px;
  width: 97%;
  left: 1.5%;
  align-items: center;
  background-color: ${colors.white};
  border-radius: ${height / 2}px;
  border: 1px ${colors.midGrey} solid;
  font-size: 1rem;
  color: ${colors.black};
  height: ${height}px;

  @media screen and (min-width: ${maxWidth}px) {
    max-width: ${maxWidth}px;
    margin: 0 auto;
    left: calc(50% - ${maxWidth / 2}px);
  }

  .main-icon {
    position: absolute;
    left: 15px;
  }

  /* Removes ios styles */
  input[type="text"] {
    /* Remove First */
    -webkit-appearance: none;
    -moz-appearance: none;
    appearance: none;
  }

  input {
    position: absolute;
    width: 100%;
    height: 100%;
    top: 0;
    left: 0;
    font-size: 1rem;
    background-color: transparent;
    border: 1px transparent solid;
    border-radius: 3px;
    padding-left: 2.65rem;
    padding-right: 2rem;

    &:focus {
      outline: 0;
      border: 1px ${props.primaryColor} solid;
      border-radius: ${height / 2}px;
    }
  }

  .right {
    position: absolute;
    right: 0.65rem;
    cursor: pointer;
  }

  svg {
    fill: ${colors.darkGrey};
  }
`;
FloatingSearchInput.propTypes = {
  /** Function which is executed to clear input */
  onClearSearch: PropTypes.func,
  /** Text value in input part of component, this is being used when searching */
  value: PropTypes.string,
  /** Boolean value used to decide if a search has been initiated. usecase could be that you dont want a user to type something while a search is ongoing */
  searching: PropTypes.bool,
  /** Function to be executed when something new has been typed */
  onChange: PropTypes.func,
  /** Placeholder text displayed when nothing has been typed */
  placeholder: PropTypes.string,
  /** Used to set className for styling */
  className: PropTypes.string,
};
const mapStateToProps = (state) => ({
  primaryColor: state.appConfig.primaryColor,
});

export default connect(mapStateToProps)(FloatingSearchInput);
