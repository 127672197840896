// Libs
import React, { useEffect, useState } from "react";
import { css } from "emotion";
import { connect, useSelector } from "react-redux";
import { bindActionCreators } from "redux";

// Components
import StatusBox from "../ui/StatusBox";
import InlineSpinner from "../ui/InlineSpinner";

// Utilities
import req from "../../utilities/request-utility";
import ListItem from "../ui/ListItem";
import getProfilePicture from "../../utilities/get-profile-picture-from-user-object";

// Config
import breakpoints from "../../config/breakpoints";

// Actions
import { addToast } from "../../actions/uiActions";

// Hooks
import useAuthorModal from "../../hooks/useAuthorModal";

// Styles
import colors from "../../style/colors";

const ChristmasHighscoreMain = (props) => {
  // Redux state
  const { language: lang } = useSelector((state) => state.language);
  const { user: me } = useSelector((state) => state.auth);
  const { primaryColor } = useSelector((state) => state.appConfig);

  // Local state
  const [error, setError] = useState(false);
  const [loading, setLoading] = useState(true);
  const [users, setUsers] = useState([]);

  // To get the users information when clicked on the image
  const authorModal = useAuthorModal();

  // UseEffect
  useEffect(() => {
    req()("/christmas-highscore/users")
      .then(({ data: users }) => {
        setUsers(users);
        setLoading(false);
      })
      .catch((error) => {
        setLoading(false);
        setError(true);
        props.addToast({
          styleType: "error",
          title: lang.networkError,
          content: lang.errorCouldNotFetchFrom__placeholder__.replace(/{{placeholder}}/gi, lang.christmasCalendarHighscore),
          duration: 20000,
        });
      });
    // eslint-disable-next-line
  }, []);

  return (
    <div className={componentStyle(primaryColor)}>
      {!error && loading && <InlineSpinner title={lang.loading} />}
      {error && !loading && <StatusBox />}
      {!error && !loading && (
        <>
          <div className="score-header">
            <p>{lang.rank}</p>
            <p className="name">{lang.name}</p>
            <p>{lang.points}</p>
          </div>
          {users.map((user) => (
            <ListItem
              clickable={true}
              maxWidth={breakpoints.lg}
              className={user.id === me.id ? "me" : ""}
              itemNumberLeft={user.rank}
              onClick={() => authorModal(user.id)}
              imageLeft={getProfilePicture(user, 36, { marginRight: "0.5rem", marginBottom: "-3px" })}
              key={user.rank}
              title={user.name}
              subTile={`${user.userGroup.title}, ${user.masterGroup.title}`}
              iconRight={user.points}
            />
          ))}
        </>
      )}
    </div>
  );
};

const componentStyle = (primaryColor) => css`
  margin: 1rem auto;

  @media screen and (min-width: ${breakpoints.lg}px) {
    width: ${breakpoints.lg}px;
    flex: initial !important; /* Removes forced 100% height */
  }

  .score-header {
    border-top-right-radius: 3px;
    border-top-left-radius: 3px;
    border-left: 0.5px solid ${colors.midGrey};
    border-right: 0.5px solid ${colors.midGrey};
    border-top: 0.5px solid ${colors.midGrey};
    text-align: right;
    padding: 0.75rem;
    font-size: 0.75rem;
    color: ${colors.darkGrey};
    background-color: ${colors.white};
    display: flex;

    p.name {
      flex: 1;
      text-align: start;
      padding-left: 0.5rem;
    }
  }

  .me {
    font-weight: 700;
    color: ${primaryColor};
    background-color: ${colors.ultraLightGrey};
  }
`;

const mapDispatchToProps = (dispatch) => ({
  addToast: bindActionCreators(addToast, dispatch),
});

export default connect(null, mapDispatchToProps)(ChristmasHighscoreMain);
