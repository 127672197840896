export default function ({ content = "", searchTerm = "", maxLength = 100 }) {
  // Type cast to be sure
  content = String(content);
  searchTerm = String(searchTerm);
  maxLength = parseInt(maxLength);

  // If content is shorter than maxLength just return it as is
  if (content.length < maxLength) return content;

  const REGEXP = new RegExp(String(searchTerm), "i");
  const MATCH_INDEX = content.search(REGEXP);
  let excerpt = "";

  // If match is in the start or isn't found return content sliced to maxLength from 0
  if (MATCH_INDEX === 0 || MATCH_INDEX === -1) {
    excerpt = content.slice(0, maxLength);
    if (excerpt.length < content.length) excerpt = `${excerpt.trim()}...`;
  }

  // If match is in end return end "..." + content sliced maxLength from end
  else if (MATCH_INDEX === MATCH_INDEX + searchTerm.length) {
    excerpt = content.slice(maxLength * -1);
    if (excerpt.length < content.length) excerpt = `...${excerpt.trim()}`;
  }

  // If match is in the middle return 1/3 of maxLenght before and 2/3 of maxlength after
  else if (MATCH_INDEX !== 0 && MATCH_INDEX !== MATCH_INDEX + searchTerm.length) {
    excerpt = content.slice(
      MATCH_INDEX - Math.round(maxLength * 0.25), // Percent of maxlength to go in front of match
      MATCH_INDEX + searchTerm.length + Math.round(maxLength * 0.75) // Percent of maxlength to go after match
    );
    if (excerpt.length < content.length) excerpt = `...${excerpt.trim()}...`;
  }

  return excerpt;
}
