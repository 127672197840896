// Libs
import React, { useEffect, useLayoutEffect } from "react";
import { useDispatch, useSelector } from "react-redux";

// Config
import pageConfig from "../../../config/pageConfig";
import contactTypes from "../config/contactsTypes";
import groupTypes from "../config/groupTypes";

// Components
import { ContactsOverview } from "./ContactsOverview";
import TabView from "../../ui/TabView";

// Hooks
import useCurrentPage from "../../../hooks/useCurrentPage";

// Utilities
import { getGroupType } from "../utilities/getGroupType";
import pageNavigatorV2 from "../../../utilities/page-navigator-v2";

// Actions
import {
  clearAllContacts,
  getRelatedContactsApps,
  setActiveTab,
  setPhoneBookFiltersValue,
  setPreviousState,
} from "../../../actions/contactsActions";

export function ContactsContent(props) {
  const dispatch = useDispatch();
  const { activeTab, previousState, filters } = useSelector((state) => state.contact);

  const { HIDE_PHONEBOOK_TAB, HIDE_KEYPERSONS_TAB, GROUP_PHONEBOOK, GROUP_KEYPERSONS } = pageConfig;

  const page = useCurrentPage({ pageId: props.match.params.pageId });
  const config = page.config;

  useLayoutEffect(() => {
    dispatch(clearAllContacts());
    dispatch(getRelatedContactsApps());

    if (previousState !== null) {
      dispatch(setActiveTab(previousState.activeTab));
      dispatch(setPhoneBookFiltersValue({ key: "selectedAppId", value: previousState.selectedAppId }));
      dispatch(setPreviousState(null));
    }

    return () => {
      dispatch(setPhoneBookFiltersValue({ key: "selectedAppId", value: null }));
    };
  }, []);

  function navigateToContactsGroup({ group = null, contactType }) {
    const tab = contactType === contactTypes.keyPerson ? 1 : 0;

    // Save state for when navigating back
    dispatch(
      setPreviousState({
        activeTab: tab,
        selectedAppId: filters.selectedAppId,
      })
    );

    // Navigate to show all contacts
    if (group === null) {
      pageNavigatorV2({
        path: `${props.match.url}/groups/0/?mainContactPageId=${page.id}&contactType=${contactType}`,
        direction: "forward",
      });
      return;
    }

    // Navigate to the contacts of the specified group
    const groupType = config
      ? getGroupType(config[contactType === contactTypes.phoneBook ? GROUP_PHONEBOOK : GROUP_KEYPERSONS])
      : groupTypes.groups;

    pageNavigatorV2({
      path: `${props.match.url}/groups/${group.id}/?mainContactPageId=${page.id}&pageTitle=${group.title}&contactType=${contactType}&groupType=${groupType}`,
      direction: "forward",
    });
  }

  if (config && config[HIDE_PHONEBOOK_TAB] && config[HIDE_KEYPERSONS_TAB]) return <></>;

  // DISPLAY: only contacts
  if (config && !config[HIDE_PHONEBOOK_TAB] && config[HIDE_KEYPERSONS_TAB] === true) {
    return (
      <ContactsOverview
        mainPage={page}
        contactType={contactTypes.phoneBook}
        groupBy={getGroupType(config[GROUP_PHONEBOOK])}
        navigateToContactsGroup={navigateToContactsGroup}
      />
    );
  }

  // DISPLAY: only keyPersons
  else if (config && !config[HIDE_KEYPERSONS_TAB] && config[HIDE_PHONEBOOK_TAB] === true) {

    return (
      <ContactsOverview
        mainPage={page}
        contactType={contactTypes.keyPerson}
        groupBy={getGroupType(config[GROUP_KEYPERSONS])}
        navigateToContactsGroup={navigateToContactsGroup}
      />
    );
  }

  // DISPLAY: phoneBook and keyPersons
  else if (config && !config[HIDE_PHONEBOOK_TAB] && !config[HIDE_KEYPERSONS_TAB]) {
    return (
      <TabView
        useScrollView={false}
        activeTabIndex={activeTab}
        tabs={[
          <ContactsOverview
            mainPage={page}
            contactType={contactTypes.phoneBook}
            groupBy={getGroupType(config[GROUP_PHONEBOOK])}
            navigateToContactsGroup={navigateToContactsGroup}
            tabBarIsIncluded={true}
          />,
          <ContactsOverview
            mainPage={page}
            contactType={contactTypes.keyPerson}
            groupBy={getGroupType(config[GROUP_KEYPERSONS])}
            navigateToContactsGroup={navigateToContactsGroup}
            tabBarIsIncluded={true}
          />,
        ]}
      />
    );
  }
  // DEFAULT IF CONFIG NOT SPECIFIED
  else {
    return (
      <TabView
        useScrollView={false}
        activeTabIndex={activeTab}
        tabs={[
          <ContactsOverview
            mainPage={page}
            contactType={contactTypes.phoneBook}
            groupBy={groupTypes.all}
            tabBarIsIncluded={true}
          />,
          <ContactsOverview
            mainPage={page}
            contactType={contactTypes.keyPerson}
            groupBy={groupTypes.groups}
            navigateToContactsGroup={navigateToContactsGroup}
            tabBarIsIncluded={true}
          />,
        ]}
      />
    );
  }
}
