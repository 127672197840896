import {
  SET_SEARCH_TERM,
  SET_END_OF_FEED_VALUE,
  GET_SEARCH_RESULTS,
  GET_SEARCH_RESULTS_SUCCESS,
  GET_SEARCH_RESULTS_FAILURE,
  SET_SEARCH_PAGE_OFFSET,
} from "../actions/actionTypes";

const initialState = {
  loading: false,
  error: false,
  results: [],
  searchTerm: "",
  endOfFeed: "",
  pageOffset: 0,
};

export default function (state = initialState, action) {
  switch (action.type) {
    case SET_SEARCH_TERM:
      return {
        ...state,
        searchTerm: action.payload,
      };

    case SET_END_OF_FEED_VALUE:
      return {
        ...state,
        endOfFeed: action.payload,
      };

    case SET_SEARCH_PAGE_OFFSET:
      return {
        ...state,
        pageOffset: action.payload,
      };

    case GET_SEARCH_RESULTS:
      return {
        ...state,
        loading: true,
        error: false,
      };

    case GET_SEARCH_RESULTS_FAILURE:
      return {
        ...state,
        loading: false,
        error: true,
      };

    case GET_SEARCH_RESULTS_SUCCESS:
      return {
        ...state,
        loading: false,
        error: false,
        results: action.payload,
      };

    default:
      return state;
  }
}
