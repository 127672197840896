import ImageCarousel from "../components/ui/ImageCarousel";
import React from "react";
import { FileOutlineIcon } from "mdi-react";
import { useSelector } from "react-redux";

// Sometimes a non-existing file might slip through. Just being safe :-)
function getFormat(file) {
  let format = "";
  try {
    format = file.split(".").slice(-1)[0].toLowerCase();
  } catch {}
  return format;
}

export default function (file) {
  // Get the format
  let format = getFormat(file);

  const { language: lang } = useSelector((state) => state.language);

  switch (format) {
    case "jpeg":
    case "jpg":
    case "png":
      return <ImageCarousel images={[{ baseURL: null, image: file }]} />;

    default:
      return (
        <div className="registration-file-attachment answer">
          <a rel="noreferrer noopener" target="_blank" href={`${file}`} className="attachment">
            <FileOutlineIcon />

            <p>{file ? file.split("/").pop() : lang.file}</p>
          </a>
        </div>
      );
  }
}
