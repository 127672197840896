// Config
import { postLayoutModes } from "../components/registration/config";

export default function getRegistrationLayout({ path }) {
  if (path.match(/registration-accordion/gi)) {
    return postLayoutModes.accordion;
  }

  return postLayoutModes.post;
}
