// Libs
import React, { useEffect, useState } from "react";
import { css } from "emotion";
import { connect, useSelector } from "react-redux";
import { bindActionCreators } from "redux";
import * as queryString from "query-string";

// Components
import Spinner from "../ui/InlineSpinner";
import StatusBox from "../ui/StatusBox";
import ListItem from "../ui/ListItem";

// Utilities
import getProfilePicture from "../../utilities/get-profile-picture-from-user-object";
import req from "../../utilities/request-utility";

// Hooks
import useAuthorModal from "../../hooks/useAuthorModal";

// Config & Styles
import breakpoints from "../../config/breakpoints";
import colors from "../../style/colors";

// Actions
import { addToast, showModalPage, updateModalPage } from "../../actions/uiActions";

const NormalHighscoreMyDepartment = (props) => {
  // Redux state
  const { language: lang } = useSelector((state) => state.language);
  const { user: me } = useSelector((state) => state.auth);
  const { primaryColor } = useSelector((state) => state.appConfig);

  // Local state
  const [error, setError] = useState(false);
  const [loading, setLoading] = useState(true);
  const [users, setUsers] = useState([]);

  // To get the users information when clicked on the image
  const authorModal = useAuthorModal(props.showModalPage, props.updateModalPage);

  // Get the quizId / dataId
  let { dataId: quizId } = queryString.parse(window.location.search);

  useEffect(() => {
    const fetchContent = async () => {
      try {
        const { data: users } = await req()(`/quiz/${quizId}/highscore/my-department`);
        setUsers(users);
        setLoading(false);
      } catch (error) {
        setLoading(false);
        setError(false);
        props.addToast({
          styleType: "error",
          title: lang.networkError,
          content: lang.errorCouldNotFetchFrom__placeholder__.replace(/{{placeholder}}/gi, props.pageTitle),
          duration: 20000,
        });
      }
    };

    fetchContent();
    // eslint-disable-next-line
  }, []);

  return (
    <div className={componentStyle(primaryColor)}>
      {!error && loading && <Spinner title={lang.loading}/>}
      {error && !loading && <StatusBox />}
      {!error && !loading && (
        <>
          <h1>{me.masterGroup.title}</h1>
          <div className="score-overview">
            <p>{lang.score}</p>
          </div>
          {users.map((user) => (
            <ListItem
              clickable={true}
              maxWidth={breakpoints.lg}
              className={user.id === me.id ? "me" : ""}
              itemNumberLeft={user.rank}
              onClick={() => authorModal(user.id)}
              imageLeft={getProfilePicture(user, 36, { marginRight: "0.5rem", marginBottom: "-3px" })}
              key={user.rank}
              title={user.name}
              subTitle={`${user.userGroup.title}, ${user.masterGroup.title}`}
              iconRight={user.points}
            />
          ))}
        </>
      )}
    </div>
  );
};

const componentStyle = (primaryColor) => css`
  margin: 1rem auto;
  max-width: ${breakpoints.lg}px;

  h1 {
    margin: 0 1rem 1rem 1rem;
  }

  .score-overview {
    justify-content: flex-end;
    border-top-right-radius: 3px;
    border-top-left-radius: 3px;
    border-left: 0.5px solid ${colors.midGrey};
    border-right: 0.5px solid ${colors.midGrey};
    border-top: 0.5px solid ${colors.midGrey};
    text-align: right;
    padding: 0.75rem;
    font-size: 0.75rem;
    color: ${colors.darkGrey};
    background-color: ${colors.white};
    display: flex;

    @media screen and (max-width: ${breakpoints.lg}px) {
      border: none;
      border-top: 0.5px solid ${colors.midGrey};
    }

    p {
      &.name {
        flex: 1;
        text-align: start;
        padding-left: 0.5rem;
      }
    }
  }

  .me {
    font-weight: 700;
    color: ${primaryColor};
    background-color: ${colors.ultraLightGrey};
  }
`;

const mapDispatchToProps = (dispatch) => ({
  showModalPage: bindActionCreators(showModalPage, dispatch),
  addToast: bindActionCreators(addToast, dispatch),
  updateModalPage: bindActionCreators(updateModalPage, dispatch),
});

export default connect(null, mapDispatchToProps)(NormalHighscoreMyDepartment);
