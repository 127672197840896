// Libs
import React from "react";
import { css } from "emotion";
import { connect, useSelector } from "react-redux";
import { bindActionCreators } from "redux";
import { showModalPage } from "../../actions/uiActions";

// Components
import ImageCarousel from "../ui/ImageCarousel";
import Button from "../ui/Button";

// Styles
import breakpoints from "../../config/breakpoints";

import Challenge from "../challenge/Challenge";
import colors from "../../style/colors";

import markdownParser from "../../utilities/markdown-parser";

const AcademyPreview = (props) => {
  // Destructure the props
  const { quiz, showModalPage } = props;

  // Language
  const lang = useSelector((state) => state.language.language);

  return (
    <div className={componentStyle(breakpoints, colors)}>
      <p className="title">{quiz.title}</p>
      {quiz.images.length > 0 && <ImageCarousel images={quiz.images} />}
      {quiz.description && <p className="description" dangerouslySetInnerHTML={markdownParser(quiz.description)} />}
      <div style={{ margin: "2rem 1rem" }}>
        <Button
          data-test-id={"begin-quiz"}
          onClick={() => {
            showModalPage({
              title: `${quiz.title}`,
              content: <Challenge challenge={quiz} />,
              pageStyle: { backgroundColor: colors.white },
            });
          }}
        >
          {lang.start__placeholder__.replace(/{{placeholder}}/gi, lang.quiz.toLowerCase())}
        </Button>
      </div>
    </div>
  );
};

const componentStyle = (breakpoints, colors) => css`
  height: 100%;
  width: 100%;
  background-color: ${colors.white};
  overflow: auto;
  border-top: 1px solid ${colors.midGrey};
  border-bottom: 1px solid ${colors.midGrey};
  border-radius: 0;

  @media screen and (min-width: ${breakpoints.md}px) {
    border: 1px solid ${colors.midGrey};
    border-radius: 3px;
  }

  .title {
    padding: 1rem;
    font-size: 1.2rem;
  }

  .description {
    padding: 0 1rem;
  }
`;

const mapDispatchToProps = (dispatch) => ({
  showModalPage: bindActionCreators(showModalPage, dispatch),
});

export default connect(null, mapDispatchToProps)(AcademyPreview);
