import translatorLanguages from "../config/translatorLanguages";

/** Checks if language is valid
 * @name isValidTranslatorLanguge
 * @param {String} translatorLanguage  - `en`, `da` etc
 * @returns {Boolean} - Returns true if translatorLanguageIdentifier is valid - otherwise false
 */
export default (language = "") => {
  if (translatorLanguages[language]) {
    return true;
  } else {
    return false;
  }
};
