import React, { Component, Fragment } from "react";
import { connect } from "react-redux";
import { css } from "emotion";
import colors from "../../style/colors";
import { colors as happyScoreColors } from "./config";

import { SmileyExcitedIcon, SmileyHappyIcon, SmileyNeutralIcon, SmileySadIcon } from "mdi-react";
import PropTypes from "prop-types";
/** Used to represent statistical data in different version of charts, examples could be either linechart or barchart
 * The data it receives should look like:
 * 
 * ```
 * {
          type: "lineChart",
          title: "Seneste uge",
          chartValues: [
            { date: "22/8", value: 4 },
            { date: "23/8", value: 3.5 },
            { date: "24/8", value: 2 },
            { date: "25/8", value: 1 },
            { date: "26/8", value: 2 },
            { date: "27/8", value: 2 },
            { date: "28/8", value: 2 }
          ]
        }
 * ```
 * ```
 * <Chart type={obj.type} values={obj.chartValues} />
 * ```
 */
class Chart extends Component {
  drawChartBackground = (chartHeight, type) => {
    return (
      <>
        {/* Vertical grid-line */}
        <svg className="chart-background">
          <line x1="0" y1="0" x2="10rem" y2="10rem" />
          {type === "lineChart" &&
            this.props.values.map((obj, index) => (
              <line
                key={index + "loop1"}
                className="grid-line"
                x1={index * (100 / (this.props.values.length - 1)) + "%"}
                y1="0"
                x2={index * (100 / (this.props.values.length - 1)) + "%"}
                y2={chartHeight + "rem"}
              />
            ))}
          {type === "barChart" &&
            this.props.values.map((obj, index) => (
              <line
                key={index + "loop2"}
                className="grid-line"
                x1={index * (100 / this.props.values.length - 1) + "%"}
                y1="0"
                x2={index * (100 / this.props.values.length - 1) + "%"}
                y2={chartHeight + "rem"}
              />
            ))}
        </svg>
        {/* Horizontal grid-line */}
        <svg className="chart-background">
          <line className="grid-line" x1="0" y1="0" x2="100%" y2="0" />
          <line className="grid-line" x1="0" y1={100 / 4 + "%"} x2="100%" y2={100 / 4 + "%"} />
          <line className="grid-line" x1="0" y1={(100 / 4) * 2 + "%"} x2="100%" y2={(100 / 4) * 2 + "%"} />
          <line className="grid-line" x1="0" y1={100 / 1.33 + "%"} x2="100%" y2={100 / 1.33 + "%"} />
        </svg>
      </>
    );
  };

  // Decides the color of the bars
  barColorPicker(val) {
    if (val < 1.5) return happyScoreColors.red;
    else if (val >= 1.5 && val < 2.5) return happyScoreColors.redLight;
    else if (val >= 2.5 && val < 3.3) return happyScoreColors.greenLight;
    else if (val >= 3.3) return happyScoreColors.green;
  }

  // Decide where the line chart should move on the x-axes
  findNextValueX(values, index) {
    const length = values.length;
    if (values[index].value > 0) {
      for (let i = length; i > 0; i--) {
        if (index > i && values[i].value > 0) {
          return i;
        }
      }
    }
    return 0;
  }

  // Decide where the line chart should move on the y-axes
  findNextValueY(values, index) {
    const length = values.length;
    for (let i = length; i >= 0; i--) {
      if (index > i && values[i].value > 0) {
        return values[i].value;
      }
    }
    return values[index].value;
  }

  render() {
    let { type, values } = this.props;
    let { primaryColor } = this.props.appConfig;
    let chartHeight = 12;
    let yAxisInterval = chartHeight / 4;

    return (
      <div className={container(primaryColor, chartHeight, yAxisInterval)}>
        {/* y-axis */}
        <div className="y-axis">
          <div className="value">
            <SmileyExcitedIcon color={happyScoreColors.green} />
          </div>
          <div className="value">
            <SmileyHappyIcon color={happyScoreColors.greenLight} />
          </div>
          <div className="value">
            <SmileyNeutralIcon color={happyScoreColors.redLight} />
          </div>
          <div className="value">
            <SmileySadIcon color={happyScoreColors.red} />
          </div>
        </div>

        {/* line chart */}
        {type === "lineChart" ? (
          <>
            <div className="chart">
              {/* Bacground grid */}
              {this.drawChartBackground(chartHeight, type)}
              {/* Line chart */}
              <svg>
                {values.map((obj, index) => (
                  <Fragment key={"line-chart" + index}>
                    {obj.value > 0 && (
                      <line
                        className="line"
                        x1={index > 0 ? index * (100 / (values.length - 1)) + "%" : 0}
                        y1={`${chartHeight - obj.value * yAxisInterval}rem`}
                        y2={`${chartHeight - this.findNextValueY(values, index) * yAxisInterval}rem`}
                        x2={index > 0 ? this.findNextValueX(values, index) * (100 / (values.length - 1)) + "%" : 0}
                      />
                    )}
                    {obj.value > 0 && (
                      <circle
                        cx={index * (100 / (values.length - 1)) + "%"}
                        cy={chartHeight - obj.value * yAxisInterval + "rem"}
                        r="7"
                        fill={primaryColor}
                      />
                    )}
                  </Fragment>
                ))}

              </svg>
            </div>
            {/* x-axis */}
            <div className="x-axis-line-chart">
              {values.map((obj, index) => (
                <div
                  key={"x-axis" + index}
                  className="value"
                  style={{
                    left: index * (100 / (values.length - 1)) + "%"
                  }}
                >
                  {obj.date}
                </div>
              ))}
            </div>
          </>
        ) : (
          // Bar chart
          <>
            <div className="chart">
              {/* Background grid */}
              {this.drawChartBackground(chartHeight, type)}
              {/* Bars */}
              {values.map((obj, index) => (
                <div
                  key={"bars" + index}
                  className={obj.value > 0 && "bar"}
                  style={
                    obj.value && {
                      height: obj.value * yAxisInterval + "rem",
                      backgroundColor: this.barColorPicker(obj.value)
                    }
                  }
                  // style={obj.value === 1 && {backgroundColor: colors.red, height: (obj.value) * yAxisInterval + "rem"}}
                />
              ))}
            </div>
            {/* x-axis */}
            {type === "barChart" && (
              <div className="x-axis-bar-chart">
                {values.map((obj, index) => (
                  <div key={"x-axis-bar" + index} className="value">
                    {obj.date}
                  </div>
                ))}
              </div>
            )}
          </>
        )}
      </div>
    );
  }
}

const container = (primaryColor, chartHeight, yAxisInterval) => css`
  padding: 2rem 0 1rem;

  .chart {
    position: relative;
    height: ${chartHeight + "rem"};
    display: flex;
    align-items: flex-end;
    justify-content: space-around;
    border-bottom: 1px ${colors.midGrey} solid;
    border-left: 1px ${colors.midGrey} solid;
    border-right: 1px ${colors.midGrey} solid;

    & .chart-background {
      position: absolute;
      height: ${chartHeight + "rem"};
      width: 100%;
      left: 0;
      top: 0;

      & .grid-line {
        stroke: ${colors.midGrey};
        stroke-width: 1;
      }
    }

    & > svg {
      overflow: visible;
      position: absolute;
      z-index: 2;
      height: ${chartHeight + "rem"};
      width: 100%;
    }

    & .bar {
      z-index: 2;
      background-color: ${primaryColor};
      padding: 1rem;
    }

    & .line {
      z-index: 2;
      stroke: ${primaryColor};
      stroke-width: 0.2rem;
    }
  }

  // y-axis
  .y-axis {
    width: 10%;
    height: ${chartHeight + 4 + "rem"};
    float: left;
    margin-top: ${(yAxisInterval / 4) * -1 + "rem"};
    margin-right: 2%;

    & .value {
      height: ${yAxisInterval + "rem"};
      color: ${primaryColor};
      text-align: center;
    }
  }

  // x-axis
  .x-axis-bar-chart {
    /* height: 4rem; */
    display: flex;
    justify-content: space-around;
    margin-top: 1rem;

    & > .value {
      width: 8rem;
      margin-top: 2rem;
      margin-left: -6rem;
      transform: rotate(320deg);
      text-align: right;
    }
  }

  .x-axis-line-chart {
    position: relative;
    /* height: 4rem; */
    display: flex;
    margin-top: 1rem;

    & > .value {
      position: absolute;
      top: 0;
      margin-left: -2rem;
      transform: rotate(320deg);
    }
  }
`;

Chart.propTypes = {
  /** Array for setting values used for drawing chart */
  value: PropTypes.array,
  /** String value used for defining chartType */
  type: PropTypes.string
};
const mapStateToProps = state => ({
  appConfig: state.appConfig
});

export default connect(mapStateToProps)(Chart);
