// Libraries
import React from "react";

import { css } from "emotion";

import { version } from "../../../package.json";

function DebugModal(props) {
  const userAgent = window.navigator.userAgent;
  const displayModeIsStandalone = window.matchMedia("(display-mode: standalone)").matches;
  // Sets isIOS for ipad iphone and ipod
  let isIOS = userAgent.match(/iPad|iPhone|iPod/) !== null ? true : false;
  // Sets isAndroid for android
  let isAndroid = userAgent.match(/Android/) !== null ? true : false;
  // Sets isAndroid for android
  let isSamsungInternet = userAgent.match(/SamsungBrowser/) !== null ? true : false;

  return (
    <div className={componentStyles()}>
      <div className="info-wrapper">
        <p className="label">version</p>
        <p className="result">{version}</p>
      </div>

      <div className="info-wrapper">
        <p className="label">userAgent </p>
        <p className="code">window.navigator.userAgent</p>
        <p className="result">
          {userAgent} {userAgent.toString()}
        </p>
      </div>

      <div className="info-wrapper">
        <p className="label">displayModeIsStandalone</p>
        <p className="code">window.matchMedia("(display-mode: standalone)").matches</p>
        <p className="result">
          {displayModeIsStandalone} {displayModeIsStandalone.toString()}
        </p>
      </div>

      <div className="info-wrapper">
        <p className="label">isIOS</p>
        <p className="code">userAgent.match(/iPad|iPhone|iPod/) !== null ? true : false</p>
        <p className="result">
          {isIOS} {isIOS.toString()}
        </p>
      </div>

      <div className="info-wrapper">
        <p className="label">isAndroid</p>
        <p className="code">userAgent.match(/Android/) !== null ? true : false</p>
        <p className="result">
          {isAndroid} {isAndroid.toString()}
        </p>
      </div>

      <div className="info-wrapper">
        <p className="label">isSamsungInternet</p>
        <p className="code">userAgent.match(/SamsungBrowser/) !== null ? true : false</p>
        <p className="result">
          {isSamsungInternet} {isSamsungInternet.toString()}
        </p>
      </div>
    </div>
  );
}

const componentStyles = () => css`
  .info-wrapper {
    background-color: #fff;
    border: 1px #ccc solid;
    padding: 0.5rem;
    max-width: 800px;
    margin: 0.25rem auto;
  }

  .label {
    font-weight: 700;
    margin: 0 0 0.5rem 0;
  }

  p.code {
    margin: 0 0 0.5rem 0;
    font-family: monospace;
    background-color: #eaf2f7;
    color: #0f1452;
    padding: 0.25rem 0.5rem;
    font-weight: 400;
    border-radius: 3px;
    font-size: 0.8rem;
  }

  p.result {
    font-size: 0.8rem;
    font-family: monospace;
    background-color: #dfffef;
    padding: 0.5rem;
    color: #064a28;
    border-radius: 4px;
  }
`;

export default DebugModal;
