export default function (markdown) {
  // Regex that finds all links as long as they start with a protocol
  // eslint-disable-next-line
  const regex =
    /(?:(?:https?):\/\/|www\.)(?:\([-A-Z0-9+&@#/%=~_|$?!:,.]*\)|[-A-Z0-9+&@#/%=~_|$?!:,.])*(?:\([-A-Z0-9+&@#/%=~_|$?!:,.]*\)|[A-Z0-9+&@#/%=~_|$])/gim;

  // // Find the links in the markdown using the regex
  let links = markdown.match(regex) || [];

  // Cleanup link and remove potential `&nbsp;` at the end of the link
  links = links.map((link) => link.replace(/&nbsp/gi, ""));

  // Organize into objects
  links = links.map((link) => ({ url: link }));

  // Remove all those links that are actual markdown links:
  links = links.filter(({ url }) => {
    let linkIndex = markdown.indexOf(url);

    // If links is at start of string there is no char before it so it can't be markdown
    if (linkIndex === 0) return true;

    // If the character before the link is either a [ or ( then it potentially is a markdown link and we will ignore it
    const charBeforeLink = markdown[linkIndex - 1];
    if (charBeforeLink === "[" || charBeforeLink === "(") {
      return false;
    } else {
      return true;
    }
  });

  links.forEach(({ url }) => {
    markdown = markdown.replace(url, `[${url}](${url})`);
  });

  return [markdown, links];
}
