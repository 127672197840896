import React, { useEffect, useState } from "react";
import { connect, useDispatch, useSelector } from "react-redux";
import { bindActionCreators } from "redux";

// Redux actions
import { getPosts, setDataId, clearFeed } from "../../actions/socialActions";
import { showModalPage } from "../../actions/uiActions";

// Utilities
import getPageFromId from "../../utilities/get-page-from-id";

// Components
import ActionWrapper from "../ui/ActionWrapper";
import Page from "../ui/Page";
import TopBar from "../ui/TopBar";
import SocialPost from "./SocialPost";
import SocialPostModal from "./SocialPostModal";
import ScrollView from "../ui/ScrollView";
import InlineSpinner from "../ui/InlineSpinner";
import { PlusIcon, AccountEditIcon, InformationOutlineIcon } from "mdi-react";
import StatusBox from "../ui/StatusBox";
import TargetGroupModal from "../ui/TargetGroupModal";

/** `SocialFeed` handles general feed behaviour. This includes fetching posts,
 * displaying error-messages if fetching fails and controlling feed end conditions
 * etc.
 *
 * Note that `SocialFeed` is a view and is meant to be rendered by the router.
 * It doesn't really make sense to use this component directly.
 *
 * @example
 * ```jsx
 * <Route exact key={996} path="/:appname/Social/:pageId" component={SocialFeed} />
 * ```
 *
 */
function SocialFeed(props) {
  const { pages, match, getPosts, showModalPage, postsLoading, postsError, posts, endOfFeed, lang, setDataId } = props;
  const [page] = useState(getPageFromId(pages, match.params.pageId));
  const dispatch = useDispatch();
  const adminId = useSelector((s) => s.auth.user.adminId);

  /** Initializes the first retrieval of posts and subscribe to the resize event*/
  useEffect(() => {
    dispatch(clearFeed());

    setDataId(page.dataId);

    getPosts();
    window.addEventListener("resize", checkIfScrollViewIsFilled);
    return () => window.removeEventListener("resize", checkIfScrollViewIsFilled);
    // eslint-disable-next-line
  }, []);

  /** Effect to check if the 10 initially laoded posts is enough to make scroll view scrollable.
   * If not more are fetched until scroll view is scrollable or feed is empty
   */
  useEffect(() => {
    checkIfScrollViewIsFilled();
    // eslint-disable-next-line
  }, [posts]);

  /** Checks if ScrollView is filled with posts
   * ScrollView has to be scrollable in order to use the lazy loading mechanism (scroll triggered)
   */
  let debouncer;
  function checkIfScrollViewIsFilled() {
    clearTimeout(debouncer);
    debouncer = setTimeout(() => {
      let scrollView = document.getElementById("social-scroll-view");
      if (!endOfFeed && !postsLoading && !postsError && scrollView && scrollView.scrollHeight - 100 < window.innerHeight) {
        getPosts();
      }
    }, 500);
  }

  /** Fetches more posts when scrolled to the end of feed */
  function onScrollEnd() {
    if (!postsLoading && !postsError && !endOfFeed) {
      getPosts();
    }
  }

  return (
    <Page>
      <TopBar
        useDefaultBackButton={true}
        title={page.title}
        actionRight={
          <>
            {page.module.toLowerCase() === "chat" && adminId && (
              <ActionWrapper
                data-test-id="social-feed__edit-users"
                onClick={() =>
                  showModalPage({
                    title: "Rediger chatgruppe",
                    content: <TargetGroupModal pageId={page.id} />,
                    useScrollView: false,
                  })
                }
              >
                <AccountEditIcon />
              </ActionWrapper>
            )}
            <ActionWrapper
              data-test-id="social-feed__add-post"
              onClick={() =>
                showModalPage({ title: lang.addPost, content: <SocialPostModal mode="add" />, useScrollView: false })
              }
            >
              <PlusIcon />
            </ActionWrapper>
          </>
        }
      />

      <ScrollView style={{ padding: "1rem 0" }} onScrollEnd={onScrollEnd} id="social-scroll-view">
        {/* Posts */}
        {posts && posts.length > 0 && posts.map((post) => <SocialPost post={post} key={`social-post-${post.id}`} />)}

        {/* Spinner */}
        {postsLoading && !postsError && <InlineSpinner data-test-id="social-spinner" />}

        {/* Error */}
        {!postsLoading && postsError && posts.length === 0 && (
          <StatusBox action={{ callback: getPosts, title: lang.tryAgain }} />
        )}
        {/* Error */}
        {!postsLoading && !postsError && posts.length === 0 && (
          <StatusBox icon={<InformationOutlineIcon />} title={lang.noPostsHere} content={lang.comeBackSoon} />
        )}
      </ScrollView>
    </Page>
  );
}

const mapStateToProps = (state) => ({
  pages: state.pages.pages,
  lang: state.language.language,
  posts: state.social.posts,
  endOfFeed: state.social.endOfFeed,
  postsLoading: state.social.loading,
  postsError: state.social.error,
});

const mapDispatchToProps = (dispatch) => ({
  getPosts: bindActionCreators(getPosts, dispatch),
  setDataId: bindActionCreators(setDataId, dispatch),
  showModalPage: bindActionCreators(showModalPage, dispatch),
});

export default connect(mapStateToProps, mapDispatchToProps)(SocialFeed);
