// Libraries
import React, { useState, useEffect, useRef, Suspense } from "react";
import { connect, useSelector } from "react-redux";
import { bindActionCreators } from "redux";
import { hideModalPage, addToast, updateModalPage, showDialog } from "../../actions/uiActions";
import { refreshPostComments, clearFeed, getPosts, replacePost } from "../../actions/socialActions";
import { css } from "emotion";
import PropTypes from "prop-types";
import { format } from "date-fns";

// Styles
import colors from "../../style/colors";
import breakpoints from "../../config/breakpoints";

// Components
import Button from "../ui/Button";
import ImageUploadMultiple from "../ui/ImageUploadMultiple";
import { AlertDecagramIcon } from "mdi-react";
import TabBar from "../../components/ui/TabBar";
import TabView from "../../components/ui/TabView";
import SocialPost from "./SocialPost";

// Utils
import req from "../../utilities/request-utility";
import FileUpload from "../ui/FileUpload";
import addActualLineBreaksToMarkdownString from "../../utilities/add-actual-line-breaks-to-markdown-string";
// import TextEditor from "../ui/TextEditor";
const TextEditor = React.lazy(() => import("../ui/TextEditor"));
/** Modal page responsible for adding and editing posts.
 * The components implements multiple sub-components including ImageUploadMultiple and is used
 * through redux
 *
 * @example
 * ```jsx
 * function editPost() {
 *   showModalPage({ title: "Edit post", content: <SocialPostModal mode="edit" post={props.post} /> });
 * }
 * ```
 */

function SocialPostModal(props) {
  const { post, mode } = props;
  const { updateModalPage, showDialog, hideModalPage } = props;
  const dataId = useSelector((s) => s.social.currentDataId);
  const lang = useSelector((s) => s.language.language);

  // Get the primary colour of the app to use in the style sheet
  const primaryColor = useSelector((s) => s.appConfig.primaryColor);
  const [savingChanges, setSavingChanges] = useState(false);

  // To control whether it is the add/edit or preview tab that is visible
  const [activeTabIndex, setActiveTabIndex] = useState(0);

  // Set the initial state of the social post depending on whether the post is being edited or added.
  const [images, setImages] = useState(post ? post.images : []);
  const [videos, setVideos] = useState(post ? post.videos : []);
  const [file, setFile] = useState(post ? post.file : {});
  const [content, setContent] = useState(post ? post.content : "");

  const [disableButton, setDisableButton] = useState(true);
  const [fileUploadDisableButton, setFileUploadDisableButton] = useState(false);

  // Get the current user from Redux Store
  const user = useSelector((s) => s.auth.user);

  // Refs
  const unsavedChanges = useRef(false);

  // Only enable the post to be submitted if either an image, a file, a video or some content has been added.
  useEffect(() => {
    if (images.length > 0 || Object.keys(file).length > 0 || content || videos.length > 0) {
      setDisableButton(false);
      unsavedChanges.current = true; // Enables the close-form dialog
    } else {
      setDisableButton(true);
      unsavedChanges.current = false; // Disables the close-form dialog
    }
  }, [images, file, content, videos]);

  useEffect(() => {
    updateModalPage({
      closeCallback: () => {
        if (unsavedChanges.current) {
          showDialog({
            title: lang.unsavedChanges,
            content: lang.dialogUnsavedChangesContent,
            primaryActionTitle: lang.yesCloseForm,
            primaryAction: hideModalPage,
            secondaryActionTitle: lang.noDontCloseForm,
            styleType: "error",
            icon: <AlertDecagramIcon />,
          });
        } else {
          hideModalPage();
        }
      },
    });
    // eslint-disable-next-line
  }, []);

  async function addPost(post) {
    setSavingChanges(true);
    // Removes extra keys (our backend validation will complain about extra keys)
    post.images = post.images.map(({ baseURL, image }) => ({ image, baseURL }));
    post.videos = post.videos.map(({ baseURL, video }) => ({ video, baseURL }));
    post.content = addActualLineBreaksToMarkdownString(post.content.trim());
    req()
      .post(`social/${dataId}/posts/`, post)
      .then(() => {
        props.clearFeed();
        props.getPosts();
        props.hideModalPage();
      })
      .catch((err) => {
        setSavingChanges(false);
        props.addToast({
          title: lang.error,
          content: `${lang.errorAddingPost}. ${lang.tryAgainOrContactSupport}`,
          icon: <AlertDecagramIcon />,
          styleType: "warning",
          duration: 10000,
        });
      });
  }

  async function editPost(post) {
    setSavingChanges(true);
    // Removes extra keys (our backend validation will complain about extra keys)
    post.images = post.images.map(({ baseURL, image }) => ({ image, baseURL }));
    post.videos = post.videos.map(({ baseURL, video }) => ({ video, baseURL }));
    req()
      .put(`social/${dataId}/posts/${post.id}`, post)
      .then(({ data }) => {
        props.replacePost({ post: data, postId: post.id });
        props.hideModalPage();
      })
      .catch((err) => {
        setSavingChanges(false);
        props.addToast({
          title: lang.error,
          content: `${lang.errorEditingPost}. ${lang.tryAgainOrContactSupport}`,
          icon: <AlertDecagramIcon />,
          styleType: "warning",
          duration: 10000,
        });
      });
  }

  function checkIfImagesOrVideo() {
    if (images.length > 0) {
      return images;
    } else if (videos.length > 0) {
      return videos;
    } else return [];
  }

  function onFileUpdate(files) {
    if (files.length > 0) {
      files[0].fileType === "video" ? setVideos(files) : setImages(files);
    } else {
      setImages([]);
      setVideos([]);
    }
  }

  // const memoizedGetContentComponent = useMemo();

  function getContentComponent() {
    return (
      <div className={componentStyle(primaryColor)}>
        {/* Content */}
        <label htmlFor="content">{lang.content}</label>
        <Suspense fallback={<p className="meta">Loading editor...</p>}>
          <TextEditor value={content} onChange={setContent} />
        </Suspense>

        {/* Images */}
        <label htmlFor="images">{lang.imagesOrVideo}</label>
        <ImageUploadMultiple
          id={"image-upload__social-post-modal"}
          disabledSubmitBtn={() => setFileUploadDisableButton(true)}
          enableSubmitBtn={() => setFileUploadDisableButton(false)}
          uploadedFiles={checkIfImagesOrVideo()}
          style={{ marginBottom: "1.5rem" }}
          allowedAmountOfImages={5}
          disableVideoUpload={false}
          boxPadding={"1rem"}
          onFileUpdate={(files) => onFileUpdate(files)}
        />

        {/* File */}
        <label htmlFor="file">{lang.file}</label>
        <FileUpload
          value={file.path}
          removeFile={() => setFile({})}
          disabledSubmitBtn={() => setFileUploadDisableButton(true)}
          enableSubmitBtn={() => setFileUploadDisableButton(false)}
          onFile={(filePath) => setFile({ path: filePath, title: filePath.split("/").slice(-1)[0] })}
        />

        <Button
          data-test-id="btn-add-post"
          active={savingChanges}
          disabled={disableButton || fileUploadDisableButton}
          onClick={
            mode === "add"
              ? () => addPost({ content, images, file, videos })
              : () => editPost({ content, images, videos, file, id: post.id })
          }
          style={{ marginTop: "2rem" }}
        >
          {mode === "add" ? lang.addPost : lang.saveChanges}
        </Button>
      </div>
    );
  }

  function getPreviewComponent() {
    let postObj = {
      id: post ? post.id : 0,
      content: addActualLineBreaksToMarkdownString(content),
      author: user,
      images,
      likes: post ? post.likes : [],
      comments: post ? post.comments : [],
      date: post ? post.date : format(new Date(), "yyyyMMddHHmmss"),
      links: post ? post.externalLink : null,
      file,
      videos,
    };
    return <SocialPost post={postObj} disableInteractionsPreview={true} style={{ margin: "1rem auto" }} />;
  }

  return (
    <>
      <TabBar
        activeTabIndex={activeTabIndex}
        tabs={[lang.content, lang.preview].map((tab, tabIndex) => ({
          title: tab,
          onClick: () => setActiveTabIndex(tabIndex),
        }))}
      />
      <TabView activeTabIndex={activeTabIndex} tabs={[getContentComponent(), getPreviewComponent()]} useScrollView={true} />
    </>
  );
}

const componentStyle = (primaryColor) => css`
  background-color: ${colors.white};
  padding: 1rem;

  @media screen and (min-width: ${breakpoints.md}px) {
    margin: 1rem auto;
    border: 1px ${colors.midGrey} solid;
    border-radius: 3px;
    max-width: ${breakpoints.md}px;
  }

  label {
    display: block;
    margin-bottom: 0.5rem;
  }
`;

const mapDispatchToProps = (dispatch) => ({
  hideModalPage: bindActionCreators(hideModalPage, dispatch),
  showDialog: bindActionCreators(showDialog, dispatch),
  updateModalPage: bindActionCreators(updateModalPage, dispatch),
  addToast: bindActionCreators(addToast, dispatch),
  refreshPostComments: bindActionCreators(refreshPostComments, dispatch),
  clearFeed: bindActionCreators(clearFeed, dispatch),
  getPosts: bindActionCreators(getPosts, dispatch),
  replacePost: bindActionCreators(replacePost, dispatch),
});

export default connect(null, mapDispatchToProps)(SocialPostModal);

SocialPostModal.propTypes = {
  /** A potential post to edit. Not mandatory and is only meant for editng capabilities */
  post: PropTypes.object,
  /** Defines wether a post is being edited or added. can be "edit" or "add". Default to "edit" behaviour */
  mode: PropTypes.oneOf(["add", "edit"]),
};
