import React from "react";
import { ReactComponent as CustomHPBygIcon } from "../images/customIcons/hp-byg.svg";
import Icon from "@mdi/react";
import {
  mdiAccountGroup,
  mdiAlert,
  mdiAlertCircleOutline,
  mdiArchive,
  mdiBarcode,
  mdiBasket,
  mdiBellOutline,
  mdiBookOpenVariant,
  mdiCakeVariant,
  mdiHandshakeOutline,
  mdiCalendarRange,
  mdiCamera,
  mdiCardAccountDetails,
  mdiCash,
  mdiChartBar,
  mdiChartLine,
  mdiCheck,
  mdiCheckboxMarkedOutline,
  mdiClipboardAccount,
  mdiClipboardCheckOutline,
  mdiClockOutline,
  mdiCog,
  mdiCommentMultipleOutline,
  mdiCommentOutline,
  mdiCommentProcessing,
  mdiCommentQuestion,
  mdiCursorPointer,
  mdiDiamond,
  mdiEmailOutline,
  mdiEmoticonHappy,
  mdiEyeOutline,
  mdiFactory,
  mdiFamilyTree,
  mdiFlag,
  mdiFlash,
  mdiFlask,
  mdiFlaskOutline,
  mdiFolder,
  mdiGavel,
  mdiGlassCocktail,
  mdiHand,
  mdiHeadphones,
  mdiHeart,
  mdiHeartPulse,
  mdiHelp,
  mdiHelpCircle,
  mdiHuman,
  mdiHumanHandsup,
  mdiHumanMale,
  mdiInformation,
  mdiInformationOutline,
  mdiLeadPencil,
  mdiLeaf,
  mdiLifebuoy,
  mdiLightbulbOnOutline,
  mdiLock,
  mdiLogin,
  mdiMap,
  mdiMedicalBag,
  mdiNewspaper,
  mdiNoteMultipleOutline,
  mdiOfficeBuilding,
  mdiOpenInNew,
  mdiPencil,
  mdiPhone,
  mdiPineTree,
  mdiPodcast,
  mdiRewind,
  mdiRoadVariant,
  mdiRocketLaunch,
  mdiRoutes,
  mdiSchool,
  mdiShare,
  mdiSilverwareVariant,
  mdiSitemap,
  mdiSoccer,
  mdiStar,
  mdiStarOutline,
  mdiTrain,
  mdiTrophyVariant,
  mdiTruck,
  mdiWater,
  mdiWheelchairAccessibility,
  mdiWrench,
  mdiBed,
  mdiSquareEditOutline,
  mdiPound,
  mdiThumbUp,
  mdiBell,
  mdiTrainVariant,
  mdiDatabase,
} from "@mdi/js";

import { DecagramIcon, EarthIcon, FileIcon, FileDocumentOutlineIcon } from "mdi-react";

/**
 * This function takes a font-awesome string and returns a similar materialdesign icon
 * ---
 * Why are there two libraries?
 * The application was initially using 'mdi-react' which i still think provides the best DX as you can import the
 * icons directly. If i type <Hel and ctrl + space vs code will provide a list of available icons and choosing one
 * on the list will auto-import the icon and auto-fill <Hel to <HelpCirlceIcon />. But mdi-react is a 3rd party port
 * of the material-design-icons library so not all icons are present here. Therefore we are also using '@mdi/js' which
 * has all icons from www.materialdesignicons.com
 *
 *
 * @param {String} faIconName - name of font-aweseome icon
 * @param {Object} styles - Style object
 * @returns {ReactElement} - Returns a React element
 */

export default function (faIconName = "", styles = {}) {
  if (!faIconName) return <Icon path={mdiHelpCircle} className="base-icon" style={styles}></Icon>;

  faIconName = faIconName.trim().toLowerCase();

  switch (faIconName) {
    case "fa-exclamation-triangle":
      return <Icon path={mdiAlert} className="base-icon" style={styles}></Icon>;

    case "fa-bolt":
      return <Icon path={mdiFlash} className="base-icon" style={styles}></Icon>;

    case "fa-flask":
      return <Icon path={mdiFlask} className="base-icon" style={styles}></Icon>;

    case "fa-universal-access":
      return <Icon path={mdiHuman} className="base-icon" style={styles}></Icon>;

    case "fa-certificate":
      return <DecagramIcon style={styles} className="base-icon" />;

    case "fa-cubes":
      return <CustomHPBygIcon className="base-icon" style={styles} className="base-icon" />;

    case "fa-water":
    case "fa-tint":
      return <Icon path={mdiWater} className="base-icon" style={styles}></Icon>;

    case "fa-hashtag":
      return <Icon path={mdiPound} className="base-icon" style={styles}></Icon>;

    case "fa-check-square-o":
      return <Icon path={mdiCheckboxMarkedOutline} className="base-icon" style={styles}></Icon>;

    case "fa-copy":
    case "fa-files-o":
      return <Icon path={mdiNoteMultipleOutline} className="base-icon" style={styles}></Icon>;

    case "fa-heartbeat":
      return <Icon path={mdiHeartPulse} className="base-icon" style={styles}></Icon>;

    case "fa-clock":
    case "fa-clock-o":
      return <Icon path={mdiClockOutline} className="base-icon" style={styles}></Icon>;

    case "fa-camera":
      return <Icon path={mdiCamera} className="base-icon" style={styles}></Icon>;

    case "fa-external-link":
      return <Icon path={mdiOpenInNew} className="base-icon" style={styles}></Icon>;

    case "fa-info":
      return <Icon path={mdiInformationOutline} className="base-icon" style={styles}></Icon>;

    case "fa-industry":
    case "fa-factory":
      return <Icon path={mdiFactory} className="base-icon" style={styles}></Icon>;

    case "fa-family-tree":
      return <Icon path={mdiFamilyTree} className="base-icon" style={styles}></Icon>;

    case "fa-flag":
      return <Icon path={mdiFlag} className="base-icon" style={styles}></Icon>;

    case "fa-flask":
      return <Icon path={mdiFlaskOutline} className="base-icon" style={styles}></Icon>;

    case "fa-globe":
      return <EarthIcon style={styles} className="base-icon" />;

    case "fa-envelope-o":
      return <Icon path={mdiEmailOutline} className="base-icon" style={styles}></Icon>;

    case "fa-exclamation":
      return <Icon path={mdiAlertCircleOutline} className="base-icon" style={styles}></Icon>;

    case "fa-medkit":
      return <Icon path={mdiMedicalBag} className="base-icon" style={styles}></Icon>;

    case "fa-map":
      return <Icon path={mdiMap} className="base-icon" style={styles}></Icon>;

    case "fa-archive":
      return <Icon path={mdiArchive} className="base-icon" style={styles}></Icon>;

    case "fa-backward":
      return <Icon path={mdiRewind} className="base-icon" style={styles}></Icon>;

    case "fa-map-signs":
      return <Icon path={mdiRoutes} className="base-icon" style={styles}></Icon>;

    case "fa-road":
      return <Icon path={mdiRoadVariant} className="base-icon" style={styles}></Icon>;

    case "fa-rocket":
      return <Icon path={mdiRocketLaunch} className="base-icon" style={styles}></Icon>;

    case "fa-concrete":
      return <Icon path={mdiHumanHandsup} className="base-icon" style={styles}></Icon>;

    case "fa-male":
      return <Icon path={mdiHumanMale} className="base-icon" style={styles}></Icon>;

    case "fa-newspaper-o":
      return <Icon path={mdiNewspaper} className="base-icon" style={styles}></Icon>;

    case "fa-trophy":
      return <Icon path={mdiTrophyVariant} className="base-icon" style={styles}></Icon>;

    case "fa-comments-o":
    case "fa fa-comments-o":
      return <Icon path={mdiCommentMultipleOutline} className="base-icon" style={styles}></Icon>;

    case "fa-comment-o":
      return <Icon path={mdiCommentOutline} className="base-icon" style={styles}></Icon>;

    case "fa-smile-o":
      return <Icon path={mdiEmoticonHappy} className="base-icon" style={styles}></Icon>;

    case "fa-bell":
      return <Icon path={mdiBell} className="base-icon" style={styles}></Icon>;

    case "fa-train":
      return <Icon path={mdiTrainVariant} className="base-icon" style={styles}></Icon>;

    case "fa-chart":
      return <Icon path={mdiChartLine} className="base-icon" style={styles}></Icon>;

    case "fa-lightbulb-o":
      return <Icon path={mdiLightbulbOnOutline} className="base-icon" style={styles}></Icon>;

    case "fa-question":
      return <Icon path={mdiHelp} className="base-icon" style={styles}></Icon>;

    case "fa fa-question":
      return <Icon path={mdiCommentQuestion} className="base-icon" style={styles}></Icon>;

    case "fa-book":
      return <Icon path={mdiBookOpenVariant} className="base-icon" style={styles}></Icon>;

    case "fa-phone":
    case "fa fa-phone":
      return <Icon path={mdiPhone} className="base-icon" style={styles}></Icon>;

    case "fa-cutlery":
      return <Icon path={mdiSilverwareVariant} className="base-icon" style={styles}></Icon>;

    case "fa-sitemap":
      return <Icon path={mdiSitemap} className="base-icon" style={styles}></Icon>;

    case "fa-graduation-cap":
    case "fa-mortar-borad":
    case "fa fa-mortar-board":
      return <Icon path={mdiSchool} className="base-icon" style={styles}></Icon>;

    case "fa-pencil":
      return <Icon path={mdiPencil} className="base-icon" style={styles}></Icon>;

    case "fa-pencil-square-o":
      return <Icon path={mdiSquareEditOutline} className="base-icon" style={styles}></Icon>;

    case "fa-heart":
    case "fa fa-heart":
      return <Icon path={mdiHeart} className="base-icon" style={styles}></Icon>;

    case "fa-id-card-o":
    case "fa-address-card-o":
    case "fa-address-card":
      return <Icon path={mdiCardAccountDetails} className="base-icon" style={styles}></Icon>;

    case "fa-money":
      return <Icon path={mdiCash} className="base-icon" style={styles}></Icon>;

    case "fa-bed":
      return <Icon path={mdiBed} className="base-icon" style={styles}></Icon>;

    case "fa-commenting-o":
    case "fa fa-commenting":
      return <Icon path={mdiCommentProcessing} className="base-icon" style={styles}></Icon>;

    case "fa-birthday-cake":
    case "fa fa-birthday-cake":
      return <Icon path={mdiCakeVariant} className="base-icon" style={styles}></Icon>;

    case "fa-calendar":
    case "fa fa-calendar":
      return <Icon path={mdiCalendarRange} className="base-icon" style={styles}></Icon>;

    case "fa-tasks":
      return <Icon path={mdiClipboardCheckOutline} className="base-icon" style={styles}></Icon>;

    case "fa-futbol-o":
    case "fa-soccer-ball-o":
      return <Icon path={mdiSoccer} className="base-icon" style={styles}></Icon>;

    case "fa-tree":
      return <Icon path={mdiPineTree} className="base-icon" style={styles}></Icon>;

    case "fa-bar-chart":
      return <Icon path={mdiChartBar} className="base-icon" style={styles}></Icon>;

    case "fa-glass":
    case "fa fa-glass":
      return <Icon path={mdiGlassCocktail} className="base-icon" style={styles}></Icon>;

    case "fa-bell-o":
    case "fa fa-bell-o":
      return <Icon path={mdiBellOutline} className="base-icon" style={styles}></Icon>;

    case "fa-check":
      return <Icon path={mdiCheck} className="base-icon" style={styles}></Icon>;

    case "fa-id-badge":
      return <Icon path={mdiClipboardAccount} className="base-icon" style={styles}></Icon>;

    case "fa-users":
      return <Icon path={mdiAccountGroup} className="base-icon" style={styles}></Icon>;

    case "fa-building":
    case "fa-building-o":
      return <Icon path={mdiOfficeBuilding} className="base-icon" style={styles}></Icon>;

    case "fa-gavel":
      return <Icon path={mdiGavel} className="base-icon" style={styles}></Icon>;

    case "fa-diamond":
      return <Icon path={mdiDiamond} className="base-icon" style={styles}></Icon>;

    case "fa-shopping-basket":
      return <Icon path={mdiBasket} className="base-icon" style={styles}></Icon>;

    case "fa-train":
      return <Icon path={mdiTrain} className="base-icon" style={styles}></Icon>;

    case "fa-eye":
      return <Icon path={mdiEyeOutline} className="base-icon" style={styles}></Icon>;

    case "fa-share":
      return <Icon path={mdiShare} className="base-icon" style={styles}></Icon>;

    case "fa-info-circle":
      return <Icon path={mdiInformation} className="base-icon" style={styles}></Icon>;

    case "fa-pencil":
      return <Icon path={mdiLeadPencil} className="base-icon" style={styles}></Icon>;

    case "fa-envira":
      return <Icon path={mdiLeaf} className="base-icon" style={styles}></Icon>;

    case "fa-wheelchair":
      return <Icon path={mdiWheelchairAccessibility} className="base-icon" style={styles}></Icon>;

    case "fa-wrench":
      return <Icon path={mdiWrench} className="base-icon" style={styles}></Icon>;

    case "fa-hand-pointer-o":
    case "fa-hand-pointer":
      return <Icon path={mdiCursorPointer} className="base-icon" style={styles}></Icon>;

    case "fa-file":
    case "fa fa-file":
      return <FileIcon style={styles} className="base-icon" />;

    case "fa-file-text-o":
      return <FileDocumentOutlineIcon style={styles} className="base-icon" />;

    case "fa-truck":
      return <Icon path={mdiTruck} className="base-icon" style={styles}></Icon>;

    case "fa-hand-spock-o":
    case "fa-hand":
      return <Icon path={mdiHand} className="base-icon" style={styles}></Icon>;

    case "fa-headphones":
      return <Icon path={mdiHeadphones} className="base-icon" style={styles}></Icon>;

    case "fa-sign-in":
      return <Icon path={mdiLogin} className="base-icon" style={styles}></Icon>;

    case "fa-podcast":
      return <Icon path={mdiPodcast} className="base-icon" style={styles}></Icon>;

    case "fa-handshake-o":
      return <Icon path={mdiHandshakeOutline} className="base-icon" style={styles}></Icon>;

    case "fa-life-ring":
      return <Icon path={mdiLifebuoy} className="base-icon" style={styles}></Icon>;

    case "fa-folder":
      return <Icon path={mdiFolder} className="base-icon" style={styles}></Icon>;

    case "fa-lock":
      return <Icon path={mdiLock} className="base-icon" style={styles}></Icon>;

    case "fa-cog":
    case "fa-gear":
      return <Icon path={mdiCog} className="base-icon" style={styles}></Icon>;

    case "fa-star-o":
      return <Icon path={mdiStarOutline} className="base-icon" style={styles}></Icon>;

    case "fa-star":
      return <Icon path={mdiStar} className="base-icon" style={styles}></Icon>;

    case "fa-line-chart":
      return <Icon path={mdiChartLine} className="base-icon" style={styles}></Icon>;

    case "fa-barcode":
      return <Icon path={mdiBarcode} className="base-icon" style={styles}></Icon>;

    case "fa-thumbs-up":
      return <Icon path={mdiThumbUp} className="base-icon" style={styles}></Icon>;

    case "fa-database":
      return <Icon path={mdiDatabase} className="base-icon" style={styles}></Icon>;

    default:
      return <Icon path={mdiHelpCircle} className="base-icon" style={styles}></Icon>;
  }
}
