import req from "../utilities/request-utility";

import { GET_PAGES, SET_TRANSITION_DIRECTION, GET_PAGES_SUCCESS, GET_PAGES_FAILURE } from "./actionTypes";

export function getPages() {
  return function (dispatch, getState) {
    dispatch({
      type: GET_PAGES,
    });

    req()
      .get("/pages")
      .then((res) => res.data)
      .then((pages) => {
        dispatch({
          type: GET_PAGES_SUCCESS,
          payload: pages,
        });

        let appTitle = getState().appConfig.title;
        updateAppTitle(pages, appTitle);
      })
      .catch((err) => {
        dispatch({
          type: GET_PAGES_FAILURE,
        });
      });
  };
}

function updateAppTitle(pages, title) {
  let notifications = 0;

  // Notifications are bubbled to parent so this should count all notifications from pages
  pages.forEach((page) => {
    notifications += page.notifications;
  });

  if (notifications > 100) notifications = "99+";

  if (notifications > 0 || notifications === "99+") {
    document.title = `(${notifications}) ${title}`;
  } else {
    document.title = `${title}`;
  }
}

export function setTransitionMode(direction, autoRemove = true) {
  return function (dispatch) {
    dispatch({
      type: SET_TRANSITION_DIRECTION,
      payload: direction,
    });

    // Auto-removes animation so it won't be reused. This is usually what you want.
    // Disable it by setting autoRemove to false when calling the function
    if (autoRemove === true) {
      setTimeout(
        () =>
          dispatch({
            type: SET_TRANSITION_DIRECTION,
            payload: "",
          }),
        300
      );
    }
  };
}
