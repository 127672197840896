// Libs
import React from "react";
import { css } from "emotion";
import { CSSTransition } from "react-transition-group";
import tinycolor from "tinycolor2";
import { useDispatch, useSelector } from "react-redux";

// Confi
import { durations } from "../../config/animations";

// Components
import ActionWrapper from "./ActionWrapper";

// Styles
import colors from "../../style/colors";

// Actions
import { showModalPage } from "../../actions/uiActions";

// Utilities
import iconConverter from "../../utilities/icon-converter";
import req from "../../utilities/request-utility";

// Components
import ThemeQuizQuestion from "../quiz/ThemeQuizQuestion";

const ThemeQuizFloatingActionButton = (props) => {
  const state = useSelector((s) => s.themeQuiz.floatingActionButton);
  const primaryColor = useSelector((s) => s.appConfig.primaryColor);
  const dispatch = useDispatch();

  async function handleButtonClick() {
    try {
      const { data: question } = await req()(`quiz/${state.quizId}/questions/random`);
      dispatch(
        showModalPage({
          content: (
            <ThemeQuizQuestion question={{ ...question, ...{ quizId: state.quizId, campaignId: state.campaignId } }} />
          ),
        })
      );
    } catch (error) {
      console.log(error);
    }
  }

  return (
    <div className={container(primaryColor)}>
      <CSSTransition
        in={state.active}
        timeout={durations.normal}
        mountOnEnter={true}
        unmountOnExit={true}
        classNames="theme-quiz-floating-action-button"
      >
        <ActionWrapper className="theme-quiz-floating-action-button" key="0" onClick={handleButtonClick}>
          {iconConverter(state.icon)}
        </ActionWrapper>
      </CSSTransition>
    </div>
  );
};

const container = (primaryColor) => css`
  .theme-quiz-floating-action-button {
    position: absolute;
    bottom: 12px;
    right: 12px;
    width: 64px;
    height: 64px;
    border-radius: 50%;
    background-color: ${primaryColor};
    will-change: auto;
    z-index: 10;
    display: flex;
    justify-content: center;
    align-items: center;
    transition: background-color 160ms ease;

    svg {
      width: 37px;
      height: 37px;
      color: ${colors.white};
      margin-top: -2px;
    }

    @media screen and (min-width: 800px) {
      bottom: 24px;
      right: 24px;
      width: 74px;
      height: 74px;

      svg {
        width: 45px;
        height: 45px;
      }
    }

    &:active {
      background-color: ${tinycolor(primaryColor).darken(10).toString()};
      transition: background-color 90ms ease;
    }

    &.theme-quiz-floating-action-button-enter {
      opacity: 0;
      transform: scale(0) rotate(40deg);
    }
    &.theme-quiz-floating-action-button-enter-active {
      opacity: 1;
      transform: scale(1) rotate(0);
      transition: opacity ${durations.normal}ms, transform ${durations.normal}ms cubic-bezier(0.585, 0.005, 0, 1.465);
    }
    &.theme-quiz-floating-action-button-exit {
      opacity: 1;
      transform: scale(1);
    }
    &.theme-quiz-floating-action-button-exit-active {
      opacity: 0;
      transform: scale(0);
      transition: opacity ${durations.normal}ms, transform ${durations.normal}ms;
    }
  }

  .theme-question-container {
    padding: 1rem;
  }
`;

export default ThemeQuizFloatingActionButton;
