// Libs
import React, { createRef, useEffect } from "react";
import { css } from "emotion";
import { disableBodyScroll, enableBodyScroll } from "body-scroll-lock";

const ScrollView = (props) => {
  const targetRef = createRef();

  function onScroll(e) {
    if (
      typeof props.onScrollEnd === "function" &&
      e.target.scrollTop + e.target.getBoundingClientRect().height + (200 || props.scrollThreshold) > e.target.scrollHeight
    ) {
      props.onScrollEnd();
    }

    if (typeof props.onScroll === "function") {
      // For legacy implementations where the scrollView's scroll is handled in parent component
      props.onScroll(e);
    }
  }

  useEffect(() => {
    disableBodyScroll(targetRef.current);
    return () => {
      enableBodyScroll(targetRef.current);
    };
  }, []);

  return (
    <div
      id={props.id}
      ref={targetRef}
      className={`scroll-view ${componentStyle(props)} ${props.className || ""} ${props.maxWidth ? "use-max-width" : ""}`}
      style={props.style}
      onScroll={onScroll}
      data-test-id={props["data-test-id"]}
    >
      {props.children}
    </div>
  );
};

const componentStyle = (props) => css`
  flex: 100% 1 1;
  overflow-x: hidden;
  overflow-y: auto;
  -webkit-overflow-scrolling: touch;

  &.use-max-width {
    max-width: ${props.maxWidth}px;
    margin: 0 auto;
  }
`;

export default ScrollView;
