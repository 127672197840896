import React from "react";
import Icon from "@mdi/react";
import {
  WrenchIcon,
  EthernetCableIcon,
  BookOpenVariantIcon,
  ClipboardCheckOutlineIcon,
  ClipboardTextOutlineIcon,
  PowerPlugIcon,
  TableSearchIcon,
  CalendarClockIcon,
  CalendarRemoveIcon,
  PlaylistCheckIcon,
  CalendarQuestionIcon,
  MedalIcon,
  FlaskOutlineIcon,
  DumbbellIcon,
  HammerIcon,
  HelpCircleIcon,
  LightbulbOnOutlineIcon,
  EggEasterIcon,
  TruckTrailerIcon,
  LaptopIcon,
  MonitorIcon,
  SitemapIcon,
  WeatherSunnyIcon,
  HumanHandsupIcon,
  PlusIcon,
} from "mdi-react";
import {
  mdiAccountMultiple,
  mdiCalendarMonth,
  mdiClipboardAccount,
  mdiClipboardAccountOutline,
  mdiEmoticonOutline,
  mdiFolderMultiplePlusOutline,
  mdiHammerScrewdriver,
  mdiHumanGreeting,
  mdiNewspaperVariantOutline,
  mdiWhistleOutline,
  mdiCashMultiple,
  mdiIsland,
  mdiAccountHardHat,
  mdiAccountVoice,
  mdiHardHat,
  mdiAccountGroup,
  mdiHalloween,
} from "@mdi/js";

export default function (mdiString = "", styles = null) {
  if (!mdiString) return <HelpCircleIcon style={styles} className="base-icon" />;

  mdiString = mdiString.trim().toLowerCase();

  switch (mdiString) {
    case "halloween":
      return <Icon path={mdiHalloween} className="base-icon" style={styles}></Icon>;
    case "account-group":
      return <Icon path={mdiAccountGroup} className="base-icon" style={styles}></Icon>;
    case "hard-hat":
      return <Icon path={mdiHardHat} className="base-icon" style={styles}></Icon>;
    case "human-greeting":
      return <Icon path={mdiHumanGreeting} className="base-icon" style={styles}></Icon>;
    case "account-hard-hat":
      return <Icon path={mdiAccountHardHat} className="base-icon" style={styles}></Icon>;
    case "island":
      return <Icon path={mdiIsland} className="base-icon" style={styles}></Icon>;
    case "cash-multiple":
      return <Icon path={mdiCashMultiple} className="base-icon" style={styles}></Icon>;
    case "calendar-month":
      return <Icon path={mdiCalendarMonth} className="base-icon" style={styles}></Icon>;
    case "emoticon-outline":
      return <Icon path={mdiEmoticonOutline} className="base-icon" style={styles}></Icon>;
    case "clipboard-account":
      return <Icon path={mdiClipboardAccount} className="base-icon" style={styles}></Icon>;
    case "newspaper-variant-outline":
      return <Icon path={mdiNewspaperVariantOutline} className="base-icon" style={styles}></Icon>;
    case "folder-multiple-plus-outline":
      return <Icon path={mdiFolderMultiplePlusOutline} className="base-icon" style={styles}></Icon>;
    case "sitemap":
      return <SitemapIcon style={styles} className="base-icon" />;
    case "hammer-screwdriver":
      return <Icon path={mdiHammerScrewdriver} className="base-icon" style={styles}></Icon>;
    case "account-multiple":
      return <Icon path={mdiAccountMultiple} className="base-icon" style={styles}></Icon>;
    case "laptop":
      return <LaptopIcon style={styles} className="base-icon" />;
    case "monitor":
      return <MonitorIcon style={styles} className="base-icon" />;
    case "lightbulb-on-outline":
      return <LightbulbOnOutlineIcon style={styles} className="base-icon" />;
    case "clipboard-account":
      return <Icon path={mdiClipboardAccountOutline} className="base-icon" style={styles}></Icon>;
    case "hammer":
      return <HammerIcon style={styles} className="base-icon" />;
    case "truck-trailer":
      return <TruckTrailerIcon style={styles} className="base-icon" />;
    case "flask-outline":
      return <FlaskOutlineIcon style={styles} className="base-icon" />;
    case "egg-easter":
      return <EggEasterIcon style={styles} className="base-icon" />;
    case "dumbbell":
      return <DumbbellIcon style={styles} className="base-icon" />;
    case "whistle-outline":
      return <Icon path={mdiWhistleOutline} className="base-icon" style={styles}></Icon>;
    case "medal":
      return <MedalIcon style={styles} className="base-icon" />;
    case "plus":
      return <PlusIcon style={styles} className="base-icon" />;
    case "calendar-clock":
      return <CalendarClockIcon style={styles} className="base-icon" />;
    case "calendar-remove":
      return <CalendarRemoveIcon style={styles} className="base-icon" />;
    case "calendar-question":
      return <CalendarQuestionIcon style={styles} className="base-icon" />;
    case "wrench-outline":
      return <WrenchIcon style={styles} className="base-icon" />;
    case "human-handsup":
      return <HumanHandsupIcon style={styles} className="base-icon" />;
    case "ethernet-cable":
      return <EthernetCableIcon style={styles} className="base-icon" />;
    case "power-plug":
      return <PowerPlugIcon style={styles} className="base-icon" />;
    case "book-open-variant":
      return <BookOpenVariantIcon style={styles} className="base-icon" />;
    case "clipboard-check-outline":
      return <ClipboardCheckOutlineIcon style={styles} className="base-icon" />;
    case "clipboard-text-outline":
      return <ClipboardTextOutlineIcon style={styles} className="base-icon" />;
    case "playlist-check-icon":
      return <PlaylistCheckIcon style={styles} className="base-icon" />;
    case "table-search":
      return <TableSearchIcon style={styles} className="base-icon" />;
    case "account-voice":
      return <Icon path={mdiAccountVoice} className="base-icon" style={styles}></Icon>;
    case "weather-sunny":
      return <WeatherSunnyIcon style={styles} className="base-icon" />;
    default:
      return <HelpCircleIcon style={styles} className="base-icon" />;
  }
}
