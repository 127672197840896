import React, { useState, useEffect } from "react";
import { useSelector } from "react-redux";

// Components
import Page from "../ui/Page";
import TopBar from "../ui/TopBar";
import ScrollView from "../ui/ScrollView";
import ListItem from "../ui/ListItem";
import Notification from "../ui/Notification";

// Utilities
import getPageFromId from "../../utilities/get-page-from-id";
import req from "../../utilities/request-utility";
import pageNavigatorV2 from "../../utilities/page-navigator-v2";
import getAppName from "../../utilities/get-app-name";
import linkPathCreator from "../../utilities/get-link-path-from-page-object";
import iconConverter from "../../utilities/icon-converter";

// Style
import breakpoints from "../../config/breakpoints";
import colors from "../../style/colors";

// Icons
import { ChevronRightIcon } from "mdi-react";

function RegistrationNavigationList(props) {
  const pages = useSelector((s) => s.pages.pages);
  const page = getPageFromId(pages, props.match.params.pageId);

  const [registrations, setRegistrations] = useState({
    data: [],
    error: false,
    loading: true,
  });

  useEffect(() => {
    getRegistrations();
  }, []);

  async function getRegistrations() {
    try {
      let { data } = await req()(`registration`);
      setRegistrations({ error: false, loading: false, data });
    } catch (err) {
      setRegistrations({ error: true, loading: false });
    }
  }

  function getPotentialPages() {
    const subPages =
      page.pages.length === 1 && page.pages[0].pages && page.pages[0].pages.length > 0 ? page.pages[0].pages : page.pages;
    return subPages.map((subPage) => (
      <ListItem
        maxWidth={breakpoints.lg}
        clickable={true}
        onClick={() =>
          pageNavigatorV2({ path: linkPathCreator(subPage, "absolute"), direction: "forward", pageId: subPage.id })
        }
        title={subPage.title}
        iconLeft={subPage.icon ? iconConverter(subPage.icon, { color: colors.darkGrey }) : null}
        notificationsRight={subPage.notifications > 0 && <Notification notifications={subPage.notifications} />}
        iconRight={<ChevronRightIcon />}
        key={subPage.id}
      />
    ));
  }

  return (
    <Page>
      <TopBar title={page.title} useDefaultBackButton={true} />

      <ScrollView style={{ padding: "1rem 0" }}>
        {!registrations.loading &&
          !registrations.error &&
          registrations.data.map((registration) => (
            <ListItem
              maxWidth={breakpoints.lg}
              clickable={true}
              onClick={() =>
                pageNavigatorV2({
                  path: `/${getAppName()}/registration${registration.showRegistrationsFeed ? "" : "-form"}/${
                    page.id
                  }?dataId=${registration.id}&registrationTitle=${registration.title}`,
                  direction: "forward",
                })
              }
              title={registration.title}
              iconRight={<ChevronRightIcon />}
              key={registration.id}
            />
          ))}
        {getPotentialPages()}
      </ScrollView>
    </Page>
  );
}

export default RegistrationNavigationList;
