import React from "react";
import { css } from "emotion";
import colors from "../../style/colors";
import PropTypes from "prop-types";

/** used for displaying item as part of a list, usecase could be: users on a highscore, events you can sign up to etc.
 * ```
 *  <ListItem
                      imageLeft={getProfilePictureFromUserObject(birthdayUser)}
                      title={birthdayUser.name}
                      subTitle={birthdayUser.userGroup}
                      style={{ marginLeft: `-${boxPadding}`, marginRight: `-${boxPadding}` }}
                    />
 * ```
*/

const ListItem = (props) => (
  <div
    style={props.style}
    ref={props.scrollTopRef}
    className={componentStyle(props) + `${props.maxWidth ? " enable-max-width" : ""} ${props.className || ""}`}
    onClick={props.onClick}
    data-test-id={props.dataTestId}
  >
    {/* Number left */}
    {props.itemNumberLeft && <div className="icon-left">{props.itemNumberLeft}</div>}
    {/* Icon left */}
    {props.iconLeft && <div className="icon-left">{props.iconLeft}</div>}
    {/* Image left */}
    {props.imageLeft && <div>{props.imageLeft}</div>}

    <div className={`content` + (props.wrapContent !== true ? " wrap" : " nowrap")}>
      <p data-test-id={props.dataTestIdTitle} style={props.titleStyle && props.titleStyle} className="title">
        {props.title}
      </p>
      <p data-test-id={props.dataTestIdmiddleTitle} style={props.middleTitleStyle} className="middle-title">
        {props.middleTitle}
      </p>
      <p data-test-id={props.dataTestIdSubTitle} style={props.subTitleStyle} className="meta">
        {props.subTitle}
      </p>
      {props.content && props.content}
    </div>

    {props.notificationsRight && (
      <div className="icon-right" style={{ marginRight: "0.25rem" }}>
        {props.notificationsRight}
      </div>
    )}

    {props.iconRight && (
      <div className="icon-right" style={props.iconRightStyle && props.iconRightStyle}>
        {props.iconRight}
      </div>
    )}
    {props.contentRight && (
      <div className="content-right" style={props.contentRightStyle && props.contentRightStyle}>
        {props.contentRight}
      </div>
    )}
  </div>
);

const componentStyle = (props) => css`
  margin-bottom: -1px;
  background-color: ${colors.white};
  border-top: 1px solid ${colors.midGrey};
  border-bottom: 1px solid ${colors.midGrey};
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 1rem 1rem;
  color: ${colors.black};
  flex-shrink: 0;
  flex-grow: 0;

  &:last-child {
    margin-bottom: 0;
  }

  &.enable-max-width {
    @media screen and (min-width: ${props.maxWidth}px) {
      max-width: ${props.maxWidth}px;
      margin-left: auto !important;
      margin-right: auto !important;
      border: 1px solid ${colors.midGrey};
    }

    &:first-of-type {
      border-top-left-radius: 3px;
      border-top-right-radius: 3px;
    }
    &:last-of-type {
      border-bottom-left-radius: 3px;
      border-bottom-right-radius: 3px;
    }
  }

  &:active,
  &:hover {
    ${props.clickable && `background-color: ${colors.ultraLightGrey};`}
    ${props.clickable && `cursor: pointer;`}
  }

  .icon-left {
    margin-right: ${props.imageLeft === false ? "5px" : "0.5rem"};
    margin-left: ${props.imageLeft === false ? "-5px" : "initial"};
  }
  .icon-right {
    margin-right: 0rem;
  }
  .icon-left,
  .icon-right {
    flex-basis: 20px;
    flex-shrink: 0;
    display: flex;
    justify-content: center;
    align-items: center;
  }
  .content {
    flex: 1 1 100%;
    overflow: hidden;
    min-width: 0;
    padding-right: 0.5rem;

    /* Default option */
    &.nowrap {
      .title {
        white-space: nowrap;
        overflow: hidden;
        text-overflow: ellipsis;
      }

      .middle-title {
        font-size: 0.9rem;
        margin-bottom: 0.125rem;
        color: ${colors.darkGrey};
      }

      .meta {
        color: ${colors.darkGrey};
        white-space: nowrap;
        overflow: hidden;
        text-overflow: ellipsis;
      }
    }

    /* Wraps content to new line if it overflows */
    &.wrap {
      .title {
        white-space: initial;
      }

      .meta {
        color: ${colors.darkGrey};
        white-space: initial;
      }
    }
  }
  .image-wrapper {
    width: 30px;
    height: 30px;
    border: 1px #ccc solid;
    border-radius: 50%;
    flex: 0 0 30px;
    margin-right: 0.5rem;
  }
`;

ListItem.propTypes = {
  /**  Used for displaying an icon on the left side */
  iconLeft: PropTypes.object,
  /**  Used for displaying an image on the left side */
  imageLeft: PropTypes.object,

  /**  Used for displaying an icon on the right side */
  // iconRight: PropTypes.object,

  /**  Used for displaying text-based content on the right side */
  contentRight: PropTypes.string,

  /** Content main title, for related info, use subtitle Prop */
  title: PropTypes.oneOfType([PropTypes.object, PropTypes.string]),

  /** Descriptive textblock related to title prop */
  subTitle: PropTypes.oneOfType([PropTypes.object, PropTypes.string]),

  /** used for deciding if content should wrap */
  wrapContent: PropTypes.bool,
  /** Used to place a component or element that has nested components */
  content: PropTypes.object,
};
export default ListItem;
