/** The markdown-editor in ekko updater spits out some weird things. This function tries to clean it up as good as it can. */

export default function (markDownString = "") {
  // Should be fixed with default value but apparently null, will not be set as "".
  // Now strings can't be the number 0, "", false, null, [] and undefined
  if (!markDownString) markDownString = "";

  /**
   * Markdown coming from ekko updater CAN be invalid for bold. A markdown editor is supposed to strip excess spaces
   * from line endings when having formatting but ekko updater doesn't do this. This means that if you mark something as bold
   * and then leave a trailing space, it won't work and the user will see * characteres instead.
   *
   * Example from ekko updater:
   * **This is bold **\n\nHere is the next Line
   *               ^- This space is the problem
   *
   * So what this regex will do is to say
   * 1)  [^\s*]     = One of any non-whitespace characters EXCEPT Asterix ("*")(ie. "m")
   * 2)  [ \u00A0]+ = One or more of either " " (regular space) or the unicode character 00A0 (a weird space that is somehow in there?) (ie. "  ")
   * 3)  \*\*       = Two asterix (ie. "**")
   * 4)  ($|\s)     = Whitespace OR end of string
   *
   * so {any none-whitespace character} + {one or more of space or the weird database space (00A0)} + {exactly two asterix'es} is replaced with "** "
   *
   * Lets say you had the string: "**This is bold **\n\nHere is the next Line"
   * This string would then be:   "**This is bold** \n\nHere is the next Line"
   */
  markDownString = markDownString.replace(/[^\s*][ \u00A0]+\*\*($|\s)/g, "** ");

  markDownString = markDownString.replace(/[^\s_][ \u00A0]+\_($|\s)/g, "_ ");

  markDownString = markDownString.replace(/\]\s\(/g, "]("); //replaces "] (" with "]("

  /*
    For reference:
    There is to types of line-feeds: New paragraph (or line-feed, end of line etc) and soft feeds. Normally they will ne
    \n for soft feeds and
    \r\n\ for new paragraphs. 

    For some reason i have figured out that with ekko updater this is what they actually are:

    \r\n\r\n/ = New paragraph (Just enter in word, google docs etc)
    \s\s\r\n/ = Soft feed (shift-enter in word, google docs etc) 
  */
  markDownString = markDownString.replace(/\r\n\r\n/g, " &nbsp;\n\n");
  markDownString = markDownString.replace(/\s\s\r\n/g, " &nbsp;\n");

  return markDownString;
}
