import React from "react";

const modes = {
  jsx: "JSX",
  markdown: "MARKDOWN",
};

export { modes };

/**
 * Utility function for highlighting matching words in a string
 * ---------------------
 * Arguments
 * @param {string} searchstring Searchstring in which to find and highlight matches
 * @param {string} searchterme term to highlight
 * @param {string} mode the serach mode. Defaults to JSX
 * @param {Object} style style to apply to the matched element
 */
export default (searchstring, searchterm, mode = "JSX", style = { fontWeight: 700 }) => {
  searchstring = String(searchstring);
  searchterm = String(searchterm);

  // Get matchStartIndex
  let matchStart = searchstring.toLowerCase().trim().indexOf(searchterm.toLowerCase());

  // No match found in string (early return)
  if (matchStart === -1) return searchstring;

  // Get match end index
  let matchEnd = matchStart + searchterm.length;

  // Split string to array
  searchstring = searchstring.split("");

  // Get string beofore, at and after match
  let strBeforeMatch = searchstring.slice(0, matchStart).join("");
  let strMatch = searchstring.slice(matchStart, matchEnd).join("");
  let strAfterMatch = searchstring.slice(matchEnd).join("");

  // Return jsx-element (no XSS-vulnabilities since html in the strings would never be parsed)
  if (mode === modes.jsx) {
    return (
      <span>
        {strBeforeMatch || ""}
        <span className="match" style={style}>
          {strMatch || ""}
        </span>
        {strAfterMatch || ""}
      </span>
    );
  }
  if (mode === modes.markdown) {
    return `${strBeforeMatch}<span class="match">${strMatch}</span>${strAfterMatch}`;
  }
};
