import { format, parse } from "date-fns";

// Utilities

export function parseDatetime(datetimeString) {
  /**
   * Expects a string-formatted timestamp and returns a human-friendly date
   * --------------
   * @parameter dateTimeString [string] a datetime string in the format of YYYYMMDDHHmm (201812302230)
   * @returns [string] formatted string 201812302230 -> 30/12-2018 22:30
   */

  try {
    return format(parse(datetimeString, "yyyyMMddHHmm", 0), "dd/MM-yyyy HH:mm");
  } catch {
    return "";
  }
}

export function parseDatetimeExtended(datetimeString) {
  /**
   * Expects a string-formatted timestamp and returns a human-friendly date
   * --------------
   * @parameter dateTimeString [string] a datetime string in the format of YYYYMMDDHHmmss (20181230223010)
   * @returns [string] formatted string 20181230223010 -> 30/12-2018 22:30:10
   */

  try {
    return format(parse(datetimeString, "yyyyMMddHHmmss", 0), "dd/MM-yyyy HH:mm:ss");
  } catch {
    return "";
  }
}

export function parseDateTimeMinimal(datetimeString) {
  return format(parse(datetimeString, "yyyyMMddHHmmss", 0), "HH:mm:ss");
}

export function parseDate(dateString, dateFormat = "dd/MM-yyyy") {
  /**
   * Expects a string-formatted timestamp and returns a human-friendly date
   * --------------
   * @parameter dateTimeString [string] a datetime string in the format of YYYYMMDD (20181230)
   * @returns [string] formatted string 20181230 -> 30/12-2018
   */

  try {
    return format(parse(dateString, "yyyyMMdd", 0), dateFormat);
  } catch {
    return "";
  }
}

export function parseTime(timeString) {
  /**
   * Expects a string-formatted timestamp and returns a human-friendly date
   * --------------
   * @parameter dateTimeString [string] a datetime string in the format of YYYYMMDDHHmm (0120)
   * @returns [string] formatted string 0120 -> 1:20
   */

  return format(parse(timeString, "HHmm", 0), "HH:mm");
}

/**
 * Expects a string-formatted timestamp and returns a human-friendly date
 * --------------
 * @parameter dateTimeString [string] a datetime string in the format of MMDD (1230)
 * @returns [string] formatted string 1230 -> 30/12
 */
export function parseMonthAndDate(dateString) {
  return format(parse(dateString, "MMdd", 0), "dd/MM");
}

export function parseDateAndTime(dateString, timeString) {
  dateString = dateString + timeString;

  try {
    return format(parse(dateString, "yyyyMMddHHmm", 0), "dd/MM-yyyy HH:mm");
  } catch {
    return "";
  }
}

/* GET DATES */

export function getDatetimeString() {
  /**
   * Returns now as a datetimeString (yyyyMMddHHmm)
   * --------------
   * No params
   * @returns [string] yyyyMMddHHmm example: 201801282359
   */

  return format(new Date(), "yyyyMMddHHmm");
}

export function getDateString() {
  /**
   * Returns now as a datetimeString (yyyyMMdd)
   * --------------
   * No params
   * @returns [string] yyyyMMdd example: 20180128
   */
  return format(new Date(), "yyyyMMdd");
}

export function getYearString() {
  /**
   * Returns now as a datetimeString (yyyy)
   * --------------
   * No params
   * @returns [string] yyyy example: 2018
   */
  return format(new Date(), "yyyy");
}

export function getMonthAndDateString() {
  /**
   * Returns now as a datetimeString (MMdd)
   * --------------
   * No params
   * @returns [string] yyyyMMddHHmm example: 20180128
   */
  return format(new Date(), "MMdd");
}
