// Libs
import React, { useEffect, useState } from "react";
import { connect, useDispatch, useSelector } from "react-redux";
import * as queryString from "query-string";
import { bindActionCreators } from "redux";

// Components
import TopBar from "../ui/TopBar";
import Page from "../ui/Page";
import TabBar from "../ui/TabBar";
import TabView from "../ui/TabView";
import ActionWrapper from "../ui/ActionWrapper";
import RegistrationPosts from "./RegistrationPosts";
import RegistrationMostLikedPosts from "./RegistrationMostLikedPosts";
import RegistrationFormBuilder from "./RegistrationFormBuilder";

// Styles
import { PlusIcon } from "mdi-react";

// Utilities and config
import { feedTypes } from "./config";
import getPageFromId from "../../utilities/get-page-from-id";
import getRegistrationLayout from "../../utilities/get-registration-layout";
import req from "../../utilities/request-utility";

// Actions
import { getRegistrationPosts, resetAll } from "../../actions/registrationActions";
import { addToast, hideDialog, hideModalPage, showDialog, showModalPage } from "../../actions/uiActions";

const RegistrationOverview = (props) => {
  const dispatch = useDispatch();

  // Page for navigation
  const { match } = props;
  const pages = useSelector((state) => state.pages.pages);
  const [page] = useState(getPageFromId(pages, match.params.pageId));
  const [registrationConfig, setRegistrationConfig] = useState({});
  const LAYOUT_MODE = getRegistrationLayout({ path: match.path });

  // Multi-language handling
  const lang = useSelector((state) => state.language.language);

  // UI Layout
  const [tabs] = useState([lang.new, lang.mostHelpful, lang.mine]);
  const [activeTab, setActiveTab] = useState(0);

  // Get the registrationId / dataId
  let { dataId: registrationId } = queryString.parse(window.location.search);
  let { registrationTitle: pageOverrideFromQueryParams } = queryString.parse(window.location.search);

  // Initial componentDidMount load the posts for the registration
  useEffect(() => {
    props.resetAll();
    getRegistrationConfiguration(registrationId);
  }, []);

  const posts = useSelector(
    (state) => state.registration[[feedTypes.all, feedTypes.mostLiked, feedTypes.mine][activeTab]].posts
  );

  const getPostsByActiveTab = () => {
    if (activeTab === 0) props.getRegistrationPosts({ registrationId, feedType: feedTypes.all });
    if (activeTab === 1) props.getRegistrationPosts({ registrationId, feedType: feedTypes.mostLiked });
    if (activeTab === 2) props.getRegistrationPosts({ registrationId, feedType: feedTypes.mine });
  };

  useEffect(() => {
    getPostsByActiveTab();
  }, [activeTab]);

  function refreshContent() {
    let currentFeedType = [feedTypes.all, feedTypes.mostLiked, feedTypes.mine][activeTab];

    dispatch(
      getRegistrationPosts({
        registrationId,
        feedType: currentFeedType,
        offset: 0,
      })
    );
  }

  function onCreateNewRegistrationClick() {
    dispatch(
      showModalPage({
        content: <RegistrationFormBuilder postSubmitCallback={refreshContent} {...props} />,
      })
    );
  }

  function getRegistrationConfiguration(registrationId) {
    req()(`registration/${registrationId}/config`)
      .then(({ data }) => {
        setRegistrationConfig(data);
      })
      .catch((err) =>
        // If api fails it will revert back to default "Like" etc. Its better than nothing
        setRegistrationConfig({
          likeTextIdentifiers: {
            youLikeThis: "youLikeThis",
            youAndOneOtherLikeThis: "youAnd__placeholder__other",
            youAndMultipleOthersLikeThis: "youAnd__placeholder__others",
            oneOrManyOtherLikeThis: "__placeholder__likeThis",
          },
        })
      );
  }

  return (
    <Page>
      <TopBar
        title={pageOverrideFromQueryParams || page.title}
        useDefaultBackButton={true}
        actionRight={
          <ActionWrapper data-test-id="btn-show-add-post-modal" onClick={onCreateNewRegistrationClick}>
            <PlusIcon />
          </ActionWrapper>
        }
      />

      <TabBar
        activeTabIndex={activeTab}
        tabs={tabs.map((tab, tabIndex) => ({
          title: tab,
          onClick: () => {
            setActiveTab(tabIndex);
          },
        }))}
      />

      <TabView
        onScrollEnd={() => getPostsByActiveTab()}
        activeTabIndex={activeTab}
        tabs={[
          <RegistrationPosts
            {...props}
            layoutMode={LAYOUT_MODE}
            feedType={feedTypes.all}
            registrationConfig={registrationConfig}
          />,
          <RegistrationMostLikedPosts {...props} layoutMode={LAYOUT_MODE} registrationConfig={registrationConfig} />,
          <RegistrationPosts
            {...props}
            layoutMode={LAYOUT_MODE}
            feedType={feedTypes.mine}
            registrationConfig={registrationConfig}
          />,
        ]}
      />
    </Page>
  );
};

const mapDispatchToProps = (dispatch) => ({
  hideDialog: bindActionCreators(hideDialog, dispatch),
  showDialog: bindActionCreators(showDialog, dispatch),
  addToast: bindActionCreators(addToast, dispatch),
  hideModalPage: bindActionCreators(hideModalPage, dispatch),
  showModalPage: bindActionCreators(showModalPage, dispatch),
  getRegistrationPosts: bindActionCreators(getRegistrationPosts, dispatch),
  resetAll: bindActionCreators(resetAll, dispatch),
});

export default connect(null, mapDispatchToProps)(RegistrationOverview);
