// Libs
import React from "react";
import { css } from "emotion";
import tinycolor from "tinycolor2";
// Components
import { MailOutlineIcon, MessageIcon, PhoneIcon } from "mdi-react";
import ActionWrapper from "../ui/ActionWrapper";

// Config
import breakpoints from "../../config/breakpoints";
import colors from "../../style/colors";
import { useSelector } from "react-redux";

function getSizeClassName(className = "small") {
  if (className === "large") return "large";
  return className;
}

function getStylingClassName(className = "transparent") {
  if (className === "filled") return "filled";
  return className;
}

const ContactMenuOptions = (props) => {
  const { email, contactPersonEmail, phone, contactPersonPhone } = props;
  const primaryColor = useSelector((s) => s.appConfig.primaryColor);

  function call(phone) {
    window.location.href = `tel:${phone}`;
  }

  function sendMail(email) {
    window.location.href = `mailto:${email}`;
  }

  function sendSms(phone) {
    window.location.href = `sms:${phone}`;
  }

  return (
    <div
      className={`${componentStyle({ primaryColor })} ${props.className || ""} ${getSizeClassName(
        props.size
      )} ${getStylingClassName(props.styling)}`}
      style={props.style && props.style}
    >
      <>
        <ActionWrapper
          className={`wrapper ${phone || contactPersonPhone ? "enabled" : "disabled"}`}
          onClick={(e) => {
            if (phone || contactPersonPhone) {
              e.stopPropagation();
              //
              call(phone || contactPersonPhone);
            }
          }}
        >
          <PhoneIcon className="icon" />
        </ActionWrapper>
        <ActionWrapper
          className={`wrapper ${phone || contactPersonPhone ? "enabled" : "disabled"}`}
          onClick={(e) => {
            if (phone || contactPersonPhone) {
              e.stopPropagation();
              sendSms(phone || contactPersonPhone);
            }
          }}
        >
          <MessageIcon className="icon" />
        </ActionWrapper>
      </>
      <ActionWrapper
        className={`wrapper ${email || contactPersonEmail ? "enabled" : "disabled"}`}
        onClick={(e) => {
          if (email || contactPersonEmail) {
            e.stopPropagation();
            sendMail(email || contactPersonEmail);
          }
        }}
      >
        <MailOutlineIcon className="icon" />
      </ActionWrapper>
    </div>
  );
};

const componentStyle = ({ primaryColor }) => css`
  display: inline-flex;

  .wrapper {
    display: flex;
    align-items: center;
    border-radius: 50%;
  }

  &.large {
    .wrapper {
      margin: 0 0.5rem;
    }

    .icon {
      padding: 0.7rem;
      height: 2.65rem;
      width: 2.65rem;
    }
  }

  &.small {
    .icon {
      margin: 0.5rem;
      height: 1.25rem;
      width: 1.25rem;

      @media screen and (max-width: ${breakpoints.md}px) {
        margin: 0.5rem;
        height: 1.1rem;
        width: 1.1rem;
      }
    }
  }

  &.transparent {
    .wrapper {
      &.enabled {
        color: ${primaryColor};
        &:hover {
          cursor: pointer;
          background-color: rgba(0, 0, 0, 0.05);
        }
      }

      &.disabled {
        cursor: not-allowed;
        color: ${colors.lightGrey};
      }
    }
  }

  &.filled {
    .wrapper {
      &.enabled {
        color: ${colors.white};
        background-color: ${primaryColor};
        cursor: pointer;
        transition: background-color 160ms ease;

        &:hover {
          transition: background-color 90ms ease;
          background-color: ${tinycolor(primaryColor).lighten(10).toString()};
        }
        &:active {
          transition: background-color 90ms ease;
          background-color: ${tinycolor(primaryColor).darken(10).toString()};
        }
      }

      &.disabled {
        cursor: not-allowed;
        color: ${colors.white};
        background-color: ${colors.lightGrey};
      }
    }
  }
`;

export default ContactMenuOptions;
