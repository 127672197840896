export default {
  da: {
    identifier: "da",
    title: "Dansk",
  },
  en: {
    identifier: "en",
    title: "English",
  },
  pl: {
    identifier: "pl",
    title: "Polski",
  },
};
