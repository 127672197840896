// Config
import pageConfig from "../../../config/pageConfig";

export default function getSkills({ contact, config }) {
  let string = "";

  if (config && config[pageConfig.SHOW_COMPETENCES] && contact.skills) string += `${contact.skills}`;

  return string;
}
