import React, { Component } from "react";
import { connect } from "react-redux";
import { css } from "emotion";
import { RadioboxBlankIcon, RadioboxMarkedIcon } from "mdi-react";
import PropTypes from "prop-types";

//Style
import common from "../../style/common";
import colors from "../../style/colors";
import {
  getHoverFromPrimaryColor,
  getActiveFromPrimaryColor,
  getSelectedFromPrimaryColor,
} from "../../utilities/color-modifiers";

/** Select list for lists where only 1 element must be selected at one time
 *
 * ```
 * Options array passed to this element should be structured:
 * [ {id: XX, title: YY}]
 * ```
 */
class RadioGroup extends Component {
  constructor(props) {
    super(props);
    this.state = {
      activeRadioId: null,
    };
  }

  onSelect = (radioId) => {
    // Emit event
    this.props.onSelect(radioId);
    this.setState({
      activeRadioId: radioId,
    });
  };

  componentDidMount() {
    if (this.props.selectedValue) this.setState({ activeRadioId: this.props.selectedValue });
  }

  render() {
    let { primaryColor } = this.props.appConfig;
    let { activeRadioId } = this.state;
    let { disabled = false } = this.props;

    return (
      <div className={`${componentStyle(primaryColor)} ${this.props.className}`} style={this.props.style}>
        {this.props.options.map((radio, index) => (
          <label
            htmlFor={radio.id}
            onClick={!disabled ? () => this.onSelect(radio.id) : {}}
            key={`radio-group-${radio.id}-${radio.title}`}
          >
            {activeRadioId === radio.id ? (
              <div className="radio selected ">
                <RadioboxMarkedIcon />
                <p>{radio.title}</p>
              </div>
            ) : (
              <div className="radio">
                <RadioboxBlankIcon />
                <p>{radio.title}</p>
              </div>
            )}
          </label>
        ))}
      </div>
    );
  }
}
const componentStyle = (primaryColor) => css`
  border: 1px solid ${colors.midGrey};
  border-radius: 3px;

  .radio {
    display: flex;
    flex-direction: row;
    align-items: center;
    -webkit-appearance: none;
    border-bottom: 1px solid ${colors.midGrey};
    padding: 0.75rem 0.85rem;
    font-weight: 400;
    transition: background-color 200ms;

    &:hover {
      background-color: ${colors.ultraLightGrey};
      cursor: pointer;
      transition: background-color 40ms;
    }

    &:active {
      background-color: ${colors.lightGrey};
      transition: background-color 80ms;
    }

    svg {
      color: ${colors.midGrey};
      flex-shrink: 0;
    }

    p {
      margin-left: 0.5rem;
      font-size: 1rem;
      font-family: ${common.fontStack};
    }

    &.selected {
      font-weight: 700;
      background-color: ${getSelectedFromPrimaryColor(primaryColor)};
      color: ${primaryColor};
    }
    &.selected svg {
      color: ${primaryColor};
    }
  }

  .radio:hover {
    cursor: pointer;
    background-color: ${getHoverFromPrimaryColor(primaryColor)};
    transition: background-color 60ms ease;
  }

  .radio:active {
    background-color: ${getActiveFromPrimaryColor(primaryColor)};
    transition: background-color 100ms ease;
  }

  .radio-horizontal {
    display: flex;
  }

  label:last-child div {
    border-bottom: none;
  }

  .show-validation {
    border: 1px solid ${colors.redMedium} !important;
  }
`;

const mapStateToProps = (state) => ({
  appConfig: state.appConfig,
});

RadioGroup.propTypes = {
  /** Function to be executed when an option is selected */
  onSelect: PropTypes.func,
  /** Array which holds the selectable elements */
  options: PropTypes.array,
  /** Override style of component with this */
  style: PropTypes.object,
};

export default connect(mapStateToProps, null)(RadioGroup);
