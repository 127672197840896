/**
 * Returns hours and minutes for a 24-hour clock
 *
 * @returns {{hours: [], minutes: []}}
 */

export default () => {
  const hours = [];
  const minutes = [];

  for (let i = 0; i < 24; i++) {
    if (i < 10) {
      hours.push("0" + JSON.stringify(i));
    } else {
      hours.push(i);
    }
  }
  for (let i = 0; i < 60; i++) {
    if (i < 10) {
      minutes.push("0" + JSON.stringify(i));
    } else {
      minutes.push(i);
    }
  }
  return { hours, minutes };
};
