// Libs
import React from "react";
import { css } from "emotion";
import { CheckDecagramIcon } from "mdi-react";

// Styles
import colors from "../../style/colors";
import { useSelector } from "react-redux";

const RegistrationPostSuccess = (props) => {
  const { language: lang } = useSelector((state) => state.language);

  const { completedText } = props;

  return (
    <div className={componentStyle(colors)}>
      {completedText === null ? (
        <>
          <CheckDecagramIcon />
          <p>{lang.thanksForAnswering}</p>
          <p>{lang.answerReceived}</p>
        </>
      ) : (
        <>
          <CheckDecagramIcon />
          <p>{completedText}</p>
        </>
      )}
    </div>
  );
};

const componentStyle = (colors) => css`
  background-color: ${colors.white};
  width: 100%;
  height: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;

  svg {
    color: ${colors.darkGrey};
    height: 2rem;
    width: 2rem;
    margin: 2rem 0 0.5rem 0;
  }

  p {
    font-weight: 800;
    color: ${colors.darkGrey};
    margin-bottom: 0.2rem;
  }

  p:last-child {
    font-weight: 500;
    font-size: 15px;
  }
`;

export default RegistrationPostSuccess;
