// Libraries
import React, { useState } from "react";
import { connect, useSelector, useDispatch } from "react-redux";
import { bindActionCreators } from "redux";
import { hideModalPage, addToast, updateModalPage } from "../../../actions/uiActions";
import { css } from "emotion";
import {
  getTargetGroupUsers,
  getOverviewUsers,
  showTargetGroup,
  hideTargetGroup,
  removeUserFromTargetGroup,
} from "../../../actions/targetGroupModalActions";
import req from "../../../utilities/request-utility";

// Styles
import colors from "../../../style/colors";

// Utils
import { ChevronUpIcon, AccountPlusOutlineIcon, AccountMultipleIcon, CloseIcon } from "mdi-react";
import ScrollView from "../ScrollView";
import TargetGroupListItem from "./TargetGroupListItem";
import StatusBox from "../StatusBox";
import InlineSpinner from "../InlineSpinner";
import ActionWrapper from "../ActionWrapper";
import common from "../../../style/common";
import breakpoints from "../../../config/breakpoints";

function SocialUsersBottomBar(props) {
  const dispatch = useDispatch();
  const { showTargetGroup, targetGroupUsers, currentPageId } = useSelector((s) => s.targetGroupModal);
  const lang = useSelector((s) => s.language.language);
  const { users, loading, error } = targetGroupUsers;
  const [isRemovingAll, setIsRemovingAll] = useState(false);

  function removeAll() {
    setIsRemovingAll(true);
    Promise.all(targetGroupUsers.users.map((u) => req().delete(`pages/${currentPageId}/users/${u.id}`)))
      .then(() => {
        dispatch(getTargetGroupUsers());
        dispatch(getOverviewUsers());
        setIsRemovingAll(false);
      })
      .catch((err) => {
        setIsRemovingAll(false);
        dispatch(addToast({ template: "error" }));
      });
  }

  return (
    <div className={`${componentStyles()} ${showTargetGroup ? "active" : ""}`}>
      <div className="bar" onClick={showTargetGroup ? props.hideTargetGroup : props.showTargetGroup}>
        <ChevronUpIcon className="expand-collapse-icon" /> <div className="vertical-seperator" />
        <AccountMultipleIcon style={{ width: "1rem", height: "1rem" }} />
        {loading ? lang.loading : `${lang.targetGroup}: ${users.length} ${lang.users.toLowerCase()}`}
      </div>
      <ScrollView className="content">
        {!error && !loading && users.length === 0 && (
          <StatusBox
            icon={<AccountPlusOutlineIcon />}
            title={lang.noUsersSelected}
            content={lang.addUsersFromSearchpage}
            action={{ title: `${lang.goTo} ${lang.searchPage}`, callback: () => dispatch(hideTargetGroup()) }}
            style={{ marginTop: "1rem" }}
          />
        )}

        {!error && !loading && users.length > 0 && (
          <ActionWrapper className="remove-all-users-button" onClick={removeAll}>
            {isRemovingAll && <InlineSpinner color={colors.red} size={14} />}
            {!isRemovingAll && (
              <>
                <CloseIcon />
                <p>
                  {lang.removeAll} {lang.users}
                </p>
              </>
            )}
          </ActionWrapper>
        )}

        {users.map((user, index) => (
          <TargetGroupListItem
            key={`target-group-user-${user.id}`}
            enableCheckmark={false}
            enableDeleteButton={true}
            user={user}
            maxWidth={breakpoints.lg}
            onClick={() => dispatch(removeUserFromTargetGroup(user))}
            style={{ marginTop: index === 0 ? "-1px" : 0 }}
          />
        ))}
      </ScrollView>
    </div>
  );
}

const componentStyles = (primaryColor) => css`
  position: absolute;
  left: 0;
  top: 0;
  width: 100%;
  height: 100vh;
  display: flex;
  flex-direction: column;
  z-index: 1;
  transform: translateY(calc(100vh - ${common.topBarHeight}px));
  transition: transform 420ms cubic-bezier(1, 0, 0, 1);

  &.active {
    transform: translateY(0);
    transition: transform 420ms cubic-bezier(0.7, 0, 0, 1);

    .expand-collapse-icon {
      transform: scale(-1);
      transition: transform 300ms ease;
    }
  }

  .bar {
    background-color: ${colors.white};
    color: ${colors.darkGrey};
    padding: 0 0.75rem;
    display: flex;
    align-items: center;
    height: ${common.topBarHeight}px;
    border-top: 1px ${colors.midGrey} solid;
    line-height: ${common.topBarHeight}px;
    cursor: pointer;
    transition: background-color 120ms ease;
    svg {
      margin-right: 0.25rem;
    }

    &:hover {
      background-color: ${colors.ultraLightGrey};
      transition: background-color 60ms ease;
    }
    &:active {
      background-color: ${colors.lightGrey};
      transition: background-color 60ms ease;
    }

    .expand-collapse-icon {
      transition: transform 300ms ease;
    }

    .vertical-seperator {
      width: 1px;
      background-color: ${colors.midGrey};
      height: 1.2rem;
      margin-right: 0.75rem;
      margin-left: 0.25rem;
    }
  }

  .content {
    background-color: ${colors.ultraLightGrey};
    flex-grow: 1;
    border-top: 1px ${colors.midGrey} solid;
  }

  .remove-all-users-button {
    display: flex;
    align-items: center;
    background-color: ${colors.lightGrey};
    padding: 0.75rem 1rem;
    margin: 0.75rem auto;
    color: ${colors.red};
    transition: background-color 120ms ease;

    svg {
      width: 1.25rem;
      height: 1.25rem;
    }

    p {
      padding: 0 0.5rem;
    }

    &:active {
      background-color: ${colors.midLightGrey};
      transition: background-color 80ms ease;
    }
  }
`;

const mapDispatchToProps = (dispatch) => ({
  updateModalPage: bindActionCreators(updateModalPage, dispatch),
  hideModalPage: bindActionCreators(hideModalPage, dispatch),
  addToast: bindActionCreators(addToast, dispatch),
  showTargetGroup: bindActionCreators(showTargetGroup, dispatch),
  hideTargetGroup: bindActionCreators(hideTargetGroup, dispatch),
});

export default connect(null, mapDispatchToProps)(SocialUsersBottomBar);
