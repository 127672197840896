// Libs
import React from "react";

// Redux
import { useSelector, useDispatch } from "react-redux";
import { hideModalPage } from "../../actions/uiActions";
// Styles
import breakpoints from "../../config/breakpoints";
import colors from "../../style/colors";
import { css } from "emotion";

// Icons
import { CertificateIcon, TimetableIcon } from "mdi-react";

// Components
import Button from "../ui/Button";
import { parseDate } from "../../utilities/parse-date";

const CertificatePassedResults = (props) => {
  // Multi-language handling
  const lang = useSelector((state) => state.language.language);

  const dispatch = useDispatch();

  // Destructure the object
  const { correctAnswers, startDate, endDate, totalQuestions, title } = props.results;

  return (
    <div className={componentStyle(breakpoints, colors)}>
      <div className="container">
        <p className="title">{lang.congratulations}</p>
        <p style={{ marginBottom: "0.5rem" }}>
          {lang.youGot__placeholderCorrectAnswers__outOf__placeholderTotalQuestions__andHaveReceivedTheCertificate
            .replace(/{{placeholderCorrectAnswers}}/gi, correctAnswers)
            .replace(/{{placeholderTotalQuestions}}/gi, totalQuestions)}
        </p>

        <p style={{ marginBottom: "1rem" }}>{`${lang.youWillYourNewCertificateUnderMyCertificates}`}</p>

        <div className="certificate-container">
          <p style={{ fontSize: "1.1rem", marginBottom: "0.3rem" }}>{title}</p>
          <div className="certificate-sub-container">
            <CertificateIcon size={17} />
            <p className="certificate-title">{`${lang.certified} ${parseDate(startDate)}`}</p>
          </div>
          <div className="certificate-sub-container">
            <TimetableIcon size={17} />
            <p className="certificate-title">{`${lang.expires} ${parseDate(endDate)}`}</p>
          </div>
        </div>
        <Button
          onClick={() => {
            dispatch(hideModalPage());
          }}
        >
          {lang.goBackToCertificates}
        </Button>
      </div>
    </div>
  );
};

const componentStyle = (breakpoints, colors) => css`
  background-color: ${colors.white};
  width: 100%;
  height: 100%;

  .container {
    padding: 1rem;
    overflow: auto;
    max-width: ${breakpoints.md}px;
    margin: auto;
  }

  .title {
    font-size: 1.3rem;
    margin-bottom: 1rem;
  }

  .certificate-container {
    padding: 0.4rem 0.8rem;
    border: 1px solid ${colors.midGrey};
    margin-bottom: 2rem;
    border-radius: 3px;
  }

  .certificate-sub-container {
    display: flex;
    flex-direction: row;
    align-items: center;
    padding: 0.2rem 0;
  }

  .certificate-title {
    font-size: 1rem;
    margin-left: 0.5rem;
  }
`;

export default CertificatePassedResults;
