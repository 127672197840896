import React from "react";
import { css } from "emotion";
import { connect, useSelector } from "react-redux";
import { bindActionCreators } from "redux";
import { showContextMenu, showModalPage, hideModalPage, showDialog, showLightBox } from "../../actions/uiActions";

// Components
import InlineSpinner from "../ui/InlineSpinner";
import StatusBox from "../ui/StatusBox";

import UserProfileEditModal from "./UserProfileEditModal";
import {
  DotsVerticalIcon,
  EditIcon,
  PhoneIcon,
  EmailOutlineIcon,
  InformationOutlineIcon,
  ClipboardTextOutlineIcon,
  MapMarkerIcon,
  AccountIcon,
} from "mdi-react";

// Utilities
import getProfilePicture from "../../utilities/get-profile-picture-from-user-object";

// Style
import colors from "../../style/colors";
import breakpoints from "../../config/breakpoints";
import ContactMenuOptions from "../DEPRECATED_contacts/ContactMenuOptions";

const UserProfile = (props) => {
  const appId = useSelector((s) => s.appConfig.appId);
  let showEmployeeId = [35, 36, 37, 38, 39, 41, 42].includes(appId) ? false : true;

  let {
    userDataLoading: loading,
    userDataError: error,
    userData: user,
    getUserData,
    allowUserToEdit,
    showContactButtons = true,
    showContextMenu,
    showModalPage,
    hideModalPage,
    showDialog,
    showLightBox,
    lang,
  } = props;

  return (
    <>
      {loading && !error && <InlineSpinner style={{ marginTop: "1rem" }} />}
      {!loading && error && <StatusBox />}
      {!loading && !error && (
        <div className={`user-profile ${componentStyles(props)}`}>
          <div className={`header ${allowUserToEdit ? "" : "public-profile"}`}>
            {getProfilePicture(user, allowUserToEdit ? 45 : 256, {}, () => {
              showLightBox({
                images: [user.profilePicture],
              });
            })}
            <div>
              <h3>{user.name}</h3>
              <p style={{ margin: "0.35rem 0" }}>{user.masterGroup.title + ", " + user.userGroup.title}</p>
              {user.application && <p style={{ color: colors.darkGrey }}>{user.application.title}</p>}
              {showContactButtons && (
                <ContactMenuOptions size="large" styling="filled" style={{ margin: "1rem 0 0 0" }} {...user} />
              )}
            </div>

            {allowUserToEdit && (
              <DotsVerticalIcon
                className="header-context-menu-toggle"
                onClick={() =>
                  // Showps context menu
                  showContextMenu([
                    {
                      icon: <EditIcon />,
                      title: lang.edit,
                      callback: () =>
                        // Shows modal page (if edit is pressed)
                        showModalPage({
                          title: lang.editProfile,
                          content: () => <UserProfileEditModal user={user} getUserData={getUserData} />,
                          closeCallback: () =>
                            showDialog({
                              title: lang.heyThere,
                              content: lang.closeFormWarningContent,
                              primaryActionTitle: lang.yesCloseForm,
                              primaryAction: hideModalPage,
                              secondaryActionTitle: lang.noDontCloseForm,
                            }),
                          useScrollView: false,
                        }),
                    },
                  ])
                }
              />
            )}
          </div>
          <div className="content">
            <>
              <p className="label">
                <PhoneIcon /> {lang.phone}
              </p>
              <a href={"tel:" + user.phone || ""}>{user.phone ? user.phone : ""}</a>
            </>

            {user.alternativePhone && (
              <>
                <p className="label">
                  <PhoneIcon /> {lang.altPhone}
                </p>
                <a href={"tel:" + user.phone || ""}>{user.alternativePhone}</a>
              </>
            )}

            <>
              <p className="label">
                <EmailOutlineIcon /> {lang.email}
              </p>
              <a href={"mailto:" + user.email || ""}>{user.email ? user.email : ""}</a>
            </>

            <>
              <p className="label">
                <InformationOutlineIcon />
                {lang.aboutMe}
              </p>
              <p className="text-content">{user.about ? user.about : ""}</p>
            </>

            <>
              <p className="label">
                <ClipboardTextOutlineIcon /> {lang.myCompetencies}
              </p>
              <p className="text-content">{user.skills ? user.skills : ""}</p>
            </>

            {showEmployeeId && user.externalId && (
              <>
                <p className="label">
                  <AccountIcon /> {lang.employeeId}
                </p>
                <p className="text-content">{user.externalId}</p>
              </>
            )}
            {user.address && (
              <>
                <p className="label">
                  <MapMarkerIcon /> {lang.address}
                </p>
                <p className="text-content">{user.address ? user.address : ""}</p>
              </>
            )}
          </div>
        </div>
      )}
    </>
  );
};

const boxPadding = "0.5";

const componentStyles = (props) => css`
  background-color: ${colors.white};
  margin: ${props.allowUserToEdit ? 1 : 0}rem 0;
  position: relative;
  border-top: 1px ${colors.midGrey} solid;
  border-bottom: 1px ${colors.midGrey} solid;

  @media screen and (min-width: ${breakpoints.lg}px) {
    width: ${breakpoints.lg}px;
    border-radius: 4px;
    border: 1px ${colors.midGrey} solid;
    margin: 1rem auto;
    flex: initial !important; /* Removes forced 100% height */
  }

  /* .contact-menu-options {
    justify-content: center;
    margin-top: 0.5rem;

    .wrapper {
      margin: 0 1rem;

      svg {
        height: 1.25rem;
        width: 1.25rem;
      }
    }
  } */

  .header-context-menu-toggle {
    width: 20px;
    height: 20px;
    position: absolute;
    top: 1rem;
    right: 1rem;
  }

  .header {
    display: flex;
    padding: ${boxPadding * 1.5}rem ${boxPadding}rem;
    border-bottom: 1px ${colors.midGrey} solid;

    &.public-profile {
      flex-direction: column;
      align-items: center;

      & > div {
        margin: 1rem 0;
        text-align: center;
      }
    }

    & > div {
      margin-right: 0.75rem;
    }

    & > div,
    & > svg {
      display: inline-block;
      vertical-align: text-top;
    }
  }

  .content {
    margin: ${boxPadding * 2}rem;

    .label {
      color: ${colors.black};
      margin-bottom: ${boxPadding * 0.7}rem;

      svg {
        width: 1.15rem;
        height: 1.15rem;
        color: ${colors.darkGrey};
        margin-right: 0.25rem;
        margin-bottom: -3px;
      }
    }

    a,
    p {
      margin-bottom: ${boxPadding * 3.25}rem;
      display: block;
    }

    p {
      color: ${colors.darkGrey};
    }

    a {
      color: ${props.appConfig.primaryColor};
      text-decoration: underline;
    }

    .text-content {
      white-space: pre-wrap;
    }
  }
`;

const mapStateToProps = (state) => ({
  appConfig: state.appConfig,
  user: state.auth.user,
  lang: state.language.language,
});
const mapDispatchToProps = (dispatch) => ({
  showContextMenu: bindActionCreators(showContextMenu, dispatch),
  showDialog: bindActionCreators(showDialog, dispatch),
  showModalPage: bindActionCreators(showModalPage, dispatch),
  hideModalPage: bindActionCreators(hideModalPage, dispatch),
  showLightBox: bindActionCreators(showLightBox, dispatch),
});

export default connect(mapStateToProps, mapDispatchToProps)(UserProfile);
