// Libs
import React, { useState, useEffect } from "react";
import { formatDistanceToNow, parse } from "date-fns";
import { css } from "emotion";

// Redux
import { useSelector, useDispatch } from "react-redux";

// Components
import ActionWrapper from "./ActionWrapper";
import { MoreVertIcon } from "mdi-react";
import CommentOnCommentButton from "./CommentOnCommentButton";
import CommentLikeButton from "./CommentLikeButton";
import PostCommentForm from "./PostCommentForm";
import UserProfile from "../profile/UserProfile";

// Utilities
import getProfilePicture from "../../utilities/get-profile-picture-from-user-object";
import getUserLocale from "../../utilities/get-user-locale";
import req from "../../utilities/request-utility";

// Style
import colors from "../../style/colors";

// Actions
import { showLightBox, showModalPage, updateModalPage, showDialog } from "../../actions/uiActions";

const PostComment = (props) => {
  // Props
  const {
    comment,
    onCommentContextMenu,
    isCommentLiked,
    onCommentLike,
    submittingLikedOnCommentId,
    isChildComment = false,
    parentCommentId = null,
    isLastComment = false,
  } = props;

  // Dispatcher
  const dispatch = useDispatch();

  // Local stategit
  const [showCommentForm, setShowCommentForm] = useState(false);

  // Redux state
  const { user } = useSelector((state) => state.auth);
  const { primaryColor } = useSelector((state) => state.appConfig);
  const { language: lang } = useSelector((state) => state.language);

  // Content
  const [content, setContent] = useState(comment.content || "");

  const profilePictureSize = isChildComment ? 26 : 45;
  const isAuthor = comment.author && comment.author.id === user.id ? true : false;

  const generateLinks = (comment) => {
    // Handle links

    // Regex that finds all links as long as they start with a protocol
    // eslint-disable-next-line
    const regex = /(?:(?:https?):\/\/|www\.)(?:\([-A-Z0-9+&@#\/%=~_|$?!:,.]*\)|[-A-Z0-9+&@#\/%=~_|$?!:,.])*(?:\([-A-Z0-9+&@#\/%=~_|$?!:,.]*\)|[A-Z0-9+&@#\/%=~_|$])/gim;
    let localContent = comment.content;
    // Find the links in the markdown using the regex
    let links = content.match(regex) || [];

    links.map((link) => {
      const reg = new RegExp(link, "g");
      localContent = localContent.replace(reg, `<a>${link}</a>`);
      return link;
    });
    setContent(localContent);
  };

  useEffect(() => {
    generateLinks(comment);

    // eslint-disable-next-line
  }, [comment]);

  function handleLinkClick(link) {
    let dialog = {
      title: lang.warningOpeningLink,
      content: lang.youAreOpening__placeholder__.replace(/{{placeholder}}/gi, link),
      styleType: "warning",
      primaryActionTitle: lang.open__placeholder__.replace(/{{placeholder}}/gi, link),
      secondaryActionTitle: lang.cancel,
      primaryAction: () => {
        const reg = new RegExp("www.", "g");
        link = link.replace(reg, "http://");
        window.open(link, "_blank");
      },
    };
    dispatch(showDialog(dialog));
  }

  async function showUserModal(userId) {
    if (!userId) return;
    dispatch(showModalPage({ content: <UserProfile userDataLoading={true} userDataError={false} /> }));
    let { data: userData } = await req()(`users/${userId}`);
    dispatch(
      updateModalPage({
        content: <UserProfile userDataLoading={false} userDataError={false} userData={userData} />,
      })
    );
  }

  function handleContentClick(e) {
    e.preventDefault();
    e.persist();
    if (e.target.tagName === "A") {
      handleLinkClick(e.target.innerText);
    }
  }

  function getContainerClasses() {
    let classes = "comment";
    if (props.className) classes += ` ${props.className}`;
    if (comment.author && comment.author.id === user.id) classes += " own";
    if (isChildComment) classes += " child";
    if (!isChildComment) classes += " parent";
    if (comment.image && comment.image.image) classes += " has-image";
    if (comment.content) classes += " has-content";
    return classes;
  }

  return (
    <div
      key={`social-post-comment-${comment.id}`}
      className={`${componentStyle(props, primaryColor)} ${getContainerClasses()}`}
    >
      {comment.content && (
        <>
          <div className="content-wrapper">
            {/* Content */}
            <p className="content" onClick={handleContentClick} dangerouslySetInnerHTML={{ __html: content }} />

            {/* Edit and settings button */}
            {isAuthor && (
              <ActionWrapper
                onClick={() => onCommentContextMenu(comment)}
                className="options-icon"
                data-test-id="comment-context-menu-toggle"
              >
                <MoreVertIcon />
              </ActionWrapper>
            )}
          </div>
          {/* The <br> tags puts the image on the next line even though the image also is inline-block */}
          <br />
        </>
      )}
      {/* Image */}
      {comment.image && comment.image.image && (
        <div className="image-wrapper">
          <img
            onClick={() =>
              dispatch(showLightBox({ images: [{ baseURL: comment.image.baseURL, image: comment.image.image }] }))
            }
            src={`${comment.image.baseURL}${comment.image.image}`}
            alt="comment-img"
          />
          {/* In case comment is image only, display the edit button directly on the image */}
          {isAuthor && !comment.content && (
            <ActionWrapper
              style={{ backgroundColor: "rgba(0,0,0,0.1)" }}
              onClick={() => onCommentContextMenu(comment)}
              className="options-icon"
              data-test-id="comment-context-menu-toggle"
            >
              <MoreVertIcon />
            </ActionWrapper>
          )}
        </div>
      )}

      {/* Author info */}
      <div className="author">
        {comment.author ? (
          <div onClick={() => showUserModal(comment.author.id)} className="profile-picture-wrapper">
            {getProfilePicture(comment.author, profilePictureSize)}
          </div>
        ) : (
          <div className="profile-picture-wrapper">{getProfilePicture({}, profilePictureSize)}</div>
        )}
        <div className={`comment-options ${isChildComment ? "child-comment" : ""}`}>
          <div className="first">
            {comment.author ? (
              /* If an author is present */
              <p onClick={() => showUserModal(comment.author.id)} className="meta">
                {isAuthor ? lang.you : comment.author.name}
              </p>
            ) : (
              /* If the author is missing */
              <p className="meta">{lang.deletedUser}</p>
            )}
            {comment.date.length > 8 && (
              <p className="meta date">
                {formatDistanceToNow(parse(comment.date, "yyyyMMddHHmmss", 0), getUserLocale(user))} {lang.ago}
              </p>
            )}
          </div>
          <div className="second">
            {!isChildComment && (
              <CommentOnCommentButton
                title={lang.answerBack}
                commentBtnVisible={showCommentForm}
                isCommentingOnComment={showCommentForm}
                disableInteractionComment={false}
                onShowForm={() => setShowCommentForm(!showCommentForm)}
              />
            )}
            {isCommentLiked && onCommentLike && (
              <CommentLikeButton
                isCommentLiked={isCommentLiked}
                submittingLikedOnCommentId={submittingLikedOnCommentId}
                onCommentLike={onCommentLike}
                comment={comment}
              />
            )}
          </div>
        </div>
      </div>

      {/* "Reply" comment form */}
      <PostCommentForm
        style={{ marginLeft: `${childCommentInset}px` }}
        showAddCommentWhenFormIsHidden={false}
        parentId={comment.id}
        showForm={showCommentForm && !isChildComment}
        boxPadding={boxPadding}
        onComment={(c) => {
          props.onComment(c, comment.id);
          setShowCommentForm(false);
        }}
        imageInputId={`image-upload-${comment.id}`}
      />

      {/** Child comments **/}
      {comment.comments &&
        comment.comments.length > 0 &&
        comment.comments.map((childComment, index) => (
          <PostComment
            key={`post-comment-child-${childComment.id}`}
            isLastComment={comment.comments.length === index + 1}
            parentCommentId={childComment.parentCommentId}
            isChildComment={true}
            onComment={props.onComment}
            submittingLikedOnCommentId={submittingLikedOnCommentId}
            onCommentLike={onCommentLike}
            isCommentLiked={isCommentLiked}
            comment={childComment}
            onCommentContextMenu={onCommentContextMenu}
          />
        ))}

      {/* Add comment to thread button */}
      {isChildComment && isLastComment && (
        <CommentOnCommentButton
          className="comment-on-comment-btn"
          title={lang.addCommentToTheThread}
          commentBtnVisible={showCommentForm}
          isCommentingOnComment={showCommentForm}
          disableInteractionComment={false}
          onShowForm={() => setShowCommentForm(!showCommentForm)}
        />
      )}

      <PostCommentForm
        showAddCommentWhenFormIsHidden={false}
        showForm={showCommentForm && isChildComment}
        boxPadding={boxPadding}
        onComment={(childComment) => {
          props.onComment(childComment, parentCommentId);
          setShowCommentForm(false);
        }}
        imageInputId={`image-upload-${comment.id}`}
      />
    </div>
  );
};

const boxPadding = "0.65";
const borderRadius = 8;
const childCommentInset = 50;

const componentStyle = (props, primaryColor) => css`
  margin-bottom: 1rem;
  margin-top: 1rem;

  &:first-of-type {
    margin-top: 0;
  }

  &.has-image {
    .content-wrapper:after {
      display: none; // Remove little arrow from comment-box
    }
  }

  &.child {
    padding-left: ${childCommentInset}px;
  }

  div.content-wrapper {
    position: relative;
    padding: 0.125rem;
    background-color: ${colors.lightGrey};
    border-radius: ${borderRadius}px;
    display: inline-block;

    .content {
      padding: 0.5rem 0.65rem;
      margin-bottom: 0;
      background-color: ${colors.lightGrey};
      position: relative;
      color: ${colors.black};
      word-break: break-word;
    }

    &:after {
      content: "";
      position: absolute;
      top: 100%;
      width: 0;
      height: 0;
      border-left: 8px solid transparent;
      border-right: 8px solid transparent;
      border-top: 8px solid ${colors.lightGrey};
    }
  }

  div.image-wrapper {
    position: relative;
    display: inline-block;
    line-height: 0; // Prevents extra space inside container
    max-width: 100%;
    img {
      max-width: 100%;
      min-width: 45px;
      max-height: 300px;
      margin-top: 1px;
      border: 1px ${colors.lightGrey} solid;
      border-radius: ${borderRadius}px;
    }
    :after {
      content: "";
      position: absolute;
      top: 100%;
      width: 0;
      height: 0;
      border-left: 8px solid transparent;
      border-right: 8px solid transparent;
      border-top: 8px solid ${colors.lightGrey};
    }
  }

  &.parent .content-wrapper:after,
  &.parent .image-wrapper:after {
    left: 20px;
  }

  &.child .content-wrapper:after,
  &.child .image-wrapper:after {
    left: 10px;
  }

  /* Users own comments */
  &.own {
    position: relative;

    .options-icon {
      color: ${colors.white};
      position: absolute;
      top: 0.5rem;
      right: 0.35rem;

      svg {
        width: 1.25rem;
        height: 1.25rem;
      }
    }

    & > div.content-wrapper {
      background-color: ${primaryColor};

      .content {
        background-color: ${primaryColor};
        color: ${colors.white};
        padding-right: 2rem;

        a {
          color: ${colors.white};
          cursor: pointer;
        }
      }

      &:after {
        border-top-color: ${primaryColor};
      }
    }

    & > .image-wrapper {
      img {
        border-color: ${primaryColor};
      }

      &:after {
        border-top-color: ${primaryColor};
      }
    }
  }

  &:last-of-type {
    margin-bottom: 0;
  }

  .comment-on-comment-btn {
    margin: 0.25rem 0;
  }

  .author {
    margin-top: 0.4rem;
    display: flex;
    align-items: center;
    white-space: nowrap;

    /* Div wrapper around profile-picture */
    .profile-picture-wrapper {
      margin: 0 0.2rem;
    }

    .meta {
      margin: 0 0.35rem;
      font-weight: 700;
    }

    .date {
      color: ${colors.darkGrey};
      margin: 0;
      font-weight: 400;
    }

    .comment-options {
      display: flex;
      align-items: flex-start;
      flex-direction: column;

      div {
        display: flex;
        padding: 0.1rem 0;
      }

      &.child-comment {
        flex-direction: row;
        align-items: center;
        width: 100%;
        overflow: hidden;

        div.first {
          max-width: calc(100% - 50px); // <- 50px is width of like button
          overflow: hidden;

          p {
            overflow: hidden;
            text-overflow: ellipsis;
          }
        }
      }
    }

    svg {
      color: ${colors.darkGrey};
      margin: 0 0.35rem;
    }
  }
`;

export default PostComment;
