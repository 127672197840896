// Libs
import React from "react";
import { CSSTransition } from "react-transition-group";
import { useDispatch, useSelector } from "react-redux";

// Components
import TopBar from "../ui/TopBar";
import Page from "../ui/Page";
import { ContactsTabBar } from "./components/ContactsTabBar";
import { ContactsContent } from "./components/ContactsContent";
import AppFilterModal from "../ui/AppFilterModal";
import { FilterOutlineIcon } from "mdi-react";
import Notification from "../ui/Notification";
import ActionWrapper from "../ui/ActionWrapper";

// Hooks
import useCurrentPage from "../../hooks/useCurrentPage";

// Styles
import basicAnimations from "../../style/basic-animations";

// Actions
import { hidePhoneBookFilters, setPhoneBookFiltersValue, showPhoneBookFilters } from "../../actions/contactsActions";

const Contacts = (props) => {
  const dispatch = useDispatch();

  const page = useCurrentPage({ pageId: props.match.params.pageId });
  const activeTab = useSelector((state) => state.contact.activeTab);
  const myApp = useSelector((s) => s.appConfig); // is used to check if we should load more to make view scrollable
  const relatedApps = useSelector((s) => s.contact.relatedApps); // is used to check if we should load more to make view scrollable
  const filters = useSelector((state) => state.contact.filters);

  return (
    <Page>
      <TopBar
        title={page.title}
        useDefaultBackButton={true}
        actionRight={
          <CSSTransition
            in={activeTab === 0 && relatedApps.length > 0}
            timeout={400}
            mountOnEnter={true}
            unmountOnExit={true}
            classNames={basicAnimations.fade}
          >
            <ActionWrapper style={{ position: "relative" }} onClick={() => dispatch(showPhoneBookFilters())}>
              <FilterOutlineIcon />
              {Number(filters.selectedAppId) !== Number(myApp.appId) && (
                <Notification notifications={1} style={{ position: "absolute", top: "2px", right: "2px" }} />
              )}
            </ActionWrapper>
          </CSSTransition>
        }
      />

      <ContactsTabBar {...props} />
      <ContactsContent {...props} />

      <AppFilterModal
        active={filters.active}
        hideFilters={() => dispatch(hidePhoneBookFilters())}
        relatedApps={relatedApps}
        onChange={(e) => dispatch(setPhoneBookFiltersValue({ key: e.target.name, value: e.target.value }))}
        values={filters}
        clearFilters={() => dispatch(setPhoneBookFiltersValue({ key: "selectedAppId", value: myApp.appId }))}
      />
    </Page>
  );
};

export default Contacts;
