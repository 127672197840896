// Libs
import React, { useEffect, useState } from "react";
import { css } from "emotion";
import PropTypes from "prop-types";
import { useSelector } from "react-redux";

// Styles
import colors from "../../style/colors";
import common from "../../style/common";
import {
  getHoverFromPrimaryColor,
  getActiveFromPrimaryColor,
  getSelectedFromPrimaryColor,
} from "../../utilities/color-modifiers";
import { CheckIcon } from "mdi-react";

/**
 * UI-Rating component
 *
 * ```
 * props.onSelect return the rating value from the clicked box
 * ```
 * @param props
 * @returns {*}
 * @constructor
 */

const YesAndNoGroup = (props) => {
  const [activeRating, setActiveRating] = useState(null);

  // Props
  const { selectedValue, className, style, options = [{ title: "YES" }, { title: "NO" }], disabled = false } = props;

  const { language: lang } = useSelector((state) => state.language);
  const yes = lang.yes;
  const no = lang.no;

  const onSelect = (score) => {
    props.onSelect(score);
    setActiveRating(score);
  };

  useEffect(() => {
    if (selectedValue) setActiveRating(selectedValue);
    // eslint-disable-next-line
  }, []);

  const { primaryColor } = useSelector((state) => state.appConfig);

  return (
    <div className={`${componentStyle(primaryColor)} ${className}`} style={style}>
      {options.map((option, index) => (
        <label
          className="select"
          htmlFor={option}
          onClick={() => (!disabled ? onSelect(option.title) : {})}
          key={`yesAndNo-${option.title}`}
        >
          {option.title === "YES" && (
            <p className={activeRating === "YES" ? "selected" : ""}>
              <CheckIcon />
              {yes}
            </p>
          )}
          {option.title === "NO" && (
            <p className={activeRating === "NO" ? "selected" : ""}>
              <CheckIcon />
              {no}
            </p>
          )}
        </label>
      ))}
    </div>
  );
};

const componentStyle = (primaryColor) => css`
  border: 1px solid ${colors.midGrey};
  border-radius: 3px;
  display: flex;
  flex-wrap: nowrap;
  flex-direction: row;
  justify-content: space-around;

  .select {
    text-align: center;
    flex-grow: 1;
    border-right: 1px solid ${colors.midGrey};
    background-color: ${colors.white};
    transition: background-color 120ms ease;

    p {
      font-family: ${common.fontStack};
      padding: 8px 0;
      color: ${colors.darkGrey};
      display: flex;
      align-items: center;
      justify-content: center;

      svg {
        width: 1.15rem;
        height: 1.15rem;
        margin-right: -1.5rem;
        opacity: 0;
        transform: scale(0);
        transition: 200ms ease all;
      }
    }

    .selected {
      font-weight: 700;
      color: ${primaryColor};
      background-color: ${getSelectedFromPrimaryColor(primaryColor)};

      svg {
        margin-right: 0.25rem;
        opacity: 1;
        transform: scale(1);
        transition: 200ms ease all;
      }
    }

    &:hover {
      cursor: pointer;
      background-color: ${getHoverFromPrimaryColor(primaryColor)};
      transition: background-color 60ms ease;
    }

    &:active {
      background-color: ${getActiveFromPrimaryColor(primaryColor)};
      transition: background-color 100ms ease;
    }
  }

  .select:last-child {
    border-right: none;
  }
`;

YesAndNoGroup.propTypes = {
  /** Array of items to be displayed. It's structure should be [ { title: XX, value YY } ]  **/
  options: PropTypes.array,

  /** Overriding of style in component, if you have very specific needs */
  style: PropTypes.object,

  /** Function to be executed when an option is selected */
  onSelect: PropTypes.func,

  /** Default value or target value **/
  selectedValue: PropTypes.string,
};

export default YesAndNoGroup;
