import React, { useEffect, useState } from "react";
import { connect, useSelector } from "react-redux";
import { bindActionCreators } from "redux";

// Redux actions
import { updateModalPage, hideModalPage, addToast } from "../../actions/uiActions";

// Components
import RadioGroup from "../ui/RadioGroup";
import breakpoints from "../../config/breakpoints";
import ScrollView from "../ui/ScrollView";
import StatusBox from "../ui/StatusBox";
import req from "../../utilities/request-utility";
import colors from "../../style/colors";
import { ApprovalIcon } from "mdi-react";
import RadioGroupShowCorrectAnswers from "../ui/RadioGroupShowCorrectAnswers";
import Button from "../ui/Button";
import { css } from "emotion";

function CheckQuestionsModal(props) {
  let { questions, subTypeId, postId, addToast } = props;
  let [answers, setAnswers] = useState([]);
  const [active, setActive] = useState(false);
  let lang = useSelector((s) => s.language.language);
  let [questionsWithAnswers, setQuestionsWithAnswers] = useState([]);
  let [showCorrectAnswers, setShowCorrectAnswers] = useState(false);

  function getUserAnswerByQuestionId(questionId) {
    return answers.filter((a) => a.questionId === questionId)[0];
  }

  function getTotalAmountOfObtainedPoints(questionsWithAnswers) {
    let obtainedPoints = 0;
    let correctlyAnsweredQuestions = questionsWithAnswers.filter((q) => q.isUserAnswerCorrect);
    if (correctlyAnsweredQuestions.length === 0) return obtainedPoints;
    correctlyAnsweredQuestions.forEach((question) => {
      obtainedPoints += question.points;
    });
    return obtainedPoints;
  }

  function getTitle() {
    let numberOfQuestion = questionsWithAnswers.length;
    let correctAnswers = questionsWithAnswers.filter((q) => q.isUserAnswerCorrect).length;

    if (correctAnswers === 0) {
      return lang.checkQuestionsSummaryTitleBad;
    } else if (correctAnswers > 0 && correctAnswers < numberOfQuestion) {
      return lang.checkQuestionsSummaryTitleAverage;
    } else if (correctAnswers === numberOfQuestion) {
      return lang.checkQuestionsSummaryTitleGood;
    } else {
      // if something goes wrong: fall back to "Status"
      return "Status";
    }
  }

  async function submitAnswers(answers) {
    try {
      setActive(true);
      for (let i = 0; i < 100; i++) {
        req().post(`/news/${subTypeId}/${postId}/answers`, answers);
      }

      let { data: correctAnswers } = await req().post(`/news/${subTypeId}/${postId}/answers`, answers);

      if (correctAnswers) {
        setQuestionsWithAnswers(correctAnswers);
        setShowCorrectAnswers(true);
        setActive(false);
        props.updateModalPage({ closeCallback: null });
      }
    } catch {
      addToast({ template: "error" });
      setActive(false);
    }
  }

  /** Handle `<RadioGroup />` selects */
  function onSelect(answerId, questionId) {
    /* Run through answers. If an object with the questionId exists
     * in the answers-array update it. Otherwise create a new answers
     * object.
     */

    // Potential index of existing answer
    let answerIndex = answers.findIndex((a) => a.questionId === questionId);

    // Checks if answer exists
    if (answerIndex === -1) {
      setAnswers([...answers, { questionId, answerId }]);
    } else {
      setAnswers(answers.map((answer) => (answer.questionId === questionId ? { questionId, answerId } : answer)));
    }
  }

  if (!questions) return <StatusBox />;

  return (
    <ScrollView style={{ backgroundColor: colors.white }} className={componentStyles()}>
      <div className="content-wrapper">
        {/* Show answerable questions */}
        {!showCorrectAnswers && (
          <>
            {questions.map((question) => (
              <>
                <h2 style={{ marginBottom: "0.5rem" }}>{question.question}</h2>
                <RadioGroup
                  style={{ marginBottom: "2rem" }}
                  onSelect={(e) => onSelect(e, question.id)}
                  options={question.answers.map(({ id, answer }) => ({ id, title: answer }))}
                />
              </>
            ))}
            <Button active={active} onClick={() => submitAnswers(answers)}>
              {lang.save}
            </Button>
          </>
        )}

        {/* Show summary of users answers */}
        {showCorrectAnswers && (
          <>
            <div className="summary">
              <h2>{getTitle()}</h2>
              <p>
                {lang.youGot__placeholderCorrectAnswers__outOf__placeholderAmountOfAnswers__andEarned__placeholderPoint__point
                  .replace(
                    "{{placeholder-correct-answers}}",
                    questionsWithAnswers.filter((q) => q.isUserAnswerCorrect).length
                  )
                  .replace("{{placeholder-amount-of-answers}}", questionsWithAnswers.length)
                  .replace("{{placeholder-point}}", getTotalAmountOfObtainedPoints(questionsWithAnswers))}
              </p>
            </div>

            {questionsWithAnswers.map((question) => (
              <>
                <h2 style={{ marginBottom: "0.5rem", color: question.isUserAnswerCorrect ? colors.green : colors.red }}>
                  {question.isUserAnswerCorrect && (
                    <ApprovalIcon
                      style={{
                        color: colors.green,
                        width: "1.4rem",
                        height: "1.4rem",
                        marginBottom: "-0.3rem",
                        marginRight: "0.35rem",
                      }}
                    />
                  )}
                  {question.question}
                </h2>
                <RadioGroupShowCorrectAnswers
                  style={{ marginBottom: "2rem" }}
                  userAnswer={getUserAnswerByQuestionId(question.id)}
                  answers={question.answers.map(({ id, answer, correct }) => ({ id, title: answer, correct }))}
                />
              </>
            ))}
            <Button onClick={props.hideModalPage}>{lang.ok}</Button>
          </>
        )}
      </div>
    </ScrollView>
  );
}

const componentStyles = () => css`
  padding: 1rem 1rem 4rem 1rem;
  .content-wrapper {
    max-width: ${breakpoints.md}px;
    margin: 0 auto;
  }

  div.summary {
    margin-bottom: 2rem;

    h2 {
      font-size: 1.3rem;
      margin-bottom: 0.5rem;
    }
  }
`;

const mapDispatchToProps = (dispatch) => ({
  addToast: bindActionCreators(addToast, dispatch),
  hideModalPage: bindActionCreators(hideModalPage, dispatch),
  updateModalPage: bindActionCreators(updateModalPage, dispatch),
});

export default connect(null, mapDispatchToProps)(CheckQuestionsModal);
