import React, { useRef, useEffect, useState } from "react";
import { css } from "emotion";
import PropTypes from "prop-types";
import { connect, useSelector } from "react-redux";
import { bindActionCreators } from "redux";
import TinyAnimate from "TinyAnimate";
import { throttle } from "lodash-es";

// Style
import colors from "../../style/colors";

// Actions
import { showLightBox } from "../../actions/uiActions";

// Icons
import { ArrowLeftIcon, ArrowRightIcon } from "mdi-react";

// Utilities
import breakpoints from "../../config/breakpoints";

// Hooks
import { useElementSize } from "../../hooks/useElementSize";

// Components
import Image from "./Image";

/** Used for displaying an array of images, if there is more images than what screenwidth can hold, it is scrollable.
 * Each Image have a fixed height to prevent shenanigans when scrolling through them.
 */

const getImageSourceFromObject = (image) => {
  if (image.baseURL && image.image) {
    return `${image.baseURL}w_727,f_auto,q_auto/${image.image}`;
  } else {
    return image.image;
  }
};

const ImageCarousel = (props) => {
  // Destructure props
  const { boxPadding, spacingBottom, images, disableLightbox, showLightBox } = props;

  const scrollHiderRef = useRef();
  const [disableLeftArrow, setDisableLeftArrow] = useState(false);
  const [disableRightArrow, setDisableRightArrow] = useState(false);

  // Arrow colors when browser window is desktop
  const primaryColor = useSelector((s) => s.appConfig.primaryColor);

  // Get the width to calculate the photo set, when resizing the browser window
  const width = useElementSize(scrollHiderRef);

  // Disable/enable arrows
  useEffect(() => {
    let element = scrollHiderRef.current;
    let throttledCheckScroll = throttle(_checkScroll, 150, { trailing: true });

    if (!element) return;

    function _checkScroll(e) {
      if (element.scrollLeft < 2) {
        setDisableLeftArrow(true);
      } else {
        setDisableLeftArrow(false);
      }

      if (element.scrollLeft + element.getBoundingClientRect().width > element.scrollWidth - 2) {
        setDisableRightArrow(true);
      } else {
        setDisableRightArrow(false);
      }
    }

    _checkScroll();

    element.addEventListener("scroll", throttledCheckScroll);
    return () => element.removeEventListener("scroll", throttledCheckScroll);
    // eslint-disable-next-line
  }, []);

  // Return null if images are empty
  if (!images) return null;

  function slide(direction) {
    // Get "current" image.
    let images = [...scrollHiderRef.current.querySelectorAll("img")];

    // Create a map over images and sort them by closest to left edge
    let imageMap = images
      .map((image, index) => ({
        index,
        offset: image.offsetLeft,
        distanceToLeftEdge: _getDeltaDistance(image, scrollHiderRef),
      }))
      .sort((a, b) => (a.distanceToLeftEdge < b.distanceToLeftEdge ? -1 : 1));

    if (direction === "left") {
      let nextImage = images[imageMap[0].index - 1] ? images[imageMap[0].index - 1] : images[0];
      let nextScrollPosition = nextImage.offsetLeft - 10;
      _animateScroll(scrollHiderRef.current.scrollLeft, nextScrollPosition, scrollHiderRef);
    }

    if (direction === "right") {
      let nextImage = images[imageMap[0].index + 1] ? images[imageMap[0].index + 1] : images[images.length - 1];
      let nextScrollPosition = nextImage.offsetLeft - 10;
      _animateScroll(scrollHiderRef.current.scrollLeft, nextScrollPosition, scrollHiderRef);
    }

    function _getDeltaDistance(image, scrollHider) {
      let distance = image.offsetLeft - scrollHider.current.scrollLeft;
      if (distance < 0) return distance * -1;
      return distance;
    }

    function _animateScroll(prev, next, scrollHider) {
      scrollHider.current &&
        TinyAnimate.animate(
          prev,
          next,
          400,
          (x) => {
            scrollHider.current.scrollLeft = x;
          },
          "easeInOutQuart"
        );
    }
  }

  if (images.length === 1) {
    return (
      <div className={imageWrapper(boxPadding, spacingBottom, primaryColor, width)} ref={scrollHiderRef}>
        <Image
          src={getImageSourceFromObject(images[0])}
          style={{ borderRadius: "3px" }}
          alt=""
          onClick={() =>
            disableLightbox !== true
              ? showLightBox({
                  images: [images[0]],
                  index: 0,
                })
              : null
          }
        />
      </div>
    );
  } else {
    return (
      <div className={imageCarousel(boxPadding, spacingBottom, primaryColor, width, breakpoints)}>
        <div id="scroll-hider" className="scroll-hider" ref={scrollHiderRef} onScroll={(e) => e.stopPropagation()}>
          {images.map((image, index) => (
            <Image
              id={`img-${index}`}
              className="img"
              src={getImageSourceFromObject(image)}
              key={index}
              alt=""
              onClick={() =>
                disableLightbox !== true
                  ? showLightBox({
                      images,
                      index,
                    })
                  : null
              }
            />
          ))}
          <div className="invisible-spacer-div"></div>
        </div>
        <div className={`arrow-right ${disableRightArrow ? "disabled" : ""}`} onClick={() => slide("right")}>
          <ArrowRightIcon className="arrow-icon" />
        </div>
        <div className={`arrow-left ${disableLeftArrow ? "disabled" : ""}`} onClick={() => slide("left")}>
          <ArrowLeftIcon className="arrow-icon" />
        </div>
      </div>
    );
  }
};

const imageCarousel = (boxPadding = "1", spacingBottom = "1", primaryColor, width, breakpoints) => css`
  height: 300px;
  margin: 0 -${boxPadding}rem ${spacingBottom}rem -${boxPadding}rem;
  margin-bottom: ${spacingBottom}rem;
  overflow: hidden;
  position: relative;

  .scroll-hider {
    display: flex;
    position: relative;
    white-space: nowrap;
    overflow-y: hidden;
    overflow-x: auto;
    -webkit-overflow-scrolling: touch;
    height: 330px;
    padding-left: ${boxPadding / 2}rem; /* If boxpadding isn't parsed */

    .invisible-spacer-div {
      width: 1rem;
      background-color: rgba(255, 255, 255, 0);
      border: 1px rgba(255, 255, 255, 0) solid;
    }
  }

  &:hover {
    .arrow-left,
    .arrow-right {
      opacity: 1;
      transform: scale(1) translateX(0);
      transition: opacity 240ms ease, transform 240ms ease;
    }
  }

  .arrow-left,
  .arrow-right {
    opacity: 0;
    top: 41%;
    position: absolute;
    display: none;
    justify-content: center;
    align-items: center;
    height: 46px;
    width: 46px;
    border: 1px solid ${colors.lightGrey};
    background-color: ${colors.white};
    cursor: pointer;
    transition: background-color 200ms ease;
    box-shadow: 0 3px 8px rgba(0, 0, 0, 0.075);
    transition: opacity 240ms ease, transform 240ms ease;

    svg {
      color: ${primaryColor};
      transition: color 200ms ease;
    }

    &:active {
      transition: background-color 80ms ease;
      background-color: ${colors.ultraLightGrey};
    }

    &.disabled {
      cursor: initial;
      svg {
        color: ${colors.midGrey};
        transition: color 200ms ease;
      }
    }
  }

  .arrow-left {
    left: -1px;
    transform: scale(1) translateX(-10px);
    border-radius: 0 50% 50% 0;
    transform-origin: left center;
  }

  .arrow-right {
    right: -1px;
    transform: scale(1) translateX(10px);
    border-radius: 50% 0 0 50%;
    transform-origin: right center;
  }

  @media screen and (min-width: ${breakpoints.md}px) {
    .arrow-left,
    .arrow-right {
      display: flex;
    }
  }

  .img {
    border: 1px ${colors.lightGrey} solid;
    border-radius: 3px;
    display: inline-block;
    height: 300px;
    margin: 0 ${boxPadding / 2}rem;
    object-fit: cover;
    max-width: ${width ? `calc(${width}px - 15%)` : "auto"};
    transition: max-width 200ms ease;
  }
`;

const imageWrapper = (boxPadding = 0, spacingBottom = 0) => css`
  margin: -${boxPadding}rem -${boxPadding}rem ${spacingBottom}rem -${boxPadding}rem;
  img {
    max-width: 100%;
    max-height: 75vh;
    margin: 0 auto 1rem auto;
    display: block;
  }
`;

const mapDispatchToProps = (dispatch) => ({
  showLightBox: bindActionCreators(showLightBox, dispatch),
});

const mapStateToProps = (state) => ({
  appConfig: state.appConfig,
});

export default connect(mapStateToProps, mapDispatchToProps)(ImageCarousel);

ImageCarousel.propTypes = {
  /** Array for the displayed images */
  images: PropTypes.array,
  /** value used for providing a bit of padding in your own implementation */
  boxPadding: PropTypes.string,
  /** value used for providing a bit of space on the bottom, in your own implementation */
  spacingBottom: PropTypes.string,
};
