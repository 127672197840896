// Libs
import React, { useState, Fragment } from "react";
import { css } from "emotion";
import { useSelector } from "react-redux";

// Components
import { CheckboxBlankOutlineIcon, CheckboxMarkedIcon, TrashCanOutlineIcon } from "mdi-react";
import ActionWrapper from "../ActionWrapper";

// Style
import colors from "../../../style/colors";

// Utilities
import getProfilePictureFromUserObject from "../../../utilities/get-profile-picture-from-user-object";

/** used for displaying item as part of a list, usecase could be: users on a highscore, events you can sign up to etc.
 * ```
 *  <ListItem
                      imageLeft={getProfilePictureFromUserObject(birthdayUser)}
                      title={birthdayUser.name}
                      subTitle={birthdayUser.userGroup}
                      style={{ marginLeft: `-${boxPadding}`, marginRight: `-${boxPadding}` }}
                    />
 * ```
*/

const ListItem = (props) => {
  const primaryColor = useSelector((s) => s.appConfig.primaryColor);
  const [isDeleted, setIsDeleted] = useState(false);
  const animationDuration = 500;

  function deleteItem() {
    setIsDeleted(true);
    setTimeout(props.onClick, animationDuration);
  }

  return (
    <div
      key={props.key}
      style={props.style}
      className={
        componentStyle({ ...props, primaryColor, animationDuration }) +
        `${props.maxWidth ? " enable-max-width" : ""} ${isDeleted ? "deleted" : ""}`
      }
      onClick={props.enableDeleteButton === true ? null : props.onClick}
    >
      {props.isSelected && props.enableCheckmark && <CheckboxMarkedIcon className="checkmark marked" />}

      {!props.isSelected && props.enableCheckmark && <CheckboxBlankOutlineIcon className="checkmark" />}

      {getProfilePictureFromUserObject(props.user, 40, { flexGrow: 0, margin: "0 0.5rem" })}

      <div className="user-wrapper">
        <p className="name">{props.user.name}</p>
        <p className="meta">
          {props.user.userGroup.title}, {props.user.masterGroup.title}
        </p>
      </div>
      {props.enableDeleteButton && (
        <ActionWrapper onClick={deleteItem} className="delete-button">
          <TrashCanOutlineIcon />
        </ActionWrapper>
      )}
    </div>
  );
};

const componentStyle = (props) => css`
  margin-bottom: -1px;
  background-color: ${colors.white};
  border-top: 1px solid ${colors.midGrey};
  border-bottom: 1px solid ${colors.midGrey};
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 0.75rem;
  color: ${colors.black};
  flex-shrink: 0;
  max-height: 100px;
  transition: background-color 190ms ease;

  &.enable-max-width {
    @media screen and (min-width: ${props.maxWidth}px) {
      max-width: ${props.maxWidth}px;
      margin-left: auto !important;
      margin-right: auto !important;
      border: 1px solid ${colors.midGrey};
      background-color: ${colors.white};
    }
  }

  &:hover {
    background-color: ${props.enableDeleteButton ? "none" : colors.ultraLightGrey};
    cursor: ${props.enableDeleteButton ? "initial" : "pointer"};
  }

  &:active {
    background-color: ${props.enableDeleteButton ? "none" : colors.lightGrey};
    cursor: pointer;
    transition: background-color 120ms ease;
  }

  &.deleted {
    max-height: 0px;
    padding-top: 0px;
    padding-bottom: 0px;
    margin-bottom: -2px;
    transform: scale(0.75, 0);
    opacity: 0;
    transition: max-height ${props.animationDuration}ms ease, padding ${props.animationDuration}ms ease,
      margin ${props.animationDuration}ms ease, transform ${props.animationDuration}ms ease,
      opacity ${props.animationDuration}ms ease;
  }

  .checkmark {
    color: ${colors.darkGrey};
  }
  .checkmark.marked {
    color: ${props.primaryColor};
  }

  .user-wrapper {
    flex-grow: 1;

    p.name {
      font-size: 1rem;
      color: ${colors.black};
      margin: 0;
    }

    p.meta {
      font-size: 0.85rem;
      color: ${colors.darkGrey};
      margin: 0;
    }
  }

  .delete-button {
    border-radius: 100%;
    width: 2.5rem;
    height: 2.5rem;
    display: flex;
    align-items: center;
    justify-content: center;
    /* transition: background-color 120ms ease; */

    svg {
      color: ${colors.red};
      width: 1.25rem;
      height: 1.25rem;
      margin-bottom: -3px;
    }

    &:hover {
      background-color: ${colors.lightGrey};
      /* transition: background-color 80ms ease; */
    }
  }
`;

export default ListItem;
