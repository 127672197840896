import React from "react";
import { HelpCircleIcon } from "mdi-react";
import { ReactComponent as CustomHPBygIcon } from "../images/customIcons/hp-byg.svg";

export default function (iconName = "", styles = null) {
  if (!iconName) return <HelpCircleIcon style={styles} />;

  iconName = iconName.trim().toLowerCase();

  switch (iconName) {
    case "custom-hp-byg":
      return <CustomHPBygIcon style={styles} />;

    default:
      return <HelpCircleIcon style={styles} />;
  }
}
