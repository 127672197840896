// Libs
import React, { useState } from "react";
import { format, parse } from "date-fns";
import { connect, useDispatch, useSelector } from "react-redux";
import { DownloadIcon, ErrorOutlineIcon, InfoOutlineIcon, PencilIcon, ContentCopyIcon } from "mdi-react";
import { bindActionCreators } from "redux";
import * as queryString from "query-string";

// Components
import Post from "../ui/Post";
import RegistrationPostContent from "./RegistrationPostContent";
import StatusBox from "../ui/StatusBox";
import InlineSpinner from "../ui/InlineSpinner";
import RegistrationFormBuilder from "./RegistrationFormBuilder";

// Utilities and config
import getUserLocale from "../../utilities/get-user-locale";
import { feedTypes, formBuilderModes, postLayoutModes } from "./config";
import { parseDatetimeExtended } from "../../utilities/parse-date";
import getPageFromId from "../../utilities/get-page-from-id";
import getProfilePictureFromUserObject from "../../utilities/get-profile-picture-from-user-object";

// Hooks
import useAuthorModal from "../../hooks/useAuthorModal";

// Actions
import { likePost, readRegistrationPost, unlikePost } from "../../actions/registrationActions";
import { addToast, showContextMenu, showModalPage, updateModalPage } from "../../actions/uiActions";

// Style & Icons
import colors from "../../style/colors";

const RegistrationPosts = (props) => {
  const dispatch = useDispatch();

  const pages = useSelector((state) => state.pages.pages);
  const [page] = useState(getPageFromId(pages, props.match.params.pageId));

  // Multi-language handling
  const lang = useSelector((state) => state.language.language);

  const registrationConfig = props.registrationConfig;
  const feedType = props.feedType || feedTypes.all;

  // To get the users information when clicked on the image
  const authorModal = useAuthorModal(props.showModalPage, props.updateModalPage);

  // Get the registrationId / dataId
  let registrationId = queryString.parse(window.location.search).dataId;

  // Redux state
  const { loading, posts, endOfFeed, error } = useSelector((state) => state.registration[feedType]);
  const { postsWithLoadingSwipes, postsWithLoadingLikes } = useSelector((state) => state.registration);
  // Used for getUserLocale()
  const { user } = useSelector((state) => state.auth);

  /******************** Likes ********************/
  function isLiked(post) {
    if (!post.hasOwnProperty("likes") || post.likes.filter((l) => l && l.id === user.id).length === 0) {
      return false;
    } else {
      return true;
    }
  }

  const onContextMenuClick = (post) => {
    let contextMenuItems = [];

    if (registrationConfig.allowPDFExport && post.pdfGeneratorURL) {
      function navigateToPDF(url) {
        if (!url) return;
        window.open(url, "_blank").focus();
      }

      contextMenuItems.push({
        icon: <DownloadIcon />,
        title: lang.downloadAsPdf,
        callback: () => navigateToPDF(post.pdfGeneratorURL ? post.pdfGeneratorURL : null),
      });
    }

    contextMenuItems.push({
      icon: <ContentCopyIcon />,
      title: lang.duplicate,
      callback: () =>
        dispatch(
          showModalPage({
            title: page.title,
            content: <RegistrationFormBuilder {...props} formMode={formBuilderModes.duplicate} post={post} />,
          })
        ),
    });

    if (post.author.id === user.id || user.adminId) {
      contextMenuItems.push({
        icon: <PencilIcon />,
        title: lang.edit,
        callback: () => {
          dispatch(
            showModalPage({
              title: page.title,
              content: <RegistrationFormBuilder {...props} formMode={formBuilderModes.edit} post={post} />,
            })
          );

          dispatch(
            addToast({
              title: `${lang.attention}!`,
              content: lang.previouslyAnsweredRegistrationQuestionsNotVisible,
              icon: <ErrorOutlineIcon />,
              styleType: "neutral",
              duration: 20000,
            })
          );
        },
      });
    }

    dispatch(showContextMenu(contextMenuItems));
  };

  return (
    <div style={{ paddingTop: "1rem" }}>
      {/* Content */}
      {posts.map((p) => (
        <Post
          showContextMenuToggle={true}
          contextMenuToggleCallback={() => onContextMenuClick(p)}
          key={`registrationPost-${p.id}`}
          disableSwipe={feedType === feedTypes.mine}
          useExpandCollapseLayout={props.layoutMode === postLayoutModes.accordion}
          expandCollapseToggle={
            <div style={{ display: "flex", alignItems: "center" }}>
              {p.author ? (
                <>
                  {getProfilePictureFromUserObject(p.author)}
                  <div className="info-wrapper" style={{ marginLeft: "0.5rem" }}>
                    <p>{p.author.name}</p>
                    <p className="meta" style={{ color: colors.darkGrey }}>
                      {parseDatetimeExtended(p.date)}
                    </p>
                  </div>
                </>
              ) : (
                <p>{parseDatetimeExtended(p.date)}</p>
              )}
            </div>
          }
          date-test-id="registration-post"
          date={format(parse(p.date, "yyyyMMddHHmmss", 0), "do MMMM yyyy", getUserLocale(user))}
          componentContent={<RegistrationPostContent answers={p.answers} />}
          points={p.points}
          author={p.author}
          disableLikesAndComments={props.disableLikesAndComment || feedType === feedTypes.mine}
          likes={p.likes}
          onLike={
            isLiked(p)
              ? () => props.unlikePost({ registrationId, postId: p.id, feedType })
              : () => props.likePost({ registrationId, postId: p.id, feedType })
          }
          liked={isLiked(p)}
          likeTextIdentifiers={registrationConfig ? registrationConfig.likeTextIdentifiers : null}
          submittingLike={postsWithLoadingLikes.indexOf(p.id) !== -1}
          read={!!p.readDate}
          readDate={p.readDate}
          onAuthorClick={() => authorModal(p.author.id)}
          submittingSwipe={postsWithLoadingSwipes.indexOf(p.id) !== -1}
          onSwipeEnd={() => props.readRegistrationPost({ registrationId, postId: p.id, feedType })}
        />
      ))}

      {/* Loading */}
      {loading && !error && !endOfFeed && (
        <InlineSpinner style={{ margin: "2rem 0" }} title={`${lang.loading} ${lang.posts}...`} />
      )}

      {/* End of feed with posts */}
      {posts.length > 0 && endOfFeed && (
        <StatusBox
          style={{ marginBottom: "4rem", marginTop: "1rem" }}
          icon={<InfoOutlineIcon />}
          title={lang.noMorePosts}
          content={lang.reachedEndOfFeed}
        />
      )}

      {/* End of feed without posts */}
      {posts.length === 0 && endOfFeed && (
        <StatusBox
          style={{ marginBottom: "4rem", marginTop: "1rem" }}
          icon={<InfoOutlineIcon />}
          title={lang.noPostsHere}
          content={lang.comeBackSoon}
        />
      )}
    </div>
  );
};

const mapDispatchToProps = (dispatch) => ({
  updateModalPage: bindActionCreators(updateModalPage, dispatch),
  showModalPage: bindActionCreators(showModalPage, dispatch),
  likePost: bindActionCreators(likePost, dispatch),
  unlikePost: bindActionCreators(unlikePost, dispatch),
  readRegistrationPost: bindActionCreators(readRegistrationPost, dispatch),
});

export default connect(null, mapDispatchToProps)(RegistrationPosts);
