// Libs
import React, { useEffect, useRef, useState } from "react";
import { css } from "emotion";
import { useSelector, useDispatch } from "react-redux";

// Styles
import colors from "../../../style/colors";

// Components
import ImageUploadSingle from "../ImageUploadSingle";
import breakpoints from "../../../config/breakpoints";
import TextInput from "../TextInput";
import Button from "../Button";
import { AlertDecagramIcon, ErrorOutlineIcon } from "mdi-react";

// Utilities
import req from "../../../utilities/request-utility";

// Actions
import { addToast, hideModalPage, showDialog } from "../../../actions/uiActions";
import { getAppConfig } from "../../../actions/appConfigActions";
import ColorPicker from "../ColorPicker";

const ManageApplication = (props) => {
  const dispatch = useDispatch();

  // Redux state
  const { frontPageImage, icon, customerLogo, primaryColor, title, appId } = useSelector((state) => state.appConfig);
  const { language: lang } = useSelector((state) => state.language);

  // Local state
  const [appConfig, setAppConfig] = useState({});
  const [loading, setLoading] = useState(true);
  const [notValid, setNotValid] = useState(true);

  // Refs

  useEffect(() => {
    setAppConfig({ frontPageImage, appIcon: icon, customerLogo, primaryColor, name: title });
    setLoading(false);
    // eslint-disable-next-line
  }, []);

  // Validate that all inputs have been filled
  useEffect(() => {
    if (Object.keys(appConfig).length > 0) {
      if (
        Object.keys(appConfig.frontPageImage).length === 0 ||
        Object.keys(appConfig.appIcon).length === 0 ||
        Object.keys(appConfig.customerLogo).length === 0 ||
        appConfig.primaryColor.length === 0 ||
        appConfig.name.length === 0
      ) {
        return setNotValid(true);
      }
      setNotValid(false);
    }
    // eslint-disable-next-line
  }, [appConfig]);

  const update = () => {
    dispatch(
      showDialog({
        styleType: "warning",
        icon: <AlertDecagramIcon />,
        title: lang.changeAppSettings,
        content: lang.aboutToChangeAppSettings,
        primaryActionTitle: lang.yesSaveChanges,
        secondaryActionTitle: lang.noNotFinishedYet,
        primaryAction: () => {
          req()
            .patch(`apps/${appId}`, appConfig)
            .then(() => {
              dispatch(getAppConfig());
              dispatch(hideModalPage());
            })
            .catch((err) => {
              dispatch(
                addToast({
                  title: lang.errorGeneral,
                  content: lang.errorCouldNotRegister,
                  icon: <ErrorOutlineIcon />,
                  styleType: "error",
                  duration: 20000,
                })
              );
            });
        },
      })
    );
  };

  return (
    <div
      className={`${componentStyle(props, appConfig.primaryColor)} ${props.className || ""}`}
      style={props.style && props.style}
    >
      {!loading && (
        <div className="wrapper">
          {/** Application Name **/}
          <p className="title">{lang.appName}</p>
          <p className="hint">
            Bruges bl.a. til visning i titellinien i en browser, under ikonet på hjemmeskærmen og som afsendernavn på sms'er
            og emails
          </p>
          <TextInput
            style={{ marginBottom: "2rem" }}
            defaultValue={appConfig.name}
            onChange={(e) => setAppConfig({ ...appConfig, name: e.target.value })}
          />

          {/** Primary Color **/}
          <p className="title">{lang.primaryColor}</p>
          <p className="hint">
            Vi bruger denne farve som den primære handling, til overskrifter og meget mere. Brug jeres brand-farve for at
            styrke identiteten i appen
          </p>
          <ColorPicker
            style={{ marginBottom: "2rem" }}
            value={appConfig.primaryColor}
            onChange={(e) => setAppConfig({ ...appConfig, primaryColor: e.target.value })}
          />

          {/** Front Page Image **/}
          <p className="title" style={{ marginTop: "1rem" }}>
            {lang.frontPageImage}
          </p>
          <p className="hint">
            Billedet vises som baggrund på forsiden. Vi gør billedet en anelse mørkere når vi viser det for at sikre høj
            kontrast til teksten
          </p>
          <ImageUploadSingle
            style={{ marginBottom: "2rem" }}
            className={appConfig.frontPageImage.image ? "image-container front-page" : "image-container"}
            uploadedFile={appConfig.frontPageImage}
            removeFile={() => setAppConfig({ ...appConfig, frontPageImage: {} })}
            onFile={(file) =>
              setAppConfig({
                ...appConfig,
                frontPageImage: {
                  image: file.file,
                  baseURL: file.baseURL,
                },
              })
            }
          />

          {/** App Icon Image **/}
          <p className="title">{lang.appIcon}</p>
          <p className="hint">
            Ikon der vises når appen føjes til hjemmeskærm og i browserens titellinie. Skal være kvadratisk eller have hvid
            baggrund
          </p>
          <ImageUploadSingle
            style={{ marginBottom: "2rem" }}
            className={appConfig.appIcon.image ? "image-container icon app" : "image-container "}
            uploadedFile={appConfig.appIcon}
            removeFile={() => setAppConfig({ ...appConfig, appIcon: {} })}
            onFile={(file) =>
              setAppConfig({
                ...appConfig,
                appIcon: {
                  image: file.file,
                  baseURL: file.baseURL,
                },
              })
            }
          />

          {/** Customer Image **/}
          <p className="title">{lang.firmLogo}</p>
          <p className="hint">
            Vises øverst på forsiden. Det kan også blive brugt andre steder (f.eks. hvis i har pdf-eksport af indhold eller i
            visse emails). Vi anbefaler et bredt billede så har i flere logo'er i virksomheden så vælg den bredeste
          </p>
          <ImageUploadSingle
            style={{ marginBottom: "2rem" }}
            className={appConfig.customerLogo.image ? "image-container icon customer" : "image-container"}
            uploadedFile={appConfig.customerLogo}
            removeFile={() => setAppConfig({ ...appConfig, customerLogo: {} })}
            onFile={(file) =>
              setAppConfig({
                ...appConfig,
                customerLogo: {
                  image: file.file,
                  baseURL: file.baseURL,
                },
              })
            }
          />

          {/** Save Changes **/}
          <Button disabled={notValid} onClick={() => update()} style={{ margin: "3rem 0 0.6rem 0" }}>
            {lang.saveChanges}
          </Button>
          <Button buttonType={"secondary"} onClick={() => dispatch(hideModalPage())}>
            {lang.cancel}
          </Button>
        </div>
      )}
    </div>
  );
};

const componentStyle = (props, color) => css`
  background-color: ${colors.white};
  display: flex;
  justify-content: center;

  .wrapper {
    width: 100%;
    max-width: ${breakpoints.md}px;
    background-color: ${colors.white};
    padding: 1rem 0.65rem 4rem 0.65rem;

    p.title {
      margin-bottom: 0.25rem;
    }
    p.hint {
      margin-bottom: 0.75rem;
      font-size: 0.9rem;
      color: ${colors.darkGrey};
    }

    .image-container {
      width: 100%;
      justify-content: center;
      align-items: center;
      margin-bottom: 1rem;

      &:last-child {
        margin-bottom: 0;
      }

      &.icon {
        padding: 2rem 3rem;

        &.app {
          img {
            width: 25%;
          }
        }

        &.customer {
          img {
            width: 75%;
          }
        }
      }
    }
  }
`;

export default ManageApplication;
