import React, { useEffect, useState } from "react";
import { css } from "emotion";
import colors from "../../style/colors";
import Button from "./Button";
import { BrokenImageIcon, ImageIcon } from "mdi-react";

/**
 * @param {Object} props
 * @param {Function} props.onLoad - Callback function to be called when image has loaded
 * @param {Function} props.onLoad - Callback function to be called when image has loaded
 * @param {String} props.id - an id
 * @param {String} props.alt - Alt text
 * @param {String} props.onClick - Event handler when clicking the image
 * @param {String} props.src - The image sourfce
 * @param {Ref} props.ref - A ref to the image
 * @param {Object} props.style - A style objecvt
 * @param {String} props.className
 *
 */
const Image = (props) => {
  let [loading, setLoading] = useState(null);
  let [error, setError] = useState(false);

  useEffect(() => {
    // Start loading display if image takes more than 300ms
    setTimeout(() => {
      setLoading((loading) => (loading === null ? true : false));
    }, 300);
  }, []);

  function onLoad() {
    if (props.onLoad) props.onLoad();
    setLoading(false);
  }

  function onError() {
    setError(true);
    setLoading(false);
  }

  function removeImageAndRerender() {
    let imageTag = document.querySelector(`img[src="${props.src}"]`);

    if (imageTag) {
      imageTag.src = props.src + `?version=${Date.now()}`;
      setLoading(null);
      setError(false);
      setTimeout(() => {
        imageTag.src = props.src; // Reset image src so the imageTag-finder still works next time
      }, 200);
    }
  }

  return (
    <>
      {error && loading === false && (
        <div className={`${imageLoader()} ${props.className}`}>
          <BrokenImageIcon color={colors.darkGrey} />
          <p className="meta" style={{ textAlign: "center", marginBottom: "0.5rem" }}>
            Image failed to load...
          </p>
          <Button onClick={removeImageAndRerender} buttonType="secondary" style={{ margin: "0 auto", width: "auto" }}>
            Try again
          </Button>
        </div>
      )}
      {!error && loading === true && (
        <div className={`${imageLoader()} ${props.className}  use-blink-animation`}>
          <ImageIcon color={colors.darkGrey} />
        </div>
      )}

      <img
        onError={onError}
        onLoad={onLoad}
        id={props.id}
        alt={props.alt}
        onClick={props.onClick}
        src={props.src}
        ref={props.ref}
        style={props.style}
        className={`${props.className} ${componentStyles()} ${loading === true ? "loading" : ""} ${error ? "error" : ""}`}
      />
    </>
  );
};

const imageLoader = () => css`
  min-width: 300px;
  background-color: ${colors.lightGrey};
  position: relative;
  border-radius: 10px;
  padding: 10px;

  svg {
    display: block;
    margin: 90px auto 0 auto;
    opacity: 0.6;
    width: 4rem;
    height: 4rem;
  }

  &.use-blink-animation {
    animation: loadingBlink 2000ms linear infinite;
  }

  @keyframes loadingBlink {
    0% {
      opacity: 0.8;
    }
    25% {
      opacity: 0.8;
    }
    35% {
      opacity: 0.4;
    }
    80% {
      opacity: 0.8;
    }
    100% {
      opacity: 0.8;
    }
  }
`;

const componentStyles = () => css`
  &.error,
  &.loading {
    display: none !important;
  }
`;

export default Image;
