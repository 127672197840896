// Libs
import React from "react";
import { useSelector } from "react-redux";

// Components
import Page from "./ui/Page";
import TopBar from "./ui/TopBar";
import StatusBox from "./ui/StatusBox";
import { AlertDecagramIcon } from "mdi-react";

// Utilities
import pageNavigatorV2 from "../utilities/page-navigator-v2";

const PageNotFound = (props) => {
  // Language
  const { language: lang } = useSelector((state) => state.language);

  return (
    <Page>
      <TopBar useDefaultBackButton={true} />
      <StatusBox
        icon={<AlertDecagramIcon />}
        style={{ marginTop: "2rem" }}
        content={lang.pageNotFound}
        action={{
          callback: () => pageNavigatorV2({ mode: "pop" }),
          title: "Gå til forsiden",
        }}
      />
    </Page>
  );
};

export default PageNotFound;
