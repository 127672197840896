import {
  // Data fecthing
  GET_TARGET_GROUP_USERS,
  GET_TARGET_GROUP_USERS_SUCCESS,
  GET_TARGET_GROUP_USERS_FAILURE,
  GET_MORE_OVERVIEW_USERS,
  GET_MORE_OVERVIEW_USERS_SUCCESS,
  GET_MORE_OVERVIEW_USERS_FAILURE,
  SET_OVERVIEW_USERS_END_OF_FEED,
  CLEAR_OVERVIEW_USERS,
  SET_OVERVIEW_USERS_COUNT,

  // Target group manipulation
  ADD_USER_TO_TARGET_GROUP,
  ADD_USER_TO_TARGET_GROUP_SUCCESS,
  ADD_USER_TO_TARGET_GROUP_FAILURE,
  REMOVE_USER_FROM_TARGET_GROUP,
  REMOVE_USER_FROM_TARGET_GROUP_SUCCESS,
  REMOVE_USER_FROM_TARGET_GROUP_FAILURE,

  // Handle user groups
  GET_USER_GROUPS_SUCCESS,
  SET_SELECTED_USER_GROUP,

  // Handle master groups
  GET_MASTER_GROUPS_SUCCESS,
  SET_SELECTED_MASTER_GROUP,

  // handle activity groups
  GET_ACTIVITY_GROUPS_SUCCESS,
  SET_SELECTED_ACTIVITY_GROUP,

  // generel
  SET_SERCHTERM,
  SET_CURRENT_PAGE_ID,
  SHOW_FILTERS,
  HIDE_FILTERS,
  SHOW_TARGET_GROUP,
  HIDE_TARGET_GROUP,
} from "../actions/actionTypes";

const initialState = {
  overviewUsers: {
    loading: false,
    error: false,
    endOfFeed: false,
    count: null,
    users: [],
  },
  targetGroupUsers: {
    loading: false,
    error: false,
    users: [],
  },
  userGroups: [],
  selectedUserGroupId: "",
  masterGroups: [],
  selectedMasterGroupId: "",
  activityGroups: [],
  selectedActivityGroupId: "",
  searchTerm: "",
  currentPageId: null,
  showTargetGroup: false,
  showFilters: false,
};

export default function (state = initialState, action) {
  switch (action.type) {
    //
    // Generel
    //
    case SET_CURRENT_PAGE_ID:
      return {
        ...state,
        currentPageId: action.payload,
      };

    case SET_SERCHTERM:
      return {
        ...state,
        searchTerm: action.payload,
      };

    case SHOW_FILTERS:
      return {
        ...state,
        showFilters: true,
      };

    case HIDE_FILTERS:
      return {
        ...state,
        showFilters: false,
      };

    case SHOW_TARGET_GROUP:
      return {
        ...state,
        showTargetGroup: true,
      };

    case HIDE_TARGET_GROUP:
      return {
        ...state,
        showTargetGroup: false,
      };

    //
    // Overview users
    //
    case SET_OVERVIEW_USERS_COUNT:
      return {
        ...state,
        overviewUsers: {
          ...state.overviewUsers,
          count: action.payload,
        },
      };

    case GET_MORE_OVERVIEW_USERS:
      return {
        ...state,
        overviewUsers: {
          ...state.overviewUsers,
          loading: true,
          error: false,
        },
      };

    case GET_MORE_OVERVIEW_USERS_SUCCESS:
      return {
        ...state,
        overviewUsers: {
          ...state.overviewUsers,
          loading: false,
          error: false,
          users: [...state.overviewUsers.users, ...action.payload],
        },
      };

    case GET_MORE_OVERVIEW_USERS_FAILURE:
      return {
        ...state,
        overviewUsers: {
          ...state.overviewUsers,
          loading: false,
          error: true,
        },
      };

    case SET_OVERVIEW_USERS_END_OF_FEED:
      return {
        ...state,
        overviewUsers: {
          ...state.overviewUsers,
          loading: false,
          error: false,
          endOfFeed: true,
        },
      };

    case CLEAR_OVERVIEW_USERS:
      return {
        ...state,
        overviewUsers: {
          ...state.overviewUsers,
          users: [],
          loading: false,
          error: false,
          endOfFeed: false,
        },
      };

    //
    // Target group users
    //
    case GET_TARGET_GROUP_USERS:
      return {
        ...state,
        targetGroupUsers: {
          ...state.targetGroupUsers,
          loading: true,
          error: false,
        },
      };

    case GET_TARGET_GROUP_USERS_SUCCESS:
      return {
        ...state,
        targetGroupUsers: {
          ...state.targetGroupUsers,
          loading: false,
          error: false,
          users: action.payload,
        },
      };

    case GET_TARGET_GROUP_USERS_FAILURE:
      return {
        ...state,
        targetGroupUsers: {
          ...state.targetGroupUsers,
          loading: false,
          error: true,
        },
      };

    //
    // Target group manipulation
    //
    case ADD_USER_TO_TARGET_GROUP:
      return {
        ...state,
        targetGroupUsers: {
          ...state.targetGroupUsers,
          users: [...state.targetGroupUsers.users, action.payload].sort((a, b) => (a.name > b.name ? 1 : -1)),
        },
      };

    case ADD_USER_TO_TARGET_GROUP_SUCCESS:
      return {
        ...state,
      };

    case ADD_USER_TO_TARGET_GROUP_FAILURE:
      return {
        ...state,
      };

    case REMOVE_USER_FROM_TARGET_GROUP:
      return {
        ...state,
        targetGroupUsers: {
          ...state.targetGroupUsers,
          users: [...state.targetGroupUsers.users].filter((u) => u.id !== action.payload.id),
        },
      };

    case REMOVE_USER_FROM_TARGET_GROUP_SUCCESS:
      return {
        ...state,
      };

    case REMOVE_USER_FROM_TARGET_GROUP_FAILURE:
      return {
        ...state,
      };

    // User groups
    case GET_USER_GROUPS_SUCCESS:
      return {
        ...state,
        userGroups: action.payload,
      };

    case SET_SELECTED_USER_GROUP: {
      return {
        ...state,
        selectedUserGroupId: action.payload,
      };
    }

    // Master groups
    case GET_MASTER_GROUPS_SUCCESS:
      return {
        ...state,
        masterGroups: action.payload,
      };

    case SET_SELECTED_MASTER_GROUP: {
      return {
        ...state,
        selectedMasterGroupId: action.payload,
      };
    }

    // Activity groups
    case GET_ACTIVITY_GROUPS_SUCCESS:
      return {
        ...state,
        activityGroups: action.payload,
      };

    case SET_SELECTED_ACTIVITY_GROUP: {
      return {
        ...state,
        selectedActivityGroupId: action.payload,
      };
    }

    default:
      return state;
  }
}
