import React, { useEffect, useState } from "react";
import { connect, useSelector, useDispatch } from "react-redux";
import { bindActionCreators } from "redux";
import { feedTypes } from "./config";

// Redux actions
import { getPosts, resetFeeds } from "../../actions/newsActions";
import { showModalPage, showContextMenu } from "../../actions/uiActions";

// Utilities
import getPageFromId from "../../utilities/get-page-from-id";
import pageNavigatorV2 from "../../utilities/page-navigator-v2";

// Components
import NewsPost from "./NewsPost";
import ActionWrapper from "../ui/ActionWrapper";
import Page from "../ui/Page";
import TopBar from "../ui/TopBar";
import InlineSpinner from "../ui/InlineSpinner";
import StatusBox from "../ui/StatusBox";
import { InformationOutlineIcon, ArrowForwardIcon, MoreVertIcon } from "mdi-react";
import ScrollView from "../ui/ScrollView";
import getLinkPathFromPageObject from "../../utilities/get-link-path-from-page-object";

function NewsFeedSingle(props) {
  // page setup
  const { match, getPosts, resetFeeds } = props;
  const { pages } = useSelector((state) => state.pages);
  const { language: lang } = useSelector((state) => state.language);
  const [page] = useState(getPageFromId(pages, match.params.pageId));
  const dispatch = useDispatch();

  // Data
  const { posts, loading, error, endOfFeed } = useSelector((state) => state.news.all);

  useEffect(() => {
    resetFeeds();
    getPosts({ subTypeId: page.dataId, feedType: feedTypes.all, postId: match.params.postId });
    // eslint-disable-next-line
  }, []);

  function goToPage() {
    pageNavigatorV2({ path: getLinkPathFromPageObject(page, "absolute"), direction: "forward", pageId: page.id });
  }

  return (
    <Page>
      <TopBar
        useDefaultBackButton={true}
        title={page.title}
        actionRight={
          <ActionWrapper
            onClick={() =>
              dispatch(
                showContextMenu([
                  {
                    title: `Gå til ${page.title}`,
                    icon: <ArrowForwardIcon />,
                    callback: goToPage,
                  },
                ])
              )
            }
          >
            <MoreVertIcon />
          </ActionWrapper>
        }
      />

      <ScrollView>
        <div style={{ height: "1rem" }} />

        {/* Content */}
        {posts.map((p) => (
          <NewsPost
            post={p}
            feedType={feedTypes.all}
            subTypeId={page.dataId}
            key={`news-post-${p.id}`}
            disableSwipe={page.config && page.config.disableSwipe === false ? false : true}
          />
        ))}

        {/* Loading */}
        {loading && !error && !endOfFeed && (
          <InlineSpinner style={{ margin: "2rem 0" }} title={`${lang.loading} ${lang.posts}...`} />
        )}

        {!loading && !error && (
          <StatusBox
            style={{ marginTop: "3rem" }}
            icon={<InformationOutlineIcon />}
            title={lang.thisIsASearchResult}
            content={lang.thePostIsFrom__placeholder__.replace(/{{placeholder}}/gi, page.title)}
            action={{
              title: lang.visit__placeholder__.replace(/{{placeholder}}/gi, page.title),
              callback: goToPage,
            }}
          />
        )}
      </ScrollView>
    </Page>
  );
}
const mapDispatchToProps = (dispatch) => ({
  getPosts: bindActionCreators(getPosts, dispatch),
  showModalPage: bindActionCreators(showModalPage, dispatch),
  resetFeeds: bindActionCreators(resetFeeds, dispatch),
});

export default connect(null, mapDispatchToProps)(NewsFeedSingle);
