export function generateURLFromState({
  searchTerm,
  limit = 10,
  offset = 0,
  selectedMasterGroupId,
  selectedUserGroupId,
  count = false,
}) {
  let URL = `users/${count === true ? "count/" : ""}?limit=${limit}&offset=${offset}&searchTerm=${searchTerm}`;
  if (selectedUserGroupId) URL += `&userGroupIds=${selectedUserGroupId}`;
  if (selectedMasterGroupId) URL += `&masterGroupIds=${selectedMasterGroupId}`;
  return URL;
}
