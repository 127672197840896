// Libs
import React, { Fragment, useEffect } from "react";
import { css } from "emotion";
import { connect, useSelector } from "react-redux";

// Components
import ListItem from "../ui/ListItem";
import Spinner from "../ui/InlineSpinner";
import StatusBox from "../ui/StatusBox";

// Config and utilities
import breakpoints from "../../config/breakpoints";
import getProfilePicture from "../../utilities/get-profile-picture-from-user-object";

// Styles
import { InfoOutlineIcon } from "mdi-react";
import useAuthorModal from "../../hooks/useAuthorModal";
import { bindActionCreators } from "redux";
import { showModalPage, updateModalPage } from "../../actions/uiActions";
import { getEventAttendees } from "../../actions/eventActions";

/**
 * Shows a list of users that are attending the event
 *
 * @returns {*}
 * @constructor
 */

const EventAttendeesList = (props) => {
  // Language
  const { language: lang } = useSelector((state) => state.language);

  // Event from redux store
  const { selectedEvent: event, loadingAttendees, errorAttendees } = useSelector((state) => state.event);

  // To get the users information when clicked on the row
  const authorModal = useAuthorModal(props.showModalPage, props.updateModalPage);

  // ComponentDidMount - gets the attendees
  useEffect(() => {
    props.getEventAttendees({ eventId: event.id });
    // eslint-disable-next-line
  }, []);

  return (
    <div className={scrollViewStyle()}>
      {/* Loading */}
      {loadingAttendees && !errorAttendees && <Spinner className="inline-spinner" />}
      {/* Error */}
      {!loadingAttendees && errorAttendees && <StatusBox />}
      {/* No attendees */}
      {!loadingAttendees && !errorAttendees && event.attendees && event.attendees.length === 0 && (
        <StatusBox className="status-box" icon={<InfoOutlineIcon />} title={lang.noAttendeesYet} content={" "} />
      )}
      {/* Attendees content*/}
      {!loadingAttendees && !errorAttendees && event.attendees && event.attendees.length > 0 && (
        <Fragment>
          <div className="top-spacing-container" />
          {event.attendees.map((attendee) => (
            <ListItem
              key={`attendee-event-${attendee.id}`}
              maxWidth={breakpoints.md}
              dataTestIdTitle={"eventAttendeeName"}
              clickable={true}
              onClick={() => authorModal(attendee.id)}
              imageLeft={getProfilePicture(attendee, 36, { marginRight: "0.5rem", marginBottom: "-3px" })}
              title={attendee.name}
              subTitle={`${
                (attendee.userGroup ? attendee.userGroup.title : "") +
                (attendee.masterGroup ? `, ${attendee.masterGroup.title}` : "")
              }`}
            />
          ))}
        </Fragment>
      )}
    </div>
  );
};

const scrollViewStyle = () => css`
  div.top-spacing-container {
    height: 1rem;
  }

  .status-box {
    margin: 1rem 0;
  }

  .inline-spinner {
    margin: 1rem 0;
  }
`;

const mapDispatchToProps = (dispatch) => ({
  getEventAttendees: bindActionCreators(getEventAttendees, dispatch),
  updateModalPage: bindActionCreators(updateModalPage, dispatch),
  showModalPage: bindActionCreators(showModalPage, dispatch),
});

export default connect(null, mapDispatchToProps)(EventAttendeesList);
