// Libs
import React from "react";
import { css } from "emotion";
import { useSelector } from "react-redux";

// Components
import { RadioboxBlankIcon, RadioboxMarkedIcon } from "mdi-react";

// Styles
import colors from "../../../style/colors";

// Utilities
import getProfilePictureFromUserObject from "../../../utilities/get-profile-picture-from-user-object";

const DataSelectorListItem = (props) => {
  const primaryColor = useSelector((s) => s.appConfig.primaryColor);

  return (
    <div
      id={props.id}
      style={props.style}
      className={`${componentStyle(props, { primaryColor })} ${props.className || ""} ${props.isSelected ? "checked" : ""}`}
      onClick={props.onClick}
    >
      {props.isSelected && <RadioboxMarkedIcon className="checked" />}
      {!props.isSelected && <RadioboxBlankIcon color={colors.darkGrey} />}

      {getProfilePictureFromUserObject({ profilePicture: props.image }, 40, { margin: "0 0.5rem" })}

      <div className="meta-wrapper">
        <p className="title">{props.title}</p>
        <p className="sub-title">{props.subTitle}</p>
      </div>
    </div>
  );
};

const componentStyle = (props, options = {}) => css`
  margin-bottom: -1px;
  background-color: ${colors.white};
  border-top: 1px solid ${colors.midGrey};
  border-bottom: 1px solid ${colors.midGrey};
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 0.75rem;
  color: ${colors.black};
  flex-shrink: 0;
  max-height: 100px;
  transition: background-color 190ms ease;
  cursor: pointer;

  &:last-child {
    margin-bottom: 0;
  }

  &:hover {
    transition: background-color 80ms ease;
    background-color: ${colors.ultraLightGrey};
  }
  &:active {
    transition: background-color 80ms ease;
    background-color: ${colors.midLightGrey};
  }

  .meta-wrapper {
    flex-grow: 1;

    p.title {
      font-size: 1rem;
      color: ${colors.black};
      margin: 0;
    }

    p.sub-title {
      font-size: 0.85rem;
      color: ${colors.darkGrey};
      margin: 0;
    }
  }

  &.checked {
    background-color: ${colors.midLightGrey};
    border-left: 3px solid ${options.primaryColor};

    svg.checked {
      fill: ${options.primaryColor};
    }
  }
`;

export default DataSelectorListItem;
