// Libs
import React, { Fragment, useEffect, useState } from "react";
import { css } from "emotion";
import { bindActionCreators } from "redux";
import { connect, useDispatch, useSelector } from "react-redux";

// Components
import { ErrorOutlineIcon } from "mdi-react";
import ScrollView from "../ui/ScrollView";
import FormBuilder from "../ui/FormBuilder";
import Button from "../ui/Button";

// Utilities and config
import breakpoints from "../../config/breakpoints";
import { attendingType } from "./config";

// Styles
import colors from "../../style/colors";

// Actions
import { addToast, hideModalPage } from "../../actions/uiActions";
import { attendEvent } from "../../actions/eventActions";

const boxPadding = 0.75;

/**
 * Will be shown when the user needs to answer some questions before they can attend the event.
 * This is here the user answers the event questions with exception for the mainQuestion, which is if
 * the user will attend the event. That question is answered when attend the event.
 *
 * @param props
 * @param props.attendEvent({eventId, fields}) -> the fields are the answered questions
 * @returns {*}
 * @constructor
 */

const EventFormBuilder = (props) => {
  const dispatch = useDispatch();

  // Event from redux store
  const { selectedEvent: event } = useSelector((state) => state.event);
  const { user } = useSelector((state) => state.auth);
  const { language: lang } = useSelector((state) => state.language);

  // Questions and answers for the event
  const [questions, setQuestions] = useState(event.questions);
  const [notValidArr, setNotValidArr] = useState([]);
  const [buttonDisabled, setButtonDisabled] = useState(false);

  // Answer the questions
  function updateFields(value, index) {
    const answer = "answer";
    setQuestions([
      ...questions.map((question, questionIndex) => (questionIndex === index ? { ...question, [answer]: value } : question)),
    ]);
  }

  function getIdsForEmptyRequiredQuestions() {
    return questions
      .filter((question) => question.isRequired === 1 && question.answer && !question.answer.title)
      .map((question) => question.id);
  }

  // Submit answer
  function submitAnswer() {
    let idsOfQuestionsNotFilled = getIdsForEmptyRequiredQuestions();

    setNotValidArr(idsOfQuestionsNotFilled);

    if (idsOfQuestionsNotFilled.length !== 0) {
      return dispatch(
        addToast({
          title: lang.missingFields,
          content: lang.fillAllFields,
          icon: <ErrorOutlineIcon />,
          styleType: "error",
          duration: 20000,
        })
      );
    }

    // Add the mainQuestion to the questions
    questions.push({ ...event.mainQuestion, answer: { title: attendingType.YES } });
    dispatch(hideModalPage());
    dispatch(attendEvent({ eventId: event.id, fields: questions, attendeeId: user.id }));
  }

  useEffect(() => {
    if (questions && notValidArr.length !== 0) setNotValidArr(getIdsForEmptyRequiredQuestions());
  }, [questions]);

  return (
    <ScrollView className={componentStyle()}>
      <div className="container">
        {questions && (
          <Fragment>
            {questions.map((question, questionIndex) => (
              <FormBuilder
                disabledSubmitBtn={() => setButtonDisabled(true)}
                enableSubmitBtn={() => setButtonDisabled(false)}
                key={`event-form-question-${questionIndex}`}
                style={{ marginBottom: "1.5rem" }}
                question={question}
                notValidArr={notValidArr}
                questionIndex={questionIndex}
                updateFields={updateFields}
              />
            ))}
          </Fragment>
        )}

        <Button disabled={buttonDisabled} className="primary-btn" onClick={() => submitAnswer()}>
          {lang.confirmAttendance}
        </Button>
        <Button buttonType={"secondary"} onClick={() => dispatch(hideModalPage())}>
          {lang.cancel}
        </Button>
      </div>
    </ScrollView>
  );
};

const componentStyle = () => css`
  background-color: ${colors.white};
  width: 100%;
  height: 100%;
  overflow: auto;

  .primary-btn {
    margin: 2rem 0 0.5rem 0;
  }

  .container {
    background-color: ${colors.white};
    max-width: ${breakpoints.md}px;
    margin: auto;
    padding: ${boxPadding}rem;

    @media screen and (min-width: ${breakpoints.md}px) {
      padding-top: ${boxPadding * 2}rem;
    }
  }
`;

export default EventFormBuilder;
