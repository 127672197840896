// Utilities
import req from "./request-utility";

// Config
import dataIdentifiers from "../config/dataIdentifiers";

export default async function ({ dataIdentifier, registrationId }) {
  try {
    let options = [];

    switch (dataIdentifier) {
      case dataIdentifiers.CURRENT_APP_USERS:
        const { data: users } = await req()(`registration/${registrationId}/question-data/${dataIdentifier}`);
        options = users;

      default:
        break;
    }
    return options;
  } catch (error) {
    throw error;
  }
}
