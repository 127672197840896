// Libs
import React, { useEffect } from "react";
import { css } from "emotion";
import { useDispatch, useSelector } from "react-redux";
import * as queryString from "query-string";

// Styles
import colors from "../../style/colors";

// Config
import breakpoints from "../../config/breakpoints";

// Actions
import { getPostsStatistics } from "../../actions/registrationActions";

const CheersForPeersStatistics = (props) => {
  const dispatch = useDispatch();

  // Redux State
  const { primaryColor } = useSelector((state) => state.appConfig);
  const { language: lang } = useSelector((state) => state.language);

  const { all, mine } = useSelector((state) => state.registration.statistics.posts);

  const { dataId: registrationId } = queryString.parse(window.location.search);

  useEffect(() => {
    dispatch(getPostsStatistics({ registrationId }));
  }, []);

  return (
    <div style={props.style} className={`${componentStyle({ primaryColor })} ${props.className ? props.className : ""}`}>
      <p className="title">{`${lang.shoulderFlap} ${lang.thisMonth.toLowerCase()}`} </p>

      <div className="cheers-wrapper">
        <div className="column-wrapper">
          <p className="label">{lang.all}</p>
          <p className="content">{all.sent !== null ? `${all.sent} ${lang.sent.toLowerCase()}` : "..."}</p>
        </div>

        <div className="column-wrapper">
          <p className="label">{`${lang.your} ${lang.shoulderFlap.toLowerCase()}`}</p>
          <p className="content">
            {mine.sent !== null && mine.received !== null
              ? `${mine.sent} ${lang.sent.toLowerCase()}, ${mine.received} ${lang.received.toLowerCase()}`
              : "..."}
          </p>
        </div>
      </div>
    </div>
  );
};

const componentStyle = ({ primaryColor }) => css`
  background-color: ${colors.white};
  max-width: ${breakpoints.md}px;
  width: 100%;
  border: 1px solid ${colors.midGrey};
  border-radius: 3px;
  padding: 1rem 1rem 0 1rem;

  p.title {
    font-size: 1.25rem;
    color: ${primaryColor};
    font-weight: bold;
    margin-bottom: 0.75rem;
  }

  div.cheers-wrapper {
    display: flex;
    flex-wrap: wrap;

    .column-wrapper {
      margin-bottom: 1rem;
    }

    .column-wrapper:first-of-type {
      border-right: 1px ${colors.lightGrey} solid;
      margin-right: 1rem;
      padding-right: 1rem;
    }

    .label {
      font-size: 0.9rem;
      margin-bottom: 0.1rem;
      color: ${colors.darkGrey};
    }
  }
`;

export default CheersForPeersStatistics;
