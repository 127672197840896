import React from "react";
import { css } from "emotion";
import { connect, useDispatch } from "react-redux";
import { version } from "../../../package.json";

// Redux actions
import { showDialog, showModalPage } from "../../actions/uiActions";

// Styling
import colors from "../../style/colors";

// Utilities
import clearLocalStorage from "../../utilities/clear-local-storage";
import getAppName from "../../utilities/get-app-name";
import deleteCacheAndRefreshPage from "../../utilities/delete-cache-and-refresh-page";
// Components
import InlineSpinner from "../ui/InlineSpinner";
import ListItem from "../ui/ListItem";
import Toggle from "../ui/Toggle";
import Button from "../ui/Button";
import { AlertDecagramIcon, ChevronDownIcon, LogoutIcon, SyncIcon, WrenchIcon } from "mdi-react";
import breakpoints from "../../config/breakpoints";
import DebugModal from "../debugModal/DebugModal";

const UserSettings = (props) => {
  let { settingsLoading: loading, settingsError: error, settings, lang, activeIdentifiers, updateSetting } = props;

  const dispatch = useDispatch();

  return (
    <div style={{ maxWidth: `${breakpoints.lg}px`, margin: "0 auto" }}>
      {/* Spinner */}
      {loading && !error && <InlineSpinner style={{ marginTop: "1rem" }} />}

      {/* General settings */}
      {!loading && !error && settings.length > 0 && (
        <>
          <h2 style={{ padding: "1rem", marginTop: "1rem" }}>General</h2>
          {settings.map((setting, settingIndex) =>
            !setting.options ? (
              <ListItem
                title={setting.title}
                maxWidth={breakpoints.lg}
                subTitle={
                  setting.content
                  // // If there is no content and there is a selectedOption-value, show the selected value
                  // (setting.selectedOption && setting.options.filter((f) => f.id === setting.selectedOption))[0].name
                }
                wrapContent={true}
                key={"setting-" + settingIndex}
                contentRight={
                  <Toggle
                    on={setting.value}
                    loading={activeIdentifiers.indexOf(setting.identifier) !== -1}
                    onClick={() => updateSetting({ identifier: setting.identifier, value: !setting.value })}
                  />
                }
              />
            ) : (
              <div className={dropdownWrapperStyle()}>
                <p>{lang.language}</p>
                <select onChange={(e) => updateSetting({ identifier: setting.identifier, value: e.target.value })}>
                  {setting.options.map((option) => (
                    <option
                      value={option.id}
                      key={option.identifier}
                      selected={setting.selectedOption === option.id ? true : false}
                    >
                      {option.name}
                    </option>
                  ))}
                </select>
                <ChevronDownIcon />
              </div>
            )
          )}
        </>
      )}

      {/* Sign out setting */}
      {!loading && (
        <div className={contentContainer()}>
          {/* Hardcoded setting */}
          <h2 style={{ margin: "2rem 0 1rem" }}>{lang.extra}</h2>
          <p style={{ marginBottom: "1rem" }}>{lang.logOutWarning}</p>
          <Button
            styleType={"warning"}
            onClick={() =>
              dispatch(
                showDialog({
                  icon: <AlertDecagramIcon />,
                  styleType: "warning",
                  title: lang.logOutWarningTitle,
                  content: lang.logOutWarningContent,
                  primaryActionTitle: lang.logOutWarningAction,
                  primaryAction: () =>
                    clearLocalStorage((err) => {
                      window.location = window.location.origin + "/" + getAppName() + "/";
                    }),
                })
              )
            }
          >
            <LogoutIcon style={{ width: "1.25rem", height: "1.25rem", marginBottom: "-0.225rem" }} /> Log out
          </Button>
        </div>
      )}

      <div className={contentContainer()}>
        {/* Hardcoded setting */}
        <h2 style={{ margin: "2rem 0 1rem" }}>Technical</h2>

        <p style={{ marginBottom: "1rem" }}>
          Clear application-cache and refresh app. Will try to update to the newest version.
        </p>
        <Button
          style={{ marginBottom: "1rem" }}
          buttonType={"secondary"}
          onClick={() =>
            dispatch(
              showDialog({
                icon: <AlertDecagramIcon />,
                styleType: "warning",
                title: "About to clear cache and refresh page",
                content: "This will update to the newest version if your app gets stuck at an old version",
                primaryActionTitle: "I understand, clear cache and refresh page",
                primaryAction: deleteCacheAndRefreshPage,
              })
            )
          }
        >
          <SyncIcon style={{ width: "1.25rem", height: "1.25rem", marginBottom: "-0.225rem" }} /> Clear cache and refresh app
        </Button>

        <Button
          buttonType={"secondary"}
          onClick={() =>
            dispatch(
              showModalPage({
                content: <DebugModal />,
              })
            )
          }
        >
          <WrenchIcon style={{ width: "1.25rem", height: "1.25rem", marginBottom: "-0.225rem" }} /> Show developer info
        </Button>
      </div>

      <p class="meta" style={{ padding: "1rem", marginTop: "3rem", textAlign: "center" }}>
        Ekko App version {version}
      </p>
    </div>
  );
};

const dropdownWrapperStyle = () => css`
  position: relative;
  background: ${colors.white};
  border-top: 1px ${colors.midGrey} solid;
  border-bottom: 1px ${colors.midGrey} solid;

  @media screen and (min-width: ${breakpoints.lg}px) {
    border: 1px ${colors.midGrey} solid;
  }

  p {
    padding: 1rem 1rem 1rem 1rem;
    margin-bottom: -2.9rem;
  }

  select {
    padding: 2rem 1rem 1.25rem 1rem;
    appearance: none;
    font-size: 0.8125rem;
    color: ${colors.darkGrey};
    border: 0;
    border-radius: 0;
    background-color: transparent;
    width: 100%;
    outline: 0;
  }

  svg {
    position: absolute;
    right: 1rem;
    top: 50%;
    transform: translateY(-50%);
    pointer-events: none;
  }
`;

const contentContainer = () => css`
  margin: 1rem 0;
  padding: 0 1rem;

  p {
    white-space: initial;
  }
`;

const mapStateToProps = (state) => ({
  lang: state.language.language,
});

export default connect(mapStateToProps)(UserSettings);
