import {
  GET_SOCIAL_POSTS,
  GET_SOCIAL_POSTS_SUCCESS,
  GET_SOCIAL_POSTS_FAILURE,
  END_OF_SOCIAL_FEED,
  REFRESH_SOCIAL_POST_COMMENTS,
  REFRESH_SOCIAL_POST_LIKES,
  CLEAR_SOCIAL_FEED,
  REMOVE_SOCIAL_POST_FROM_FEED,
  SET_SOCIAL_POST_AS_DELETED,
  REPLACE_SOCIAL_POST,
  SET_SOCIAL_CURRENT_DATA_ID,
} from "../actions/actionTypes";
import req from "../utilities/request-utility";
import { REFRESH_SOCIAL_POST_COMMENT_LIKES } from "./actionTypes";

function getIsLocalSocial() {
  try {
    if (window.location.pathname.match(/localsocial/gi)) {
      return true;
    } else {
      return false;
    }
  } catch {
    return false;
  }
}

/**
 * @param {Object} anonoymous
 * @param {Number|Null} anonoymous.postId - If specified only one post will be fetched
 *
 * The arguments looks a bit funny, but it's actually not that bad:
 * The last `= {}` sets the object's default value. It would be the same as
 * function someFunction(id = 0, content = "", enableStuff = false).
 * It looks funny because the key postId inside the default object also
 * has a default value :)
 */
export function getPosts({ postId = null } = {}) {
  /**
   * Fetches posts from api.
   * Appends posts onto already existing posts.
   * Use clearFeed() to empty feed.
   */
  return async function (dispatch, getState) {
    try {
      const state = getState().social;
      const isLocalSocial = getIsLocalSocial();

      // Safe-guard to prevent duplicate post-fetchings!
      if (state.loading === true) return;

      dispatch({ type: GET_SOCIAL_POSTS });

      // Logical branching for single post views or feed views
      if (postId === null) {
        getPostForFeed();
      } else {
        getPostForSinglePostView();
      }

      async function getPostForFeed() {
        let limit = 10;

        // For first fetch start with only 2 posts
        if (state.posts.length === 0) limit = 2;

        let URL = `social/${state.currentDataId}/posts/?limit=${limit}&offset=${state.posts.length}`;

        if (isLocalSocial) URL += `&onlyShowPostsFromMyMasterGroup=1`;

        let { data: posts } = await req()(URL);
        handleFetchedPosts(posts);
      }
      async function getPostForSinglePostView() {
        let { data: post } = await req()(`social/${state.currentDataId}/posts/${postId}`);
        handleFetchedPosts([post]); // Post is an object so stick it in an array before continuing
      }

      function handleFetchedPosts(posts) {
        if (posts.length) {
          dispatch({
            type: GET_SOCIAL_POSTS_SUCCESS,
            payload: posts,
          });
        } else {
          dispatch({
            type: END_OF_SOCIAL_FEED,
          });
        }
      }
    } catch (err) {
      dispatch({ type: GET_SOCIAL_POSTS_FAILURE });
    }
  };
}

export function replacePost({ post, postId }) {
  /** Replaces a post in the feed. Can be used after successful edits or if a post
   * needs to be refreshed for whatever reason. Note that this doesn't fetch the post
   * you have to do that elsewhere (A fresh post is returned when editing posts)
   */

  return {
    type: REPLACE_SOCIAL_POST,
    payload: {
      post: post,
      postId: postId,
    },
  };
}

export function refreshPostComments(postId, cb) {
  return async function (dispatch, getState) {
    try {
      let { data: comments } = await req()(`social/${getState().social.currentDataId}/posts/${postId}/comments`);
      dispatch({
        type: REFRESH_SOCIAL_POST_COMMENTS,
        payload: {
          comments: comments,
          postId: postId,
        },
      });
      // Invokes callback if specified
      if (cb) cb(null);
    } catch (err) {
      if (cb) cb(true);
    }
  };
}

export function refreshPostLikes(postId, callback) {
  return async function (dispatch, getState) {
    try {
      let { data: likes } = await req()(`social/${getState().social.currentDataId}/posts/${postId}/likes`);
      dispatch({
        type: REFRESH_SOCIAL_POST_LIKES,
        payload: {
          likes: likes,
          postId: postId,
        },
      });
      // Invokes callback if specified
      if (callback) callback(null);
    } catch (err) {
      if (callback) callback(true);
    }
  };
}

/**
 * Fetches the latest likes for a given comment of a social post
 * @param postId {Integer}
 * @param commentId {Integer}
 * @param callback {function}
 */
export function refreshPostCommentLikes({ postId, commentId, callback = null }) {
  return async function (dispatch, getState) {
    try {
      let { data: likes } = await req()(
        `social/${getState().social.currentDataId}/posts/${postId}/comments/${commentId}/likes`
      );

      dispatch({
        type: REFRESH_SOCIAL_POST_COMMENT_LIKES,
        payload: {
          likes,
          postId: postId,
          commentId: commentId,
        },
      });
      // Invokes callback if specified
      if (callback) callback(null);
    } catch (err) {
      if (callback) callback(true);
    }
  };
}

export function clearFeed() {
  return {
    type: CLEAR_SOCIAL_FEED,
  };
}

export function removePostFromFeed(postId) {
  return function (dispatch) {
    dispatch({
      type: SET_SOCIAL_POST_AS_DELETED,
      payload: postId,
    });

    setTimeout(
      () =>
        dispatch({
          type: REMOVE_SOCIAL_POST_FROM_FEED,
          payload: postId,
        }),
      600 // MAGIC NUMBER WARNING: This number has to be longer than the removal animation time
    );
  };
}

export function setDataId(dataId) {
  return {
    type: SET_SOCIAL_CURRENT_DATA_ID,
    payload: dataId,
  };
}
