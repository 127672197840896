// Libs
import React from "react";

function VideoPlayer({ baseURL, video }) {
  return (
    <video controls controlsList="nodownload" poster={`${baseURL}f_jpg/${video}`}>
      <source src={`${baseURL}f_mp4,q_auto,w_1600/${video}`} type="video/mp4"></source>
      <source src={`${baseURL}f_webm,q_auto,w_1600/${video}`} type="video/webm"></source>
    </video>
  );
}

export default VideoPlayer;
