export const registrationTypes = {
  RADIO: "RADIO",
  RADIO_HORIZONTAL: "RADIO_HORIZONTAL",
  DROPDOWN: "DROPDOWN",
  CHECKBOX: "CHECKBOX",
  RATING: "RATING",
  RATING_CUSTOM_LABELS: "RATING_CUSTOM_LABELS",
  FILE: "FILE",
  YES_NO: "YES_NO",
  TEXT: "TEXT",
  DATE: "DATE",
  TIME: "TIME",
  IMAGE: "IMAGE",
  CHECKLIST: "CHECKLIST",
  RADIO_TRAFFIC_LIGHT: "RADIO_TRAFFIC_LIGHT",
  DATA_SELECT: "DATA_SELECT",
};

export const feedTypes = {
  all: "all",
  mostLiked: "mostLiked",
  mine: "mine",
  toMe: "toMe",
};

export const postLayoutModes = {
  post: "post",
  accordion: "accordion"
};

export const localStorageAction = {
  saving: "saving",
  delete: "delete",
  get: "get",
}

export const formBuilderModes = {
  edit: "edit",
  duplicate: "duplicate",
};
