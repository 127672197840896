// Libraries
import React, { useState, useEffect, useRef, Suspense } from "react";
import { connect, useDispatch, useSelector } from "react-redux";
import { bindActionCreators } from "redux";
import PropTypes from "prop-types";
import { format, addMonths, parse } from "date-fns";
import { css } from "emotion";

// Styles
import colors from "../../style/colors";
import breakpoints from "../../config/breakpoints";

// Components
import ImageUploadMultiple from "../ui/ImageUploadMultiple";
import InlineSpinner from "../ui/InlineSpinner";
import TextInput from "../ui/TextInput";
import DatePicker from "../ui/DatePicker";
import Button from "../ui/Button";
import DropDownList from "../ui/DropDownList";
import TabBar from "../../components/ui/TabBar";
import TabView from "../../components/ui/TabView";

// Utils
import req from "../../utilities/request-utility";
import { AlertOctagonIcon, TranslateIcon, PlusIcon, AlertDecagramIcon } from "mdi-react";

// Redux actions
import { resetFeeds, getPosts, updateUnreadCount } from "../../actions/newsActions";
import { hideModalPage, addToast, updateModalPage, showDialog } from "../../actions/uiActions";
// import TextEditor from "../ui/TextEditor";
import NewsPost from "./NewsPost";
import addActualLineBreaksToMarkdownString from "../../utilities/add-actual-line-breaks-to-markdown-string";
const TextEditor = React.lazy(() => import("../ui/TextEditor"));

/** Modal page for editing comments.
 *
 * Adding comments is done inline in the Post component.
 * Is implemented as a component passed to a redux action
 *
 * @example
 * ```jsx
 * showModalPage({
 *   title: `${lang.edit} ${lang.comment.toLowerCase()}`,
 *   content: <NewsCommentModal comment={comment} postId={id} />
 * });
 * ```
 */

function NewsCommentModal(props) {
  const dispatch = useDispatch();

  // State
  const [appLanguages, setAppLanguages] = useState({ primary: {}, foreign: {} });
  const [loading, setLoading] = useState(false);
  const [savingPost, setSavingPost] = useState(false);
  const [enableForeignLanguage, setEnableForeignLanguage] = useState(false);
  // To control whether it is the add/edit or preview tab that is visible
  const [activeTabIndex, setActiveTabIndex] = useState(0);

  // Get the language object from Redux Store
  const { language: lang } = useSelector((state) => state.language);
  // Get the current user from Redux Store
  const { user } = useSelector((state) => state.auth);
  // Get the primary colour of the app to use in the style sheet
  const { primaryColor } = useSelector((state) => state.appConfig);

  // Props
  const { subTypeId, feedType, addToast, postId, updateModalPage, showDialog, hideModalPage } = props;

  // Refs
  const unsavedChanges = useRef(false);
  const formDataRef = useRef();

  useEffect(() => {
    req()("meta/app-languages")
      .then(({ data }) => setAppLanguages(data))
      .catch(() => {
        addToast({
          styleType: "error",
          icon: <AlertOctagonIcon />,
          duration: "60000",
          title: lang.networkError,
          content: lang.couldNotFetchLanguages,
        });
      });

    // eslint-disable-next-line
  }, []);

  const [formData, setFormData] = useState({
    title: "",
    title_fl: "",
    content: "",
    content_fl: "",
    date: format(new Date(), "yyyy-MM-dd"),
    endDate: format(addMonths(new Date(), 1), "yyyy-MM-dd"),
    images: [],
    likes: [],
    comments: [],
    documents: [],
    links: [],
    allowLikesAndComments: 1,
    points: 0,
  });

  formDataRef.current = formData;

  // Checks if a post is being edited. IF the post is being edited, a full version of the post
  // is fetched from the api. (some data will be excluded in the feed)
  useEffect(() => {
    if (postId) {
      setLoading(true);
      req()(`news/${subTypeId}/${postId}?editMode=1`)
        .then(({ data: post }) => {
          let localFormData = {
            title: post.title || "",
            title_fl: post.title_fl || "",
            content: post.content || "",
            content_fl: post.content_fl || "",
            date: post.date ? format(parse(post.date, "yyyyMMdd", 0), "yyyy-MM-dd") : format(new Date(), "yyyy-MM-dd"),
            endDate: post.endDate
              ? format(parse(post.endDate, "yyyyMMdd", 0), "yyyy-MM-dd")
              : format(addMonths(new Date(), 1), "yyyy-MM-dd"),
            images: post.images || [],
            likes: post.likes || [],
            comments: post.comments || [],
            documents: post.files || [],
            links: post.links || [],
            allowLikesAndComments: post.allowLikesAndComments ? 1 : 0,
            points: post.points || 0,
          };

          if (post.video && post.video.video && post.video.baseURL) {
            localFormData.images = [
              {
                baseURL: post.video.baseURL,
                video: post.video.video,
              },
            ];
          }

          setFormData(localFormData);

          // If post has content and it's content is differentiated in foreign and primary by default show
          // both languages
          if (post.title !== post.title_fl || post.content !== post.content_fl) {
            setEnableForeignLanguage(true);
          }

          setLoading(false);
        })
        .catch((err) => {
          addToast({ template: "error" });
        });
    }

    updateModalPage({
      closeCallback: () => {
        if (unsavedChanges.current) {
          showDialog({
            title: lang.unsavedChanges,
            content: lang.dialogUnsavedChangesContent,
            primaryActionTitle: lang.yesCloseForm,
            primaryAction: hideModalPage,
            secondaryActionTitle: lang.noDontCloseForm,
            styleType: "error",
            icon: <AlertDecagramIcon />,
          });
        } else {
          hideModalPage();
        }
      },
    });
    // eslint-disable-next-line
  }, []);

  function savePost() {
    /** Validate date:
     * 1. Check that title and content is set
     * 2. Check that endDate is greater than date
     * 3. Check that title is less than XXX characters
     * 3. Check that content is less than XXX characters
     * 4. Format dates
     */
    let apiReadyData = {
      title: formData.title ? formData.title.trim() : "",
      title_fl: formData.title_fl ? formData.title_fl.trim() : "",
      content: formData.content ? addActualLineBreaksToMarkdownString(formData.content.trim()) : "",
      content_fl: formData.content_fl ? addActualLineBreaksToMarkdownString(formData.content_fl.trim()) : "",
      date: format(parse(formData.date, "yyyy-MM-dd", 0), "yyyyMMdd"),
      endDate: format(parse(formData.endDate, "yyyy-MM-dd", 0), "yyyyMMdd"),
      points: parseInt(formData.points),
      allowLikesAndComments: parseInt(formData.allowLikesAndComments) === 1 ? true : false,
    };

    // Assume that image/video's are images since only 1 video is allowed
    if (formData.images.length > 1) {
      apiReadyData.images = formData.images;
      // If only 1 file is present check if it's a video
    } else if (formData.images.length === 1 && formData.images[0].fileType === "video") {
      apiReadyData.video = formData.images[0];
      // If only 1 file is present check if it's an image
    } else if (formData.images.length === 1 && formData.images[0].fileType === "image") {
      apiReadyData.images = formData.images;
    }

    if (!enableForeignLanguage) {
      apiReadyData.title_fl = apiReadyData.title;
      apiReadyData.content_fl = apiReadyData.content;
    }

    let baseToast = {
      styleType: "error",
      icon: <AlertOctagonIcon />,
      duration: "60000",
    };

    //  Check title and content
    if (
      (!appLanguages.foreign && !apiReadyData.title) ||
      !apiReadyData.content ||
      (appLanguages.foreign && !apiReadyData.title) ||
      !apiReadyData.content ||
      !apiReadyData.title_fl ||
      !apiReadyData.content_fl
    ) {
      addToast({
        ...baseToast,
        title: lang.errorNewsSomethingsMissing,
        content: lang.errorNewsMarkedFieldsMissing,
      });
      return;
    }

    if (apiReadyData.endDate < apiReadyData.date) {
      addToast({
        ...baseToast,
        title: lang.errorNewsInvalidDatesTitle,
        content: lang.errorNewsInvalidDatesContent,
      });
      return;
    }

    setSavingPost(true);

    if (!postId) {
      req().post(`news/${subTypeId}/`, apiReadyData).then(thenHandler).catch(catchHandler);
    } else {
      req().patch(`news/${subTypeId}/${postId}`, apiReadyData).then(thenHandler).catch(catchHandler);
    }

    function thenHandler() {
      setSavingPost(false);

      dispatch(updateUnreadCount({ subTypeId }));
      props.resetFeeds();
      props.getPosts({ subTypeId, feedType });
      props.hideModalPage();
    }

    function catchHandler() {
      setSavingPost(false);

      addToast({
        ...baseToast,
        title: lang.AddingPostError,
        content: lang.tryAgainOrContactSupport,
      });
    }
  }

  function onChange(e) {
    unsavedChanges.current = true; // Enables the close-form dialog
    setFormData({
      ...formData,
      [e.target.name]: e.target.value,
    });
    formDataRef.current = formData;
  }

  function setContent(name, value) {
    unsavedChanges.current = true; // Enables the close-form dialog
    setFormData({
      ...formData,
      [name]: value,
    });
    formDataRef.current = formData;
  }

  function getContentComponent() {
    return (
      <div className={componentStyles(primaryColor)}>
        <div className="form-container">
          {/************** Images **************/}
          <h2>{lang.imagesOrVideo}</h2>

          <ImageUploadMultiple
            id={"image-upload__news-post-modal"}
            style={{ marginBottom: "2rem" }}
            allowedAmountOfImages={5}
            boxPadding={"1rem"}
            uploadedFiles={formData.images}
            onFileUpdate={(e) => {
              setFormData({ ...formDataRef.current, images: e });
            }}
          />

          {/************** Title **************/}
          <h2>{lang.title} *</h2>

          {enableForeignLanguage && <label htmlFor="">{appLanguages.primary.name}</label>}
          <TextInput
            style={{ marginBottom: enableForeignLanguage ? "1rem" : "2rem" }}
            onChange={onChange}
            name="title"
            value={formData.title}
          />

          {enableForeignLanguage && (
            <>
              <label htmlFor="">{appLanguages.foreign.name}</label>
              <TextInput style={{ marginBottom: "2rem" }} onChange={onChange} name="title_fl" value={formData.title_fl} />
            </>
          )}

          {/************** Content **************/}
          <h2>{lang.content} *</h2>

          {enableForeignLanguage && <label htmlFor="">{appLanguages.primary.name}</label>}
          <Suspense fallback={<p className="meta">Loading editor...</p>}>
            <TextEditor
              style={{ marginBottom: enableForeignLanguage ? "1rem" : "2rem" }}
              onChange={(e) => setContent("content", e)}
              value={formData.content}
            />
          </Suspense>

          {enableForeignLanguage && (
            <>
              <label htmlFor="">{appLanguages.foreign.name}</label>
              <Suspense fallback={<p className="meta">Loading editor...</p>}>
                <TextEditor
                  style={{ marginBottom: "2rem" }}
                  onChange={(e) => setContent("content_fl", e)}
                  value={formData.content_fl}
                />
              </Suspense>
            </>
          )}

          {/************** Meta - Likes and comments **************/}
          <h2>{lang.allowLikesAndComments}</h2>
          <DropDownList
            dropDownListContent={[
              { id: 1, title: lang.yes },
              { id: 0, title: lang.no },
            ]}
            style={{ marginBottom: "2rem" }}
            selectedContentId={formData.allowLikesAndComments}
            onChange={onChange}
            name="allowLikesAndComments"
          />

          {/************** Meta - Points **************/}
          <h2>{lang.points}</h2>
          <DropDownList
            dropDownListContent={[
              { id: 0, title: lang.noPoints },
              { id: 1, title: "1" },
              { id: 2, title: "2" },
              { id: 3, title: "3" },
              { id: 4, title: "4" },
              { id: 5, title: "5" },
            ]}
            style={{ marginBottom: "2rem" }}
            selectedContentId={formData.points || 0}
            onChange={onChange}
            placeholder={lang.choosePoints}
            name="points"
          />

          {/************** Date **************/}
          <h2>{lang.date}</h2>

          <label htmlFor="">{lang.showFrom}</label>
          <DatePicker style={{ marginBottom: "1rem" }} onChange={onChange} defaultDate={formData.date} name="date" />

          <label htmlFor="">{lang.showTo}</label>
          <DatePicker style={{ marginBottom: "1rem" }} onChange={onChange} defaultDate={formData.endDate} name="endDate" />

          <p style={{ marginBottom: "3rem" }} className="meta">
            * {lang.requiredField}
          </p>

          {/************** Actions **************/}
          {appLanguages.foreign && (
            <Button
              buttonType="secondary"
              style={{ marginBottom: "0.5rem" }}
              onClick={() => setEnableForeignLanguage(!enableForeignLanguage)}
            >
              <TranslateIcon style={{ width: "1.25rem", height: "1.25rem", margin: "0 0.4rem -3px 0" }} />
              {enableForeignLanguage ? lang.deactivate : lang.activate} {lang.multilingualContent}
            </Button>
          )}

          <Button buttonType="primary" active={savingPost} onClick={savePost} data-test-id="btn-add-news-post">
            <PlusIcon style={{ width: "1.25rem", height: "1.25rem", margin: "0 0.4rem -3px 0" }} />
            {postId ? lang.saveChanges : lang.createPost}
          </Button>
        </div>
      </div>
    );
  }

  function getPreviewComponent(foreign = false) {
    let postObj = {
      id: 0,
      title: foreign ? formData.title_fl : formData.title,
      content: foreign ? formData.content_fl : formData.content,
      author: user,
      images: formData.images,
      date: format(parse(formData.date, "yyyy-MM-dd", 0), "yyyyMMdd"),
      links: formData.links,
      files: formData.file,
      points: formData.points,
    };

    postObj.content = addActualLineBreaksToMarkdownString(postObj.content);

    // eslint-disable-next-line
    if (formData.allowLikesAndComments == 1) {
      postObj.likes = formData.likes;
      postObj.comments = formData.comments;
    } else {
      if (postObj.hasOwnProperty("likes")) delete postObj.likes;
      if (postObj.hasOwnProperty("comments")) delete postObj.comments;
    }

    if (formData.images.length === 1 && formData.images[0].fileType === "video") {
      postObj.video = formData.images[0];
    }
    return <NewsPost post={postObj} disableInteractionsPreview={true} style={{ margin: "1rem auto" }} />;
  }

  function getTabs() {
    const tabs = [lang.content];
    if (appLanguages.primary.name) {
      tabs.push(`${lang.preview} ${appLanguages.primary.name.toLowerCase()}`);
    }
    if (appLanguages.primary.name && enableForeignLanguage) {
      tabs.push(`${lang.preview} ${appLanguages.foreign.name.toLowerCase()}`);
    }
    return tabs;
  }

  function getTabViews() {
    let tabViews = [getContentComponent(), getPreviewComponent()];

    if (enableForeignLanguage) {
      tabViews.push(getPreviewComponent(true));
    }
    return tabViews;
  }

  return loading ? (
    <InlineSpinner style={{ marginTop: "2rem" }} title="Loading post..." />
  ) : (
    <>
      <TabBar
        activeTabIndex={activeTabIndex}
        tabs={getTabs().map((tab, tabIndex) => ({
          title: tab,
          onClick: () => {
            setActiveTabIndex(tabIndex);
          },
        }))}
      />
      <TabView activeTabIndex={activeTabIndex} tabs={getTabViews()} useScrollView={true} />
    </>
  );
}

const componentStyles = (primaryColor) => css`
  h2 {
    margin-bottom: 0.5rem;
    font-weight: 700;
    font-size: 1rem;
    color: ${colors.black};
  }

  .form-container {
    background-color: ${colors.white};
    padding: 1rem;

    @media screen and (min-width: ${breakpoints.md}px) {
      margin: 1rem auto;
      border: 1px ${colors.midGrey} solid;
      border-radius: 3px;
      max-width: ${breakpoints.md}px;
    }
  }

  label {
    display: block;
    margin-bottom: 0.5rem;
    color: ${colors.darkGrey};
    font-size: 0.9rem;
  }
`;

const mapDispatchToProps = (dispatch) => ({
  resetFeeds: bindActionCreators(resetFeeds, dispatch),
  getPosts: bindActionCreators(getPosts, dispatch),
  updateModalPage: bindActionCreators(updateModalPage, dispatch),
  hideModalPage: bindActionCreators(hideModalPage, dispatch),
  showDialog: bindActionCreators(showDialog, dispatch),
  addToast: bindActionCreators(addToast, dispatch),
});

export default connect(null, mapDispatchToProps)(NewsCommentModal);

NewsCommentModal.propTypes = {
  /** The id of the post to which the comment is being edited */
  postId: PropTypes.number,
  /** The comment being edited (it has more attributes, but only id and comment is needed as the author is implicitly defined through auth) */
  comment: PropTypes.shape({
    id: PropTypes.number,
    comment: PropTypes.string,
  }),
};
