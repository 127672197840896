// Libs
import React, { useEffect, useState } from "react";
import { css } from "emotion";

// Redux
import { connect, useSelector } from "react-redux";
import { bindActionCreators } from "redux";
import { addToast, showModalPage } from "../../actions/uiActions";
import { getChallengeOverview } from "../../actions/academyActions";
import queryString from "query-string";

// Config
import breakpoints from "../../config/breakpoints";

// Components
import Spinner from "../ui/InlineSpinner";
import Button from "../ui/Button";
import ImageCarousel from "../ui/ImageCarousel";
import ChallengeResults from "./ChallengeResults";
import RadioGroup from "../ui/RadioGroup";
import StepBox from "../ui/StepBox";

// Styles
import { ArrowRightIcon, ErrorOutlineIcon } from "mdi-react";
import colors from "../../style/colors";

// Utilities
import req from "../../utilities/request-utility";
import { getDatetimeString } from "../../utilities/parse-date";

const Challenge = (props) => {
  // Destructure the props
  const { showModalPage } = props;

  // Saves the challenge to be able to manipulate with it
  const { challenge } = props;

  // Multi-language handling
  const lang = useSelector((state) => state.language.language);

  // The stepper is used when the user wants the next question
  const [step, setStep] = useState(0);
  const [spinner, setSpinner] = useState(false);

  // Selected answer saves the index of the selected answer
  const [selectedAnswer, setSelectedAnswer] = useState(null);

  // The users answers are saved here
  const [answers, setAnswers] = useState([]);

  // The start date from when the user first starts the challenge
  const [startDate] = useState(getDatetimeString());

  // Counter for how long it takes to finish the challenge in seconds
  const [counter, setCounter] = useState(0);

  // Timer for how long it takes for the user to take the challenge
  useEffect(() => {
    const interval = setInterval(() => {
      setCounter((counter) => counter + 1);
    }, 1000);
    return () => clearInterval(interval);
  }, []);

  // Submits the answer to the question
  const submitAnswer = (answer) => {
    if (step !== challenge.questions.length - 1) {
      setAnswers([...answers, answer]);
      setStep(step + 1);
      setSelectedAnswer(null);
    } else {
      setSpinner(true);
      req()
        .post(`challenge/${challenge.id}/answers`, {
          userAnswers: [...answers, answer],
          answerTimeSeconds: counter,
          startDate: startDate,
          endDate: getDatetimeString(),
        })
        .then(({ data: results }) => {
          showModalPage({
            title: `${lang.challengeAnswers}`,
            useScrollView: false,
            content: <ChallengeResults results={results} />,
            pageStyle: { backgroundColor: colors.white },
          });
        })
        .then(() => {
          let { dataId: challengeId } = queryString.parse(window.location.search);
          props.getChallengeOverview(challengeId);
        })
        .catch((err) => {
          props.addToast({
            title: `${lang.errorGeneral}`,
            content: `${lang.errorCouldNotGetAnswers}`,
            icon: <ErrorOutlineIcon />,
            styleType: "error",
            duration: 20000,
          });
        });
    }
  };

  return (
    <div className={componentStyle()}>
      {spinner && <Spinner />}
      {challenge && !spinner && (
        <div className="content">
          <StepBox
            style={{ marginBottom: "0.5rem" }}
            currentStep={step + 1}
            lastStep={challenge.questions.length}
            title={lang.questions.toLowerCase()}
          />
          <p className="title">{`${challenge.questions[step].title}`}</p>
          <ImageCarousel images={challenge.questions[step].images} />

          {/* Questions */}
          {step < challenge.questions.length && (
            <RadioGroup
              style={{ marginBottom: "2rem" }}
              options={challenge.questions[step].answers}
              onSelect={(radioId) => {
                setSelectedAnswer(challenge.questions[step].answers.findIndex((a) => a.id === radioId));
              }}
            />
          )}

          <Button
            data-test-id={"next-question"}
            disabled={selectedAnswer === null}
            onClick={() => {
              submitAnswer({
                answerId: challenge.questions[step].answers[selectedAnswer].id,
                questionId: challenge.questions[step].id,
              });
            }}
            style={{ display: "flex", flexDirection: "row", alignItems: "center", justifyContent: "center" }}
          >
            <p style={{ marginRight: "0.4rem" }}>{lang.next}</p>
            <ArrowRightIcon size={20} />
          </Button>
        </div>
      )}
    </div>
  );
};

const componentStyle = () => css`
  background-color: ${colors.white};
  width: 100%;
  height: 100%;
  padding: 1rem 1rem 4rem 1rem;
  margin-bottom: 4rem;

  .content  {
    max-width: ${breakpoints.md}px;
    margin: auto;
  }

  .title {
    font-weight: bold;
    margin-bottom: 1rem;
  }
`;

const mapDispatchToProps = (dispatch) => ({
  getChallengeOverview: bindActionCreators(getChallengeOverview, dispatch),
  showModalPage: bindActionCreators(showModalPage, dispatch),
  addToast: bindActionCreators(addToast, dispatch),
});

export default connect(null, mapDispatchToProps)(Challenge);
