const pageConfig = {
  ////////////////////
  // PHONE BOOK AND KEY PERSONS
  ////////////////////

  PHONEBOOK_LANG_OVERWRITE: "PHONEBOOK_LANG_OVERWRITE",
  KEYPERSONS_LANG_OVERWRITE: "KEYPERSONS_LANG_OVERWRITE",
  HIDE_KEYPERSONS_TAB: "HIDE_KEYPERSONS_TAB",
  HIDE_PHONEBOOK_TAB: "HIDE_PHONEBOOK_TAB",
  GROUP_PHONEBOOK: "GROUP_PHONEBOOK",
  GROUP_KEYPERSONS: "GROUP_KEYPERSONS",
  HIDE_USER_GROUP: "HIDE_USER_GROUP",
  HIDE_MASTER_GROUP: "HIDE_MASTER_GROUP",
  HIDE_JOB_TITLE: "HIDE_JOB_TITLE",
  SHOW_COMPETENCES: "SHOW_COMPETENCES",

  PHONEBOOK_HIDE_EMPLOYEE_ID: "PHONEBOOK_HIDE_EMPLOYEE_ID",
  PHONEBOOK_HIDE_USERGROUP: "PHONEBOOK_HIDE_USERGROUP",

  ////////////////////
  // Birthdays
  ////////////////////
  BIRTHDAYS_SHOW_AGE: "BIRTHDAYS_SHOW_AGE",

  ////////////////////
  // NEWS
  ////////////////////
  NEWS_POSTS_SORT_ORDER: "NEWS_POSTS_SORT_ORDER",
  INCLUDE_FILES_FROM_LINK_COLLECTION: "INCLUDE_FILES_FROM_LINK_COLLECTION",
  READ_ACTION_LANG_OVERWRITE: "READ_ACTION_LANG_OVERWRITE",
};

export default pageConfig;
