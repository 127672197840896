// Libs
import React, { useEffect, useState } from "react";
import { connect, useSelector } from "react-redux";
import { bindActionCreators } from "redux";

// Components
import Page from "../ui/Page";
import TopBar from "../ui/TopBar";
import TabBar from "../ui/TabBar";
import TabView from "../ui/TabView";
import EventDetails from "./EventDetails";
import EventAttendeesList from "./EventAttendeesList";

// Styles
import { AccountMultipleIcon, InformationOutlineIcon } from "mdi-react";

// Actions
import { getEvent, resetEvent } from "../../actions/eventActions";

/**
 * This is the overview on a selected event.
 * It has 2 tabs: EventDetails and EventAttendeesList
 *
 * EventDetails shows the more specific information about the event. It is also here the user can
 * answer if they are attending the event
 *
 * EventAttendeesList shows a list of all the users that are attending the event
 *
 * @param props
 * @returns {*}
 * @constructor
 */

const EventDetailsOverview = (props) => {
  // Page setup
  const { match } = props;

  // Params
  const { eventId } = match.params;

  // Redux state
  const { selectedEvent: event } = useSelector((state) => state.event);

  // Language
  const { language: lang } = useSelector((state) => state.language);

  // On initial load - get the event
  useEffect(() => {
    props.getEvent({ eventId });

    // ComponentWillUnmount - reset the selected event
    return () => {
      props.resetEvent();
    };

    // eslint-disable-next-line
  }, []);

  // Tab layout
  const tabs = [
    <span>
      <InformationOutlineIcon /> {lang.info}
    </span>,
    <span>
      <AccountMultipleIcon /> {`${lang.listOfAttendees} ${event && event.numberOfAttendees ? event.numberOfAttendees : ""}`}
    </span>,
  ];

  // Current tab the user stands on
  const [activeTab, setActiveTab] = useState(0);

  return (
    <Page>
      <TopBar title={lang.events} useDefaultBackButton={true} />

      <TabBar
        activeTabIndex={activeTab}
        tabs={tabs.map((tab, tabIndex) => ({
          title: tab,
          onClick: () => {
            setActiveTab(tabIndex);
          },
        }))}
      />
      <TabView activeTabIndex={activeTab} tabs={[<EventDetails />, <EventAttendeesList />]} />
    </Page>
  );
};

const mapDispatchToProps = (dispatch) => ({
  resetEvent: bindActionCreators(resetEvent, dispatch),
  getEvent: bindActionCreators(getEvent, dispatch),
});

export default connect(null, mapDispatchToProps)(EventDetailsOverview);
