import React from "react";
import { connect } from "react-redux";
import { bindActionCreators } from "redux";
import { useSelector } from "react-redux";
import { css } from "emotion";
import getProfilePicture from "../../utilities/get-profile-picture-from-user-object";
import colors from "../../style/colors";
import { AlertCircleOutlineIcon } from "mdi-react";
import { showModalPage, updateModalPage } from "../../actions/uiActions";
import req from "../../utilities/request-utility";
import UserProfile from "../profile/UserProfile";

import PropTypes from "prop-types";

const PostLikes = (props) => {
  const { showModalPage, updateModalPage, likes = [] } = props;
  const lang = useSelector((state) => state.language.language);

  async function showUserModal(userId) {
    if (!userId) return;
    showModalPage({ content: <UserProfile userDataLoading={true} userDataError={false} /> });
    let { data: userData } = await req()(`users/${userId}`);
    updateModalPage({
      content: <UserProfile userDataLoading={false} userDataError={false} userData={userData} />,
    });
  }

  return (
    <div className={style(props)}>
      {likes.length === 0 && (
        <p className="meta">
          <AlertCircleOutlineIcon /> {lang.noLikes}
        </p>
      )}
      {likes.map((user) =>
        !user ? (
          <div className="like" data-test-id="like" key={`post-like-deleted-user`}>
            {getProfilePicture({}, 35)}
            <div className="user-group" style={{ marginLeft: "0.35rem" }}>
              {lang.deletedUser}
            </div>
          </div>
        ) : (
          <div className="like" data-test-id="like" key={`post-like-${user.id}`} onClick={() => showUserModal(user.id)}>
            {user && getProfilePicture(user, 35)}
            <div style={{ marginLeft: "0.35rem" }}>
              <p className="name">{user ? user.name : lang.deletedUser}</p>
              <p className="user-group">
                {user && `${user.masterGroup.title.trim()}`}{" "}
                {user && user.userGroup.title !== user.masterGroup.title.trim() ? `, ${user.userGroup.title}` : ""}
              </p>
            </div>
          </div>
        )
      )}
    </div>
  );
};

const style = (props) => css`
  margin-left: -${props.boxPadding}rem;
  margin-right: -${props.boxPadding}rem;
  padding: ${props.boxPadding}rem ${props.boxPadding}rem ${props.boxPadding * 2.5}rem ${props.boxPadding}rem;
  max-height: 50vh;
  overflow-y: auto;
  -webkit-overflow-scrolling: touch;
  border-top: 1px ${colors.lightGrey} solid;
  background-color: ${colors.white};

  .meta {
    /* If updating this make sure to also update the one in PostComments to match */
    margin-bottom: -${props.boxPadding}rem;
    color: ${colors.darkGrey};

    svg {
      width: 0.9rem;
      height: 0.9rem;
      color: ${colors.darkGrey};
      vertical-align: bottom;
      margin-bottom: 2px;
    }
  }

  .like {
    display: flex;
    align-items: center;
    flex-shrink: 0;
    color: ${colors.darkGrey};
    margin-bottom: 0.75rem;

    &:last-of-type {
      margin-bottom: -${props.boxPadding}rem;
    }

    svg {
      color: ${colors.darkGrey};
      /* margin-right: 0.25rem; */
    }

    p {
      color: ${colors.black};
      font-weight: 400;
      font-size: 0.85rem;

      &.name {
        font-weight: 700;
      }

      &.user-group {
        color: ${colors.darkGrey};
      }
    }
  }
`;
PostLikes.propTypes = {
  /** Array which holds likes to be displayed by component */
  likes: PropTypes.array,
};

const mapDispatchToProps = (dispatch) => ({
  showModalPage: bindActionCreators(showModalPage, dispatch),
  updateModalPage: bindActionCreators(updateModalPage, dispatch),
});

export default connect(null, mapDispatchToProps)(PostLikes);
