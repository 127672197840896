import React, { useState } from "react";

//css
import colors from "../../style/colors";

// icons
import { AppleSafariIcon, AlertCircleOutlineIcon, ArrowDownIcon } from "mdi-react";
import { css } from "emotion";

const InAppBrowserDialog = (props) => {
  let [show, setShow] = useState(true);

  if (!show) return null;

  return (
    <div className={componentStyle(props)}>
      <div className="dialog">
        <AlertCircleOutlineIcon className="alert-icon" />

        <p>
          Click this <AppleSafariIcon style={{ margin: "0rem" }} color="#007aff" /> icon to continue
        </p>

        <button onClick={() => setShow(false)}>Dismiss</button>
      </div>

      <ArrowDownIcon className="button-pointer" />
    </div>
  );
};

const componentStyle = (props) => css`
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: rgba(0, 0, 0, 0.7);
  backdrop-filter: blur(4px);
  display: flex;
  align-items: center;
  justify-content: center;

  .dialog {
    padding: 1rem;
    margin: 0.5rem;
    background-color: ${colors.white};
    border-radius: 10px;
    box-shadow: 0 4px 12px rgba(0, 0, 0, 0.2);

    .alert-icon {
      display: block;
      margin: 0 auto 1rem auto;
      width: 2rem;
      height: 2rem;
      color: ${colors.darkGrey};
    }

    p {
      text-align: center;
    }

    button {
      border: 0px;
      background-color: transparent;
      color: ${colors.darkGrey};
      display: block;
      margin: 2rem auto 0 auto;
      font-size: 0.8rem;
      text-align: center;
    }
  }

  .button-pointer {
    color: ${colors.white};
    position: absolute;
    bottom: 25px;
    right: -2px;
    width: 50px;
    height: 50px;
    animation: bounce 1.5s infinite ease;
  }

  @keyframes bounce {
    0% {
      transform: translateY(0px);
    }

    70% {
      transform: translateY(-20px);
    }

    100% {
      transform: translateY(0px);
    }
  }
`;

export default InAppBrowserDialog;
