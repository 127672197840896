import React, { useEffect } from "react";
import { css } from "emotion";
import colors from "../../style/colors";
import isIos from "../../utilities/is-ios";
import { useDispatch } from "react-redux";
import { setTransitionMode } from "../../actions/pagesActions";

/**
 * @param {Object} props
 * @param {String} props.backgroundColor - a color
 */
const Page = (props) => {
  const dispatch = useDispatch();

  // This part is a bit werid. This sets a default page-transition mode of backwards.
  // This is done to enable page-animation when using build in browser back-button.
  // Everywhere in the app where we do controlled navigations (clicking buttons, links etc)
  // we provide an animation. This is all good for EVERYTHING except iOS in fullscreen. For
  // iOS in fullscreen the user can use swipe from left gesture to trigger a navigation back
  // (at least for iOS > 13). If we provided this default animation there the user would first
  // swipe the current page off from screen while seeing the other page below and THEN when they
  // let go, they would see the old page blink back and take up the screen and then animate itself
  // away.
  // What we are doing here is basically setting the backwards animation everytime a page has mounted
  // on any non-ios devices after more than 300ms.
  useEffect(() => {
    if (!isIos()) setTimeout(() => dispatch(setTransitionMode("backward", false)), 300);

    // eslint-disable-next-line
  }, []);

  return (
    <div
      data-test-id={props["data-test-id"] || "page"}
      className={`page ${componentStyle(props)} ${props.className || ""}`}
      ref={props.hostRef}
      style={props.style}
    >
      {props.children}
    </div>
  );
};

const componentStyle = (props) => css`
  background-color: ${props.backgroundColor || colors.lightGrey};
  height: 100vh;
  width: 100vw;
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  overflow: hidden;
  position: fixed;
  will-change: auto;
`;

export default Page;
