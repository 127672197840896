import React, { useEffect, useState } from "react";
import { connect, useSelector } from "react-redux";
import { bindActionCreators } from "redux";
import { feedTypes } from "./config";

// Redux actions
import { getPosts, resetFeeds } from "../../actions/newsActions";
import { showModalPage } from "../../actions/uiActions";

// Utilities
import getPageFromId from "../../utilities/get-page-from-id";

// Components
import Page from "../ui/Page";
import TopBar from "../ui/TopBar";
import InlineSpinner from "../ui/InlineSpinner";
import StatusBox from "../ui/StatusBox";
import ScrollView from "../ui/ScrollView";
import colors from "../../style/colors";
import { InfoOutlineIcon, DownloadIcon, FeatureSearchOutlineIcon } from "mdi-react";

import breakpoints from "../../config/breakpoints";
import ListItem from "../ui/ListItem";
import FloatingSearchInput from "../ui/FloatingSearchInput";
import highlightMatch from "../../utilities/highlight-match";

function NewsFileArchive(props) {
  // page setup
  const { match, getPosts, resetFeeds } = props;
  const { primaryColor } = useSelector((state) => state.appConfig);
  const { pages } = useSelector((state) => state.pages);
  const { language: lang } = useSelector((state) => state.language);
  const [page] = useState(getPageFromId(pages, match.params.pageId));
  const [searchterm, setSearchterm] = useState("");
  const [filteredPosts, setFilteredPosts] = useState([]);

  // Data
  const { posts, loading, error, endOfFeed } = useSelector((state) => state.news.all);

  useEffect(() => {
    if (!searchterm && posts.length > 0) {
      setFilteredPosts(posts);
    } else {
      setFilteredPosts(
        posts.filter((p) => {
          if (
            p.title.toLowerCase().indexOf(searchterm.toLowerCase()) !== -1 //||
            // (p.files && p.files[0] && p.files[0].length && p.files[0].file.indexOf(searchterm) !== -1)
          ) {
            return true;
          } else {
            return false;
          }
        })
      );
    }
  }, [searchterm, posts]);

  useEffect(() => {
    resetFeeds();
    getPosts({ subTypeId: page.dataId, feedType: feedTypes.all, limit: 10000 });
    // eslint-disable-next-line
  }, []);

  return (
    <Page>
      <TopBar useDefaultBackButton={true} title={page.title} />

      <FloatingSearchInput
        onClearSearch={() => setSearchterm("")}
        onChange={(e) => setSearchterm(e.target.value)}
        value={searchterm}
      />

      <ScrollView style={{ paddingTop: "4.45rem" }}>
        {/* Loading */}
        {loading && !error && !endOfFeed && (
          <InlineSpinner style={{ margin: "2rem 0" }} title={`${lang.loading} ${lang.posts}...`} />
        )}

        {/* End of feed without posts */}
        {posts.length === 0 && endOfFeed && (
          <StatusBox
            style={{ marginBottom: "2rem" }}
            icon={<InfoOutlineIcon />}
            title={lang.noPostsHere}
            content={lang.comeBackSoon}
          />
        )}

        {filteredPosts.length === 0 && posts.length > 0 && searchterm && (
          <StatusBox
            icon={<FeatureSearchOutlineIcon />}
            title={lang.noResults}
            content={lang.trySearchingForSomethingElse}
            style={{ margin: "1rem auto" }}
          />
        )}

        {/* Content */}
        {filteredPosts
          .filter((p) => p.files && p.files.length > 0)
          .map((post) => (
            <ListItem
              key={post.id}
              onClick={() => window.open(`${post.files[0].baseURL}${post.files[0].file}`)}
              title={
                <span style={{ color: primaryColor, fontWeight: 700 }}>
                  {highlightMatch(post.title, searchterm, "JSX", {
                    display: "inline-block",
                    borderBottom: `1px ${colors.darkGrey} dashed`,
                    backgroundColor: colors.yellowLight,
                    borderRadius: "3px",
                  })}
                </span>
              }
              subTitle={post.files[0].file}
              maxWidth={breakpoints.lg}
              clickable={true}
              iconLeft={<DownloadIcon style={{ color: primaryColor }} />}
            />
          ))}
      </ScrollView>
    </Page>
  );
}

const mapDispatchToProps = (dispatch) => ({
  getPosts: bindActionCreators(getPosts, dispatch),
  showModalPage: bindActionCreators(showModalPage, dispatch),
  resetFeeds: bindActionCreators(resetFeeds, dispatch),
});

export default connect(null, mapDispatchToProps)(NewsFileArchive);
