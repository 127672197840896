// Libraries
import React, { useEffect, useState } from "react";
import { connect, useSelector, useDispatch } from "react-redux";
import { bindActionCreators } from "redux";
import { hideModalPage, addToast, updateModalPage } from "../../../actions/uiActions";
import {
  getOverviewUsers,
  setSearchTerm,
  showFilters,
  hideFilters,
  setCurrentPageId,
  getTargetGroupUsers,
  addUserToTargetGroup,
  removeUserFromTargetGroup,
  refreshOverviewUsers,
  getMasterUserAndTargetGroups,
} from "../../../actions/targetGroupModalActions";

// Styles
import colors from "../../../style/colors";

// Components
import ActionWrapper from "../../ui/ActionWrapper";
import ScrollView from "../../ui/ScrollView";
import FloatingSearchInput from "../../ui/FloatingSearchInput";
import Notification from "../../ui/Notification";
import StatusBox from "../../ui/StatusBox";

// Utils
import { css } from "emotion";
import { FilterOutlineIcon, AccountSearchOutlineIcon, PlusIcon } from "mdi-react";
import TargetGroupFilters from "./TargetGroupFilters";
import TargetGroupBottomBar from "./TargetGroupBottomBar";
import TargetGroupListItem from "./TargetGroupListItem";
import InlineSpinner from "../InlineSpinner";
import req from "../../../utilities/request-utility";
import breakpoints from "../../../config/breakpoints";
import { generateURLFromState } from "./target-group-utilities";

function TargetGroupModal(props) {
  const dispatch = useDispatch();
  const [isAddingAll, setIsAddingAll] = useState(false);
  const lang = useSelector((s) => s.language.language);
  const primaryColor = useSelector((s) => s.appConfig.primaryColor);
  const {
    overviewUsers,
    searchTerm,
    showFilters,
    targetGroupUsers,
    selectedMasterGroupId,
    masterGroups,
    selectedUserGroupId,
    userGroups,
  } = useSelector((s) => s.targetGroupModal);
  const depOverviewUsers = overviewUsers.users;

  useEffect(() => {
    dispatch(setCurrentPageId(props.pageId));
    dispatch(getOverviewUsers());
    dispatch(getTargetGroupUsers());
    dispatch(getMasterUserAndTargetGroups());
    window.addEventListener("resize", checkIfScrollViewIsFilled);
    return () => window.removeEventListener("resize", checkIfScrollViewIsFilled);
    // eslint-disable-next-line
  }, []);

  /** Effect to check if the 10 initially laoded posts is enough to make scroll view scrollable.
   * If not more are fetched until scroll view is scrollable or feed is empty
   */
  useEffect(() => {
    checkIfScrollViewIsFilled();
    // eslint-disable-next-line
  }, [depOverviewUsers]);

  /** Checks if ScrollView is filled with posts
   * ScrollView has to be scrollable in order to use the lazy loading mechanism (scroll triggered)
   */
  let debouncer;
  function checkIfScrollViewIsFilled() {
    clearTimeout(debouncer);
    debouncer = setTimeout(() => {
      let scrollView = document.getElementById("target-group-scroll-view");
      let { endOfFeed, loading, error } = overviewUsers;
      if (!endOfFeed && !loading && !error && scrollView && scrollView.scrollHeight - 100 < window.innerHeight) {
        dispatch(getOverviewUsers());
      }
    }, 500);
  }

  function onScrollEnd() {
    let { endOfFeed, loading, error } = overviewUsers;
    if (!loading && !error && !endOfFeed) dispatch(getOverviewUsers());
  }

  function addAll() {
    let URL = generateURLFromState({ searchTerm, limit: 50000, selectedMasterGroupId, selectedUserGroupId });

    setIsAddingAll(true);
    req()(URL).then((res) => {
      // Filter users to only include those that aren't already in the target group (its a bit cpu heavy, but
      // since the network implementation is a bit dumb - not bulked - this is cpu well spent)
      let filteredUsers = res.data.filter(
        (user) => targetGroupUsers.users.findIndex((targetGroupUser) => targetGroupUser.id === user.id) === -1
      );

      Promise.all(filteredUsers.map((u) => req().put(`pages/${props.pageId}/users/${u.id}`))).then(() => {
        dispatch(getTargetGroupUsers());
        dispatch(refreshOverviewUsers());
        setIsAddingAll(false);
      });
    });
  }

  useEffect(() => {
    let notificationsCount = 0;
    if (selectedMasterGroupId) notificationsCount++;
    if (selectedUserGroupId) notificationsCount++;

    props.updateModalPage({
      actionRight: (
        <ActionWrapper style={{ position: "relative" }} onClick={showFilters ? props.hideFilters : props.showFilters}>
          <FilterOutlineIcon />
          {notificationsCount > 0 && (
            <Notification notifications={notificationsCount} style={{ position: "absolute", top: "2px", right: "2px" }} />
          )}
        </ActionWrapper>
      ),
    });
    // THERE IS A REASON FOR HAVING BOTH showFilters and props.showFilters - one is a
    // boolean the other is function - sry for bad naming :)
    // eslint-disable-next-line
  }, [showFilters, props.hideModalPage, props.showFilters, selectedUserGroupId, selectedMasterGroupId]);

  function getSearchStatusDescription() {
    let descriptions = [];

    descriptions.push(<>{lang.showingResults}</>);

    // prettier-ignore
    if (searchTerm)descriptions.push(<>{lang.matchingSearchterm} <strong>{searchTerm}</strong></>);

    // prettier-ignore
    if (selectedMasterGroupId) descriptions.push(<>{lang.from} {lang.theMainGroup} <strong>{masterGroups.find((m) => parseInt(m.id) === parseInt(selectedMasterGroupId)).title}</strong></>);

    // prettier-ignore
    if (selectedUserGroupId) descriptions.push(<>{lang.from} {lang.theSubGroup} <strong>{userGroups.find((u) => parseInt(u.id) === parseInt(selectedUserGroupId)).title}</strong></>);

    // prettier-ignore
    if (descriptions.length === 2) return (<p>{descriptions[0]} {descriptions[1]}</p>);

    // prettier-ignore
    if (descriptions.length === 3) return (<p>{descriptions[0]} {descriptions[1]} {lang.and} {descriptions[2]}</p>);

    // prettier-ignore
    if (descriptions.length === 4) return (<p>{descriptions[0]} {descriptions[1]}, {descriptions[2]} {lang.and} {descriptions[3]}</p>);

    return null;
  }

  return (
    <>
      <ScrollView className={componentStyles({ primaryColor })} onScrollEnd={onScrollEnd} id="target-group-scroll-view">
        <FloatingSearchInput
          value={searchTerm}
          onChange={(e) => dispatch(setSearchTerm(e.target.value))}
          onClearSearch={() => dispatch(setSearchTerm(""))}
        />

        <div className="search-meta-information">{getSearchStatusDescription()}</div>

        {overviewUsers.users.length !== 0 && (
          <ActionWrapper className="toggle-all-or-none-button" onClick={addAll}>
            {/* <CheckboxMarkedIcon className="checkmark marked" /> */}
            {!isAddingAll && <PlusIcon className="checkmark" />}
            {isAddingAll && (
              <InlineSpinner size="18" style={{ marginTop: "0.25rem", marginBottom: "-0.35rem", marginRight: "0.37rem" }} />
            )}
            <p>
              {lang.add} {lang.all.toLowerCase()}
            </p>
          </ActionWrapper>
        )}

        {overviewUsers.users.map((user) => {
          let isSelected = targetGroupUsers.users.some((u) => u.id === user.id);
          return (
            <TargetGroupListItem
              key={`target-group-user-${user.id}`}
              user={user}
              enableCheckmark={true}
              onClick={() => {
                console.log("click");

                if (isSelected) {
                  dispatch(removeUserFromTargetGroup(user));
                } else {
                  dispatch(addUserToTargetGroup(user));
                }
                // (isSelected ? dispatch(removeUserFromTargetGroup(user)) : dispatch(addUserToTargetGroup(user)))
              }}
              isSelected={isSelected}
              maxWidth={breakpoints.lg}
            />
          );
        })}

        {overviewUsers.users.length === 0 && !overviewUsers.loading && !overviewUsers.error && overviewUsers.endOfFeed && (
          <StatusBox
            icon={<AccountSearchOutlineIcon />}
            title={lang.noUsersFound}
            content={lang.tryABroaderSearch}
            style={{ marginTop: "1rem" }}
          />
        )}

        {overviewUsers.loading === true && overviewUsers.error === false && <InlineSpinner style={{ marginTop: "1rem" }} />}
      </ScrollView>
      <TargetGroupFilters />
      <TargetGroupBottomBar />
    </>
  );
}

const componentStyles = ({ primaryColor }) => css`
  padding: 67px 0;
  background-color: ${colors.lightGrey};

  .toggle-all-or-none-button {
    background-color: ${colors.white};
    border-top: 1px ${colors.midGrey} solid;
    padding: 0.75rem;
    display: flex;
    align-items: center;
    width: 100%;
    border-radius: 0px;

    @media screen and (min-width: ${breakpoints.lg}px) {
      margin: 0 auto;
      border-radius: 4px 4px 0 0;
      max-width: ${breakpoints.lg}px;
      border-left: 1px ${colors.midGrey} solid;
      border-right: 1px ${colors.midGrey} solid;
    }

    &:hover {
      background-color: ${colors.ultraLightGrey};
      cursor: pointer;
    }

    p {
      font-size: 1rem;
      margin-left: 0.75rem;
      color: ${colors.black};
    }

    .checkmark {
      color: ${colors.darkGrey};
    }
    .checkmark.marked {
      color: ${primaryColor};
    }
  }

  .search-meta-information {
    color: ${colors.darkGrey};
    text-align: center;

    p {
      padding: 0.5rem 1rem 1rem 1rem;
      font-size: 0.9rem;
    }
  }
`;

const mapDispatchToProps = (dispatch) => ({
  updateModalPage: bindActionCreators(updateModalPage, dispatch),
  hideModalPage: bindActionCreators(hideModalPage, dispatch),
  addToast: bindActionCreators(addToast, dispatch),
  showFilters: bindActionCreators(showFilters, dispatch),
  hideFilters: bindActionCreators(hideFilters, dispatch),
});

export default connect(null, mapDispatchToProps)(TargetGroupModal);
