import axios from "axios";
import {
  GET_APP_CONFIG,
  GET_APP_CONFIG_SUCCESS,
  GET_APP_CONFIG_FAILURE,
  SET_APP_LANGUAGE,
  CLEAR_APP_CONFIG_LOGIN_TYPES,
} from "./actionTypes";
import getAppName from "../utilities/get-app-name";

import setIcons from "../utilities/set-icons";

export function getAppConfig(cb) {
  return async function (dispatch, getState) {
    dispatch({
      type: GET_APP_CONFIG,
    });

    // Get api-config (has the api-endpoint)
    let { data: apiConfig } = await axios(`/api-config.json`);

    let token = getState().auth.token;

    axios
      .get(`${apiConfig.url}apps/config/${getAppName()}`, {
        headers: { authorization: token ? `bearer ${token}` : "" },
      })
      .then(({ data }) => {
        // Set document title
        document.title = data.title;

        // Set icons (favicon and touch-icons)
        setIcons(data.icon);

        // Optional callback
        if (cb) cb();

        return dispatch({
          type: GET_APP_CONFIG_SUCCESS,
          payload: {
            appId: data.id,
            title: data.title,
            primaryColor: data.primaryColor,
            apiUrl: apiConfig.url,
            icon: data.icon,
            frontPageImage: data.frontPageImage,
            customerLogo: data.customerLogo,
            allowedLoginTypes: data.allowedLoginTypes,
            enableHighscore: data.enableHighscore,
            allowInAppConfiguration: data.allowInAppConfiguration,
            enableNewsTranslation: data.enableNewsTranslation,
          },
        });
      })
      .catch(() => {
        return dispatch({
          type: GET_APP_CONFIG_FAILURE,
        });
      });
  };
}

export function setAppLanguage(language) {
  return {
    type: SET_APP_LANGUAGE,
    payload: language,
  };
}

export function clearLoginTypes() {
  return {
    type: CLEAR_APP_CONFIG_LOGIN_TYPES,
  };
}
