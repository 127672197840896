// Libs
import React from "react";
import { css } from "emotion";

// Styles
import colors from "../../style/colors";

// Config
import breakpoints from "../../config/breakpoints";
import { registrationTypes } from "../registration/config";

// Components
import ImageCarousel from "./ImageCarousel";
import RadioGroup from "./RadioGroup";
import HorizontalRadioGroup from "./HorizontalRadioGroup";
import CheckboxGroup from "./CheckboxGroup";
import DatePicker from "./DatePicker";
import YesAndNoGroup from "./YesAndNoGroup";
import TimePicker from "./TimePicker";
import TextareaInput from "./TextareaInput";
import FileViewer from "./FileViewer";

const FormBuilderView = (props) => {
  // Props
  const { question, showQuestionTitles = true } = props;

  return (
    <div className={componentStyle()} key={`form-view-element-${question.title}`}>
      {/** TEXT **/}
      {question.type === registrationTypes.TEXT && (
        <div className="type-container" style={props.style}>
          {showQuestionTitles && <p>{question.title}</p>}
          <TextareaInput disabled={true} value={question.answer.title || ""} />
        </div>
      )}

      {/** IMAGE **/}
      {question.type === registrationTypes.IMAGE && (
        <div className="type-container" style={props.style}>
          {showQuestionTitles && <p>{question.title}</p>}
          <ImageCarousel images={[{ image: question.answer.title, baseURL: question.answer.baseURL }]} />
        </div>
      )}

      {/** FILE **/}
      {question.type === registrationTypes.FILE && (
        <div className="type-container" style={props.style}>
          {showQuestionTitles && <p className="title">{question.title}</p>}
          <div className="file-wrapper">
            <FileViewer
              href={question.answer && question.answer.title}
              key={`formbuilder-view-${question.answer.id}`}
              title={question.answer && question.answer.title && question.answer.title.split("/").slice(-1)[0]}
            />
          </div>
        </div>
      )}

      {/** RADIO **/}
      {question.type === registrationTypes.RADIO && (
        <div className="type-container" style={props.style}>
          {showQuestionTitles && <p className="title">{question.title}</p>}
          <RadioGroup
            disabled={true}
            selectedValue={question.answer.title ? question.answer.title[0] : null}
            options={question.options}
          />
        </div>
      )}

      {/** RADIO HORIZONTAL **/}
      {question.type === registrationTypes.RADIO_HORIZONTAL && (
        <div className="type-container" style={props.style}>
          {showQuestionTitles && <p className="title">{question.title}</p>}
          <HorizontalRadioGroup disabled={true} selectedValue={question.answer.title} options={question.options} />
        </div>
      )}

      {/** CHECKBOX **/}
      {question.type === registrationTypes.CHECKBOX && (
        <div className="type-container" style={props.style}>
          {showQuestionTitles && <p className="title">{question.title}</p>}
          <CheckboxGroup disabled={true} selectedValues={question.answer.title} options={question.options} />
        </div>
      )}

      {/** DATE **/}
      {question.type === registrationTypes.DATE && (
        <div className="type-container" style={props.style}>
          {showQuestionTitles && <p className="title">{question.title}</p>}
          <DatePicker disabled={true} name="date" defaultDate={question.answer ? question.answer.title : ""} />
        </div>
      )}

      {/** YES_NO **/}
      {question.type === registrationTypes.YES_NO && (
        <div className="type-container" style={props.style}>
          {showQuestionTitles && <p className="title">{question.title}</p>}
          <YesAndNoGroup disabled={true} selectedValue={question.answer.title} />
        </div>
      )}

      {/** TIME **/}
      {question.type === registrationTypes.TIME && (
        <div className="type-container" style={props.style}>
          {showQuestionTitles && <p className="title">{question.title}</p>}
          <TimePicker disabled={true} selectedValue={question.answer.title} options={question.options} />
        </div>
      )}

      {/** EXTRA COMMENT **/}
      {question.answer.answerComment && question.answer.answerComment.length > 0 && (
        <div className="type-container" style={props.style}>
          <TextareaInput disabled={true} value={question.answer.answerComment || ""} />
        </div>
      )}

      {/** EXTRA IMAGE **/}
      {question.answer.answerImage && question.answer.answerImage.length > 0 && (
        <div className="type-container" style={props.style}>
          {showQuestionTitles && <p>{question.title}</p>}
          <ImageCarousel images={[{ image: question.answer.answerImage, baseURL: question.answer.answerImageBaseURL }]} />
        </div>
      )}
    </div>
  );
};

const boxPadding = 0.75;

const componentStyle = () => css`
  div.type-container {
    div.file-wrapper {
      border: 1px solid ${colors.midGrey};
      border-radius: 3px;
    }

    p.title {
      margin-bottom: 0.5rem;
    }

    .not-valid {
      border: 1px solid ${colors.redMedium};
    }

    .show-validate-title {
      color: ${colors.redMedium};
    }
  }

  .seperator {
    height: 1px;
    background-color: ${colors.midGrey};
    margin: 1.5rem -${boxPadding}rem;

    @media screen and (min-width: ${breakpoints.md}px) {
      margin: 1.5rem 0;
    }
  }
`;

export default FormBuilderView;
