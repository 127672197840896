// Libs
import React, { useState } from "react";
import { css } from "emotion";
import { useDispatch, useSelector } from "react-redux";

// Styles
import colors from "../../style/colors";

// Components
import { CheckCircleOutlineIcon, CloseIcon, CheckIcon, DeleteIcon, AlertDecagramIcon } from "mdi-react";
import Button from "../ui/Button";
import FormBuilder from "../ui/FormBuilder";
import FormBuilderView from "../ui/FormBuilderView";
import ContextMenuButton from "../ui/ContextMenuButton";
import ActionWrapper from "../ui/ActionWrapper";

// Config
import { parseDateTimeMinimal } from "../../utilities/parse-date";
import getProfilePicture from "../../utilities/get-profile-picture-from-user-object";

// Actions
import { showContextMenu, showDialog } from "../../actions/uiActions";

// Hooks
import useAuthorModal from "../../hooks/useAuthorModal";
import { durations } from "../../config/animations";
import AnimateHeight from "react-animate-height";

const ChecklistTask = (props) => {
  // Props
  const {
    question,
    questionIndex,
    updateChecklistCategories,
    categoryIndex,
    resetChecklistItem,
    notValidArr,
    checklistIsCompleted,
  } = props;

  const primaryColor = useSelector((s) => s.appConfig.primaryColor);

  // Dispatch
  const dispatch = useDispatch();

  // Local state
  const [isOpen, setIsOpen] = useState(false);
  const [buttonDisabled, setButtonDisabled] = useState(false);

  const authorModal = useAuthorModal();

  const { language: lang } = useSelector((state) => state.language);

  // Show more options on a checklist item (three dots icon)
  const onContextMenuClick = ({ resetChecklistFunction }) => {
    let contextMenuItem = [];

    contextMenuItem.push({
      icon: <DeleteIcon />,
      title: lang.delete,
      callback: () => {
        dispatch(
          showDialog({
            styleType: "warning",
            icon: <AlertDecagramIcon />,
            title: lang.deleteChecklist,
            content: lang.deleteCommentInfo,
            primaryActionTitle: lang.yeDeleteAnswer,
            secondaryActionTitle: lang.noKeepTheAnswer,
            primaryAction: () => resetChecklistFunction(),
          })
        );
      },
    });

    dispatch(showContextMenu(contextMenuItem));
  };

  const isAnswerChecked = (answer) => {
    if (answer.title && answer.title.length > 0 && !answer.isAnswerCheckedEnabled) {
      return true;
    } else if (answer.isAnswerChecked) {
      return true;
    }
    return false;
  };

  return (
    <div
      className={`${componentStyle(primaryColor)} ${props.className} ${isOpen ? "open" : "closed"} ${
        isAnswerChecked(question.answer) ? "checked" : "unchecked"
      }`}
      style={props.style}
      onClick={props.onClick}
    >
      {/** TOP SECTION **/}
      <div key={`checklist-question-${question.id}`}>
        <div className="title-container" onClick={() => setIsOpen(!isOpen)}>
          <CheckCircleOutlineIcon className="icon-checked" />
          <p>{question.title}</p>
          <div className="icon-wrapper">
            {question.answer.submittedTimestamp && question.answer.user && (
              <ContextMenuButton
                style={{ marginRight: "-0.35rem" }}
                onClick={(e) => {
                  e.stopPropagation();
                  onContextMenuClick({
                    resetChecklistFunction: () => resetChecklistItem({ categoryIndex, questionIndex }),
                  });
                }}
              />
            )}
            <CloseIcon className="icon-close close" />
          </div>
        </div>

        <AnimateHeight height={isOpen ? "auto" : 0} duration={durations.normal} animateOpacity={true}>
          <div className="checklist-item-content">
            {/** CHECKLIST IS OPEN AND HAS NOT BEEN CONFIRMED YET **/}
            {!checklistIsCompleted && !question.answer.isAnswerChecked && (
              <div className="checklist-opened-container-edit">
                <div className="question-type-container">
                  {question.answer.isAnswerCheckedEnabled &&
                    question.answer.isAnswerChecked &&
                    question.answer.submittedTimestamp &&
                    question.answer.user && (
                      <div
                        className={
                          question.answer.isAnswerCheckedEnabled && question.answer.isAnswerChecked
                            ? "description-container"
                            : "description-container is-checked-enabled"
                        }
                      >
                        <div className="checklist-details">
                          <CheckCircleOutlineIcon className="checked" />
                          <ActionWrapper onClick={() => authorModal(question.answer.user.id)}>
                            {getProfilePicture(question.answer ? question.answer.user : {}, 18, { marginRight: "0.25rem" })}
                            <p>{question.answer.user.name}</p>
                          </ActionWrapper>
                        </div>
                      </div>
                    )}
                  <FormBuilder
                    showQuestionTitles={false}
                    disabledSubmitBtn={() => setButtonDisabled(true)}
                    enableSubmitBtn={() => setButtonDisabled(false)}
                    question={question}
                    style={{ marginBottom: "1rem" }}
                    questionIndex={questionIndex}
                    updateFields={updateChecklistCategories}
                    categoryIndex={categoryIndex}
                    notValidArr={notValidArr}
                  />
                </div>
                {question.answer.isAnswerCheckedEnabled && !question.answer.isAnswerChecked && (
                  <Button
                    disabled={buttonDisabled || question.answer.title === null || question.answer.title === ""}
                    className="submit-btn"
                    style={{ marginBottom: "1rem" }}
                    onClick={() =>
                      updateChecklistCategories({ ...question.answer, isAnswerChecked: 1 }, questionIndex, categoryIndex)
                    }
                  >
                    <CheckIcon className="completed-check" /> {lang.markAsCompleted}
                  </Button>
                )}
              </div>
            )}

            {/** CHECKLIST IS OPEN AND HAS BEEN CONFIRMED **/}
            {(checklistIsCompleted || question.answer.isAnswerChecked) && (
              <div className="checklist-opened-container">
                <div className="description-container">
                  <div className="checklist-details">
                    <CheckCircleOutlineIcon className="checked" />
                    <p>{question.answer.submittedTimestamp && parseDateTimeMinimal(question.answer.submittedTimestamp)}</p>
                    <ActionWrapper style={{ display: "flex" }} onClick={() => authorModal(question.answer.user.id)}>
                      {getProfilePicture(question.answer ? question.answer.user : {}, 18, { marginRight: "0.25rem" })}
                      <p>{question.answer.user && question.answer.user.name}</p>
                    </ActionWrapper>
                  </div>
                </div>

                <FormBuilderView style={{ marginBottom: "1rem" }} question={question} showQuestionTitles={false} />
              </div>
            )}
          </div>
        </AnimateHeight>
      </div>
    </div>
  );
};

const titleContainerPadding = "0.85";

const componentStyle = (primaryColor) => css`
  display: flex;
  flex-direction: column;
  background-color: ${colors.white};
  border: 1px solid ${colors.midGrey};

  &.unchecked {
    .icon-checked {
      opacity: 0;
      transform: scale(0);
      margin-right: -1.35rem;
      transition: opacity 500ms ease, margin-right 500ms ease, transform 500ms ease;
    }
  }

  &.checked {
    .icon-checked {
      opacity: 1;
      transform: scale(1);
      margin-right: 0.35rem;
      transition: opacity 500ms ease, margin-right 500ms ease, transform 500ms ease;
    }
    .title-container p {
      text-decoration: line-through;
      color: ${colors.darkGrey};
    }
  }

  &.open {
    margin: 0.5rem 0;
    border-radius: 3px;
    overflow: hidden;
    transition: margin ${durations.normal}ms ease, border-left ${durations.normal}ms ease;

    & > div {
      border-left: 3px solid ${primaryColor};
      transition: border ${durations.normal}ms ease;
    }

    &:first-of-type {
      margin-top: 0;
    }

    .icon-close {
      transform: rotate(0deg);
      transition: transform ${durations.normal}ms ease;
    }
  }

  &.closed {
    margin: -1px 0 0 0;
    transition: margin ${durations.normal}ms ease, border-left ${durations.normal}ms ease;

    & > div {
      border-left: 3px solid transparent;
      transition: border ${durations.normal}ms ease;
    }

    .icon-close {
      transform: rotate(45deg);
      transition: transform ${durations.normal}ms ease;
    }
  }

  &.not-valid {
    border: 1px solid ${colors.midGrey};

    & > div {
      border-left: 3px solid ${colors.red};
      transition: border ${durations.normal}ms ease;
    }

    .title-container p {
      color: ${colors.red};
    }
  }

  .title-container {
    display: flex;
    justify-content: space-between;
    width: 100%;
    appearance: none;
    padding: ${titleContainerPadding}rem;

    div.icon-wrapper {
      display: flex;
      align-items: center;
      margin: -${titleContainerPadding}rem;
      padding: 0 ${titleContainerPadding}rem;

      svg:last-of-type {
        margin-left: 0.5rem;
      }
    }

    &:hover {
      background-color: ${colors.ultraLightGrey};
      cursor: pointer;
    }

    svg.icon-checked {
      color: ${colors.green};
      width: 1.35rem;
      height: 1.35rem;
    }

    p {
      flex-grow: 1;
    }

    svg.icon-close {
      color: ${colors.darkGrey};
    }
  }

  .checklist-item-content {
    & > div {
      padding: 0.85rem 0.85rem 0 0.85rem;
    }
  }

  /***********************************/

  &:last-child {
    border-bottom: 1px solid ${colors.midGrey};
  }

  div.description-container {
    display: flex;
    justify-content: space-between;
    margin: 0rem 0 1rem 0;

    div.checklist-details {
      display: flex;
      align-items: center;
      color: ${colors.darkGrey};

      svg.checked,
      svg.profile {
        margin-right: 0.45rem;
        height: 1.2rem;
        width: 1.2rem;
      }

      p {
        font-size: 0.85rem;
        margin-right: 1rem;
      }
    }
  }

  div.is-checked-enabled {
    flex-direction: row-reverse;
  }

  div.checklist-opened-container {
    display: flex;
    flex-direction: column;

    p.comment {
      font-size: 1rem;
    }
  }

  div.checklist-opened-container-edit {
    button {
      display: flex;
      align-items: center;
      justify-content: center;
      padding: 0.5rem;

      svg.completed-check {
        margin-right: 0.4rem;
        margin-bottom: 0.25rem;
      }
    }

    label.title {
      margin-bottom: 0.5rem;
    }

    div.details-container {
      display: flex;
      flex-direction: column;
    }
  }
`;

export default ChecklistTask;
