// Libs
import React, { useEffect, useState } from "react";
import { connect, useSelector } from "react-redux";
import * as queryString from "query-string";
import { bindActionCreators } from "redux";

// Components
import TopBar from "../ui/TopBar";
import Page from "../ui/Page";
import RegistrationFormBuilder from "./RegistrationFormBuilder";

// Actions
import { getRegistrationPosts, resetAll } from "../../actions/registrationActions";
import { addToast, hideDialog, hideModalPage, showDialog, showModalPage } from "../../actions/uiActions";
import { css } from "emotion";
import { durations } from "../../config/animations";
import getPageFromId from "../../utilities/get-page-from-id";

const RegistrationOverview = (props) => {
  // Multi-language handling
  const lang = useSelector((state) => state.language.language);
  const [isFormReady, setIsFormReady] = useState(false);

  const [pageTitle, setPageTitle] = useState("");

  // Local storage variable
  const [useLocalStorage, setUseLocalStorage] = useState(false);

  // Get the registrationId / dataId
  let { dataId: registrationId } = queryString.parse(window.location.search);

  // Initial componentDidMount load the posts for the registration
  useEffect(() => {
    isThereARegistrationFormSaved();
    // eslint-disable-next-line
  }, []);

  // Delete the saved registration
  const deleteLocaleRegistration = () => {
    try {
      localStorage.removeItem(`registration-fields-${registrationId}`);
      localStorage.removeItem(`registration-images-${registrationId}`);
      localStorage.removeItem(`registration-description-${registrationId}`);
      localStorage.removeItem(`registration-title-${registrationId}`);
      localStorage.removeItem(`registration-completedText-${registrationId}`);
    } catch (err) {
      throw err;
    }
  };

  const isThereARegistrationFormSaved = () => {
    try {
      let registrationFields = localStorage.getItem(`registration-fields-${registrationId}`);
      registrationFields = JSON.parse(registrationFields);
      if (Array.isArray(registrationFields) && registrationFields.length > 0) {
        setTimeout(() => {
          props.showDialog({
            allowClosing: false,
            title: lang.hello,
            content: lang.continueFromLastCheckDescription,
            primaryActionTitle: lang.yesContinueFromLastCheck,
            primaryAction: () => {
              setUseLocalStorage(true);
              setIsFormReady(true);
            },
            secondaryAction: () => {
              setUseLocalStorage(false);
              setIsFormReady(true);
              props.hideDialog();
              deleteLocaleRegistration();
            },
            secondaryActionTitle: lang.createNewForm,
          });
        }, 300);
      } else {
        setUseLocalStorage(false);
        setIsFormReady(true);
      }
    } catch (err) {
      throw err;
    }
  };

  const updatePageTitle = (pageTitle) => {
    setPageTitle(pageTitle);
  };

  return (
    <Page className={componentStyle()}>
      {/* position: fixed; top: 0; prevents ios from scrolling the topbar out of view when jumping between inputs with the 
      input selector buttons in the on-screen-keyboard */}
      <TopBar useDefaultBackButton={true} title={pageTitle} style={{ position: "fixed", top: 0 }} />
      <div className={`form-wrapper ${isFormReady ? "show" : "hide"}`}>
        {isFormReady && (
          <RegistrationFormBuilder
            updatePageTitle={updatePageTitle}
            useStandaloneFormMode={true}
            loadFromLocaleStorage={useLocalStorage}
          />
        )}
      </div>
    </Page>
  );
};

const componentStyle = () => css`
  div.form-wrapper {
    display: flex;
    height: 100%;
  }

  div.hide {
    opacity: 0;
    transition: opacity ${durations.normal}ms ease;
  }

  div.show {
    opacity: 1;
    transition: opacity ${durations.normal}ms ease;
  }
`;

const mapDispatchToProps = (dispatch) => ({
  hideDialog: bindActionCreators(hideDialog, dispatch),
  showDialog: bindActionCreators(showDialog, dispatch),
  addToast: bindActionCreators(addToast, dispatch),
  hideModalPage: bindActionCreators(hideModalPage, dispatch),
  showModalPage: bindActionCreators(showModalPage, dispatch),
  getRegistrationPosts: bindActionCreators(getRegistrationPosts, dispatch),
  resetAll: bindActionCreators(resetAll, dispatch),
});

export default connect(null, mapDispatchToProps)(RegistrationOverview);
