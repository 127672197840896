// Libs
import React, { useEffect, useState } from "react";
import { useSelector } from "react-redux";
// Components
import Page from "../ui/Page";
import TopBar from "../ui/TopBar";

// Utilities
import getPageFromId from "../../utilities/get-page-from-id";
import { css } from "emotion";
import ScrollView from "../ui/ScrollView";
import getProfilePictureFromUserObject from "../../utilities/get-profile-picture-from-user-object";
import { format } from "date-fns/esm";
import Image from "../ui/Image";
import colors from "../../style/colors";
import breakpoints from "../../config/breakpoints";
import req from "../../utilities/request-utility";
import { CheckboxMarkedOutlineIcon, InformationOutlineIcon } from "mdi-react";

function NavigationList(props) {
  const [verifiedData, setVerifiedData] = useState("");
  const [cvr, setCvr] = useState("");
  useEffect(() => {
    setVerifiedData(format(new Date(), "HH:mm:ss dd-MM-yyyy"));
    req()(`apps/cvr`).then(({ data }) => setCvr(data.cvr));
  }, []);

  const pages = useSelector((state) => state.pages.pages);
  const lang = useSelector((state) => state.language.language);
  const user = useSelector((state) => state.auth.user);
  const appConfig = useSelector((state) => state.appConfig);
  const [page] = useState(getPageFromId(pages, props.match.params.pageId));

  return (
    <Page className={componentStyles()} backgroundColor={colors.white}>
      <TopBar title={page.title} useDefaultBackButton={true} />
      <ScrollView>
        <div className="content">
          {getProfilePictureFromUserObject(user, 196, { margin: "0 auto 1rem auto", display: "block" })}
          <h2>{user.name}</h2>
          <Image className="company-logo" src={`${appConfig.customerLogo.baseURL}${appConfig.customerLogo.image}`} />

          <h3>
            <InformationOutlineIcon />
            CVR:
          </h3>
          <p>{cvr ? cvr : lang.loading}</p>
          <h3 style={{ color: colors.green }}>
            <CheckboxMarkedOutlineIcon color={colors.green} />
            {lang.verified}
          </h3>
          <p>{verifiedData}</p>
        </div>
      </ScrollView>
    </Page>
  );
}

const componentStyles = () => css`
  div.content  {
    padding: 1rem;
    max-width: ${breakpoints.xs}px;
    margin: 0 auto;
    text-align: center;

    .company-logo {
      width: 100%;
      max-width: 300px;
      margin: 0 0 2rem 0;
    }

    h2 {
      font-size: 1.5rem;
      margin: 0 0 2rem 0;
    }

    h3 {
      font-size: 1.05rem;
      margin: 0 0 0.25rem 0;

      svg {
        color: ${colors.darkGrey};
        margin-right: 0.25rem;
        height: 1.25rem;
        height: 1.25rem;
        margin-bottom: -0.15rem;
      }
    }

    p {
      font-size: 1rem;
      margin: 0 0 1.25rem 0;
    }
  }
`;

export default NavigationList;
