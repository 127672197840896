// Libs
import React from "react";

// ActionTypes
import {
  END_OF_REGISTRATIONS_POSTS,
  GET_REGISTRATION_POSTS,
  GET_REGISTRATION_POSTS_FAILURE,
  GET_REGISTRATION_POSTS_STATISTICS_SUCCESS,
  GET_REGISTRATION_POSTS_SUCCESS,
  LIKE_REGISTRATION_POST,
  LIKE_REGISTRATION_POST_FAILURE,
  READ_REGISTRATION_POST,
  READ_REGISTRATION_POST_FAILURE,
  REFETCH_REGISTRATION_POST_FAILURE,
  REFETCH_REGISTRATION_POST_LIKES_FAILURE,
  REFETCH_REGISTRATION_POST_LIKES_SUCCESS,
  REFETCH_REGISTRATION_POST_SUCCESS,
  RESET_REGISTRATION,
  RESET_REGISTRATION_FEED,
  UNLIKE_REGISTRATION_POST,
  UNLIKE_REGISTRATION_POST_FAILURE,
} from "./actionTypes";

// Utilities & config
import req from "../utilities/request-utility";
import { feedTypes } from "../components/registration/config";

// Actions
import { addToast } from "./uiActions";

// Styles
import { ErrorOutlineIcon } from "mdi-react";
import { getPages } from "./pagesActions";

/** Fetches posts from api.
 *  Appends posts onto already existing posts.
 *  Use clearFeed() to empty feed.
 * @param {Object} anonymous
 * @param {Integer} registrationId
 * @param {String} feedType
 * @param {Integer} limit
 * @param {Integer} offset
 */
export function getRegistrationPosts({ registrationId, feedType = feedTypes.all, limit = 5, offset = null, postId = null }) {
  return async function (dispatch, getState) {
    try {
      let state = getState().registration[feedType];

      let posts = [];

      if (offset === null) offset = state.posts.length;

      // Safe-guard to prevent duplicate post-fetchings!
      if (state.loading || state.error || state.endOfFeed) return;

      dispatch({ type: GET_REGISTRATION_POSTS, payload: { feedType } });

      if (postId == null) {
        await getRegistrationPostForFeed();
      } else {
        await getRegistrationPostSingle();
      }

      async function getRegistrationPostForFeed() {
        let URL;

        if (feedType === feedTypes.all) {
          URL = `registration/${registrationId}/posts/?limit=${limit}&offset=${offset}`;
        }
        if (feedType === feedTypes.mostLiked) {
          URL = `registration/${registrationId}/posts-most-liked/?limit=${limit}&offset=${offset}`;
        }
        if (feedType === feedTypes.mine) {
          URL = `registration/${registrationId}/posts/?limit=${limit}&offset=${offset}&showMyPostsOnly=1`;
        }
        if (feedType === feedTypes.toMe) {
          URL = `registration/${registrationId}/posts/?limit=${limit}&offset=${offset}&showPostsToMeOnly=1`;
        }

        const { data } = await req()(URL);
        handleFetchedPosts(data);
      }

      async function getRegistrationPostSingle() {
        const { data: post } = await req()(`registration/${registrationId}/posts/${postId}`);
        handleFetchedPosts([post]);
      }

      function handleFetchedPosts(posts) {
        if (posts.length) {
          dispatch({
            type: GET_REGISTRATION_POSTS_SUCCESS,
            payload: { posts, feedType },
          });
        } else {
          dispatch({
            type: END_OF_REGISTRATIONS_POSTS,
            payload: { feedType },
          });
        }
      }
    } catch (err) {
      dispatch({ type: GET_REGISTRATION_POSTS_FAILURE, payload: { feedType } });
      const { language: lang } = getState().language;
      addToast({
        title: lang.errorGeneral,
        content: lang.errorCouldNotGetPosts,
        icon: <ErrorOutlineIcon />,
        styleType: "error",
        duration: 20000,
      })(dispatch, getState);
    }
  };
}

/** Marks the post as read.
 * @param {Object} anonymous
 * @param {Integer} registrationId
 * @param {Integer} postId
 * @param {string} feedType
 */
export function readRegistrationPost({ registrationId, postId, feedType }) {
  return async function (dispatch, getState) {
    try {
      dispatch({
        type: READ_REGISTRATION_POST,
        payload: postId,
      });

      await req().put(`registration/${registrationId}/posts/${postId}/read`);

      if (feedType === feedTypes.all) {
        refreshRegistrationPost({ registrationId, postId, feedType })(dispatch, getState);
      }

      // Update pages so notification count goes down when you go back
      getPages()(dispatch, getState);
    } catch (err) {
      dispatch({
        type: READ_REGISTRATION_POST_FAILURE,
        payload: { postId, feedType },
      });
      const { language: lang } = getState().language;
      addToast({
        title: lang.errorGeneral,
        content: lang.errorCouldNotRegister,
        icon: <ErrorOutlineIcon />,
        styleType: "error",
        duration: 20000,
      })(dispatch, getState);
    }
  };
}

/** Fetches and update the newest version of the post and updates it in the posts array
 * @param {Object} anonymous
 * @param {Integer} registrationId
 * @param {Integer} postId
 * @param {string} feedType
 */
export function refreshRegistrationPost({ registrationId, postId, feedType }) {
  return async function (dispatch) {
    try {
      let { data: post } = await req()(`registration/${registrationId}/posts/${postId}`);

      dispatch({
        type: REFETCH_REGISTRATION_POST_SUCCESS,
        payload: { post, feedType },
      });
    } catch (err) {
      dispatch({
        type: REFETCH_REGISTRATION_POST_FAILURE,
        payload: { feedType },
      });
    }
  };
}

/**
 * Gets the updated versions of likes for the given registration post.
 * Is used for when like/unlike of a registration post
 * @param {Object} anonymous
 * @param {Integer} registrationId
 * @param {Integer} postId
 * @param {String} feedType
 */
export function refreshRegistrationPostLikes({ registrationId, postId, feedType }) {
  return async function (dispatch) {
    try {
      let { data: likes } = await req()(`registration/${registrationId}/posts/${postId}/likes`);
      dispatch({
        type: REFETCH_REGISTRATION_POST_LIKES_SUCCESS,
        payload: { likes, feedType, postId },
      });
    } catch (err) {
      dispatch({
        type: REFETCH_REGISTRATION_POST_LIKES_FAILURE,
        payload: { feedType },
      });
    }
  };
}

/**
 * Resets the specified anniversaries feedType
 * @param {String} feedType
 */
export function resetFeed(feedType) {
  return function (dispatch) {
    dispatch({
      type: RESET_REGISTRATION_FEED,
      payload: feedType,
    });
  };
}

/**
 * Resets the registration feed - this include all feedTypes
 */
export function resetAll() {
  return function (dispatch) {
    dispatch({
      type: RESET_REGISTRATION,
    });
  };
}

/**
 * Removes the like from the registration post
 * @param {Object} anonymous
 * @param {Integer} registrationId
 * @param {Integer} postId
 * @param {String} feedType
 */
export function unlikePost({ registrationId, postId, feedType }) {
  return async function (dispatch, getState) {
    try {
      dispatch({
        type: UNLIKE_REGISTRATION_POST,
        payload: postId,
      });

      await req().delete(`registration/${registrationId}/posts/${postId}/like`);

      if (feedType === feedTypes.all) {
        refreshRegistrationPostLikes({ registrationId, postId, feedType })(dispatch, getState);
        resetFeed(feedTypes.mostLiked)(dispatch, getState);
        getRegistrationPosts({ registrationId, feedType: feedTypes.mostLiked })(dispatch, getState);
      }
    } catch (error) {
      dispatch({
        type: UNLIKE_REGISTRATION_POST_FAILURE,
        payload: { postId, feedType },
      });
      const { language: lang } = getState().language;
      addToast({
        title: lang.errorGeneral,
        content: lang.errorCouldNotRegister,
        icon: <ErrorOutlineIcon />,
        styleType: "error",
        duration: 20000,
      })(dispatch, getState);
    }
  };
}

/**
 * Adds a like to the registration post
 * @param {Object} anonymous
 * @param {Integer} registrationId
 * @param {Integer} postId
 * @param {String} feedType
 */
export function likePost({ registrationId, postId, feedType }) {
  return async function (dispatch, getState) {
    try {
      dispatch({
        type: LIKE_REGISTRATION_POST,
        payload: postId,
      });

      await req().put(`registration/${registrationId}/posts/${postId}/like`);

      if (feedType === feedTypes.all) {
        refreshRegistrationPostLikes({ registrationId, postId, feedType })(dispatch, getState);
        resetFeed(feedTypes.mostLiked)(dispatch, getState);
        getRegistrationPosts({ registrationId, feedType: feedTypes.mostLiked })(dispatch, getState);
      }
    } catch (error) {
      const { language: lang } = getState().language;
      dispatch({
        type: LIKE_REGISTRATION_POST_FAILURE,
        payload: { postId, feedType },
      });
      addToast({
        title: lang.errorGeneral,
        content: lang.errorCouldNotRegister,
        icon: <ErrorOutlineIcon />,
        styleType: "error",
        duration: 20000,
      })(dispatch, getState);
    }
  };
}

export function getPostsStatistics({ registrationId }) {
  return async function (dispatch, getState) {
    try {
      const { data: statistics } = await req()(`registration/${registrationId}/posts/statistics`);

      if (!statistics) return;

      dispatch({
        type: GET_REGISTRATION_POSTS_STATISTICS_SUCCESS,
        payload: statistics,
      });
    } catch (error) {
      const { language: lang } = getState().language;
      addToast({
        title: lang.errorGeneral,
        content: lang.errorCouldNotFetchStatistics,
        icon: <ErrorOutlineIcon />,
        styleType: "error",
        duration: 20000,
      })(dispatch, getState);
    }
  };
}
