import React from "react";
import { css } from "emotion";

import colors from "../../style/colors";
import common from "../../style/common";
import PropTypes from "prop-types";
import { CSSTransition } from "react-transition-group";

/** Small components to display the unread/unhandled elements for the specific user since their last interaction,
 * this could for example be: display a different number for notfications over 50 */
const Notification = (props) => (
  <CSSTransition
    in={props.notifications > 0}
    timeout={900}
    mountOnEnter={true}
    unmountOnExit={true}
    classNames="notification"
  >
    <span className={style(props.primaryColor, props.notifications)} style={props.style}>
      {props.notifications > 20 ? "20+" : props.notifications}
    </span>
  </CSSTransition>
);
const style = (primaryColor, notifications) => css`
  background-color: ${colors.red};
  border-radius: 30px;
  height: 21px;
  text-align: center;
  color: ${colors.white};
  display: inline-flex;
  justify-content: center;
  align-items: center;
  padding: 0.5rem;
  font-size: 0.9rem;
  font-weight: 700;
  font-family: ${common.fontStack};

  &.notification-enter {
    opacity: 0;
  }
  &.notification-enter-active {
    animation: notificationEnter 900ms forwards ease-in;
  }
  &.notification-exit {
    opacity: 1;
    transform: scale(1);
  }
  &.notification-exit-active {
    transform: scale(1.7);
    opacity: 0;
    transition: transform 400ms ease, opacity 400ms;
  }

  @keyframes notificationEnter {
    0% {
      transform: scale(0.7) rotate(-15deg);
      opacity: 0;
    }
    20% {
      transform: scale(0.7) rotate(15deg);
      opacity: 0.5;
    }
    40% {
      transform: scale(0.7) rotate(-15deg);
      opacity: 1;
    }
    60% {
      transform: scale(1.2) rotate(15deg);
      opacity: 1;
    }
    80% {
      transform: scale(1) rotate(-15deg);
      opacity: 1;
    }
    100% {
      transform: scale(1) rotate(0deg);
      opacity: 1;
    }
  }
`;

Notification.propTypes = {
  /** override styles in your current implementation */
  style: PropTypes.object,
  /** Numerical value for the number of elements not seen/activated by the user */
  notifications: PropTypes.number,
  /** The applications primary color */
  primaryColor: PropTypes.string,
};
export default Notification;
