import React, { useEffect, useState } from "react";
import { connect, useSelector } from "react-redux";
import { bindActionCreators } from "redux";
import { feedTypes } from "./config";
import { css } from "emotion";
import queryString from "query-string";
import AnimateHeight from "react-animate-height";

// Redux actions
import { getPosts, resetFeeds } from "../../actions/newsActions";
import { showModalPage } from "../../actions/uiActions";

// Components
import Page from "../ui/Page";
import TopBar from "../ui/TopBar";
import InlineSpinner from "../ui/InlineSpinner";
import StatusBox from "../ui/StatusBox";
import ImageCarousel from "../ui/ImageCarousel";
import { PlusIcon, InfoOutlineIcon, DownloadIcon, OpenInNewIcon, AccountCircleIcon } from "mdi-react";
import ScrollView from "../ui/ScrollView";
import colors from "../../style/colors";
import markdownParser from "../../utilities/markdown-parser";
import FloatingSearchInput from "../ui/FloatingSearchInput";

// Config
import pageConfig from "../../config/pageConfig";
import breakpoints from "../../config/breakpoints";
import { durations } from "../../config/animations";

// Utilities
import highlightMatch from "../../utilities/highlight-match";
import smoothScrollToFixedNumber from "../../utilities/smooth-scroll-to-fixed-number";
import useCurrentPage from "../../hooks/useCurrentPage";
import extractValueFromObject from "../../utilities/extract-value-from-object";

function NewsFeedFaq(props) {
  // page setup
  const { getPosts, resetFeeds } = props;
  const primaryColor = useSelector((state) => state.appConfig.primaryColor);
  const lang = useSelector((state) => state.language.language);
  const [activeAccordionId, setActiveAccordionId] = useState();
  const [hasScrolled, setHasScrolled] = useState(false);

  const page = useCurrentPage({ pageId: props.match.params.pageId });
  const includeFilesFromLinkCollection = page.config && page.config[pageConfig.INCLUDE_FILES_FROM_LINK_COLLECTION] === true;

  const sort = extractValueFromObject({ object: page.config, key: pageConfig.NEWS_POSTS_SORT_ORDER });

  // Data
  const { posts, loading, error, endOfFeed } = useSelector((state) => state.news.all);

  // Search term to filter the FAQs
  const [searchTerm, setSearchTerm] = useState("");

  const [filteredFAQs, setFilteredFAQs] = useState([...posts]);

  // Filter FAQs
  const filterFAQs = () => {
    const newFilteredFAQs = posts.filter(
      (post) => post.title.toLowerCase().search(searchTerm) !== -1 || post.content.toLowerCase().search(searchTerm) !== -1
    );
    setFilteredFAQs(newFilteredFAQs);
  };

  useEffect(() => {
    filterFAQs();
    // eslint-disable-next-line
  }, [searchTerm]);

  // Open the first element if FAQ only has one post
  useEffect(() => {
    if (posts.length === 1) setActiveAccordionId(posts[0].id);
    setFilteredFAQs([...posts]);
  }, [posts]);

  // Smooth scroll to scrollToId query-param
  useEffect(() => {
    if (filteredFAQs.length === 1) setActiveAccordionId(filteredFAQs[0].id);
    else if (filteredFAQs.length > 1 || !filteredFAQs.length) setActiveAccordionId();
  }, [filteredFAQs]);

  useEffect(() => {
    // resetFeeds();
    // getPosts({ subTypeId: page.dataId, feedType: feedTypes.all, limit: 10000 });
    let queryStrings = queryString.parse(window.location.search);
    if (queryStrings.scrollToId) {
      // Only run once
      if (hasScrolled === true || !queryStrings.scrollToId) return;

      if (!loading && !error && posts.length) {
        setTimeout(() => {
          scrollToId(queryStrings.scrollToId);
          setHasScrolled(true);
        }, 500);
      }
    }
    // eslint-disable-next-line
  }, [posts, loading, error]);

  useEffect(() => {
    resetFeeds();
    getPosts({ subTypeId: page.dataId, feedType: feedTypes.all, limit: 10000, sort, includeFilesFromLinkCollection });
    // setQueryStrings(queryString.parse(window.location.search));
    // eslint-disable-next-line
  }, []);

  function scrollToId(id) {
    setActiveAccordionId(parseInt(id));

    setTimeout(() => {
      let container = document.querySelector(`.scroll-view`);
      let accordion = document.querySelector(`[data-accordion-id='${id}']`);
      if (container && accordion)
        smoothScrollToFixedNumber({ element: container, scrollTo: accordion.offsetTop - 120, duration: durations.slow });
    }, 300);
  }

  return (
    <Page>
      <TopBar useDefaultBackButton={true} title={page.title} />
      <ScrollView
        className={container(primaryColor)}
        style={posts.length > 1 ? { paddingTop: "70px" } : { paddingTop: "1rem" }}
      >
        {/* Loading */}
        {loading && !error && !endOfFeed && (
          <InlineSpinner style={{ margin: "2rem 0" }} title={`${lang.loading} ${lang.posts}...`} />
        )}

        {/* Nothing matches search*/}
        {filteredFAQs.length === 0 && posts.length > 0 && !loading && (
          <StatusBox
            style={{ marginBottom: "2rem" }}
            icon={<InfoOutlineIcon />}
            title={lang.noResults}
            content={lang.trySearchingForSomethingElse}
          />
        )}

        {/* End of feed with posts */}
        {posts.length > 0 && !loading && endOfFeed && (
          <StatusBox
            style={{ marginBottom: "2rem" }}
            icon={<InfoOutlineIcon />}
            title={lang.noMorePosts}
            content={lang.reachedEndOfFeed}
          />
        )}

        {/* End of feed without posts */}
        {posts.length === 0 && !loading && endOfFeed && (
          <StatusBox
            style={{ marginBottom: "2rem" }}
            icon={<InfoOutlineIcon />}
            title={lang.noPostsHere}
            content={lang.comeBackSoon}
          />
        )}

        {/* Content */}
        {filteredFAQs.map((post, questionId) => (
          <div
            key={questionId}
            className={`accordion ${activeAccordionId === post.id ? "expanded" : ""}`}
            data-accordion-id={post.id}
          >
            <p
              className="accordion-question"
              onClick={() => setActiveAccordionId(activeAccordionId === post.id ? null : post.id)}
            >
              {highlightMatch(post.title, searchTerm)}
            </p>
            <PlusIcon className="plus-icon" />
            <AnimateHeight
              height={activeAccordionId === post.id ? "auto" : 0}
              duration={durations.normal}
              animateOpacity={true}
            >
              <div className="accordion-answer">
                {post.approvedBy && post.documentOwner && (
                  <div style={{ display: "flex", alignItems: "center", marginBottom: "1.5rem" }}>
                    <AccountCircleIcon
                      style={{ marginRight: "0.35rem", width: "1.15rem", height: "1.15rem" }}
                      color={colors.black}
                    />
                    <p style={{ color: colors.black }}>
                      Godkendt af: {post.approvedBy}. Dokumentejer: {post.documentOwner}
                    </p>
                  </div>
                )}

                {post.youtubeEmbed && (
                  <div className="videoWrapper">
                    <iframe
                      title={post.title}
                      frameborder="0"
                      allow="accelerometer; encrypted-media; gyroscope;"
                      allowfullscreen
                      src={post.youtubeEmbed}
                    ></iframe>
                  </div>
                )}

                {/* Video */}
                {post.video && (
                  <div className="videoWrapper">
                    <video
                      controls
                      controlsList="nodownload"
                      poster={`${post.video.baseURL}f_jpg/${post.video.video}`}
                      src={`${post.video.baseURL}f_auto,q_auto,w_1600/${post.video.video}`}
                    ></video>
                  </div>
                )}

                {/* Images */}
                {post.images.length > 0 && <ImageCarousel boxPadding="1rem" images={post.images} />}
                {post.content && (
                  <div
                    className="content"
                    dangerouslySetInnerHTML={markdownParser(highlightMatch(post.content, searchTerm, "MARKDOWN"))}
                  ></div>
                )}

                {/* Files */}
                {post.files && !post.files.length ? null : (
                  <div className="attachments-container">
                    {post.files.map((file, fileKey) => (
                      <a
                        className="attachment"
                        key={fileKey}
                        href={`${file.baseURL}${file.file}`}
                        target="_blank"
                        rel="noopener noreferrer"
                      >
                        <DownloadIcon style={{ fill: primaryColor }} />
                        <p>{file.title}</p>
                      </a>
                    ))}
                  </div>
                )}

                {/* External links */}
                {post.links && !post.links.length ? null : (
                  <div className="attachments-container">
                    {post.links.map((link, linkKey) => (
                      <a className="attachment" key={linkKey} href={link.URL} target="_blank" rel="noopener noreferrer">
                        <OpenInNewIcon style={{ fill: primaryColor }} />
                        <p>{link.title}</p>
                      </a>
                    ))}
                  </div>
                )}
              </div>
            </AnimateHeight>
          </div>
        ))}
      </ScrollView>{" "}
      {posts.length > 1 && (
        <FloatingSearchInput
          className="search-contacts-input"
          dataTestId={"inputField"}
          onChange={(e) => setSearchTerm(e.target.value.toLowerCase())}
          value={searchTerm}
          onClearSearch={() => {
            setSearchTerm("");
          }}
        />
      )}
    </Page>
  );
}

const container = (primaryColor) => css`
  padding-bottom: 3rem;

  .accordion {
    width: 100%;
    background-color: ${colors.white};
    border-top: 1px ${colors.midGrey} solid;
    border-bottom: 1px ${colors.midGrey} solid;
    position: relative;
    overflow: hidden;
    transition: margin 200ms ease;
    margin-bottom: -1px;

    span.match {
      display: inline-block;
      border-bottom: 1px ${colors.darkGrey} dashed;
      background-color: ${colors.yellowLight};
      border-radius: 3px;
    }

    @media (min-width: ${breakpoints.lg}px) {
      max-width: ${breakpoints.lg}px;
      border-left: 1px ${colors.midGrey} solid;
      border-right: 1px ${colors.midGrey} solid;
      margin: 0 auto -1px auto;
      /* Collapsed */
      &:first-child {
        border-radius: 3px 3px 0 0;
      }
      &:last-child {
        border-radius: 0 0 3px 3px;
      }
    }

    /* Collapsed */
    &:first-child {
      border-top: 1px ${colors.midGrey} solid;
    }

    .accordion-question {
      padding-right: 40px;
      padding: 1rem 3rem 1rem 1rem;
      transition: 200ms padding ease;

      &:hover {
        cursor: pointer;
        background-color: ${colors.ultraLightGrey};
      }

      &:active {
        background-color: ${colors.lightGrey};
      }
    }

    .plus-icon {
      position: absolute;
      top: 1rem;
      right: 1rem;
      transition: 240ms transform ease;
      color: ${colors.darkGrey};
      pointer-events: none;
    }

    /* Disable clicks on images -> prevents bug where hidden images opens lightbox */
    & img {
      pointer-events: none;
    }

    .content {
      user-select: text;

      *::selection {
        background: ${primaryColor};
        color: ${colors.white};
        border-radius: 3px;
      }

      ul,
      ol {
        margin-left: 1rem;
      }

      p,
      ul {
        margin-bottom: 0.75rem;
        line-height: 1.35;
      }

      margin-bottom: 0rem;
      transition: 200ms margin ease;
    }

    .accordion-answer {
      padding: 1rem;

      .videoWrapper {
        position: relative;
        padding-bottom: 56.25%;
        padding-top: 25px;
        height: 0;
        margin-bottom: 1rem;
      }

      video,
      iframe {
        width: 100%;
        border-radius: 3px;
        border: 1px ${colors.lightGrey} solid;
        position: absolute;
        top: 0;
        left: 0;
        height: 100%;
      }
    }

    /* Expanded */
    &.expanded {
      margin: 1rem auto;
      transition: margin 200ms ease;

      &:first-of-type {
        margin-top: 0;
      }

      .content {
        margin-bottom: 1rem;
        transition: 200ms margin ease;
      }

      .accordion-question {
        color: ${primaryColor};
        font-weight: 700;
        padding-bottom: 1rem;
        transition: 200ms padding ease;
      }

      & img {
        pointer-events: initial; /* Allows images to be clicked */
      }

      .plus-icon {
        transform: rotate(45deg);
        transition: 200ms transform ease;
        color: ${primaryColor};
      }
    }
  }

  .attachments-container {
    border: 1px ${colors.midGrey} solid;
    border-radius: 3px;
    overflow: hidden;
    margin-bottom: 1rem;

    :last-child {
      margin-bottom: 0px;
    }

    .attachment {
      text-decoration: none;
      display: block;
      color: ${colors.darkGrey};
      border-bottom: 1px ${colors.midGrey} solid;
      padding: 0.5rem;
      position: relative;
      transition: background-color 180ms ease;

      &:last-child {
        border-bottom: 0px;
      }

      &:active {
        background-color: ${colors.lightGrey};
        transition: background-color 180ms ease;
      }

      p {
        padding-left: 1.75rem;
      }

      svg {
        position: absolute;
        top: 50%;
        left: 0.6rem;
        transform: translateY(-50%);
        width: 1rem;
        height: 1rem;
        vertical-align: middle;
        fill: ${colors.darkGrey};
      }
    }
  }
`;

const mapDispatchToProps = (dispatch) => ({
  getPosts: bindActionCreators(getPosts, dispatch),
  showModalPage: bindActionCreators(showModalPage, dispatch),
  resetFeeds: bindActionCreators(resetFeeds, dispatch),
});

export default connect(null, mapDispatchToProps)(NewsFeedFaq);
