import React, { useEffect } from "react";
import { useSelector, useDispatch } from "react-redux";
import { css } from "emotion";
import { useDebouncedCallback } from "use-debounce";
import queryString from "query-string";
import { FeatureSearchOutlineIcon, CloseIcon } from "mdi-react";

// Components
import ScrollView from "../ScrollView";
import InlineSpinner from "../InlineSpinner";
import SearchResultNewsPost from "./SearchResultNewsPost";
import SearchResultUser from "./SearchResultUser";
import SearchResultPage from "./SearchResultPage";
import SearchResultSocialPost from "./SearchResultSocialPost";
import FloatingSearchInput from "../FloatingSearchInput";
import Page from "../Page";
import TopBar from "../TopBar";
import ActionWrapper from "../ActionWrapper";
import StatusBox from "../StatusBox";

// Style
import colors from "../../../style/colors";
import breakpoints from "../../../config/breakpoints";

// Actions
import { setSearchTerm, clearAll, getResults } from "../../../actions/searchPageActions";

// Utilities
import isElementScrollable from "../../../utilities/is-element-scrollable";
import pageNavigatorV2, { pageTransitions } from "../../../utilities/page-navigator-v2";

const SearchModalMain = (props) => {
  const dispatch = useDispatch();
  const { results, searchTerm, loading, error, endOfFeed } = useSelector((s) => s.searchPage);
  const lang = useSelector((state) => state.language.language);
  const [debouncedGetResults] = useDebouncedCallback(getMoreResults, 500);

  useEffect(() => {
    let { scrollTo } = queryString.parse(window.location.search);
    let el = document.getElementById("el-search-page-scroll-view");
    if (!el) return;
    el.scrollTo(0, scrollTo);
  }, []);

  useEffect(() => {
    debouncedGetResults();

    // eslint-disable-next-line
  }, [searchTerm]);

  useEffect(() => {
    // If scrollview is not scrollable after new results try with more
    if (!isElementScrollable(document.getElementById("el-search-page-scroll-view")) && results.length > 0) {
      getMoreResults();
    }
    // eslint-disable-next-line
  }, [results]);

  function getMoreResults() {
    if (!loading && !error) {
      dispatch(getResults());
    }
  }

  function getResultComponentByType(result) {
    switch (result.type) {
      case "USER":
        return <SearchResultUser result={result} key={`user-result__${result.data.id}`} />;
      case "SOCIAL_POST":
        return <SearchResultSocialPost result={result} key={`social-post__${result.data.id}`} />;
      case "NEWS_POST":
        return <SearchResultNewsPost result={result} key={`news-post__${result.data.id}}`} />;
      case "PAGE":
        return <SearchResultPage result={result} key={`page__${result.page.id}`} />;
      default:
        return null;
    }
  }

  return (
    <Page>
      <TopBar
        title={lang.search}
        actionLeft={
          <ActionWrapper onClick={() => pageNavigatorV2({ mode: "pop", direction: pageTransitions.modalPageDown })}>
            <CloseIcon />
          </ActionWrapper>
        }
      />
      <ScrollView onScrollEnd={getMoreResults} className={componentStyles()} id="el-search-page-scroll-view">
        {/* content */}
        {results.map(getResultComponentByType)}

        {/* Loading */}
        {loading && <InlineSpinner style={{ margin: "2rem auto" }} />}

        {/* Status box */}
        {endOfFeed && (
          <StatusBox
            icon={<FeatureSearchOutlineIcon />}
            title={results.length > 0 ? lang.noMoreResults : lang.noResults}
            content={results.length > 0 ? lang.endOfResults : lang.trySearchingForSomethingElse}
            style={{ margin: "2rem auto" }}
          />
        )}

        {/* Status box */}
        {!loading && !error && !searchTerm && results.length === 0 && !endOfFeed && (
          <StatusBox
            icon={<FeatureSearchOutlineIcon />}
            title={lang.startTypingToSearch}
            content={lang.searchPossibilities}
            style={{ margin: "2rem auto" }}
          />
        )}

        {/* SeachBar: Positioned at bottom to prevent z-index use (it's position: fixed so dom-placement doesn't matter) */}
        <FloatingSearchInput
          searching={loading}
          value={searchTerm}
          onClearSearch={() => dispatch(clearAll())}
          onChange={(e) => dispatch(setSearchTerm(e.target.value))}
          autoFocusOnRender={true}
          style={{ zIndex: 3 }}
        />
      </ScrollView>
    </Page>
  );
};

const componentStyles = (props) => css`
  padding-top: 68px;
  padding-bottom: 3rem;

  .list-element {
    background-color: ${colors.white};
    border-top: 1px ${colors.midGrey} solid;
    border-bottom: 1px ${colors.midGrey} solid;
    padding: 1rem;
    margin-bottom: -1px;
    position: relative;
    cursor: pointer;
    transition: background-color 120ms ease;

    @media screen and (min-width: ${breakpoints.lg}px) {
      max-width: ${breakpoints.lg}px;
      margin: 0 auto -1px auto;
      border: 1px ${colors.midGrey} solid;

      &:first-of-type {
        border-top-left-radius: 3px;
        border-top-right-radius: 3px;
      }

      &:last-of-type(1) {
        border-bottom-left-radius: 3px;
        border-bottom-right-radius: 3px;
      }
    }

    &:hover {
      background-color: ${colors.ultraLightGrey};
      transition: background-color 50ms ease;

      .left-side-gradient {
        opacity: 0;
      }
    }

    &:active {
      background-color: ${colors.lightGrey};
      transition: background-color 80ms ease;
    }

    svg.navigation-arrow-indicator {
      position: absolute;
      right: 0.5rem;
      top: 50%;
      transform: translateY(-50%);
      width: 1.25rem;
      height: 1.25rem;
      color: ${colors.darkGrey};
    }

    p.title {
      font-size: 1.15rem;
      margin-bottom: 0.45rem;
    }

    div.content {
      color: ${colors.black};
      overflow: hidden;

      p {
        overflow: hidden;
        text-overflow: ellipsis;
      }

      span.match {
        font-weight: bold;
      }
    }
  }
`;

export default SearchModalMain;
