// Libs
import React, { useEffect } from "react";
import { connect, useSelector } from "react-redux";
import { bindActionCreators } from "redux";

// Config
import { feedTypes } from "./config";
import pageConfig from "../../config/pageConfig";

// Redux actions
import { getPosts, resetFeeds } from "../../actions/newsActions";
import { showModalPage } from "../../actions/uiActions";

// Components
import NewsPost from "./NewsPost";
import ActionWrapper from "../ui/ActionWrapper";
import Page from "../ui/Page";
import TopBar from "../ui/TopBar";
import InlineSpinner from "../ui/InlineSpinner";
import StatusBox from "../ui/StatusBox";
import { PlusIcon, InfoOutlineIcon } from "mdi-react";
import NewsPostModal from "./NewsPostModal";
import ScrollView from "../ui/ScrollView";

// Utilities
import useCurrentPage from "../../hooks/useCurrentPage";
import extractValueFromObject from "../../utilities/extract-value-from-object";

function NewsFeedSingle(props) {
  // page setup
  const { match, getPosts, resetFeeds, showModalPage } = props;

  const lang = useSelector((state) => state.language.language);
  const adminId = useSelector((state) => state.auth.user.adminId);

  const page = useCurrentPage({ pageId: match.params.pageId });
  const sort = extractValueFromObject({ object: page.config, key: pageConfig.NEWS_POSTS_SORT_ORDER });

  // Data
  const { posts, loading, error, endOfFeed } = useSelector((state) => state.news.all);

  useEffect(() => {
    resetFeeds();
    getPosts({ subTypeId: page.dataId, feedType: feedTypes.all });
    // eslint-disable-next-line
  }, []);

  // Fetches more posts if scrollview isn't yet scrollable
  useEffect(() => {
    setTimeout(() => {
      let currentFeed = document.querySelector(".scroll-view");
      if (currentFeed && currentFeed.scrollHeight <= currentFeed.clientHeight)
        getPosts({ subTypeId: page.dataId, feedType: feedTypes.all, sort });
    }, 500); // wait for render... I know this is a bit hacky but the action doesn't have to fire immedietly

    // eslint-disable-next-line
  }, [posts, loading, error]);

  return (
    <Page>
      <TopBar
        useDefaultBackButton={true}
        title={page.title}
        actionRight={
          !adminId ? null : (
            <ActionWrapper
              data-test-id="btn-show-add-post-modal"
              onClick={() =>
                showModalPage({
                  useScrollView: false,
                  content: <NewsPostModal subTypeId={page.dataId} feedType={feedTypes.all} />,
                })
              }
            >
              <PlusIcon />
            </ActionWrapper>
          )
        }
      />

      <ScrollView onScrollEnd={() => getPosts({ subTypeId: page.dataId, feedType: feedTypes.all })}>
        <div style={{ height: "1rem" }} />

        {/* Content */}
        {posts.map((p) => (
          <NewsPost
            post={p}
            feedType={feedTypes.all}
            subTypeId={page.dataId}
            key={`news-post-${p.id}`}
            disableSwipe={page.config && page.config.disableSwipe === false ? false : true}
          />
        ))}

        {/* Loading */}
        {loading && !error && !endOfFeed && (
          <InlineSpinner style={{ margin: "2rem 0" }} title={`${lang.loading} ${lang.posts}...`} />
        )}

        {/* End of feed with posts (if only one post don't show this message) */}
        {posts.length > 0 && endOfFeed && posts.length !== 1 && (
          <StatusBox
            style={{ marginBottom: "2rem" }}
            icon={<InfoOutlineIcon />}
            title={lang.noMorePosts}
            content={lang.reachedEndOfFeed}
          />
        )}

        {/* End of feed without posts */}
        {posts.length === 0 && endOfFeed && (
          <StatusBox
            style={{ marginBottom: "2rem" }}
            icon={<InfoOutlineIcon />}
            title={lang.noPostsHere}
            content={lang.comeBackSoon}
          />
        )}
      </ScrollView>
    </Page>
  );
}

const mapDispatchToProps = (dispatch) => ({
  getPosts: bindActionCreators(getPosts, dispatch),
  showModalPage: bindActionCreators(showModalPage, dispatch),
  resetFeeds: bindActionCreators(resetFeeds, dispatch),
});

export default connect(null, mapDispatchToProps)(NewsFeedSingle);
