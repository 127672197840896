// Libs
import React from "react";
import { css } from "emotion";

// Config
import { registrationTypes } from "../registration/config";
import breakpoints from "../../config/breakpoints";
import { useSelector } from "react-redux";

// Components
import TextareaInput from "./TextareaInput";
import ImageUploadMultiple from "./ImageUploadMultiple";
import FileUpload from "./FileUpload";
import RadioGroup from "./RadioGroup";
import HorizontalRadioGroup from "./HorizontalRadioGroup";
import CheckboxGroup from "./CheckboxGroup";
import DatePicker from "./DatePicker";
import YesAndNoGroup from "./YesAndNoGroup";
import TimePicker from "./TimePicker";
import DropDownList from "./DropDownList";
import DataSelector from "./dataSelector/DataSelector";

// Style
import colors from "../../style/colors";
import TrafficLightRadioGroup from "./TrafficLightRadioGroup";
import registrationQuestionLayoutHints from "../../config/registrationQuestionLayoutHints";

function getRowsFromLayoutHint({ layoutHint }) {
  switch (layoutHint) {
    case registrationQuestionLayoutHints.inline:
    case registrationQuestionLayoutHints.small:
      return 1;
    case registrationQuestionLayoutHints.default:
      return 4;
    case registrationQuestionLayoutHints.large:
      return 12;
  }
}

/**
 *
 * @returns {Object} props
 * @param {Object} props.question
 * @param {Object} props.notValidArr - Skriv forklaring
 * @param {Function} props.updateFields
 * @param {Integer} props.questionIndex
 *
 * Element that displays questions based on the question types - it's currently used in the registration and events modules
 */

const FormBuilder = (props) => {
  const { language: lang } = useSelector((state) => state.language);

  // Props
  const {
    categoryIndex = null,
    disabledSubmitBtn = null,
    enableSubmitBtn = null,
    notValidArr = [],
    question,
    questionIndex,
    showOnly = false,
    showQuestionTitles = true,
    updateFields,
  } = props;

  const isQuestionValid = notValidArr.some((invalidQuestionId) => invalidQuestionId === question.id) ? true : false;

  return (
    <div className={componentStyle() + ` ${question.layoutHint}`} key={`form-element-${question.title}`}>
      {/** DATA SELECT **/}
      {question.type === registrationTypes.DATA_SELECT && (
        <div style={props.style} className="type-container">
          {showQuestionTitles && <p className={`title ${isQuestionValid ? "show-validate-title" : ""}`}>{question.title}</p>}
          <DataSelector
            value={question.answer && question.answer.title}
            onSelect={(option) => updateFields({ title: option }, questionIndex, categoryIndex)}
            dataIdentifier={question.dataIdentifier}
          />
        </div>
      )}

      {/** TEXT **/}
      {question.type === registrationTypes.TEXT && (
        <div style={props.style} className="type-container">
          {showQuestionTitles && <p className={`title ${isQuestionValid ? "show-validate-title" : ""}`}>{question.title}</p>}
          <TextareaInput
            rows={getRowsFromLayoutHint({ layoutHint: question.layoutHint })}
            minHeight={"1rem"}
            disabled={showOnly}
            className={`input ${isQuestionValid ? "not-valid" : "title"}`}
            placeholder={lang.writeHere}
            value={question.answer ? question.answer.title : ""}
            onChange={(e) => updateFields({ ...question.answer, title: e.target.value }, questionIndex, categoryIndex)}
          />
        </div>
      )}

      {/** IMAGE **/}
      {question.type === registrationTypes.IMAGE && (
        <div style={props.style} className="type-container">
          {showQuestionTitles && <p className={`title ${isQuestionValid ? "show-validate-title" : ""}`}>{question.title}</p>}
          <ImageUploadMultiple
            id={`image-upload__form-builder__${question.id}`}
            disabledSubmitBtn={disabledSubmitBtn}
            enableSubmitBtn={enableSubmitBtn}
            className={isQuestionValid ? "not-valid" : "title"}
            uploadedFiles={
              question.answer && question.answer.baseURL && question.answer.title
                ? [{ image: question.answer.title, baseURL: question.answer.baseURL }]
                : []
            }
            shouldResetFiles={question.answer && !question.answer.title && !question.answer.title && true}
            allowedAmountOfImages={1}
            disableVideoUpload={true}
            showSingleImageMessage={true}
            onFileUpdate={(files) =>
              files.length > 0
                ? updateFields(
                    { ...question.answer, title: files[0].image, baseURL: files[0].baseURL },
                    questionIndex,
                    categoryIndex
                  )
                : updateFields({ ...question.answer, title: null, baseURL: null }, questionIndex, categoryIndex)
            }
          />
        </div>
      )}

      {/** FILE **/}
      {question.type === registrationTypes.FILE && (
        <div style={props.style} className="type-container">
          {showQuestionTitles && <p className="title">{question.title}</p>}
          <FileUpload
            placeholder={(question.answer && question.answer.title && question.answer.title.split("/").slice(-1)[0]) || ""}
            disabledSubmitBtn={disabledSubmitBtn}
            enableSubmitBtn={enableSubmitBtn}
            value={question.answer ? question.answer.title : null}
            className={isQuestionValid ? "not-valid" : "title"}
            removeFile={() => updateFields({ ...question.answer, title: null }, questionIndex, categoryIndex)}
            onFile={(filePath) => updateFields({ ...question.answer, title: filePath }, questionIndex, categoryIndex)}
          />
        </div>
      )}

      {/** RADIO **/}
      {question.type === registrationTypes.RADIO && (
        <div style={props.style} className="type-container">
          {showQuestionTitles && <p className={`title ${isQuestionValid ? "show-validate-title" : ""}`}>{question.title}</p>}
          <RadioGroup
            disabled={showOnly}
            className={isQuestionValid ? "not-valid" : "title"}
            selectedValue={question.answer && question.answer.title}
            options={question.options}
            onSelect={(value) => updateFields({ ...question.answer, title: value }, questionIndex, categoryIndex)}
          />
        </div>
      )}

      {/** RADIO HORIZONTAL **/}
      {question.type === registrationTypes.RADIO_HORIZONTAL && (
        <div style={props.style} className="type-container">
          {showQuestionTitles && <p className={`title ${isQuestionValid ? "show-validate-title" : ""}`}>{question.title}</p>}
          <HorizontalRadioGroup
            disabled={showOnly}
            className={isQuestionValid ? "not-valid" : "title"}
            selectedValue={question.answer && question.answer.title}
            options={question.options}
            onSelect={(value) => updateFields({ ...question.answer, title: value }, questionIndex, categoryIndex)}
          />
        </div>
      )}

      {/** DROPDOWN **/}
      {question.type === registrationTypes.DROPDOWN && (
        <div style={props.style} className="type-container">
          {showQuestionTitles && <p className={`title ${isQuestionValid ? "show-validate-title" : ""}`}>{question.title}</p>}
          <DropDownList
            dropDownListContent={question.options}
            className={isQuestionValid ? "not-valid" : "title"}
            selectedContentId={question && question.answer ? question.answer.title : 0}
            onChange={(e) =>
              updateFields({ ...question.answer, title: parseInt(e.target.value) }, questionIndex, categoryIndex)
            }
            placeholder={lang.pickOneFromFromTheList}
            name="registration-dropdown"
          />
        </div>
      )}

      {/** RADIO TRAFFIC LIGHT **/}
      {question.type === registrationTypes.RADIO_TRAFFIC_LIGHT && (
        <div style={props.style} className="type-container">
          {showQuestionTitles && <p className={`title ${isQuestionValid ? "show-validate-title" : ""}`}>{question.title}</p>}
          <TrafficLightRadioGroup
            disabled={showOnly}
            className={isQuestionValid ? "not-valid" : "title"}
            selectedValue={question.answer && question.answer.title}
            options={question.options}
            onSelect={(value) => updateFields({ ...question.answer, title: value }, questionIndex, categoryIndex)}
          />
        </div>
      )}

      {/** CHECKBOX **/}
      {question.type === registrationTypes.CHECKBOX && (
        <div style={props.style} className="type-container">
          {showQuestionTitles && <p className={`title ${isQuestionValid ? "show-validate-title" : ""}`}>{question.title}</p>}
          <CheckboxGroup
            disabled={showOnly}
            className={isQuestionValid ? "not-valid" : "title"}
            selectedValues={question.answer && question.answer.title}
            options={question.options}
            onSelect={(value) => updateFields({ ...question.answer, title: value }, questionIndex, categoryIndex)}
          />
        </div>
      )}

      {/** DATE **/}
      {question.type === registrationTypes.DATE && (
        <div style={props.style} className="type-container">
          {showQuestionTitles && <p className={`title ${isQuestionValid ? "show-validate-title" : ""}`}>{question.title}</p>}
          <DatePicker
            disabled={showOnly}
            className={`input ${isQuestionValid ? "not-valid" : "title"}`}
            name="date"
            // defaultDate={question.answer ? question.answer.title : format(new Date(), "yyyy-MM-dd")}
            defaultDate={question.answer ? question.answer.title : ""}
            onDateChange={(date) => updateFields({ ...question.answer, title: date }, questionIndex, categoryIndex)}
          />
        </div>
      )}

      {/** YES_NO **/}
      {question.type === registrationTypes.YES_NO && (
        <div style={props.style} className="type-container">
          {showQuestionTitles && <p className={`title ${isQuestionValid ? "show-validate-title" : ""}`}>{question.title}</p>}
          <YesAndNoGroup
            disabled={showOnly}
            className={`input ${isQuestionValid ? "not-valid" : "title"}`}
            selectedValue={question.answer && question.answer.title}
            onSelect={(value) => updateFields({ ...question.answer, title: value }, questionIndex, categoryIndex)}
          />
        </div>
      )}

      {/** TIME **/}
      {question.type === registrationTypes.TIME && (
        <div style={props.style} className="type-container">
          {showQuestionTitles && <p className={`title ${isQuestionValid ? "show-validate-title" : ""}`}>{question.title}</p>}
          <TimePicker
            shouldResetTime={question.answer && !question.answer.title && true}
            value={question.answer && question.answer.title}
            className={`input ${isQuestionValid ? "not-valid" : "title"}`}
            selectedValue={question.answer ? question.answer.title : null}
            options={question.options}
            onSelect={(value) => updateFields({ ...question.answer, title: value }, questionIndex, categoryIndex)}
          />
        </div>
      )}

      {/** EXTRA COMMENT **/}
      {question.answer && question.answer.isCommentEnabled && (
        <div style={props.style} className="type-container">
          <label className="title">{lang.commentOptional}</label>
          <TextareaInput
            name="about"
            disabled={false}
            placeholder={lang.typeAnswerHere}
            backgroundColor={colors.ultraLightGrey}
            minHeight={"100px"}
            value={question.answer.answerComment || ""}
            onChange={(e) =>
              updateFields({ ...question.answer, answerComment: e.target.value }, questionIndex, categoryIndex)
            }
          />
        </div>
      )}

      {/** EXTRA IMAGE **/}
      {question.answer && question.answer.isImageEnabled && (
        <div style={props.style} className="type-container">
          <label className="title">Billede (valgfri)</label>
          <ImageUploadMultiple
            id={`image-upload__form-builder__${question.id}`}
            disabledSubmitBtn={disabledSubmitBtn}
            enableSubmitBtn={enableSubmitBtn}
            uploadedFiles={
              question.answer &&
              question.answer.answerImage && [
                { image: question.answer.answerImage, baseURL: question.answer.answerImageBaseURL },
              ]
            }
            shouldResetFiles={question.answer && !question.answer.answerImage && true}
            allowedAmountOfImages={1}
            disableVideoUpload={true}
            showSingleImageMessage={true}
            onFileUpdate={(files) =>
              files.length > 0
                ? updateFields(
                    { ...question.answer, answerImage: files[0].image, answerImageBaseURL: files[0].baseURL },
                    questionIndex,
                    categoryIndex
                  )
                : updateFields(
                    question.answer ? { ...question.answer, answerImage: null, answerImageBaseURL: null } : null,
                    questionIndex,
                    categoryIndex
                  )
            }
          />
        </div>
      )}
    </div>
  );
};

const boxPadding = 0.75;

const componentStyle = () => css`
  div.type-container {
    p.title {
      margin-bottom: 0.5rem;
    }

    .not-valid {
      border: 1px solid ${colors.redMedium};
      border-radius: 5px;
    }

    .show-validate-title {
      color: ${colors.redMedium};
    }
  }

  label {
    display: block;
  }

  .seperator {
    height: 1px;
    background-color: ${colors.midGrey};
    margin: 1.5rem -${boxPadding}rem;

    @media screen and (min-width: ${breakpoints.md}px) {
      margin: 1.5rem 0;
    }
  }

  /* Inline layout */
  &.${registrationQuestionLayoutHints.inline} {
    .type-container {
      display: flex;
      justify-content: space-between;
      align-items: center;
      flex-wrap: wrap;

      & > p.title {
        margin-right: 0.5rem;
        margin-bottom: 0.5rem;
      }

      .input {
        flex-shrink: 1;
        flex-basis: 50%;
        flex-grow: 1;
        margin-bottom: 0.5rem;
      }
    }
  }
`;

export default FormBuilder;
