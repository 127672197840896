// Libs
import React from "react";
import { css } from "emotion";

// Components
import { ChevronRightIcon, AccountIcon, ClockOutlineIcon, CheckIcon } from "mdi-react";

// Styles
import colors from "../../style/colors";

// Utilities and config
import { parseDatetime } from "../../utilities/parse-date";
import breakpoints from "../../config/breakpoints";

const ChecklistItem = (props) => {
  const { title, author, date, completed = false, completedDate = false, style, className, dataTestId, onClick } = props;

  return (
    <div
      data-test-id={dataTestId}
      onClick={onClick}
      className={`${componentStyle({ completed })} ${className}`}
      style={style}
    >
      <div className="content-wrapper">
        <p className="title">{title}</p>
        <div className="info-wrapper">
          <AccountIcon />
          <p>{author.name}</p>
        </div>
        <div className="info-wrapper">
          <ClockOutlineIcon />
          <p>{parseDatetime(date)}</p>
        </div>
        {completedDate && (
          <div className="info-wrapper">
            <CheckIcon />
            <p>{parseDatetime(completedDate)}</p>
          </div>
        )}
      </div>

      {/* SVG arrow-icon to the right */}
      <div className="arrow-container">
        <ChevronRightIcon />
      </div>
    </div>
  );
};

const componentStyle = ({ completed }) => css`
  display: flex;
  justify-content: space-between;
  background-color: ${colors.white};
  border: 1px solid ${colors.midGrey};
  padding: 0.65rem;
  color: ${colors.black};
  margin: auto;
  max-width: ${breakpoints.md}px;
  margin-bottom: -1px;

  &:hover {
    background-color: ${colors.ultraLightGrey};
    cursor: pointer;
    transition: background-color 80ms ease;
  }
  &:active {
    background-color: ${colors.lightGrey};
    transition: background-color 140ms ease;
  }

  &:first-of-type {
    border-radius: 3px 3px 0 0;
  }
  &:last-of-type {
    border-radius: 0 0 3px 3px;
  }

  div.content-wrapper {
    display: flex;
    flex-direction: column;
    overflow: hidden;

    p.title {
      font-size: 1rem;
      margin-bottom: 0.35rem;
      overflow: hidden;
      text-overflow: ellipsis;

      @media screen and (min-width: ${breakpoints.md}px) {
        font-size: 1.125rem;
      }
    }

    div.info-wrapper {
      display: flex;
      align-items: center;
      margin-bottom: 0.35rem;
      color: ${colors.darkGrey};

      &:last-of-type {
        margin-bottom: 0rem;
      }

      svg {
        height: 1rem;
        width: 1rem;
        margin-right: 0.3rem;
      }

      p {
        font-size: 0.875rem;
      }
    }
  }

  div.arrow-container {
    display: flex;
    align-items: center;
    justify-content: flex-end;

    svg {
      fill: ${colors.midDarkGrey};
    }
  }
`;

export default ChecklistItem;
