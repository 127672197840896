import React, { useEffect, useState } from "react";
import { connect, useDispatch, useSelector } from "react-redux";

// Redux actions
import { getPosts, setDataId, clearFeed } from "../../actions/socialActions";
import { showContextMenu } from "../../actions/uiActions";

// Utilities
import getPageFromId from "../../utilities/get-page-from-id";
import pageNavigatorV2 from "../../utilities/page-navigator-v2";

// Components
import ActionWrapper from "../ui/ActionWrapper";
import Page from "../ui/Page";
import TopBar from "../ui/TopBar";
import SocialPost from "./SocialPost";
import ScrollView from "../ui/ScrollView";
import InlineSpinner from "../ui/InlineSpinner";
import { MoreVertIcon, InformationOutlineIcon, ArrowForwardIcon } from "mdi-react";
import StatusBox from "../ui/StatusBox";
import getLinkPathFromPageObject from "../../utilities/get-link-path-from-page-object";

/** `SocialFeed` handles general feed behaviour. This includes fetching posts,
 * displaying error-messages if fetching fails and controlling feed end conditions
 * etc.
 *
 * Note that `SocialFeed` is a view and is meant to be rendered by the router.
 * It doesn't really make sense to use this component directly.
 *
 * @example
 * ```jsx
 * <Route exact key={996} path="/:appname/Social/:pageId" component={SocialFeed} />
 * ```
 *
 */
function SocialSingle(props) {
  const { match } = props;
  const { pages } = useSelector((state) => state.pages);
  const { language: lang } = useSelector((state) => state.language);
  const { loading, error, posts } = useSelector((state) => state.social);
  const [page] = useState(getPageFromId(pages, match.params.pageId));
  const dispatch = useDispatch();

  /** Initializes the first retrieval of posts and subscribe to the resize event*/
  useEffect(() => {
    dispatch(clearFeed());
    dispatch(setDataId(page.dataId));
    dispatch(getPosts({ postId: match.params.postId }));

    // eslint-disable-next-line
  }, []);

  function goToSocial() {
    pageNavigatorV2({ path: getLinkPathFromPageObject(page, "absolute"), direction: "forward", pageId: page.id });
  }

  return (
    <Page>
      <TopBar
        useDefaultBackButton={true}
        title={`Post from ${page.title}`}
        actionRight={
          <ActionWrapper
            onClick={() =>
              dispatch(
                showContextMenu([
                  {
                    title: `Gå til ${page.title}`,
                    icon: <ArrowForwardIcon />,
                    callback: goToSocial,
                  },
                ])
              )
            }
          >
            <MoreVertIcon />
          </ActionWrapper>
        }
      />

      <ScrollView style={{ padding: "1rem 0" }} id="social-scroll-view">
        {/* Spinner */}
        {loading && !error && <InlineSpinner data-test-id="social-spinner" />}

        {/* Error */}
        {!loading && error && <StatusBox />}

        {/* Posts */}
        {!loading && !error && posts.length > 0 && <SocialPost post={posts[0]} key={`social-post-${posts[0].id}`} />}

        {!loading && !error && (
          <StatusBox
            style={{ marginTop: "3rem" }}
            icon={<InformationOutlineIcon />}
            title={lang.thisIsASearchResult}
            content={lang.thePostIsFrom__placeholder__.replace(/{{placeholder}}/gi, page.title)}
            action={{
              title: lang.visit__placeholder__.replace(/{{placeholder}}/gi, page.title),
              callback: goToSocial,
            }}
          />
        )}
      </ScrollView>
    </Page>
  );
}

const mapStateToProps = (state) => ({
  pages: state.pages.pages,
  lang: state.language.language,
  posts: state.social.posts,
  endOfFeed: state.social.endOfFeed,
  postsLoading: state.social.loading,
  postsError: state.social.error,
});

export default connect(mapStateToProps)(SocialSingle);
