// Libs
import React, { Fragment, useEffect, useState } from "react";
import { useSelector } from "react-redux";
import { css } from "emotion";

// Components
import Page from "../ui/Page";
import TopBar from "../ui/TopBar";
import ScrollView from "../ui/ScrollView";
import ListItem from "../ui/ListItem";
import InlineSpinner from "../ui/InlineSpinner";
import StatusBox from "../ui/StatusBox";

// Utilities and config
import getProfilePictureFromUserObject from "../../utilities/get-profile-picture-from-user-object";
import breakpoints from "../../config/breakpoints";

// Styles
import { InfoOutlineIcon } from "mdi-react";

// Hooks
import useAuthorModal from "../../hooks/useAuthorModal";
import req from "../../utilities/request-utility";
import { addDays, format, getISOWeek, parse, startOfWeek } from "date-fns";
import getUserLocale from "../../utilities/get-user-locale";
import colors from "../../style/colors";

function getStartOfCurrentWeekDate() {
  return format(startOfWeek(new Date(), { weekStartsOn: 1 }), "yyyyMMdd"); // Week starts on mondays (1) instead of sundays (0)
}

const Schedule = (props) => {
  const { groupId } = props.match.params;
  const primaryColor = useSelector((state) => state.appConfig.primaryColor);
  const [pageTitle, setPageTitle] = useState("...");
  const authorModal = useAuthorModal();
  const [fetchDateStart, setFetchDateStart] = useState(getStartOfCurrentWeekDate());
  const [fetchDateEnd, setFetchDateEnd] = useState(format(addDays(new Date(), 7), "yyyyMMdd"));
  const { language: lang } = useSelector((state) => state.language);
  const user = useSelector((state) => state.auth.user);

  const [schedule, setSchedule] = useState({ loading: false, error: false, endOfFeed: false, data: [] });

  function getGroupTitle() {
    // Get schedule groups for title
    req()(`schedules/groups`)
      .then(({ data: groups }) => {
        setPageTitle(groups.filter((group) => group.id === groupId)[0].title);
      })
      .catch(() => {
        setPageTitle("Schedule");
      });
  }

  function getScheduleData() {
    if (schedule.loading || schedule.endOfFeed) return;

    setSchedule((prevState) => ({ ...prevState, loading: true }));

    req()(`schedules/groups/${groupId}/shifts?startDate=${fetchDateStart}&endDate=${fetchDateEnd}`)
      .then(({ data }) => {
        // The next start fetch date is the day after the previous last day (if previous call ended 21/8 the next one beghins from 22/8)
        setFetchDateStart(format(addDays(parse(fetchDateEnd, "yyyyMMdd", 0), 1), "yyyyMMdd"));
        setFetchDateEnd(format(addDays(parse(fetchDateEnd, "yyyyMMdd", 0), 8), "yyyyMMdd"));
        setSchedule((prevState) => ({
          ...prevState,
          error: false,
          loading: false,
          endOfFeed: data.length === 0 ? true : false,
          data: [...prevState.data, ...data],
        }));
      })
      .catch(() => {
        setSchedule((prevState) => ({ ...prevState, error: true, loading: false }));
      });
  }

  // Get the groups
  useEffect(() => {
    getGroupTitle();
    getScheduleData();

    // Get schedules to display
  }, []);

  /**
   * This function might seem a bit overly complex and it might be.. So i will try to explain what it does :)
   * When we receive the shifts from the api they are a flat list. ie: [<shift1>, <shift2>, <shift3>, etc...]
   * The layout we want is something like:
   *
   *   Week 1
   *     Monday
   *     - Shift 1
   *     - Shift 2
   *     Tuesday
   *     - Shift 3
   *     - Shift 4
   *     ...rest of week
   *   Week 2
   *     Thursday
   *     - Shift 5
   *     - Shift 6
   *     Friday
   *     - Shift 7
   *     - Shift 8
   *
   * So when we iterate over the schedule we save a reference to the last used weekNumber and the last used date.
   * If the current schedule-entry's date differs from the previously used date, we will render a date-header and
   * do likewise with the weeknumer-header and update the previously used header.
   *
   * This solution is quite flexible and
   * - doesn't require every week to have shifts.
   * - doesn't require a time interval when fetching (we are doing so anyways though)
   *
   * I am not a big fan of shorthands but i am using:
   * prevDate and prevWeekNumber to mark the previously used date and week number as well as
   * curDate and curWeekNumber to mark the data in the current iteration
   *
   */
  function scheduleRenderer(scheduleData) {
    // Keep reference of last rendered date and weekNr
    let prevDate, prevWeekNumber;

    return () => {
      // map over schedule data and render list item and potentially also render headings for weeks and dates
      return scheduleData.map((scheduleEntry) => {
        // Current entry's data
        let curDate = scheduleEntry.date;
        let curWeekNumber = getISOWeek(parse(curDate, "yyyyMMdd", 0), { weekStartsOn: 1 });

        // Decides if we should render a week number in this iteration
        let renderWeekNumber = false;
        if (prevWeekNumber !== curWeekNumber) {
          renderWeekNumber = true;
          prevWeekNumber = curWeekNumber; // Update reference for next iteration
        }

        // Decides if we should render the date in this iteration
        let renderDate = false;
        if (prevDate !== curDate) {
          renderDate = true;
          prevDate = curDate; // Update reference for next iteration
        }

        function getSubTitle(scheduleEntry) {
          let comment;

          /* prettier-ignore */
          if (scheduleEntry.comment) {
            comment = <>{" - "}<span style={{ fontWeight: 700, color: colors.orange }}>{scheduleEntry.comment}</span></>;
          } else if (scheduleEntry.activity) {
            comment = <>{" - "} <span style={{ fontWeight: 700, color: colors.green }}>{scheduleEntry.activity}</span></>;
          } else if (scheduleEntry.actualShiftActivity) {
            comment = <>{" - "} <span style={{ fontWeight: 700, color: colors.red }}>{scheduleEntry.actualShiftActivity}</span></>;
          }

          /* prettier-ignore */
          return <>{scheduleEntry.startTime} - {scheduleEntry.endTime}{comment}</>;
        }

        return (
          <Fragment key={`${scheduleEntry.date}_${scheduleEntry.startTime}_${scheduleEntry.user.name}`}>
            {/* Week header (is rendered when week changes in data) */}
            {renderWeekNumber && (
              <p className="week">
                {lang.week} {curWeekNumber}
              </p>
            )}

            {/* Date header (is rendered when date changes in data) */}
            {renderDate && (
              <p className="date">{format(parse(curDate, "yyyyMMdd", 0), "eeee d. LLLL", getUserLocale(user))}</p>
            )}

            {/* Shifts */}
            <ListItem
              maxWidth={breakpoints.md}
              clickable={scheduleEntry.user.id ? true : false}
              onClick={() => (scheduleEntry.user.id ? authorModal(scheduleEntry.user.id) : null)}
              title={scheduleEntry.user.name}
              subTitle={getSubTitle(scheduleEntry)}
              imageLeft={getProfilePictureFromUserObject(scheduleEntry.user, undefined, { marginRight: "0.6rem" })}
            />
          </Fragment>
        );
      });
    };
  }

  return (
    <Page>
      <TopBar title={pageTitle} useDefaultBackButton={true} />
      <ScrollView onScrollEnd={getScheduleData}>
        <div className={componentStyle(primaryColor)}>
          {/* This is the actual rendering */}
          {scheduleRenderer(schedule.data)()}

          {/** Loading **/}
          {schedule.loading && !schedule.error && !schedule.endOfFeed && (
            <InlineSpinner style={{ margin: "2rem 0" }} title={`${lang.loading} ${lang.shifts.toLowerCase()}...`} />
          )}

          {/** End of feed with shifts **/}
          {schedule.data.length > 0 && schedule.endOfFeed && (
            <StatusBox
              style={{ margin: "2rem 0 5rem 0" }}
              icon={<InfoOutlineIcon />}
              title={lang.noMoreshifts}
              content={lang.reachedEndOfFeed}
            />
          )}

          {/** End of feed without shifts **/}
          {schedule.data.length === 0 && schedule.endOfFeed && (
            <StatusBox
              style={{ margin: "2rem 0 5rem 0" }}
              icon={<InfoOutlineIcon />}
              title={lang.noShiftsCreatedYet}
              content={lang.comeBackSoon}
            />
          )}
        </div>
      </ScrollView>
    </Page>
  );
};

const componentStyle = (primaryColor) => css`
  max-width: ${breakpoints.md}px;
  margin: auto;
  padding-top: 1rem;

  p.week {
    font-size: 1.7rem;
    margin: 5rem 0 1rem 0;
    color: ${primaryColor};
    font-weight: 700;

    &:first-of-type {
      margin-top: 1rem;
    }
  }

  p.date {
    font-size: 1.25rem;
    margin: 2rem 0 1rem 0;
    color: ${primaryColor};
  }

  @media screen and (max-width: ${breakpoints.md + 20}px) {
    p.week,
    p.date {
      padding: 0 1rem;
    }
  }
`;

export default Schedule;
