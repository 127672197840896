import React, { useState, useEffect, useRef } from "react";
import { connect } from "react-redux";
import { bindActionCreators } from "redux";
import { CSSTransition } from "react-transition-group";
import Hammer from "react-hammerjs";
import TinyAnimate from "TinyAnimate";
import Impetus from "impetus";

// Actions
import { hideLightBox } from "../../actions/uiActions";

//Style
import { css } from "emotion";
import colors from "../../style/colors";

// Icons
import { CloseIcon, ArrowLeftIcon, ArrowRightIcon } from "mdi-react";

// components
import Page from "./Page";
import InlineSpinner from "./InlineSpinner";
import ActionWrapper from "../ui/ActionWrapper";

function Lightbox(props) {
  const { active, closeCallback, images, index = 0 } = props.Lightbox;
  const { hideLightBox, lang } = props;

  const [loading, setLoading] = useState(true);
  const [imageError, setImageError] = useState(false);
  const [activeImage, setActiveImage] = useState(0);

  // State
  const [zoom, setZoom] = useState(1);
  const [translateX, setTranslateX] = useState(0);
  const [translateY, setTranslateY] = useState(0);
  const [dataOnPinchStart, setDataOnPinchStart] = useState();

  const [impetusEl] = useState(() => {
    let impetus = new Impetus({
      update: (x, y) => {
        setTranslateX(x);
        setTranslateY(y);
      },
    });
    impetus.pause();
    return impetus;
  });
  const [fittedImageSize, setFittedImageSize] = useState({ width: "auto", height: "auto", ready: false });

  const imageRef = useRef();

  // Config
  const MIN_SCALE = 1;
  let MAX_SCALE = 3;
  const ANIMATION_DURATION = 280;

  useEffect(() => {
    if (active) {
      window.addEventListener("resize", updateBounds);
      window.addEventListener("resize", onImageLoad);
      setZoom(1);
      setTranslateX(0);
      setTranslateY(0);
      updateBounds();
      impetusEl.setValues(0, 0);
    } else {
      impetusEl.pause();
    }
    return () => {
      setLoading(true);
      setImageError(false);
      window.removeEventListener("resize", updateBounds);
      window.removeEventListener("resize", onImageLoad);
    };
    // eslint-disable-next-line
  }, [active]);

  useEffect(() => {
    setActiveImage(index);
    // eslint-disable-next-line
  }, [index, images]);

  /********************** Functionality ***********************/
  function zoomInWithTap(e) {
    // Get center of doubletaps
    let touchPos = e.center;

    // Get position of image
    let imageData = imageRef.current.getBoundingClientRect();

    // Calculate relatve offsets
    let offsetLeft = touchPos.x - imageData.left;
    let offsetTop = touchPos.y - imageData.top;

    // Convert them to percentage
    let relativeLeft = offsetLeft / imageData.width; // 0-1
    let relativeTop = offsetTop / imageData.height; // 0-1

    // Get new size for image
    let naturalWidth = imageRef.current.naturalWidth;
    let naturalHeight = imageRef.current.naturalHeight;

    // Checks if image can be scaled by MAX_SCALE factor without pixelating. If it cant it will use
    // the image's original size (NATURAL_WIDTH and NATURAL_HEIGHT)
    let newWidth, newHeight, newScale;
    if (imageData.width * MAX_SCALE < naturalWidth && imageData.height * MAX_SCALE < naturalHeight) {
      newWidth = imageData.width * MAX_SCALE;
      newHeight = imageData.height * MAX_SCALE;
      newScale = MAX_SCALE;
    } else {
      newWidth = naturalWidth;
      newHeight = naturalHeight;
      newScale = naturalWidth / imageData.width;
    }

    // Calculate new translate values
    let newTranslateX = restrictTranslateX(newWidth * (0.5 - relativeLeft), newWidth);
    let newTranslateY = restrictTranslateY(newHeight * (0.5 - relativeTop), newHeight);

    // Amimate to new zoom position
    TinyAnimate.animate(MIN_SCALE, newScale, ANIMATION_DURATION, (s) => setZoom(s), "easeInOutQuad");
    TinyAnimate.animate(translateX, newTranslateX, ANIMATION_DURATION, (x) => setTranslateX(x), "easeInOutQuad");
    TinyAnimate.animate(translateY, newTranslateY, ANIMATION_DURATION, (y) => setTranslateY(y), "easeInOutQuad");

    // Wait for zoom animation
    setTimeout(() => {
      updateBounds();
      impetusEl.setValues(newTranslateX, newTranslateY);
      impetusEl.resume();
    }, ANIMATION_DURATION);
  }
  function zoomOutWithTap() {
    // Pause impetus so it doesn't move the image while zooming out and calculating new styles
    impetusEl.pause();

    TinyAnimate.animate(zoom, MIN_SCALE, ANIMATION_DURATION, (s) => setZoom(s), "easeInOutQuad");
    TinyAnimate.animate(translateX, 0, ANIMATION_DURATION, (x) => setTranslateX(x), "easeInOutQuad");
    TinyAnimate.animate(translateY, 0, ANIMATION_DURATION, (y) => setTranslateY(y), "easeInOutQuad");
  }

  /********************** Events ***********************/
  function onDoubleTap(e) {
    if (zoom === MIN_SCALE) {
      zoomInWithTap(e);
    } else {
      zoomOutWithTap();
    }
  }
  function onPinchStart(e) {
    impetusEl.pause();

    // Get center of doubletaps
    let touchPos = e.center;

    // Calculate relatve offsets
    let offsetLeft = touchPos.x;
    let offsetTop = touchPos.y; // - imageData.top;

    // Values used throughout the pinch lifetime
    setDataOnPinchStart({
      initialZoom: zoom,
      initialTranslateX: translateX,
      initialTranslateY: translateY,
      offsetLeft,
      offsetTop,
    });
  }
  function onPinch(e) {
    let { initialZoom, initialTranslateX, initialTranslateY, offsetLeft, offsetTop } = dataOnPinchStart;
    let scale = initialZoom * e.scale;

    /** Limit scale
     *  while allowing temporary overscaling to give a more natural limit than a hard stop
     */
    let extraScale = 0;
    if (scale > MAX_SCALE) {
      extraScale = Math.pow(scale - MAX_SCALE, 0.35);
      scale = MAX_SCALE;
    }

    // Get translate offsets
    let translateXOffset = initialZoom === MIN_SCALE ? (window.innerWidth / 2 - offsetLeft) * (scale + extraScale - 1) : 0;
    let translateYOffset = initialZoom === MIN_SCALE ? (window.innerHeight / 2 - offsetTop) * (scale + extraScale - 1) : 0;

    // Calculate new translate values
    let newTranslateX = e.deltaX + initialTranslateX * e.scale + translateXOffset;
    let newTranslateY = e.deltaY + initialTranslateY * e.scale + translateYOffset;

    // Original solution
    setZoom(scale + extraScale);
    setTranslateX(newTranslateX);
    setTranslateY(newTranslateY);

    // Old solution
    // setTranslateX(e.deltaX + initialTranslateX * e.scale);
    // setTranslateY(e.deltaY + initialTranslateY * e.scale);
  }
  function onPinchEnd(e) {
    /*
      Cases:
      ---
      1) Image is fine
         Conditions
         -> zoom is between min and max
         -> image is within bounds

         Action
         -> Reenable impetus and update bounds

      2) Image is overscaled but within bound
         Conditions:
         -> zoom is more than max
         -> image is within bounds

         Action
         -> scale zoom down to maximum
         -> down-scale transforms by the factor of which the scale is reduced

      3) Image is overscaled but outside of bounds
         -> zoom is more than max
         -> image is not within bounds

         Action
         -> animate zoom down to maximum
         -> find transforms that matches the out of bounds one bests

      4) Image is within scale but is without bounds
         -> zoom is within min and max
         -> image is not within bounds

      5) Image is underscaled
         -> zoom is less than min (and image will always be out of bounds in this case)

         Action
         -> scale image to minimum zoom and reset transforms

      6) No idea how to end up here!
         Action
         -> Reset view?

      ---
      Utilities to make this more readable
      - isOutOfBounds()
      - isOverScaled()
      - isUnderScaled()

    */

    // 1) image is fine
    if (!isOverScaled() && !isUnderScaled() && isWithinBounds()) {
      reenableImpetus();

      // 2) Image is overscaled but within bound
    } else if (isOverScaled() && isWithinBounds()) {
      let newTranslateX = translateX * (MAX_SCALE / zoom);
      let newTranslateY = translateY * (MAX_SCALE / zoom);
      TinyAnimate.animate(zoom, MAX_SCALE, ANIMATION_DURATION, (s) => setZoom(s), "easeInOutQuad");
      TinyAnimate.animate(translateX, newTranslateX, ANIMATION_DURATION, (x) => setTranslateX(x), "easeInOutQuad");
      TinyAnimate.animate(translateY, newTranslateY, ANIMATION_DURATION, (y) => setTranslateY(y), "easeInOutQuad");
      reenableImpetus(ANIMATION_DURATION, { x: newTranslateX, y: newTranslateY });

      // 3) Image is overscaled but outside of bounds
    } else if (isOverScaled() && !isWithinBounds()) {
      let downScaleFactor = MAX_SCALE / zoom;
      let imageData = imageRef.current.getBoundingClientRect();

      // Calculate new translates
      let newTranslateX = restrictTranslateX(translateX, imageData.width * downScaleFactor);
      let newTranslateY = restrictTranslateY(translateY, imageData.height * downScaleFactor);

      TinyAnimate.animate(zoom, MAX_SCALE, ANIMATION_DURATION, (s) => setZoom(s), "easeInOutQuad");
      TinyAnimate.animate(translateX, newTranslateX, ANIMATION_DURATION, (x) => setTranslateX(x), "easeInOutQuad");
      TinyAnimate.animate(translateY, newTranslateY, ANIMATION_DURATION, (y) => setTranslateY(y), "easeInOutQuad");

      reenableImpetus(ANIMATION_DURATION, { x: newTranslateX, y: newTranslateY });

      // 4) Image is underscaled
    } else if (!isOverScaled() && !isUnderScaled() && !isWithinBounds()) {
      let imageData = imageRef.current.getBoundingClientRect();

      let newTranslateX = restrictTranslateX(translateX, imageData.width);
      let newTranslateY = restrictTranslateY(translateY, imageData.height);

      TinyAnimate.animate(translateX, newTranslateX, ANIMATION_DURATION, (x) => setTranslateX(x), "easeInOutQuad");
      TinyAnimate.animate(translateY, newTranslateY, ANIMATION_DURATION, (y) => setTranslateY(y), "easeInOutQuad");
      reenableImpetus(ANIMATION_DURATION, { x: newTranslateX, y: newTranslateY });

      // 5) Image is underscaled
    } else if (isUnderScaled()) {
      TinyAnimate.animate(zoom, MIN_SCALE, ANIMATION_DURATION, (s) => setZoom(s), "easeInOutQuad");
      TinyAnimate.animate(translateX, 0, ANIMATION_DURATION, (x) => setTranslateX(x), "easeInOutQuad");
      TinyAnimate.animate(translateY, 0, ANIMATION_DURATION, (y) => setTranslateY(y), "easeInOutQuad");
      reenableImpetus(ANIMATION_DURATION, { x: 0, y: 0 }, MIN_SCALE);
    }
    function reenableImpetus(delay = 0, translates, newZoom) {
      setTimeout(() => {
        if (translates) {
          impetusEl.setValues(translates.x, translates.y);
        }

        // if newZoom is specified AND new zoom is the same as minimum zoom, don't resume impetus as
        // this means that the image should not be draggable since it isn't zoomed
        if (newZoom && newZoom === MIN_SCALE) {
          return;
        } else {
          updateBounds();
          impetusEl.resume();
        }
      }, delay);
    }
  }
  function onImageLoad() {
    setLoading(false);
    let fittedImageSize = getFittedImageSize();
    setFittedImageSize({
      width: fittedImageSize.width,
      height: fittedImageSize.height,
      ready: true,
    });
  }

  function onImageError() {
    setLoading(false);
    setImageError(true);
  }
  /********************** Utilities ***********************/
  function getBounds(imageData) {
    let bounds = {};

    // Get horizontal bounds
    if (imageData.left > 0) {
      bounds.left = 0;
      bounds.right = 1;
    } else {
      bounds.left = ((imageData.width - window.innerWidth) / 2) * -1;
      bounds.right = (imageData.width - window.innerWidth) / 2;
    }

    // // Get vertical bounds
    if (imageData.top > 0) {
      bounds.top = 0;
      bounds.bottom = 1;
    } else {
      bounds.top = ((imageData.height - window.innerHeight) / 2) * -1;
      bounds.bottom = (imageData.height - window.innerHeight) / 2;
    }

    return bounds;
  }
  function updateBounds() {
    if (!imageRef.current) return;

    let bounds = getBounds(imageRef.current.getBoundingClientRect());
    impetusEl.setBoundX([bounds.left, bounds.right]);
    impetusEl.setBoundY([bounds.top, bounds.bottom]);

    if (bounds.bottom === 1) {
      // There is some obscure edge-case where if the image is overscaled
      // at pinchEnd AND not within bounds in the top, the bottom will be
      // set to 1 instead of whatever the actual value should be. This is
      // some kind of weird bug with getBoundingClientRect()
      setTimeout(updateBounds, 500);
    }
  }
  function getFittedImageSize() {
    /**
     * DOCS: Is a substitute background-position: contain
     * Returns an anonymous object with either a width or height key. Will only
     * scale images to their max-size or the specified max-scale from settings
     *
     * Reimplemtation note: Maybe object-fit could substitute this function?
     */

    // get imageData
    let imageData = imageRef.current.getBoundingClientRect();

    // Get sizes of viewport
    let viewport = {
      width: window.innerWidth,
      height: window.innerHeight,
    };

    // Get sizes of image (natural size)
    let naturalWidth = imageRef.current.naturalWidth;
    let naturalHeight = imageRef.current.naturalHeight;

    // Calculate image ratio to determine which way to scale
    let imageRatio = imageData.width / imageData.height;
    let viewportRatio = viewport.width / viewport.height;

    if (imageRatio > viewportRatio) {
      // Scale by width and leave height as auto
      if (naturalWidth > viewport.width) {
        return { width: viewport.width + "px", height: "initial" };
      } else {
        return { width: naturalWidth + "px", height: "initial" };
      }
    } else {
      // Scale by height and leave width as auto
      if (naturalHeight > viewport.height) {
        return { height: viewport.height + "px", width: "initial" };
      } else {
        return { height: naturalHeight + "px", width: "initial" };
      }
    }
  }
  function restrictTranslateX(translateX, imageWidth) {
    let MAX_newTranslateX = (imageWidth - window.innerWidth) / 2;
    let MIN_newTranslateX = MAX_newTranslateX * -1;

    // No translate is needed
    if (imageWidth <= window.innerWidth) {
      return 0;
    }

    // newTranslateX is too big
    if (translateX >= MAX_newTranslateX) {
      return MAX_newTranslateX;
    }

    // newTranslateX is too small
    if (translateX <= MIN_newTranslateX) {
      return MIN_newTranslateX;
    }

    // Return what was passed to the function
    return translateX;
  }
  function restrictTranslateY(translateY, imageHeight) {
    let MAX_newTranslateY = (imageHeight - window.innerHeight) / 2;
    let MIN_newTranslateY = MAX_newTranslateY * -1;

    // No translate is needed
    if (imageHeight <= window.innerHeight) {
      return 0;
    }

    // newTranslateY is too big
    if (translateY >= MAX_newTranslateY) {
      return MAX_newTranslateY;
    }

    // newTranslateY is too small
    if (translateY <= MIN_newTranslateY) {
      return MIN_newTranslateY;
    }

    // Return what was passed to the function
    return translateY;
  }
  function isOverScaled() {
    if (zoom > MAX_SCALE) {
      return true;
    } else {
      return false;
    }
  }
  function isUnderScaled() {
    if (zoom < MIN_SCALE) {
      return true;
    } else {
      return false;
    }
  }
  function isWithinBounds() {
    let imageData = imageRef.current.getBoundingClientRect();

    // Get calculated max offsets.
    let restrictedTranslates = {
      x: restrictTranslateX(translateX, imageData.width),
      y: restrictTranslateY(translateY, imageData.height),
    };

    // Check translateX
    if (
      // Check X
      // Image is too small to have an offset
      (restrictedTranslates.x === 0 && translateX !== 0) ||
      // Images's offset is too big (to far right)
      (restrictedTranslates.x > 0 && translateX > restrictedTranslates.x) ||
      // Images's offset is too small (to far left)
      (restrictedTranslates.x < 0 && translateX < restrictedTranslates.x) ||
      // Check Y
      // Image is too small to have an offset but has one
      (restrictedTranslates.y === 0 && translateY !== 0) ||
      // Images's offset is too big (to far up)
      (restrictedTranslates.y > 0 && translateY > restrictedTranslates.y) ||
      // Images's offset is too small (to far down)
      (restrictedTranslates.y < 0 && translateY < restrictedTranslates.y)
    ) {
      return false;
    } else {
      return true;
    }
  }

  function getImageURL(image) {
    if (image.baseURL) {
      return `${image.baseURL || ""}w_1400,h_1400,c_limit,q_auto,f_auto/${image.image}`;
    } else {
      return image.image;
    }
  }
  function handleArrowClick(direction) {
    if (direction === "left" && activeImage !== 0) {
      setActiveImage(activeImage - 1);
      reset();
    }
    if (direction === "right" && activeImage < images.length - 1) {
      setActiveImage(activeImage + 1);
      reset();
    }

    function reset() {
      TinyAnimate.animate(zoom, MIN_SCALE, ANIMATION_DURATION, () => setZoom(1), "easeInOutQuad");
      TinyAnimate.animate(translateX, 0, ANIMATION_DURATION, () => setTranslateX(0), "easeInOutQuad");
      TinyAnimate.animate(translateY, 0, ANIMATION_DURATION, () => setTranslateY(0), "easeInOutQuad");
    }
  }

  const closeLightbox = () => {
    // As the lightbox is rendered in App.js and do not unmount, this has to run when the close icon is clicked.
    // The active image is set to 0, so the app doesn't crash if it tried to open up just one image.
    // The timeout is used to not create a flash of the first image whilst the image is fading out.
    setTimeout(() => {
      setActiveImage(0);
    }, 300);
    if (closeCallback) {
      closeCallback(hideLightBox);
    } else {
      hideLightBox();
    }
  };

  return (
    <div className={`${container()} `}>
      <CSSTransition in={active} timeout={300} mountOnEnter={true} unmountOnExit={true} classNames="lightbox">
        <Page backgroundColor={"#0a0a0a"} className={`lightbox-page`}>
          <div className={`actions arrow-container ${images.length === 1 && "hidden"}`}>
            <ActionWrapper
              onClick={() => {
                handleArrowClick("left");
              }}
            >
              <ArrowLeftIcon style={{ opacity: activeImage === 0 ? 0.2 : 1 }} />
            </ActionWrapper>
            <ActionWrapper
              onClick={() => {
                handleArrowClick("right");
              }}
            >
              <ArrowRightIcon style={{ opacity: activeImage === images.length - 1 ? 0.2 : 1 }} />
            </ActionWrapper>
          </div>
          {images.length !== 0 && (
            <Hammer
              onDoubleTap={onDoubleTap}
              onPinchStart={onPinchStart}
              onPinch={onPinch}
              onPinchEnd={onPinchEnd}
              options={{
                recognizers: {
                  pinch: { enable: true },
                },
              }}
            >
              {/* Extra div to enable ref's (https://github.com/JedWatson/react-hammerjs/issues/83) */}
              <div className="image-container">
                <img
                  ref={imageRef}
                  src={getImageURL(images[activeImage])}
                  alt=""
                  onError={onImageError}
                  onLoad={onImageLoad}
                  className={fittedImageSize.ready ? "show" : "hide"}
                  style={{
                    width: fittedImageSize.width,
                    height: fittedImageSize.height,
                    transform: `translate3d(${translateX}px, ${translateY}px, 0px) scale(${zoom})`,
                  }}
                />
                {loading && <InlineSpinner className="spinner-wrapper" color={colors.lightGrey} />}
                {imageError && <p className="image-error-message">{lang.imageDoesNotExist}</p>}
              </div>
            </Hammer>
          )}
          <div className={`actions`}>
            <CloseIcon onClick={closeLightbox} />
          </div>
        </Page>
      </CSSTransition>
    </div>
  );
}

const container = () => css`
  position: absolute;
  top: 0;
  left: 0;
  z-index: 20;

  .spinner-wrapper {
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate3d(-50%, -50%, 0);
  }

  .image-error-message {
    color: ${colors.white};
  }

  &.lightbox-enter {
    opacity: 0;
  }
  &.lightbox-enter-active {
    opacity: 1;
    transition: opacity 300ms;
  }
  &.lightbox-exit {
    opacity: 1;
    transform: scale(1);
  }
  &.lightbox-exit-active {
    opacity: 0;
    transition: opacity 300ms;
  }

  .lightbox-page {
    position: absolute;
    top: 0;
    left: 0;

    &.lightbox-enter {
      opacity: 0;
    }
    &.lightbox-enter-active {
      opacity: 1;
      transition: opacity 300ms;
    }
    &.lightbox-exit {
      opacity: 1;
      transform: scale(1);
    }
    &.lightbox-exit-active {
      opacity: 0;
      transition: opacity 300ms;
    }

    .actions {
      position: absolute;
      width: 100%;
      left: 0;
      top: 0;
      padding: 0.5rem;
      color: ${colors.white};
      opacity: 1;
      transition: opacity 0.3s;

      &.fade-out {
        opacity: 0;
      }

      svg {
        background-color: rgba(0, 0, 0, 0.4);
        border-radius: 50%;
        padding: 0.5rem;
        width: 2.5rem;
        height: 2.5rem;
      }
    }

    .arrow-container {
      top: auto;
      bottom: 0;
      z-index: 10;
      display: flex;
      justify-content: space-around;

      &.hidden {
        display: none;
      }
    }

    .image-container {
      display: flex;
      justify-content: center;
      align-items: center;
      height: 100%;

      img {
        opacity: 0;
        transition: opacity 300ms;

        &.show {
          opacity: 1;
          transition: opacity 300ms;
        }
      }
    }
  }
`;

const mapStateToProps = (state) => ({
  Lightbox: state.ui.Lightbox,
  lang: state.language.language,
});

const mapDispatchToProps = (dispatch) => ({
  hideLightBox: bindActionCreators(hideLightBox, dispatch),
});

export default connect(mapStateToProps, mapDispatchToProps)(Lightbox);
