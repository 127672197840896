import React, { Component, Fragment } from "react";
import { connect } from "react-redux";
import PropTypes from "prop-types";
import { css } from "emotion";
import { bindActionCreators } from "redux";
import { durations } from "../../config/animations";
import breakpoints from "../../config/breakpoints";
import { hideDialog, showDialog } from "../../actions/uiActions";
import req from "../../utilities/request-utility";
import asyncDelay from "../../utilities/async-delay";
import convertNoneMarkdownLinksToMarkdown from "../../utilities/convertNoneMarkdownLinksToMarkdown";

// Components
import ActionWrapper from "./ActionWrapper";
import ImageCarousel from "./ImageCarousel";
import PostComments from "./PostComments";
import PostLikes from "./PostLikes";
import Slider from "./Slider";
import Notification from "./Notification";
import ContextMenuButton from "./ContextMenuButton";

// Style
import colors from "../../style/colors";

// Icons
import {
  ClockOutlineIcon,
  OpenInNewIcon,
  CheckIcon,
  WebIcon,
  PlusIcon,
  AccountCircleIcon,
  GlobeIcon,
  TalkIcon,
  StopIcon,
} from "mdi-react";

// Utilities
import getProfilePicture from "../../utilities/get-profile-picture-from-user-object";
import { parse, format } from "date-fns";
import markdownParser from "../../utilities/markdown-parser";
import RegistrationPosts from "../registration/RegistrationPosts";
import FileViewer from "./FileViewer";
import LikeButton from "./LikeButton";
import CommentButton from "./CommentButton";
import Image from "./Image";
import AnimateHeight from "react-animate-height";
import InlineSpinner from "./InlineSpinner";
import VideoPlayer from "./VideoPlayer";

class Post extends Component {
  constructor(props) {
    super(props);
    this.state = {
      commentsVisible: false,
      likesVisible: false,
      contentMarkDown: "",
      linksFromContent: [],
      isExpanded: false,
      animateReadStatusIn: false,
    };
  }

  componentDidMount() {
    this.setState({ content: this.props.content });
    this.generateLinks();

    /** If the post is already read, the read status will appear with the post. If the post is not read
     * the read-status will animate in after the slider has dissappeared
     */
    if (!this.props.read) {
      this.setState({ animateReadStatusIn: true });
    }
  }

  generateLinks = async () => {
    let { content, enableMarkdownContent } = this.props;

    // If content isn't markdown don't parse links OR If no content is present then don't parse it... duh :-)
    if (!enableMarkdownContent || !content) return;

    let [convertedContent, convertedLinks] = convertNoneMarkdownLinksToMarkdown(content);

    this.setState({ linksFromContent: convertedLinks, contentMarkDown: convertedContent });

    await asyncDelay(1000);

    convertedLinks = await Promise.all(
      convertedLinks.map((link) => {
        return new Promise((resolve, reject) => {
          req()(`meta/og-extractor/?url=${encodeURIComponent(link.url)}`)
            .then(({ data }) => resolve({ ...link, ...data }))
            .catch(() => resolve({ ...link }));
        });
      })
    );

    this.setState({ linksFromContent: [...convertedLinks] });
  };

  getLikeText = (liked, likes) => {
    /**
     * Returns the "N synes godt om"
     * or "Du og N andre"
     * etc.
     */
    let {
      lang,
      // Allows you to change "You like this" into ie. "You think this is useful"
      likeTextIdentifiers = {
        youLikeThis: "youLikeThis",
        youAndOneOtherLikeThis: "youAnd__placeholder__other",
        youAndMultipleOthersLikeThis: "youAnd__placeholder__others",
        oneOrManyOtherLikeThis: "__placeholder__likeThis",
      },
    } = this.props;

    // User liked post and no others have
    if (liked && likes.length === 1) {
      return lang[likeTextIdentifiers.youLikeThis];

      // User liked post and one other
    } else if (liked && likes.length === 2) {
      return lang[likeTextIdentifiers.youAndOneOtherLikeThis].replace("{{placeholder}}", likes.length - 1);

      // Users liked post and several others
    } else if (liked && likes.length > 2) {
      return lang[likeTextIdentifiers.youAndMultipleOthersLikeThis].replace("{{placeholder}}", likes.length - 1);

      // Post is not liked by user
    } else {
      return lang[likeTextIdentifiers.oneOrManyOtherLikeThis].replace("{{placeholder}}", likes.length);
    }
  };

  handleCommentDeletion = (commentId) => {
    let newComments = this.props.comments.filter((comment) => comment.id !== commentId);
    this.props.handleCommentUpdate(this.props.id, newComments);
  };

  handleLinkClick = (link) => {
    const { lang, showDialog } = this.props;
    let dialog = {
      title: lang.warningOpeningLink,
      content: lang.youAreOpening__placeholder__.replace(/{{placeholder}}/gi, link),
      styleType: "neutral",
      primaryActionTitle: lang.open__placeholder__.replace(/{{placeholder}}/gi, link),
      secondaryActionTitle: lang.cancel,
      primaryAction: () => {
        // If link starts with "www" it will be replaced with "http://www"
        // We will let it be up to the externla page to upgrade to https if it supports it
        link = link.replace(/^www/g, "http://www");
        window.open(link, "_blank");
      },
    };
    showDialog(dialog);
  };

  componentDidUpdate(prevProps, prevState, snapshot) {
    if (this.props.content !== prevProps.content) this.generateLinks();
  }

  render() {
    let {
      author,
      approvedBy,
      hideAuthorInApp = false,
      onAuthorClick,
      comments,
      className,
      content,
      isTranslated,
      isTranslating,
      isPlayingAudio,
      isLoadingAudio,
      enableTranslation,
      translateContent,
      readContent,

      contextMenuToggleCallback,
      date,
      disableSwipe,
      documentOwner,
      documents = [],
      animateOut,
      id,
      images,
      liked,
      likes,
      links,
      onLike,
      onCommentLike,
      onSwipeEnd,
      points,
      primaryColor,
      appConfig,
      read,
      readDate,
      readActionLangOverwrite,
      showContextMenuToggle,
      style,
      swipeCompleted,
      title,
      user,
      video,
      youtubeEmbed,
      componentContent,
      enableMarkdownContent = false,
      onCommentShow = null,
      onCommentHide = null,
      // Comments
      loadingComments,
      submittingComment,
      onCommentContextMenu,
      commentsContainerRef,
      // Likes
      hideLikeButton,
      submittingLike,
      submittingLikedOnCommentId,
      isCommentLiked,
      lang,
      // config
      disableInteractionsPreview = false,
      disableLikesAndComments = false,
      // expand/collapse mode
      useExpandCollapseLayout = false,
      expandCollapseToggle = null,
    } = this.props;
    let { commentsVisible, likesVisible, linksFromContent, contentMarkDown } = this.state;

    return (
      <div
        className={`
        ${componentStyles(primaryColor, this.props)}
        ${animateOut ? "animate-out" : ""} 
        ${this.state.isExpanded ? "expanded" : "collapsed"} 
        ${className ? className : ""}
        ${disableInteractionsPreview ? "disable-interactions" : ""}
        `}
        style={style}
        data-test-id={this.props["data-test-id"] || ""}
      >
        {useExpandCollapseLayout === true && (
          <ActionWrapper
            className="expand-collapse-toggle"
            onClick={() => this.setState({ isExpanded: !this.state.isExpanded })}
          >
            {expandCollapseToggle}
            <div style={{ display: "flex", alignItems: "center" }}>
              {!disableSwipe && !read && <Notification notifications={1} />}
              <PlusIcon className="expanded-indicator-icon" />
            </div>
          </ActionWrapper>
        )}
        <AnimateHeight
          height={this.state.isExpanded || useExpandCollapseLayout === false ? "auto" : 0}
          duration={durations.normal}
          animateOpacity={true}
        >
          {/* Title */}
          {title && (
            <h1 style={{ marginBottom: "1rem" }} data-test-id="post__title">
              {title}

              {/* Context menu toggle for posts with a title (news posts) */}
              {showContextMenuToggle && (
                <ContextMenuButton
                  onClick={contextMenuToggleCallback}
                  data-test-id="post-context-menu-toggle"
                  style={{ margin: "-0.35rem -0.35rem 0 0" }}
                />
              )}
            </h1>
          )}

          {/* Meta data - author and date */}
          {((author && author.id) || date) && (
            <div className={`meta-information ${disableInteractionsPreview ? "disable-interactions" : ""}`}>
              <div>
                {!useExpandCollapseLayout && !hideAuthorInApp && author && author.id && date && (
                  <ActionWrapper className="information-item author" onClick={onAuthorClick} data-test-id="btn-post-author">
                    {getProfilePicture(author, 34, { marginRight: "0.5rem" })}
                    <div className="author-and-date-wrapper">
                      <p className="author">{author.name}</p>
                      <p className="date">{date}</p>
                    </div>
                  </ActionWrapper>
                )}

                {!useExpandCollapseLayout && hideAuthorInApp && date && (
                  <div className="information-item author">
                    {getProfilePicture({ profilePicture: appConfig.icon }, 34, { marginRight: "0.5rem" })}
                    <div className="author-and-date-wrapper">
                      <p className="author">{appConfig.title}</p>
                      <p className="date">{date}</p>
                    </div>
                  </div>
                )}

                {!useExpandCollapseLayout && (!author || !author.id) && date && !hideAuthorInApp && (
                  <div className="information-item">
                    <ClockOutlineIcon />
                    <p>{date}</p>
                  </div>
                )}
              </div>

              {/* Context menu toggle for posts without a title (social posts) */}
              {showContextMenuToggle && !title && (
                <ContextMenuButton
                  onClick={contextMenuToggleCallback}
                  data-test-id="post-context-menu-toggle"
                  style={{ margin: "-0.35rem -0.35rem 0 0" }}
                />
              )}
            </div>
          )}

          {approvedBy && documentOwner && (
            <div className="information-item">
              <AccountCircleIcon color={colors.darkGrey} />
              <p>
                Godkendt af: ${approvedBy}. Dokumentejer: ${documentOwner}
              </p>
            </div>
          )}

          {video && <VideoPlayer baseURL={video.baseURL} video={video.video} />}

          {youtubeEmbed && (
            <iframe
              title={title}
              frameborder="0"
              allow="accelerometer; encrypted-media; gyroscope;"
              allowfullscreen
              src={youtubeEmbed}
            ></iframe>
          )}

          {/* Image carousel */}
          {images && images.length > 0 && (
            <ImageCarousel disableLightbox={false} spacingBottom={spacingBottom} boxPadding={boxPadding} images={images} />
          )}

          {/* Component content*/}
          {componentContent && <div>{componentContent}</div>}

          {/* Content */}
          {content && !enableMarkdownContent && (
            <p data-test-id="post__content" className="content">
              {content}
            </p>
          )}

          {content && enableMarkdownContent === true && (
            <div
              data-test-id="post__content"
              className="content-container"
              onClick={(e) => {
                e.preventDefault();
                e.persist();
                if (e.target.tagName === "A") {
                  this.handleLinkClick(e.target.getAttribute("href"));
                }
              }}
              dangerouslySetInnerHTML={markdownParser(contentMarkDown)}
            />
          )}

          {enableTranslation && (
            <div className="translate-and-read-wrapper">
              {isTranslating && (
                <ActionWrapper className="button translate-button">
                  <GlobeIcon /> {lang.translating}...
                </ActionWrapper>
              )}
              {!isTranslating && !isTranslated && (
                <ActionWrapper className="button translate-button" onClick={translateContent}>
                  <GlobeIcon /> {lang.translate}
                </ActionWrapper>
              )}
              {!isTranslating && isTranslated && (
                <ActionWrapper
                  className="button translate-button"
                  onClick={() => translateContent({ explicitlyChangeLanguage: true })}
                >
                  <GlobeIcon /> {lang.changeLanguage}
                </ActionWrapper>
              )}

              <ActionWrapper className="button translate-button" onClick={readContent}>
                {!isPlayingAudio && !isLoadingAudio && (
                  <>
                    <TalkIcon /> {lang.readOutLoud}
                  </>
                )}
                {isPlayingAudio && !isLoadingAudio && (
                  <>
                    <StopIcon /> {lang.stopReading}
                  </>
                )}
                {!isPlayingAudio && isLoadingAudio && (
                  <>
                    <TalkIcon /> {lang.preparingSpeech}...
                  </>
                )}
              </ActionWrapper>
            </div>
          )}

          {/* Documents */}
          {!documents || documents.length === 0 ? null : (
            <div className="attachments-container">
              {documents.map((document) => (
                <FileViewer
                  key={`file-viewer-compontent${document.file}`}
                  href={`${document.baseURL || ""}${document.file}`}
                  title={document.title}
                />
              ))}
            </div>
          )}

          {/* External links */}
          {!links || links.length === 0 ? null : (
            <div className="attachments-container">
              {links.map((link, linkKey) => (
                <a className="attachment" key={linkKey} href={link.URL} target="_blank" rel="noopener noreferrer">
                  <OpenInNewIcon style={{ fill: this.props.primaryColor }} />
                  <p>{link.title}</p>
                </a>
              ))}
            </div>
          )}

          {linksFromContent.length > 0 && (
            <div className="links-container">
              {linksFromContent.map((link) => (
                <ActionWrapper
                  key={`link-key-${link.url}`}
                  style={{ width: "100%" }}
                  onClick={() => this.handleLinkClick(link.url)}
                >
                  <div className="link-container">
                    <div>
                      {link.image ? (
                        <Image src={link.image} />
                      ) : (
                        <div className="image-standin">
                          <WebIcon style={{ fill: this.props.primaryColor }} />
                        </div>
                      )}
                      <p>{link.title ? link.title : link.url}</p>
                    </div>
                    <OpenInNewIcon className="external-link-icon" style={{ fill: this.props.primaryColor }} />
                  </div>
                </ActionWrapper>
              ))}
            </div>
          )}

          {/* Swipe */}
          {!disableSwipe && !read && (
            <Slider
              style={{ marginBottom: "0.5rem" }}
              swipeCompleted={swipeCompleted}
              onSwipeEnd={onSwipeEnd}
              points={points}
              readActionLangOverwrite={readActionLangOverwrite}
            />
          )}

          {/* Read post */}
          {!disableSwipe && read && (
            <p
              className={`read-status meta ${this.state.animateReadStatusIn ? "animate-in" : ""}`}
              style={{ marginBottom: "0.5rem", color: colors.darkGrey }}
            >
              <CheckIcon style={{ width: "1rem", height: "1rem", marginBottom: "-3px" }} />{" "}
              {readActionLangOverwrite ? lang[readActionLangOverwrite] : lang.readAndUnderstood}{" "}
              {format(parse(readDate, "yyyyMMddHHmmss", 0), "dd/MM-yyyy HH:mm:ss")}
            </p>
          )}

          {/* Post actions (Likes, comments, approve, etc.) */}
          {disableLikesAndComments !== true && (likes || comments) && (
            <div className="post-actions">
              {/* Likes */}
              {likes && hideLikeButton !== true && (
                <LikeButton
                  liked={liked}
                  likes={likes}
                  submittingLike={submittingLike}
                  onLike={onLike}
                  likesVisible={likesVisible}
                  getLikeText={() => this.getLikeText(liked, likes)}
                  showComments={() =>
                    this.setState({
                      commentsVisible: false,
                      likesVisible: !likesVisible,
                    })
                  }
                />
              )}

              {hideLikeButton && (
                <p className="meta" style={{ color: colors.darkGrey }}>
                  {this.getLikeText(liked, likes)}
                </p>
              )}

              {/* Comments */}
              {comments && (
                <CommentButton
                  commentsVisible={this.state.commentsVisible}
                  comments={comments}
                  onClick={() => {
                    if (this.state.commentsVisible && onCommentHide) {
                      onCommentHide();
                    } else if (!this.state.commentsVisible && onCommentShow) {
                      onCommentShow();
                    }
                    this.setState({
                      commentsVisible: !commentsVisible,
                      likesVisible: false,
                    });
                  }}
                />
              )}
            </div>
          )}
        </AnimateHeight>

        {/* Likes */}
        <AnimateHeight
          id="comment-animation-wrapper"
          height={
            // This logic is a bit convoluted:
            // likes && likesVisible is sort of the normal logic. This means that if  there are likes and they are visible -> show the likes
            // (useExpandCollapseLayout === false || this.state.isExpanded === true) means that if there are likes and they are visible
            // AND we are either not using the expandCollapseLayout (previously the only option) OR the post is expanded -> show the likes
            likes && likesVisible && (useExpandCollapseLayout === false || this.state.isExpanded === true) ? "auto" : 0
          }
          duration={durations.normal}
          animateOpacity={true}
        >
          <div data-test-id="post-likes-container">
            <PostLikes likes={likes} boxPadding={boxPadding} />
          </div>
        </AnimateHeight>

        {/* Comments */}
        <AnimateHeight
          id="comment-animation-wrapper"
          height={comments && commentsVisible ? "auto" : 0}
          duration={durations.normal}
          animateOpacity={true}
        >
          <PostComments
            postId={id}
            submittingComment={submittingComment}
            onComment={this.props.onComment}
            isCommentLiked={isCommentLiked}
            submittingLikedOnCommentId={submittingLikedOnCommentId}
            onCommentLike={onCommentLike}
            comments={comments}
            loadingComments={loadingComments}
            boxPadding={boxPadding}
            user={user}
            onCommentContextMenu={onCommentContextMenu}
            forwardRef={commentsContainerRef}
          />
        </AnimateHeight>
      </div>
    );
  }
}

const spacingBottom = "0.75";
const boxPadding = "0.65";

const componentStyles = (primaryColor, props) => css`
  background-color: ${colors.white};
  margin-bottom: ${props.useExpandCollapseLayout === true ? "-1px" : "1rem"};
  border-top: 1px ${colors.midGrey} solid;
  border-bottom: 1px ${colors.midGrey} solid;
  white-space: initial;
  padding: ${boxPadding}rem ${boxPadding}rem 0 ${boxPadding}rem;
  overflow: hidden;
  max-height: 100000px;
  transition: max-height ${durations.slow}ms ease, border-width ${durations.slow}ms ease, padding ${durations.slow}ms ease,
    margin ${durations.slow}ms ease, opacity ${durations.slow * 1.5}ms ease, transform ${durations.slow}ms ease;

  @media screen and (min-width: ${breakpoints.md}px) {
    margin: 0 auto ${props.useExpandCollapseLayout === true ? "-1px" : "1rem"} auto;
    border: 1px ${colors.midGrey} solid;
    border-radius: 3px;
    max-width: ${breakpoints.md}px;
  }

  /* Class added when post is deleted */
  &.animate-out {
    max-height: 0px;
    padding-top: 0px;
    padding-bottom: 0px;
    margin-top: 0px;
    margin-bottom: 0px;
    border-width: 0px;
    opacity: 0;
    transform: scale(0.25);
    transform-origin: center center;
  }

  /* Class added if comments, likes or swipe shouldn't be interactive */
  &.disable-interactions {
    touch-action: none;
    pointer-events: none;
  }

  /* Expand  collapse */
  &.collapsed {
    transition: margin ${durations.normal}ms ease;
  }
  &.expanded {
    margin: 0.5rem auto;
    transition: margin ${durations.normal}ms ease;

    &:first-of-type {
      margin-top: 0;
    }

    .expand-collapse-toggle svg.expanded-indicator-icon {
      transform: rotate(-45deg);
      fill: ${primaryColor};
    }
  }

  .expand-collapse-toggle {
    display: flex;
    justify-content: space-between;
    align-items: center;
    width: calc(100% + ${boxPadding * 2}rem);
    margin: -${boxPadding}rem -${boxPadding}rem 0 -${boxPadding}rem;
    padding: ${boxPadding}rem;

    svg.expanded-indicator-icon {
      margin-left: 0.5rem;
      transition: transform ${durations.normal}ms ease;
      transform: rotate(0deg);
      color: ${colors.darkGrey};
    }
  }

  /* Header */
  h1 {
    margin-bottom: ${spacingBottom}rem;
    display: flex;
    align-items: flex-start;
    justify-content: space-between;
    color: ${primaryColor};
    font-weight: 700;
  }

  .contextMenuToggle {
    width: 1.25rem;
    height: 1.25rem;
    margin-right: -0.3rem;
    flex-shrink: 0;
    color: ${colors.black};
  }

  /* Video tag */
  video {
    background-color: ${colors.black};
    width: 100%;
    border-radius: 3px;
    border: 1px ${colors.lightGrey} solid;
    margin-bottom: 1rem;
    max-height: 50vw;

    &:focus {
      outline: 0;
    }
  }

  /* Youtube embed tag */
  iframe {
    background-color: ${colors.black};
    width: 100%;
    height: 350px;
    border-radius: 3px;
    border: 1px ${colors.lightGrey} solid;
    margin-bottom: 1rem;

    &:focus {
      outline: 0;
    }
  }

  /* Meta */
  .meta-information {
    display: flex;
    align-items: flex-start;
    justify-content: space-between;
    margin-bottom: ${spacingBottom * 1}rem;

    & > div {
      display: flex;
      align-items: center;
      flex-wrap: wrap;

      .information-item {
        display: flex;
        align-items: center;
        margin-right: 1rem;
        margin-bottom: ${spacingBottom * 0.5}rem;

        .author-and-date-wrapper {
          text-align: left;

          p.author {
            color: ${colors.trueBlack};
            margin: -1px 0 0.15rem 0;
            font-size: 0.9rem;
          }
          p.date {
            color: ${colors.midDarkGrey};
            font-size: 0.8rem;
          }
        }

        & > div {
          margin-bottom: 0px !important;
        }

        svg {
          width: 1rem;
          height: 1rem;
          color: ${colors.darkGrey};
          margin-right: 0.5rem;
          flex-shrink: 0;
        }

        p {
          font-size: 0.9rem;
          color: ${colors.darkGrey};
        }
      }
    }
  }

  /* Content */
  .content {
    margin-bottom: ${spacingBottom * 2}rem;
    line-height: 1.3;
    color: ${colors.black};
    white-space: pre-wrap;

    a {
      color: ${primaryColor};
      text-decoration: underline;
    }
  }

  .content-container {
    margin-bottom: ${spacingBottom * 2}rem;
    line-height: 1.5;
    color: ${colors.black};
    user-select: text;

    *::selection {
      background: ${primaryColor};
      color: ${colors.white};
      border-radius: 3px;
    }

    a {
      color: ${primaryColor};
      text-decoration: underline;
    }

    p {
      white-space: break-spaces;
    }

    p,
    ul,
    li {
      margin-bottom: 0.5rem;
    }

    h1,
    h2,
    h3,
    h4,
    h5 {
      margin-top: 1.5rem;
      margin-bottom: 0.65rem;
      &:first-child {
        margin-top: 0rem;
      }
    }

    ul {
      list-style-type: disc;
      margin-left: 1.5rem;
    }

    ol {
      margin-left: 1.5rem;
    }

    h1 {
      font-size: 1.15rem;
      color: ${primaryColor};
      font-weight: 700;
    }
    h2 {
      font-size: 1.1rem;
      color: ${primaryColor};
      font-weight: 700;
    }
    h3 {
      font-size: 1.1rem;
      color: ${primaryColor};
      font-weight: 700;
    }
    h4 {
      font-size: 1rem;
      color: ${primaryColor};
      font-weight: 700;
    }
  }

  /* Documents amd links */
  .attachments-container {
    border: 1px ${colors.midGrey} solid;
    border-radius: 3px;
    margin-bottom: ${spacingBottom}rem;
    overflow: hidden;

    .attachment {
      text-decoration: none;
      display: block;
      color: ${colors.darkGrey};
      border-bottom: 1px ${colors.midGrey} solid;
      padding: 0.5rem;
      position: relative;
      transition: background-color 180ms ease;

      &:last-child {
        border-bottom: 0px;
      }

      &:active {
        background-color: ${colors.lightGrey};
        transition: background-color 180ms ease;
      }

      p {
        padding-left: 1.75rem;
      }

      svg {
        position: absolute;
        top: 50%;
        left: 0.6rem;
        transform: translateY(-50%);
        width: 1rem;
        height: 1rem;
        vertical-align: middle;
        fill: ${colors.darkGrey};
      }
    }
  }

  /* Links */
  .links-container {
    border: 1px solid ${colors.midGrey};
    border-radius: 3px;
    margin-bottom: 1rem;

    button {
      border-bottom: 1px solid ${colors.midGrey};
      border-bottom-left-radius: 0px;
      border-bottom-right-radius: 0px;

      &:last-child {
        border-bottom: none;
      }
    }

    .link-container {
      display: flex;
      justify-content: space-between;
      align-items: center;
      padding: 5px;

      > div {
        display: flex;
        align-items: center;
      }

      img,
      .image-standin {
        width: 40px;
        min-width: 40px;
        height: 40px;
        margin-right: 0.5rem;
        border-radius: 3px;
        flex-shrink: 0;
      }

      p {
        font-size: 0.9rem;
        max-height: 38px; // Sort of magic number here!
        overflow: hidden;
      }

      .image-standin {
        position: relative;
        background-color: ${colors.lightGrey};
        border-radius: 3px;

        svg {
          top: calc(50%);
          left: calc(50%);
          transform: translate3d(-50%, -50%, 0);
          position: absolute;
        }
      }

      img {
        object-fit: cover;
      }

      svg.external-link-icon {
        flex-shrink: 0;
        width: 1rem;
        height: 1rem;
        margin-right: 0.5rem;
      }
    }
    :last-child {
      .link-container {
        border-bottom: none;
      }
    }
  }

  p.read-status {
    &.animate-in {
      line-height: 42px;
      opacity: 0;
      animation-delay: 200ms;
      animation: read-status-enter 600ms ease forwards;
    }

    @keyframes read-status-enter {
      0% {
        line-height: 42px;
        opacity: 0;
        transform: translateX(30px) scale(0.8);
      }
      100% {
        line-height: 10px;
        opacity: 1;
        transform: translateX(0px) scale(1);
      }
    }
  }

  /* Post-actions (like, comments, approve etc. */
  .post-actions {
    display: flex;
    justify-content: space-between;
    margin-top: 1rem;
    margin-bottom: ${boxPadding}rem;
  }

  .translate-and-read-wrapper {
    margin-top: -0.5rem;
    margin-bottom: 1rem;

    .button {
      color: ${colors.darkGrey};
      display: inline-block;
      font-size: 0.875rem;
      padding: 0.15rem 0.25rem;
      margin-left: -0.25rem;
      margin-right: 0.65rem;

      svg {
        margin: 0 0.3rem -2px 0;
        width: 0.9rem;
        height: 0.9rem;
        color: ${colors.darkGrey};
      }
    }
  }
`;

Post.propTypes = {
  /** Bool value which decides whether or not the contextmenu is visible */
  showContextMenuToggle: PropTypes.bool,
  /** Function to be executed when contextmenu, eg. you should pass an instance of reduxaction showcontextmenu to this */
  contextMenuToggleCallback: PropTypes.func,
  /** Main title of element */
  title: PropTypes.string,
  /** Array of images to be shown in the element */
  images: PropTypes.array,
  /** Creator of the post */
  author: PropTypes.object,
  /** Creation date of the post */
  date: PropTypes.string,
  /** Numerical value which tells how many points a swipe action gives */
  points: PropTypes.number,
  /** Text body of the element */
  // content: PropTypes.string,
  /** Related documents to the post */
  documents: PropTypes.array,
  /** Related links to the post */
  links: PropTypes.array,
  /** Bool value which defines if post should be swiped to confirm read-status */
  disableSwipe: PropTypes.bool,
  /** Bool value which defines if post is read or not */
  read: PropTypes.bool,
  /** Array of objects of users that have liked the post */
  likes: PropTypes.array,
  /** Array of comments related to post */
  comments: PropTypes.array,
  /** Bool value which decides if likes are visible */
  likesVisible: PropTypes.bool,
  /** Bool value which tells if specific user has liked post*/
  liked: PropTypes.bool,
  /** Function to be executed when post is "swiped" */
  onSwipeEnd: PropTypes.func,
  /** Bool value which decides if comments are visible */
  commentsVisible: PropTypes.bool,
  /** Bool value which decides whether or not the post viewing a preview and thereby a non-interactive edition of the post */
  disableInteractionsPreview: PropTypes.bool,
};

RegistrationPosts.defaultProps = {
  hideLikeButton: false,
};

const mapStateToProps = (state) => ({
  appConfig: state.appConfig,
  primaryColor: state.appConfig.primaryColor,
  lang: state.language.language,
});

const mapDispatchToProps = (dispatch) => ({
  showDialog: bindActionCreators(showDialog, dispatch),
  hideDialog: bindActionCreators(hideDialog, dispatch),
});
export default connect(mapStateToProps, mapDispatchToProps, null, { forwardRef: true })(Post);
