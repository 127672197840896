import React from "react";
import { css } from "emotion";
import { BackspaceIcon } from "mdi-react";
import colors from "../../style/colors";
import { CSSTransition } from "react-transition-group";
import { durations } from "../../config/animations";
import ActionWrapper from "./ActionWrapper";

const Numpad = (props) => {
  const { active } = props;
  return (
    <CSSTransition in={active} timeout={durations.normal} mountOnEnter={true} unmountOnExit={true} classNames="numpad">
      <table className={componentStyle()}>
        <tbody>
          <tr>
            <td>
              <ActionWrapper className="key-wrapper" data-test-id="numpad-key_1" onClick={() => props.onKeyPress("1")}>
                1
              </ActionWrapper>
            </td>
            <td>
              <ActionWrapper className="key-wrapper" data-test-id="numpad-key_2" onClick={() => props.onKeyPress("2")}>
                2
              </ActionWrapper>
            </td>
            <td>
              <ActionWrapper className="key-wrapper" data-test-id="numpad-key_3" onClick={() => props.onKeyPress("3")}>
                3
              </ActionWrapper>
            </td>
          </tr>
          <tr>
            <td>
              <ActionWrapper className="key-wrapper" data-test-id="numpad-key_4" onClick={() => props.onKeyPress("4")}>
                4
              </ActionWrapper>
            </td>
            <td>
              <ActionWrapper className="key-wrapper" data-test-id="numpad-key_5" onClick={() => props.onKeyPress("5")}>
                5
              </ActionWrapper>
            </td>
            <td>
              <ActionWrapper className="key-wrapper" data-test-id="numpad-key_6" onClick={() => props.onKeyPress("6")}>
                6
              </ActionWrapper>
            </td>
          </tr>
          <tr>
            <td>
              <ActionWrapper className="key-wrapper" data-test-id="numpad-key_7" onClick={() => props.onKeyPress("7")}>
                7
              </ActionWrapper>
            </td>
            <td>
              <ActionWrapper className="key-wrapper" data-test-id="numpad-key_8" onClick={() => props.onKeyPress("8")}>
                8
              </ActionWrapper>
            </td>
            <td>
              <ActionWrapper className="key-wrapper" data-test-id="numpad-key_9" onClick={() => props.onKeyPress("9")}>
                9
              </ActionWrapper>
            </td>
          </tr>
          <tr>
            <td>
              <ActionWrapper
                className="key-wrapper"
                data-test-id="numpad-key_delete"
                onClick={() => props.onKeyPress("delete")}
              >
                <BackspaceIcon />
              </ActionWrapper>
            </td>
            <td>
              <ActionWrapper className="key-wrapper" data-test-id="numpad-key_0" onClick={() => props.onKeyPress("0")}>
                0
              </ActionWrapper>
            </td>
            <td>
              <ActionWrapper className="key-wrapper" data-test-id="numpad-key_+" onClick={() => props.onKeyPress("+")}>
                +
              </ActionWrapper>
            </td>
          </tr>
        </tbody>
      </table>
    </CSSTransition>
  );
};

const componentStyle = () => css`
  overflow: hidden;
  border-collapse: collapse;
  background-color: ${colors.white};
  width: 100%;
  height: 40vh;
  max-height: 300px;
  min-height: 120px;
  box-shadow: 0 0 3px rgba(0, 0, 0, 0.07);
  position: absolute;
  bottom: 0;

  @media screen and (min-width: 1000px) {
    width: 800px;
    margin: 0 auto 1rem auto;
    border: 1px ${colors.lightGrey} solid;
    border-radius: 4px;
    box-shadow: 0px 8px 13px rgba(0, 0, 0, 0.1);
  }

  &.numpad-enter {
    opacity: 0;
    transform: translate3d(0, 100%, 0);
  }
  &.numpad-enter-active {
    opacity: 1;
    transform: translate3d(0, 0px, 0);
    transition: opacity ${durations.normal}ms, transform ${durations.normal}ms;
  }
  &.numpad-exit {
    opacity: 1;
    transform: translate3d(0, 0px, 0);
  }
  &.numpad-exit-active {
    opacity: 0;

    transform: translate3d(0, 100%, 0);
    transition: opacity ${durations.normal}ms, transform ${durations.normal}ms;
  }

  td {
    border: 1px blue solid;
    width: 33%;
    height: 25%;
    border: 1px ${colors.lightGrey} solid;
    border-top: 1px ${colors.midLightGrey} solid;
  }
  .key-wrapper {
    width: 100%;
    height: 100%;
    text-align: center;
    font-family: "Open Sans", sans-serif;
    font-weight: 700;
    color: ${colors.black};
    font-size: 1rem;

    &:focus {
      background-color: initial;
    }

    &:hover {
      background-color: rgba(0, 0, 0, 0.02);
    }
    &:active {
      background-color: rgba(0, 0, 0, 0.05);
    }
  }

  svg {
    color: ${colors.darkGrey};
  }
`;

export default Numpad;
