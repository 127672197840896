// Libs
import React from "react";
import { css } from "emotion";
import ActionWrapper from "./ActionWrapper";

import { ReplyIcon } from "mdi-react";
import colors from "../../style/colors";
import { useSelector } from "react-redux";

const CommentOnCommentButton = (props) => {
  // Props
  const { isCommentingOnComment, disableInteractionComment, onShowForm } = props;

  // Redux state
  const { primaryColor } = useSelector((state) => state.appConfig);

  return (
    <div
      className={`${componentStyle(props, primaryColor)} ${props.className ? props.className : ""}`}
      style={disableInteractionComment ? { touchAction: "none", pointerEvents: "none" } : {}}
    >
      <ActionWrapper
        className={`comment-wrapper ${isCommentingOnComment ? "shown" : "hidden"}`}
        onClick={onShowForm}
        data-test-id={isCommentingOnComment ? "shown" : "hidden"}
      >
        <ReplyIcon />
        <p>{props.title}</p>
      </ActionWrapper>
    </div>
  );
};

const height = 24;

const componentStyle = (props, primaryColor) => css`
  .comment-wrapper {
    display: flex;
    border-radius: ${height / 2}px;
    height: ${height}px;
    padding: 0.2rem 0.2rem;
    border: 1px solid ${colors.midGrey};
    align-items: center;
    justify-content: center;
    position: relative;
    overflow: hidden;

    p {
      /* line-height: ${height}px; */
      transition: color 220ms ease;
      font-size: 0.75rem;
      margin-right: 0.5rem;
    }

    svg {
      transition: color 220ms ease;
      width: 1rem;
      height: 1rem;
    }

    &.shown {
      background-color: ${primaryColor};
      border-color: ${primaryColor};

      p {
        color: ${colors.white};
      }

      svg {
        fill: ${colors.white};
      }
    }

    &.hidden {
      background-color: ${colors.white};

      p {
        color: ${colors.darkGrey};
      }

      svg {
        fill: ${colors.darkGrey};
      }
    }
  }
`;

export default CommentOnCommentButton;
