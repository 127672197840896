// Libs
import React, { useEffect, useState } from "react";
import { css } from "emotion";
import { connect, useSelector } from "react-redux";
import { bindActionCreators } from "redux";
import { addToast, updateModalPage } from "../../actions/uiActions";

// Utilities
import req from "../../utilities/request-utility";

// Styles
import { ArrowRightIcon, ErrorOutlineIcon } from "mdi-react";
import colors from "../../style/colors";

// Components
import QuizResults from "./QuizResults";
import Spinner from "../ui/InlineSpinner";
import Button from "../ui/Button";
import breakpoints from "../../config/breakpoints";
import ImageCarousel from "../ui/ImageCarousel";
import { getQuizzesOverview } from "../../actions/academyActions";
import RadioGroup from "../ui/RadioGroup";
import StepBox from "../ui/StepBox";

const Quiz = (props) => {
  // Saves the quiz to be able to manipulate with it
  const [quiz, setQuiz] = useState(null);

  // Language
  const lang = useSelector((state) => state.language.language);

  // The stepper is used when the user wants the next question
  const [step, setStep] = useState(0);
  const [spinner, setSpinner] = useState(true);

  // Selected answer saves the index of the selected answer
  const [selectedAnswer, setSelectedAnswer] = useState(null);

  // ComponentDidMount function to set the spinner to false and to set the quiz to state
  useEffect(() => {
    req()
      .get(`quiz/${props.quizId}`)
      .then(({ data: quiz }) => {
        setQuiz(quiz);
        setSpinner(false);
      })
      .catch(() => {
        props.addToast({
          title: `${lang.errorGeneral}`,
          content: `${lang.errorCouldNotGetAnswers}`,
          icon: <ErrorOutlineIcon />,
          styleType: "error",
          duration: 20000,
        });
      });
    // eslint-disable-next-line
  }, []);

  // Submits the answer to the question
  const submitAnswer = (answer) => {
    setSpinner(true);
    req()
      .post(`quiz/${props.quizId}/answers`, answer)
      .then(({ data }) => {
        if (step !== quiz.questions.length - 1) {
          // Update the progress info
          setQuiz({ ...quiz, reachedQuestionPosition: quiz.reachedQuestionPosition + 1 });
          // Next question
          setStep(step + 1);
          // Selected answer is now null again
          setSelectedAnswer(null);
          // Spinner is false
          setSpinner(false);
        } else {
          props.getQuizzesOverview();
          // If the you has answered the last question then show the results
          props.updateModalPage({
            title: lang.quizAnswers,
            content: <QuizResults quizId={quiz.id} />,
            useScrollView: false,
            pageStyle: { backgroundColor: colors.white },
          });
        }
      })
      .catch(() => {
        props.addToast({
          title: lang.error,
          content: lang.errorCouldNotSaveYourAnswer,
          icon: <ErrorOutlineIcon />,
          styleType: "error",
          duration: 20000,
        });
      });
  };

  return (
    <div className={componentStyle(breakpoints, colors)}>
      <div className="content">
        {/* Loading */}
        {spinner && <Spinner />}

        {/* Content */}
        {quiz && !spinner && (
          <>
            <StepBox
              style={{ marginBottom: "0.5rem" }}
              currentStep={quiz.reachedQuestionPosition}
              lastStep={quiz.questionsLength}
              title={lang.questions.toLowerCase()}
            />
            <p className="title">{`${quiz.questions[step].title}`}</p>
            {quiz.questions[step].images && quiz.questions[step].images.length > 0 && (
              <ImageCarousel images={quiz.questions[step].images} />
            )}

            {/* Questions */}
            {step < quiz.questions.length && (
              <RadioGroup
                style={{ marginBottom: "2rem" }}
                options={quiz.questions[step].answers}
                onSelect={(radioId) => {
                  setSelectedAnswer(quiz.questions[step].answers.findIndex((a) => a.id === radioId));
                }}
              />
            )}

            <Button
              data-test-id={"next-question"}
              disabled={selectedAnswer === null}
              onClick={() =>
                void submitAnswer({
                  answerId: quiz.questions[step].answers[selectedAnswer].id,
                  questionId: quiz.questions[step].id,
                })
              }
              style={{
                display: "flex",
                flexDirection: "row",
                alignItems: "center",
                justifyContent: "center",
                marginBottom: "5rem",
              }}
            >
              <p style={{ marginRight: "0.4rem" }}>{lang.next}</p>
              <ArrowRightIcon size={20} />
            </Button>
          </>
        )}
      </div>
    </div>
  );
};

const componentStyle = (breakpoints, colors) => css`
  background-color: ${colors.white};
  width: 100%;
  height: 100%;
  padding: 1rem;
  /* overflow: auto; */

  .questions-progress {
    background-color: ${colors.lightGrey};
    display: inline-block;
    padding: 0.35rem 0.85rem;
    font-size: 0.85rem;
    border-radius: 3px;
    margin-bottom: 1.5rem;
    font-weight: 700;
    color: ${colors.darkGrey};
  }

  .content  {
    max-width: ${breakpoints.md}px;
    margin: 0 auto;
    display: block;
    margin-bottom: 5rem;
    width: 100%;
  }

  .title {
    font-weight: bold;
    margin-bottom: 1rem;
  }
`;

const mapDispatchToProps = (dispatch) => ({
  getQuizzesOverview: bindActionCreators(getQuizzesOverview, dispatch),
  updateModalPage: bindActionCreators(updateModalPage, dispatch),
  addToast: bindActionCreators(addToast, dispatch),
});

export default connect(null, mapDispatchToProps)(Quiz);
