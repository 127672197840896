import req from "../utilities/request-utility";
import { GET_LANGUAGE, GET_LANGUAGE_SUCCESS, GET_LANGUAGE_FAILURE } from "./actionTypes";

export function getLanguage(cb) {
  return function (dispatch, getState) {
    dispatch({
      type: GET_LANGUAGE,
    });

    // Whenever langauge is refetched is a good opppertunity to make sure the html tag's lang attribute
    // is set correctly:
    let state = getState();
    if (state && state.auth && state.auth.user && state.auth.user.language) {
      document.querySelector("html").setAttribute("lang", state.auth.user.language);
    }

    req()("/language/")
      .then(({ data }) => {
        dispatch({
          type: GET_LANGUAGE_SUCCESS,
          payload: data,
        });
        if (cb) cb();
      })
      .catch(() => {
        dispatch({
          type: GET_LANGUAGE_FAILURE,
        });
        if (cb) cb();
      });
  };
}
