import React, { useState, useEffect, Fragment } from "react";
import { connect, useDispatch } from "react-redux";
import { css } from "emotion";
import queryString from "query-string";
import "../../style/installation-flow-styles.css";
import req from "../../utilities/request-utility";

//css
import colors from "../../style/colors";

// icons
import {
  PlayCircleIcon,
  LifebuoyIcon,
  AndroidIcon,
  EarthIcon,
  AppleIcon,
  MoreVertIcon,
  ArrowUpIcon,
  LightbulbOnOutlineIcon,
  MenuIcon,
  PlusIcon,
  InfoOutlineIcon,
} from "mdi-react";
import { ReactComponent as IosAddToHomeScreenIcon } from "./ios-add-to-homescreen-icon.svg";
import { ReactComponent as IosShareIcon } from "./ios-share-icon.svg";

// components
import Button from "../ui/Button";
import StatusBox from "../ui/StatusBox";

// Media
import installationVideoIOSEN from "./installation-video-ios-EN.mp4";
import installationVideoIOSDA from "./installation-video-ios-DA.mp4";
import installationVideoAndroidEN from "./installation-video-android-EN.mp4";
import installationVideoAndroidDA from "./installation-video-android-DA.mp4";
import InAppBrowserDialog from "./InAppBrowserDialog";
import getAppName from "../../utilities/get-app-name";
import { showModalPage } from "../../actions/uiActions";
import DebugModal from "../debugModal/DebugModal";
import ModalPage from "../ui/ModalPage";

function isIOSInAppBrowser() {
  // Check if device is iPad, iPhone or iPod (this bit is naive and should probably check additional stuff)
  if (!Boolean(window.navigator.userAgent.match(/iPad|iPhone|iPod/))) return false;

  // Check if navigator is standalone but display-mode isn't
  if (window.navigator.standalone === true && window.matchMedia("(display-mode: standalone)").matches === false) {
    return true;
  } else {
    return false;
  }
}

function checkIfChrome88AndAndroid() {
  // Check if device is iPad, iPhone or iPod (this bit is naive and should probably check additional stuff)
  if (Boolean(window.navigator.userAgent.match(/Android.*Chrome\/(88|87)/))) {
    return true;
  } else {
    return false;
  }
}

/**
 * Installation flow component. Shows how to install the app.
 *
 * The component will respond to certain query-params and as these are a bit difficult to document
 * otherwise they are listed here:
 * - `lang` {String} sets the users language. examples: "da", "en", "se", "fi" etc.
 * - `user` {String} sets the users name. examples: "John", "Jens", "Rikke"
 * - `showInstallationScreen` {Boolean} if true, the app will show the installation screen at startup instead of the frontpage
 *
 */

const InstallationFlow = (props) => {
  const dispatch = useDispatch();

  // User name (can be passed as a queryString since user isn't logged in)
  async function getLang() {
    try {
      let { data } = await req()(`language/installation-flow/${userLanguage}`);
      setLang(data);
      // requestAnimationFrame is used to prevent the app from crashing.
      // This way the language is defined and then the we weait for at least
      // a single event-loop cycle before continuing.
      requestAnimationFrame(() => {
        setIsReady(true);
      });
    } catch (err) {
      setIsReady(true);
      setError(true);
    }
  }

  // State variables
  // Extracts the user agent (can be saved in different places)
  let userAgent = navigator.userAgent || navigator.vendor || window.opera;
  let [isIOS, setIsIOS] = useState(userAgent.match(/iPad|iPhone|iPod/) !== null ? true : false);
  let [isAndroid, setIsAndroid] = useState(userAgent.match(/Android/) !== null ? true : false);
  let [isSamsungInternet, setIsSamsungInternet] = useState(userAgent.match(/SamsungBrowser/) !== null ? true : false);
  // User name used for greeting ("Hi ${user}!")
  let [user, setUser] = useState("");
  // Defines the users current language ("da", "en", "fi", etc)
  let [userLanguage, setUserLanguage] = useState("");
  // Language variables (lang.hi -> "Hi" | "Hej" | "Hey")
  let [lang, setLang] = useState({});
  // Controls the inital spinner (can be retoggled if language changes)
  let [isReady, setIsReady] = useState(false);
  let [error, setError] = useState(false);
  // controls wether or not the extended guidance is shown
  let [extended, setExtended] = useState(false);
  let [isInAppBrowserState, setIsInAppBrowser] = useState(false);
  let [isChrome88AndAndroid, setIsChrome88AndAndroid] = useState(false);

  // Parse query-strings
  useEffect(() => {
    setIsInAppBrowser(isIOSInAppBrowser());
    setIsChrome88AndAndroid(checkIfChrome88AndAndroid());

    // Get potential user from query-string
    let queryStrings = queryString.parse(window.location.search);
    // Set user
    if (queryStrings.user) setUser(queryStrings.user);
    // set language
    if (queryStrings.lang) {
      setUserLanguage(queryStrings.lang);
    } else {
      setUserLanguage("en");
    }
  }, []);

  // Get the language-variables
  useEffect(() => {
    if (userLanguage) {
      setIsReady(false);
      getLang();
    }
    // eslint-disable-next-line
  }, [userLanguage]);

  /** Takes a string and replaces the location string with a react element
   * @param {String} string
   * @param {String} location
   * @param {ReactComponent} icon
   * @returns {ReactComponent}
   * @example
   * let string = "some string {{placeholder}} rest of string";
   * let location = "{{placeholder}}";
   * let icon = <LifebuoyIcon/>
   *
   * insertIcon(string, location, icon)
   * -> (<>some string <LifebuoyIcon/> rest of string</>)
   */
  const insertIcon = (string, location, icon) => (
    <>
      {string.split(location)[0]}
      {icon}
      {string.split(location)[1]}
    </>
  );

  const insertIcons = (string, locations, icons) => (
    <>
      {string.split(locations).map((part, i) => {
        return (
          <Fragment key={i}>
            {part}
            {icons.shift()}
          </Fragment>
        );
      })}
    </>
  );

  return (
    <div className={componentStyle(props)}>
      {isReady && error && (
        /* This is intentionally not translated as the error could be that language-variables weren't fetched */
        <StatusBox
          title="Something went wrong"
          content="We were unable to start the app. Please contact us at support@toecho.dk"
        />
      )}

      {isReady && !error && lang && props.appConfig && (
        <div className="main-content">
          {/* Hi and welcome-text */}
          <h2 style={{ marginBottom: "1.25rem" }}>
            {lang.installationHello} {user} 👋
          </h2>
          <p style={{ marginBottom: "1.5rem" }}>
            {lang.welcomeTo__placeholder__app.replace(/{{placeholder}}/g, props.appConfig.title)}
          </p>

          {/* Installation instructions */}

          {/* (1) */}
          <div className="list-element">
            <div className="circled-number">1</div>
            <p>
              {isIOS &&
                insertIcon(
                  lang.iosClickOn__placeholderShareIcon__,
                  "{{placeholder-share-icon}}",
                  <IosShareIcon className="inline-svg-icon" />
                )}

              {/* Adnroid */}
              {isAndroid && (
                <>
                  {/* Chrome */}
                  {!isSamsungInternet &&
                    insertIcon(
                      lang.androidClickOn__placeholderMoreOptionsIcon__,
                      "{{placeholder-more-options-icon}}",
                      <MoreVertIcon className="inline-svg-icon" />
                    )}

                  {/* Samsung */}
                  {isSamsungInternet &&
                    insertIcon(
                      lang.samsungBrowserStep1__placeholder__,
                      "{{placeholder}}",
                      <MenuIcon className="inline-svg-icon" />
                    )}
                </>
              )}
            </p>
          </div>

          {isChrome88AndAndroid && (
            <>
              <div className="onscreen-alert-container">
                <h3>{lang.chrome88WarningHeading}</h3>
                <p>{lang.chrome88warningParagraph1}</p>
                <p>{lang.chrome88warningParagraph2}</p>
                <p>{lang.chrome88warningParagraph3}</p>
                <p>https://ekkoapp.app/{getAppName()}/</p>
              </div>
              <div className="chrome-88-pointing-arrow">
                <ArrowUpIcon />
              </div>
            </>
          )}

          {isAndroid && isSamsungInternet && (
            <div className="onscreen-alert-container">
              <h3 style={{ marginBottom: "0.35rem" }}>
                <InfoOutlineIcon style={{ margin: "0 0.35rem 0 0" }} />
                Obs!
              </h3>
              <p>
                {insertIcon(
                  "Hvis du ikke kan se {{placeholder}}-ikonet nederst,",
                  "{{placeholder}}",
                  <MenuIcon className="inline-svg-icon" />
                )}
                {insertIcon(
                  ` men har {{placeholder}}-ikonet i toppen så klik på det og vælg "Føj til hjemmskærm/startskærm"`,
                  "{{placeholder}}",
                  <MoreVertIcon className="inline-svg-icon" />
                )}
                i stedet for at følge resten af guiden.
              </p>
            </div>
          )}

          {/* (2) */}
          <div className="list-element">
            <div className="circled-number">2</div>
            <p>
              {isIOS &&
                insertIcon(
                  lang.iosChoose__placeholderAddToHomescreenIcon__,
                  "{{placeholder-add-to-homescreen-icon}}",
                  <IosAddToHomeScreenIcon className="inline-svg-icon" />
                )}
              {isAndroid && (
                <>
                  {!isSamsungInternet && lang.androidChooseAddToHomescreen}
                  {isSamsungInternet &&
                    insertIcon(
                      lang.samsungBrowserStep2__placeholder__,
                      "{{placeholder}}",
                      <PlusIcon className="inline-svg-icon" />
                    )}
                </>
              )}
            </p>
          </div>

          {/* (3) */}
          <div className="list-element">
            <div className="circled-number">3</div>
            <p>
              {isIOS && lang.iosOpenTheAppFromYourHomescreen}

              {isAndroid && (
                <>
                  {!isSamsungInternet && lang.androidOpentheAppFromYourHomescreen}
                  {isSamsungInternet && lang.samsungBrowserStep3}
                </>
              )}
            </p>
          </div>

          {/* (4) - Only for samsung */}
          {isSamsungInternet && (
            <div className="list-element">
              <div className="circled-number">4</div>
              <p>{lang.androidOpentheAppFromYourHomescreen}</p>
            </div>
          )}

          {/* ("info") */}
          <div className="list-element">
            <div className="circled-number">
              <LightbulbOnOutlineIcon style={{ margin: "0.35rem auto", width: "1rem", height: "1rem" }} />
            </div>
            <p style={{ color: colors.darkGrey }}>{lang.remeberToRemoveTheOldIcon}</p>
          </div>
        </div>
      )}
      {/* Need help / more info */}
      {isReady && !error && !extended && (
        <div className="need-help-container">
          <div className="seperator" />
          <p className="need-help" onClick={() => setTimeout(() => setExtended(!extended), 200)}>
            <LifebuoyIcon />
            {lang.needHelp}
          </p>
        </div>
      )}
      {/* Extended guide */}
      {isReady && !error && extended && (
        <>
          <div className="extended-content">
            <div className="seperator" style={{ marginBottom: "2rem" }} />
            <h2>{lang.extendedInstallationGuide}</h2>
            <p className="need-help" onClick={() => setTimeout(() => setExtended(!extended), 200)}>
              {lang.hideHelp}
            </p>

            <video style={{ display: "none" }}>
              {isIOS && userLanguage === "en" && <source type="video/mp4" src={installationVideoIOSEN} />}
              {isIOS && userLanguage === "da" && <source type="video/mp4" src={installationVideoIOSDA} />}
              {isAndroid && userLanguage === "en" && <source type="video/mp4" src={installationVideoAndroidEN} />}
              {isAndroid && userLanguage === "da" && <source type="video/mp4" src={installationVideoAndroidDA} />}
            </video>

            {/* Button list */}
            <Button
              iconLeft={<PlayCircleIcon />}
              buttonType="primary"
              onClick={() => document.querySelector("video").play()}
            >
              {lang.seeInstallationVideo}
            </Button>
            <Button
              iconLeft={<EarthIcon />}
              buttonType="secondary"
              onClick={() => (userLanguage === "en" ? setUserLanguage("da") : setUserLanguage("en"))}
            >
              {lang.changeGuideTo__placeholder__.replace("{{placeholder}}", userLanguage === "en" ? "danish" : "english")}
            </Button>
            <Button
              iconLeft={isIOS ? <AndroidIcon /> : <AppleIcon />}
              buttonType="secondary"
              onClick={() => {
                setIsIOS(!isIOS);
                setIsAndroid(!isAndroid);
              }}
            >
              {isIOS ? lang.seeAndroidGuide : lang.seeIOSGuide}
            </Button>

            <ul>
              {isIOS && (
                <>
                  <h2 style={{ textAlign: "left", marginTop: "3.5rem", marginBottom: "1.25rem" }}>{lang.commonProblems}</h2>
                  <li>
                    <p className="header">
                      {insertIcon(
                        lang.cantFind__placeholder__icon,
                        "{{placeholder}}",
                        <IosShareIcon className="inline-svg-icon" />
                      )}
                    </p>
                    <p>{lang.cantFindShareIconContent}</p>
                  </li>
                  <li>
                    <p className="header">
                      {insertIcon(
                        lang.cantFind__placeholder__icon,
                        "{{placeholder}}",
                        <IosAddToHomeScreenIcon className="inline-svg-icon" />
                      )}
                    </p>
                    <p>
                      {insertIcons(
                        lang.cantFindAddToHomescreenIconContent__placeholderShareIcon____placeholderAddToHomescreenIcon__,
                        /{{placeholder-share-icon}}|{{placeholder-add-to-homescreen-icon}}/,
                        [
                          <IosShareIcon key="icon-0" className="inline-svg-icon" />,
                          <IosAddToHomeScreenIcon key="icon-1" className="inline-svg-icon" />,
                        ]
                      )}
                    </p>
                  </li>
                  <li>
                    <p className="header">I do not speak danish</p>
                    <p>Scroll up and find the "Change guide to english" button above.</p>
                  </li>
                  <li>
                    <p className="header">{lang.dontHaveAnIphone}</p>
                    <p>{lang.dontHaveAnIphoneContent}</p>
                  </li>
                  <li>
                    <p className="header">{lang.alreadyAddedTheApp}</p>
                    <p>{lang.alreadyAddedTheAppContent}</p>
                  </li>
                </>
              )}
            </ul>

            <p className="need-help" onClick={() => dispatch(showModalPage({ content: <DebugModal /> }))}>
              Show developer info
            </p>
          </div>

          {/*  <div className="text-body">
              <h4>
                {isIOS && phrases.device.ios[lang].commonProblemsHeadline}
                {isAndroid && phrases.device.android[lang].commonProblemsHeadline}
              </h4>
              <ul>
                <li>
                  <p>
                    {isIOS && phrases.device.ios[lang].commonProblenmsbullet1Headline}
                    {isAndroid && phrases.device.android[lang].phrase1}
                  </p>
                  <p>
                    {isIOS && phrases.device.ios[lang].commonProblenmsbullet1text}
                    {isAndroid && phrases.device.android[lang].phrase1}
                  </p>
                </li>
                <li>
                  <p>
                    {isIOS && phrases.device.ios[lang].commonProblenmsbullet2Headline}
                    {isAndroid && phrases.device.android[lang].phrase1}
                  </p>
                  <p>
                    {isIOS && phrases.device.ios[lang].commonProblenmsbullet2text}
                    {isAndroid && phrases.device.android[lang].phrase1}
                  </p>
                </li>
                <li>
                  <p>
                    {isIOS && phrases.device.ios[lang].commonProblenmsbullet3Headline}
                    {isAndroid && phrases.device.android[lang].phrase1}
                  </p>
                  <p>
                    {isIOS && phrases.device.ios[lang].commonProblenmsbullet3text}
                    {isAndroid && phrases.device.android[lang].phrase1}
                  </p>
                </li>
                <li>
                  <p>
                    {isIOS && phrases.device.ios[lang].commonProblenmsbullet4Headline}
                    {isAndroid && phrases.device.android[lang].phrase1}
                  </p>
                  <p>
                    {isIOS && phrases.device.ios[lang].commonProblenmsbullet4text}
                    {isAndroid && phrases.device.android[lang].phrase1}
                  </p>
                </li>
              </ul>
            </div> */}
        </>
      )}
      <p className="need-help" onClick={() => props.onCloseGuide()}>
        {lang.closeGuide}
      </p>
      {isInAppBrowserState && <InAppBrowserDialog />}
      <ModalPage />
    </div>
  );
};

const paddingSides = 1.5;

const componentStyle = (props) => css`
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  min-height: 110vh; /* Appr. viewport height in a mobile browser with toolbars visible */
  padding: 2rem ${paddingSides}rem 0 ${paddingSides}rem;

  .text-body {
    margin: 2rem;
  }
  .main-content {
    flex: 1 0 90%;
  }

  .need-help-container {
    margin-bottom: 4.5rem;
  }

  .extended-content {
    margin-top: 4rem;

    h2 {
      text-align: center;
    }

    ul {
      .header {
        font-weight: 700;
        margin-bottom: 0.5rem;
        color: ${colors.black};
      }

      li {
        margin-bottom: 2rem;
      }

      p {
        color: ${colors.darkGrey};
      }
    }
  }

  /* General "global" styles */
  svg {
    margin: 0 0.5rem 0 0.5rem;
    vertical-align: sub;
  }

  button {
    margin-bottom: 0.75rem;
  }

  svg.inline-svg-icon {
    display: inline-block;
    width: 1.5rem;
    height: 1.5rem;
    margin: 0;
    vertical-align: center;
  }

  .list-element {
    display: flex;
    align-items: center;
    margin-bottom: 1.5rem;

    .circled-number {
      margin-right: 1rem;
      border: 1px solid ${colors.midGrey};
      border-radius: -0.2rem;
      border-radius: 50%;
      text-align: center;
      min-width: 30px;
      height: 30px;
      color: ${props.appConfig.primaryColor};
      font-weight: bold;
      font-size: 1.2rem;
      margin-top: 0.2rem;
    }

    p {
      padding: 0;
    }
  }

  .seperator {
    margin: 0 -${paddingSides}rem;
    height: 1px;
    background-color: ${colors.midGrey};
  }

  .need-help {
    text-align: center;
    color: ${colors.darkGrey};
    padding: 0.5rem 1rem;
    margin: 0.5rem 0;
    text-decoration: underline;

    svg {
      margin-bottom: -2px;
    }

    &:active {
      background-color: ${colors.lightGrey};
      border-radius: 5px;
    }
  }

  .onscreen-alert-container {
    border-radius: 4px;
    border: 1px ${colors.midGrey} solid;
    border-left: 4px ${props.appConfig.primaryColor} solid;
    padding: 1rem;
    margin-left: 3rem;
    margin-bottom: 2rem;

    h3 {
      color: ${props.appConfig.primaryColor};
      font-size: 1.1rem;
      margin-bottom: 1rem;
    }

    p {
      color: ${colors.black};
      font-size: 1rem;
      margin-bottom: 0.5rem;
    }
  }

  .chrome-88-pointing-arrow {
    width: 2.7rem;
    height: 2.7rem;
    display: flex;
    justify-content: center;
    align-items: center;
    border-radius: 50%;
    border: 1px ${colors.midGrey} solid;
    background-color: ${colors.white};
    position: fixed;
    top: 0.5rem;
    right: 0.5rem;
    animation: chrome88bounce 1.5s infinite ease;

    svg {
      color: ${props.appConfig.primaryColor};
    }
    @keyframes chrome88bounce {
      0% {
        transform: translateY(0px);
      }

      70% {
        transform: translateY(20px);
      }

      100% {
        transform: translateY(0px);
      }
    }
  }
`;

const mapStateToProps = (state) => ({
  userName: state.auth.user.name,
  lang: state.language.language,
  appConfig: state.appConfig,
});

export default connect(mapStateToProps)(InstallationFlow);
