// Libs
import React, { useEffect, useLayoutEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";

// Utilities
import getPageFromId from "../../../utilities/get-page-from-id";

// Components
import Page from "../../ui/Page";
import TopBar from "../../ui/TopBar";
import Button from "../../ui/Button";
import { addToast, hideModalPage, showModalPage } from "../../../actions/uiActions";
import InlineSpinner from "../../ui/InlineSpinner";
import colors from "../../../style/colors";
import { parseDate, parseDatetime } from "../../../utilities/parse-date";
import { css } from "emotion";
import markdownParser from "../../../utilities/markdown-parser";
import ScrollView from "../../ui/ScrollView";
import ButtonRounded from "../../ui/ButtonRounded";
import breakpoints from "../../../config/breakpoints";
import Image from "../../ui/Image";
import req from "../../../utilities/request-utility";
import getProfilePictureFromUserObject from "../../../utilities/get-profile-picture-from-user-object";
import { ImageIcon, InfoOutlineIcon, CheckDecagramIcon, InformationOutlineIcon } from "mdi-react";
import EquipmentBookingStartBookingModal from "../EquipmentBookingStartBookingModal";
import EquipmentBookingEndBookingModal from "../EquipmentBookingEndBookingModal";
import styleTypes from "../../../config/styleTypes";
import { getMyBookingsCount } from "../../../actions/equipmentBookingActions";
import TabBar from "../../ui/TabBar";
import StatusBox from "../../ui/StatusBox";

function EquipmentBookingSingleHistoryTab({ match }) {
  const [history, setHistory] = useState([]);
  const [loadingHistory, setLoadingHistory] = useState([]);

  const { pages } = useSelector((state) => state.pages);
  const [page] = useState(getPageFromId(pages, match.params.pageId));

  function getEquipmentHistory() {
    setLoadingHistory(true);
    let limit = history.length > 2 ? 20 : 2;
    let offset = history.length;
    req()(
      `equipment-booking/${page.dataId}/equipment/${match.params.equipmentId}/booking-history?limit=${limit}&offset=${offset}`
    )
      .then(({ data }) => {
        setLoadingHistory(false);
        setHistory([...history, ...data]);
      })
      .catch(() => {
        setLoadingHistory(false);
      });
  }

  useEffect(() => {
    getEquipmentHistory();
  }, []);

  return (
    <div className={componentStyles()}>
      {history.map((historyEntry) => (
        <div className="booking-details">
          <div className="upper-section">
            {getProfilePictureFromUserObject(historyEntry.user, 38)}
            <div className="user-details small">
              <p className="name">{historyEntry.user.name}</p>
              <p className="meta">
                Booket fra {parseDatetime(historyEntry.bookingStartDate)} til {parseDatetime(historyEntry.bookingEndDate)}
              </p>
            </div>
          </div>
          <p className="comment-label">Kommentar ved bookning:</p>
          {historyEntry.bookingStartComment && <p className="comment-content">{historyEntry.bookingStartComment}</p>}
          {!historyEntry.bookingStartComment && (
            <p className="comment-content meta" style={{ opacity: 0.8 }}>
              <InformationOutlineIcon style={{ width: "1rem", height: "1rem", marginBottom: "-0.2rem" }} /> Ingen kommentar
            </p>
          )}

          <p className="comment-label" style={{ marginTop: "1rem" }}>
            Kommentar ved afsluttet bookning:
          </p>
          {historyEntry.bookingEndComment && <p className="comment-content">{historyEntry.bookingEndComment}</p>}
          {!historyEntry.bookingEndComment && (
            <p className="comment-content meta" style={{ opacity: 0.8 }}>
              <InformationOutlineIcon style={{ width: "1rem", height: "1rem", marginBottom: "-0.2rem" }} /> Ingen kommentar
            </p>
          )}
        </div>
      ))}

      {history.length === 0 && (
        <StatusBox
          icon={<InformationOutlineIcon />}
          title="Der er ingenting at vise her endnu"
          content="Du kan blive den første til at booke udstyret"
        />
      )}

      {history.length > 0 && !loadingHistory && (
        <ButtonRounded onClick={getEquipmentHistory} style={{ margin: "2rem auto" }} secondary={true}>
          Indlæs mere historik
        </ButtonRounded>
      )}

      {history.length > 0 && loadingHistory && (
        <p className="meta" style={{ textAlign: "center", margin: "2rem 0" }}>
          <InlineSpinner style={{ display: "inline-block", margin: "0 0.25rem -0.6rem 0" }} size="14" /> Indlæser historik...
        </p>
      )}
    </div>
  );
}

const componentStyles = () => css`
  max-width: ${breakpoints.md}px;
  margin: 1rem auto 5rem auto;

  p.meta {
    color: ${colors.darkGrey};
  }

  .booking-details {
    border: 1px ${colors.midGrey} solid;
    background-color: ${colors.white};
    border-radius: 3px;
    padding: 0.85rem;
    margin-bottom: 0.5rem;

    .upper-section {
      display: flex;
      margin-bottom: 0.85rem;

      .user-details {
        padding-left: 0.5rem;
      }
    }

    .comment-label {
      margin-bottom: 0.15rem;
    }

    .comment-content {
      font-size: 0.95rem;
      color: ${colors.darkGrey};
      white-space: pre-wrap;
    }
  }
`;

export default EquipmentBookingSingleHistoryTab;
