import pageConfig from "../../../config/pageConfig";

export function getMiddleTitle({ contact, config = {} }) {
  let string = "";

  if ((config && config[pageConfig.HIDE_JOB_TITLE] !== true && contact.jobTitle) || !config) string += contact.jobTitle;

  if (contact.externalId) string += ` (${contact.externalId})`;

  return string;
}
