import req from "../utilities/request-utility";
import {
  // Data fecthing
  GET_TARGET_GROUP_USERS,
  GET_TARGET_GROUP_USERS_SUCCESS,
  GET_TARGET_GROUP_USERS_FAILURE,
  GET_MORE_OVERVIEW_USERS,
  GET_MORE_OVERVIEW_USERS_SUCCESS,
  GET_MORE_OVERVIEW_USERS_FAILURE,
  SET_OVERVIEW_USERS_END_OF_FEED,

  // Handle user groups
  GET_USER_GROUPS_SUCCESS,
  SET_SELECTED_USER_GROUP,

  // Handle master groups
  GET_MASTER_GROUPS_SUCCESS,
  SET_SELECTED_MASTER_GROUP,

  // handle activity groups
  GET_ACTIVITY_GROUPS_SUCCESS,
  SET_SELECTED_ACTIVITY_GROUP,

  // Target group manipulation
  ADD_USER_TO_TARGET_GROUP,
  ADD_USER_TO_TARGET_GROUP_SUCCESS,
  ADD_USER_TO_TARGET_GROUP_FAILURE,
  REMOVE_USER_FROM_TARGET_GROUP,
  REMOVE_USER_FROM_TARGET_GROUP_SUCCESS,
  REMOVE_USER_FROM_TARGET_GROUP_FAILURE,

  // generel
  SET_SERCHTERM,
  SET_CURRENT_PAGE_ID,
  CLEAR_OVERVIEW_USERS,
  SHOW_FILTERS,
  HIDE_FILTERS,
  SHOW_TARGET_GROUP,
  HIDE_TARGET_GROUP,
  SET_OVERVIEW_USERS_COUNT,
} from "../actions/actionTypes";
import { generateURLFromState } from "../components/ui/TargetGroupModal/target-group-utilities";

export const showFilters = () => ({ type: SHOW_FILTERS });
export const hideFilters = () => ({ type: HIDE_FILTERS });
export const showTargetGroup = () => ({ type: SHOW_TARGET_GROUP });
export const hideTargetGroup = () => ({ type: HIDE_TARGET_GROUP });
export const setCurrentPageId = (id) => ({ type: SET_CURRENT_PAGE_ID, payload: id });

let _timeout;
export function setSearchTerm(string) {
  return function (dispatch, getState) {
    dispatch({
      type: SET_SERCHTERM,
      payload: string,
    });

    clearTimeout(_timeout);
    _timeout = setTimeout(() => {
      dispatch({ type: CLEAR_OVERVIEW_USERS });
      getOverviewUsers()(dispatch, getState);
    }, 300);
  };
}

export function getOverviewUsers() {
  return function (dispatch, getState) {
    let { overviewUsers, searchTerm, selectedMasterGroupId, selectedUserGroupId } = getState().targetGroupModal;
    if (overviewUsers.loading || overviewUsers.error || overviewUsers.endOfFeed) return;

    dispatch({ type: GET_MORE_OVERVIEW_USERS });

    let URL = generateURLFromState({
      selectedMasterGroupId,
      selectedUserGroupId,
      searchTerm,
      offset: overviewUsers.users.length,
    });

    req()(URL)
      .then((res) => {
        dispatch({ type: GET_MORE_OVERVIEW_USERS_SUCCESS, payload: res.data });
        if (res.data.length < 10) dispatch({ type: SET_OVERVIEW_USERS_END_OF_FEED });
      })
      .catch((err) => void dispatch({ type: GET_MORE_OVERVIEW_USERS_FAILURE }));

    let CountURL = generateURLFromState({
      selectedMasterGroupId,
      selectedUserGroupId,
      searchTerm,
      offset: overviewUsers.users.length,
      count: true,
    });

    req()(CountURL).then((res) => void dispatch({ type: SET_OVERVIEW_USERS_COUNT, payload: res.data.count }));
  };
}

export function refreshOverviewUsers() {
  return function (dispatch, getState) {
    let { overviewUsers, searchTerm, selectedMasterGroupId, selectedUserGroupId } = getState().targetGroupModal;

    let URL = generateURLFromState({
      selectedMasterGroupId,
      selectedUserGroupId,
      searchTerm,
      limit: overviewUsers.users.length,
      offset: 0,
    });

    dispatch({ type: GET_MORE_OVERVIEW_USERS });
    req()(URL).then((res) => {
      dispatch({ type: CLEAR_OVERVIEW_USERS });
      dispatch({ type: GET_MORE_OVERVIEW_USERS_SUCCESS, payload: res.data });
    });
  };
}

/** Fetches new users and replaces those in state */
export function getTargetGroupUsers() {
  return function (dispatch, getState) {
    dispatch({ type: GET_TARGET_GROUP_USERS });
    req()(`pages/${getState().targetGroupModal.currentPageId}/users`)
      .then((res) => void dispatch({ type: GET_TARGET_GROUP_USERS_SUCCESS, payload: res.data }))
      .catch((err) => void dispatch({ type: GET_TARGET_GROUP_USERS_FAILURE }));
  };
}

export function addUserToTargetGroup(user) {
  return function (dispatch, getState) {
    dispatch({ type: ADD_USER_TO_TARGET_GROUP, payload: user }); // Just pretend it worked

    // Also do it
    req()
      .put(`pages/${getState().targetGroupModal.currentPageId}/users/${user.id}`)
      .then((res) => void dispatch({ type: ADD_USER_TO_TARGET_GROUP_SUCCESS, payload: user }))
      .catch((err) => void dispatch({ type: ADD_USER_TO_TARGET_GROUP_FAILURE }));
  };
}

export function removeUserFromTargetGroup(user) {
  return function (dispatch, getState) {
    dispatch({ type: REMOVE_USER_FROM_TARGET_GROUP, payload: user }); // Just pretend it worked

    // Also do it
    req()
      .delete(`pages/${getState().targetGroupModal.currentPageId}/users/${user.id}`)
      .then((res) => void dispatch({ type: REMOVE_USER_FROM_TARGET_GROUP_SUCCESS, payload: user }))
      .catch((err) => void dispatch({ type: REMOVE_USER_FROM_TARGET_GROUP_FAILURE }));
  };
}

export function getMasterUserAndTargetGroups() {
  return function (dispatch, getState) {
    Promise.all([req()(`user-groups`), req()(`master-groups`), req()(`activity-groups`)]).then(
      ([userGroups, masterGroups, activityGroups]) => {
        dispatch({
          type: GET_USER_GROUPS_SUCCESS,
          payload: userGroups.data,
        });
        dispatch({
          type: GET_MASTER_GROUPS_SUCCESS,
          payload: masterGroups.data,
        });
        dispatch({
          type: GET_ACTIVITY_GROUPS_SUCCESS,
          payload: activityGroups.data,
        });
      }
    );
  };
}

export function setSelectedMasterGroup(id) {
  return function (dispatch, getState) {
    dispatch({
      type: SET_SELECTED_MASTER_GROUP,
      payload: id,
    });
    dispatch({ type: CLEAR_OVERVIEW_USERS });
    getOverviewUsers()(dispatch, getState);
  };
}
export function setSelectedUserGroup(id) {
  return function (dispatch, getState) {
    dispatch({
      type: SET_SELECTED_USER_GROUP,
      payload: id,
    });
    dispatch({ type: CLEAR_OVERVIEW_USERS });
    getOverviewUsers()(dispatch, getState);
  };
}

export function clearAllFilters() {
  return function (dispatch, getState) {
    dispatch({
      type: SET_SELECTED_ACTIVITY_GROUP,
      payload: "",
    });
    dispatch({
      type: SET_SELECTED_MASTER_GROUP,
      payload: "",
    });
    dispatch({
      type: SET_SELECTED_USER_GROUP,
      payload: "",
    });
    dispatch({ type: CLEAR_OVERVIEW_USERS });
    getOverviewUsers()(dispatch, getState);
  };
}
