// Libs
import React from "react";
import queryString from "query-string";
import { useSelector } from "react-redux";

// Components
import Page from "../ui/Page";
import TopBar from "../ui/TopBar";
import { ContactsOverview } from "./components/ContactsOverview";

// Hooks
import useCurrentPage from "../../hooks/useCurrentPage";

// Config
import groupTypes from "./config/groupTypes";

export default function ContactsGroup({ match }) {
  const { language: lang } = useSelector((state) => state.language);

  // Params
  const groupId = parseInt(match.params.groupId) || null;
  let { mainContactPageId, pageTitle, contactType, groupType = null } = queryString.parse(window.location.search);

  const page = useCurrentPage({ pageId: mainContactPageId });

  return (
    <Page>
      <TopBar title={groupId ? pageTitle : lang.showAll} useDefaultBackButton={true} />
      <ContactsOverview
        isMain={false}
        mainPage={page}
        groupId={groupId}
        contactType={contactType}
        groupType={groupType}
        groupBy={groupTypes.all}
      />
    </Page>
  );
}
