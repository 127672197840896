// Libs
import React, { Component, Suspense } from "react";
import { Route, Switch } from "react-router";
import queryString from "query-string";
import { Router } from "react-router-dom";
import { connect } from "react-redux";
import { TransitionGroup, CSSTransition } from "react-transition-group";
import { bindActionCreators } from "redux";
import { css } from "emotion";

// Redux actions
import { checkToken } from "./actions/authActions";
import { getPages } from "./actions/pagesActions";
import { getAppConfig } from "./actions/appConfigActions";
import { getLanguage } from "./actions/languageActions";

// Components
import Birthdays from "./components/birthdays/Birthdays";
import CertificateOverview from "./components/certificate/CertificateOverview";
import ChallengeOverview from "./components/challenge/ChallengeOverview";
import EquipmentBookingOverview from "./components/equipmentBooking/EquipmentBookingOverview";
import EquipmentBookingSingle from "./components/equipmentBooking/EquipmentBookingSingle/EquipmentBookingSingle";
import FrontPage from "./components/FrontPage";
import HappyScore from "./components/happyScore/HappyScore";
import Highscore from "./components/highscore/Highscore";
import IdCard from "./components/idCard/IdCard";
import InstallationFlow from "./components/installationFlow/InstallationFlow";
import LoginPage from "./components/login/LoginPage";
import NavigationList from "./components/NavigationList";
import NewsFeedFaq from "./components/news/NewsFeedFaq";
import NewsFeedSingle from "./components/news/NewsFeedSingle";
import NewsFeedTabs from "./components/news/NewsFeedTabs";
import NewsFeedArticle from "./components/news/NewsFeedArticle";
import NewsFileArchive from "./components/news/NewsFileArchive";
import Profile from "./components/profile/Profile";
import RegistrationOverview from "./components/registration/RegistrationOverview";
import MyRegistrationsOverview from "./components/registration/MyRegistrationsOverview";
import QuizOverview from "./components/quiz/QuizOverview";
import SideMenu from "./components/SideMenu";
import ChatNavigationList from "./components/social/ChatNavigationList";
import SocialFeed from "./components/social/SocialFeed";
import EventOverview from "./components/event/EventOverview";
import EventDetailsOverview from "./components/event/EventDetailsOverview";
import SocialSingle from "./components/social/SocialSingle";
import NewsSinglePost from "./components/news/NewsSinglePost";
import IFrame from "./components/iFrame/IFrame";
import SickLeave from "./components/sickLeave/SickLeave";
import RegistrationNavigationList from "./components/registration/RegistrationNavigationList";
import ScheduleOverview from "./components/schedule/ScheduleOverview";
import Schedule from "./components/schedule/Schedule";
import Anniversary from "./components/anniversary/anniversary";
import Checklist from "./components/checklist/Checklist";
import ChristmasCalendar from "./components/christmanCalendar/ChristmasCalendar";
import ChristmasHighscoreOverview from "./components/christmasHighscore/ChristmasHighscoreOverview";
import NormalHighscoreOverview from "./components/normalHighscore/NormalHighscoreOverview";
import SurveyNavigationList from "./components/survey/SurveyNavigationList";
import SurveySingle from "./components/survey/SurveySingle";
import ScheduledChecklistNavigationList from "./components/scheduledChecklist/ScheduledChecklistNavigationList";
import ScheduledChecklist from "./components/scheduledChecklist/ScheduledChecklist";
import RegistrationForm from "./components/registration/RegistrationForm";
import CheersForPeersOverview from "./components/cheersForPeers/CheersForPeersOverview";
import Contacts from "./components/contacts/Contacts";
import ChatGroupUsersPage from "./components/social/ChatGroupUsersPage";
import ContactsGroup from "./components/contacts/ContactsGroup";

// UI Components
import SearchModalMain from "./components/ui/searchModal/";
import ContextMenu from "./components/ui/ContextMenu";
import Dialog from "./components/ui/Dialog";
import LoadingPage from "./components/ui/LoadingPage";
import ModalPage from "./components/ui/ModalPage";
import ToastHandler from "./components/ui/ToastHandler";
import Lightbox from "./components/ui/Lightbox";
import SkeletonPage from "./components/ui/SkeletonPage";
import PageNotFound from "./components/PageNotFound";
import ChecklistOverview from "./components/checklist/ChecklistOverview";
import ThemeQuizFloatingActionButton from "./components/ui/ThemeQuizFloatingActionButton";
import LinkCollectionOverview from "./components/linkCollection/LinkCollectionOverview";
import CheersToMe from "./components/cheersForPeers/CheersToMe";

// Utilities
import getAppName from "./utilities/get-app-name";
import history from "./utilities/navigation-history";

// actions
import { showDialog, hideDialog } from "./actions/uiActions";

// icons
import { AlertDecagramIcon } from "mdi-react";

class App extends Component {
  constructor(props) {
    super(props);

    this.state = {
      isStandalone: false,
      isAndroid: false,
      isIOS: false,
      showInstallationGuide: false,
    };

    this.refreshNotificationsInterval = undefined;
  }

  componentDidMount() {
    this.props.getAppConfig(this.startNotificationRefresher);
    this.props.checkToken();
    this.findUserAgentAndDetectStandalone();

    let potentialSpinner = document.querySelector(".hardcoded-spinner");
    if (potentialSpinner) potentialSpinner.remove();

    // Check if app i installed to home screen. Otherwise show dialog to prompt user to install it
    setTimeout(() => {
      // if (process.env.NODE_ENV === "development") return;

      // Get potential user from query-string
      let queryStrings = queryString.parse(window.location.search);

      if (!this.state.isStandalone && (this.state.isIOS || this.state.isAndroid) && this.props.auth.isAuthenticated) {
        // If app is not installed and the device is either ios or android but the user is logged in, show installation help prompt
        this.displayAddToHomeScreenDialog();
      } else if (
        (!this.state.isStandalone && (this.state.isIOS || this.state.isAndroid) && !this.props.auth.isAuthenticated) ||
        queryStrings.showInstallationScreen
      ) {
        // If app is not installed and the device is either ios or android or a querystring is present in url and the
        // user is not logged in, show installation guide
        this.setState({ showInstallationGuide: true });
      }
    }, 0);
  }

  componentWillUnmount() {
    clearInterval(this.refreshNotificationsInterval);
  }

  startNotificationRefresher = () => {
    if (this.props.auth.isAuthenticated) {
      this.refreshNotificationsInterval = setInterval(this.updateNotificationsInTitle, 120000); // 3 minutes
      this.updateNotificationsInTitle();
    }
  };

  // Denne function er oplagt til at lave som custom hook med state variablerne isIOS og isAndroud. Det kræver bare at App classen skrives om til et Function component
  findUserAgentAndDetectStandalone() {
    const userAgent = navigator.userAgent || navigator.vendor || window.opera;
    const isIOS = userAgent.match(/iPad|iPhone|iPod/) !== null ? true : false;
    const isAndroid = userAgent.match(/Android/) !== null ? true : false;
    const displayModeIsStandalone = window.matchMedia("(display-mode: standalone)").matches;
    const IN_APP_BROWSER_THRESHOLD = 80;

    /* Why is this 94 you might ask? What logic lies behind this magic number?

    EDIT: 94 is not enough becasue some android versions will remove the bar completely while the stock android
    which i have access to through the emulator leaves a few pixels of the bar.. Now i am back to guessing and trying.
    As long as we are above 72 it SHOULD be ok.

    Here is my calculations:

    | Tilstand                      | window.innerHeight | screen.height | Diff    |
    | ----------------------------- | ------------------ | ------------- | ------- |
    | Med bundbar (in app browser)  | 604                | 732           | 128     |
    | Uden bundbar (in app browser) | 636                | 732           | 96      |
    | Med bundbar (app)             | 660                | 732           | 72      |
    | Uden bundbar (app)            | 692                | 732           | 40      |

    Please refer to this beautiful illustration for reference:

    Android phone:
    +--------+
    |  10:31 | <-- Status bar
    |        |
    |        |
    |        |
    |________|
    | < [] O | <-- Bottom drawer
    +--------+


    BTW I don't expect this to work all the time. It should be good enough but will probably cause issue along the way!
    Escpeically since android lets you customize so many things that some users will have many different settings. Like
    a custom action drawer or a statusbar of triple height etc.
    This is ONLY implemented to allow the users from the old app to install the new app. In this flow the users will open
    the new app from a link in the old app. This link opens in an in-app browser and it is very important that this in-app
    browser shows the installation guide and NOT the app itself. At some point in the far future when we are done migrating
    apps this can be removed.
    - Jens Hartfelt 28/1-2021

    */

    let isStandalone;
    if (displayModeIsStandalone && isIOS) {
      // If device is IOS and display-mode: standalone is set:
      isStandalone = true;
    } else if (
      displayModeIsStandalone &&
      isAndroid &&
      window.screen.height - window.innerHeight < IN_APP_BROWSER_THRESHOLD
    ) {
      // If device is Android and display-mode: standalone is set AND the app fills up more than the entire screen minus
      // 94px for status-bar and androids action drawer ( <|  []  () )
      isStandalone = true;
    } else {
      isStandalone = false;
    }

    this.setState({ isIOS, isAndroid, isStandalone });
  }

  displayAddToHomeScreenDialog() {
    // if (process.env.NODE_ENV === "development") return;
    const DISMISS_COUNT_KEY = `${getAppName()}-installation-dismiss-count`;
    let dismissCount = Number(localStorage.getItem(DISMISS_COUNT_KEY)) || 0;

    let { lang, showDialog, hideDialog } = this.props;

    if (dismissCount && dismissCount >= 5) return;

    showDialog({
      icon: <AlertDecagramIcon />,
      title: lang.installationHeyThere,
      content: lang.dialogNotAddedToHomescreenContent,
      primaryActionTitle: lang.dialogNotAddedToHomescreenPrimaryAction,
      primaryAction: () => this.setState({ showInstallationGuide: true }),
      secondaryActionTitle: lang.dialogNotAddedToHomescreenSecondaryAction,
      allowClosing: false,
      secondaryAction: () => {
        hideDialog();
        localStorage.setItem(DISMISS_COUNT_KEY, ++dismissCount);
      },
    });
  }

  updateNotificationsInTitle = () => {
    this.props.getPages();
  };

  // keepIsWindowsActiveUpdated = () => {
  //   window.addEventListener("blur", () => this.setState({ isWindowActive: false }));
  //   window.addEventListener("focus", () => this.setState({ isWindowActive: true }));

  //   setInterval(() => {
  //     console.log("update title?");
  //     document.title = document.hidden ? "Not focused" : "Focused";
  //   }, 1000);
  // };

  closeInstallationGuide = () => {
    this.setState({ showInstallationGuide: false });
  };

  render() {
    /**
     * States:
     * ---
     * 1) User is not logged in, app is not running standalone and device is iOS or Android
     *    -> Show installation flow
     *
     * 2) User is not logged in, device is not iOS or android
     *    -> Show login screen
     *
     * 3) User is logged in (if not standalong AND ios or android also show installation-flow dialog)
     *    -> Show frontpage
     */

    let { transitionDirection, auth } = this.props;

    // 0
    if (this.props.appConfig.apiUrl === "" && this.props.appConfig.loading === true) {
      return <LoadingPage />;
    }

    // 1
    else if (this.state.showInstallationGuide) {
      return <InstallationFlow onCloseGuide={this.closeInstallationGuide} />;

      // 2
    } else if (!auth.checkingToken && !auth.isAuthenticated && !this.state.showInstallationGuide) {
      return <LoginPage />;
    } else {
      window.Rollbar.configure({
        enabled: process.env.NODE_ENV === "production" ? true : false,
        captureIp: "anonymize",
        payload: {
          person: {
            id: auth.user.id,
            username: auth.user.name,
          },
        },
      });
      return (
        <div className={componentStyle()} data-app-root>
          <Router history={history}>
            <Route
              render={({ location }) => [
                <TransitionGroup key={0}>
                  <CSSTransition key={location.key} classNames={`page-${transitionDirection}`} timeout={240}>
                    <Suspense fallback={<SkeletonPage />}>
                      <Switch location={location}>
                        {/* ChristmasCalendar */}
                        <Route exact key={998} path="/:appname/ChristmasQuiz/:pageId" component={ChristmasCalendar} />
                        {/* News */}
                        <Route exact key={997} path="/:appname/newsfeedtabs/:pageId" component={NewsFeedTabs} />
                        <Route exact key={994} path="/:appname/newsfeedtabs/:pageId/:postId" component={NewsSinglePost} />
                        <Route exact key={996} path="/:appname/newsfeedsingle/:pageId" component={NewsFeedSingle} />
                        <Route exact key={996} path="/:appname/newsfeedarticle/:pageId" component={NewsFeedArticle} />
                        <Route exact key={994} path="/:appname/newsfeedsingle/:pageId/:postId" component={NewsSinglePost} />
                        <Route exact key={995} path="/:appname/newsfeedfaq/:pageId" component={NewsFeedFaq} />
                        <Route exact key={994} path="/:appname/newsfeedfaq/:pageId/:postId" component={NewsSinglePost} />
                        <Route exact key={994} path="/:appname/news/:pageId/:postId" component={NewsFeedFaq} />
                        <Route exact key={997} path="/:appname/newsfeedsingle/:pageId" component={NewsFeedSingle} />
                        <Route exact key={997} path="/:appname/newsfeedfaq/:pageId" component={NewsFeedFaq} />
                        <Route exact key={997} path="/:appname/newsfilearchive/:pageId" component={NewsFileArchive} />
                        {/* Anniversary */}
                        <Route exact key={997} path="/:appname/anniversary/:pageId" component={Anniversary} />
                        {/* Contacts */}
                        <Route exact key={988} path="/:appname/contacts/:pageId/" component={Contacts} />
                        <Route exact key={988} path="/:appname/contacts/:pageId/groups/:groupId" component={ContactsGroup} />
                        <Route exact key={985} path="/:appname/Checklist/:pageId" component={ChecklistOverview} />
                        <Route exact key={985} path="/:appname/Checklist/:pageId/:checklistId" component={Checklist} />
                        <Route exact key={989} path="/:appname/Profile/:userId" component={Profile} />
                        <Route exact key={998} path="/:appname/NavigationList/:pageId/" component={NavigationList} />
                        <Route exact key={998} path="/:appname/NavigationGrid/:pageId/" component={NavigationList} />
                        <Route exact key={996} path="/:appname/social/:pageId" component={SocialFeed} />
                        <Route exact key={996} path="/:appname/localsocial/:pageId" component={SocialFeed} />
                        <Route exact key={996} path="/:appname/social/:pageId/:postId" component={SocialSingle} />
                        <Route exact key={996} path="/:appname/chatNavigationList/:pageId" component={ChatNavigationList} />
                        <Route exact key={996} path="/:appname/chat/:pageId" component={SocialFeed} />
                        <Route exact key={996} path="/:appname/chat/:pageId/users" component={ChatGroupUsersPage} />
                        <Route exact key={996} path="/:appname/chat/:pageId/:postId" component={SocialSingle} />
                        <Route exact key={992} path="/:appname/Birthdays/:pageId" component={Birthdays} />
                        <Route exact key={993} path="/:appname/idcard/:pageId" component={IdCard} />
                        <Route exact key={993} path="/:appname/Quiz/:pageId" component={QuizOverview} />
                        <Route
                          exact
                          key={993}
                          path="/:appname/NormalHighscore/:pageId"
                          component={NormalHighscoreOverview}
                        />
                        <Route
                          exact
                          key={993}
                          path="/:appname/link-collection-overview/:pageId"
                          component={LinkCollectionOverview}
                        />
                        <Route exact key={993} path="/:appname/Challenge/:pageId" component={ChallengeOverview} />
                        <Route exact key={993} path="/:appname/Certificate/:pageId" component={CertificateOverview} />
                        <Route exact key={994} path="/:appname/Schedule/:pageId" component={ScheduleOverview} />
                        <Route exact key={994} path="/:appname/Schedule/:pageId/:groupId" component={Schedule} />
                        <Route
                          exact
                          key={994}
                          path="/:appname/survey-navigation-list/:pageId"
                          component={SurveyNavigationList}
                        />
                        <Route exact key={994} path="/:appname/survey/:pageId/:registrationId" component={SurveySingle} />
                        <Route
                          exact
                          key={993}
                          path="/:appname/registration-navigation-list/:pageId"
                          component={RegistrationNavigationList}
                        />
                        <Route exact key={993} path="/:appname/registration/:pageId" component={RegistrationOverview} />
                        <Route
                          exact
                          key={993}
                          path="/:appname/registration-accordion/:pageId"
                          component={RegistrationOverview}
                        />
                        <Route
                          exact
                          key={993}
                          path="/:appname/my-registration/:pageId"
                          component={MyRegistrationsOverview}
                        />
                        <Route
                          exact
                          key={993}
                          path="/:appname/my-registration-accordion/:pageId"
                          component={MyRegistrationsOverview}
                        />
                        <Route exact key={993} path="/:appname/registration-form/:pageId" component={RegistrationForm} />
                        <Route
                          exact
                          key={993}
                          path="/:appname/cheers-for-peers/:pageId"
                          component={CheersForPeersOverview}
                        />
                        <Route exact key={993} path="/:appname/cheers-to-me-accordion/:pageId" component={CheersToMe} />

                        {/* Equipment booking */}
                        <Route
                          exact
                          key="1032"
                          path="/:appname/equipment-booking/:pageId"
                          component={EquipmentBookingOverview}
                        />
                        <Route
                          exact
                          key="1032"
                          path="/:appname/equipment-booking/:pageId/equipment/:equipmentId"
                          component={EquipmentBookingSingle}
                        />

                        <Route exact key={993} path="/:appname/events/:pageId" component={EventOverview} />
                        <Route exact key={993} path="/:appname/events/:pageId/:eventId" component={EventDetailsOverview} />
                        <Route exact key={993} path="/:appname/sick-leave/:pageId" component={SickLeave} />

                        <Route exact key={994} path="/:appname/Iframes/:pageId" component={IFrame} />

                        {/* Single file modules */}
                        <Route exact key={995} path="/:appname/Highscore/:pageId" component={Highscore} />
                        <Route
                          exact
                          key={999}
                          path="/:appname/christmasHighscore/:pageId"
                          component={ChristmasHighscoreOverview}
                        />
                        <Route exact key={985} path="/:appname/HappyScore/:pageId" component={HappyScore} />
                        {/* Events */}
                        {/* <Route exact key={982} path="/:appname/Events/:pageId" component={Events} />
                        <Route exact key={984} path="/:appname/Events/:pageId/:eventId" component={EventDetails} /> */}
                        {/* Courses */}
                        {/* <Route exact key={10} path="/:appname/Courses/:pageId" component={CoursesOverview} />
                        <Route exact key={11} path="/:appname/Courses/:pageId/:courseId" component={CourseDetails} />
                        <Route
                          exact
                          key={12}
                          path="/:appname/Courses/:pageId/:courseId/:dateId"
                          component={CourseSingleDate}
                        /> */}
                        {/* Scheduled checklists module */}
                        <Route
                          exact
                          key="1001"
                          path="/:appname/checklist-navigation-list/:pageId"
                          component={ScheduledChecklistNavigationList}
                        />
                        <Route
                          exact
                          key="1001"
                          path="/:appname/scheduled-checklist/:pageId"
                          component={ScheduledChecklist}
                        />

                        {/* Default and frontpage */}
                        <Route exact key={983} path="/:appname/search" component={SearchModalMain} />
                        <Route exact key={983} path="/:appname/FrontPage" component={FrontPage} />
                        <Route exact key={983} path="/:appname/" component={FrontPage} />

                        {/* Route not found */}
                        <Route exact path="*" key={423} component={PageNotFound} />
                      </Switch>
                    </Suspense>
                  </CSSTransition>
                </TransitionGroup>,
              ]}
            />
          </Router>

          {/* General UI components */}
          <ThemeQuizFloatingActionButton />
          <ModalPage />
          <ContextMenu />
          <ToastHandler />
          <Dialog />
          <Lightbox />
          <SideMenu />
        </div>
      );
    }
  }
}

const componentStyle = () => css`
  width: 100%;
  height: 100vh;
  overflow: hidden;
  position: relative;
`;

const mapStateToProps = (state) => ({
  transitionDirection: state.pages.transitionDirection,
  pages: state.pages,
  appConfig: state.appConfig,
  auth: state.auth,
  lang: state.language.language,
});

const mapDispatchToProps = (dispatch) => ({
  getPages: bindActionCreators(getPages, dispatch),
  getAppConfig: bindActionCreators(getAppConfig, dispatch),
  getLanguage: bindActionCreators(getLanguage, dispatch),
  checkToken: bindActionCreators(checkToken, dispatch),
  showDialog: bindActionCreators(showDialog, dispatch),
  hideDialog: bindActionCreators(hideDialog, dispatch),
});

export default connect(mapStateToProps, mapDispatchToProps)(App);
