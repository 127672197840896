import React, { useState } from "react";
import { useSelector } from "react-redux";
import { css } from "emotion";
import iconConverter from "../../../utilities/icon-converter";
import getPageFromId from "../../../utilities/get-page-from-id";
import hm from "../../../utilities/highlight-match";
import colors from "../../../style/colors";
import { ChevronRightIcon } from "mdi-react";

const SearchPageBreadcrumb = ({ page, isLarge }) => {
  const searchTerm = useSelector((s) => s.searchPage.searchTerm);
  const pages = useSelector((s) => s.pages.pages);
  const [pageStack] = useState(() => getPageStack(page, pages, [page]));

  function getPageStack(page, pages, pageStack = []) {
    let parent = getPageFromId(pages, page.parentId);

    if (parent) {
      pageStack.push(parent);
      return getPageStack(parent, pages, pageStack);
    }

    return pageStack.reverse();
  }

  return (
    <div className={`${componentStyles({ isLarge })}`}>
      <div className="left-side-gradient"></div>

      {pageStack.map((p, index) => (
        <>
          <div className={`page-indicator ${p.id === page.id ? "actual-match" : ""}`}>
            {p.icon && iconConverter(p.icon)}
            <p>{p.id === page.id ? hm(p.title, searchTerm) : p.title}</p>
          </div>
          {index !== pageStack.length - 1 && <ChevronRightIcon className="page-seperator" />}
        </>
      ))}
    </div>
  );
};

const componentStyles = ({ isLarge }) => css`
  display: flex;
  align-items: center;
  justify-content: flex-end;
  padding-right: 1rem;
  position: relative;
  overflow: hidden;
  margin-left: -1rem;
  padding-left: 1rem;

  .left-side-gradient {
    position: absolute;
    left: 0rem;
    height: 100%;
    width: 1.25rem;
    z-index: 1;
    background: linear-gradient(to right, rgba(255, 255, 255, 1) 20%, rgba(255, 255, 255, 0) 100%);
  }

  .page-seperator {
    width: 1rem;
    height: 1rem;
    color: ${colors.darkGrey};
    margin: 0 0.25rem;
    flex-shrink: 0;
  }

  .page-indicator {
    display: flex;
    align-items: center;
    flex-shrink: 0;
    font-size: ${isLarge === true ? `1rem` : `0.8rem`};
    color: ${isLarge === true ? colors.black : colors.darkGrey};

    svg {
      color: ${colors.darkGrey};
      width: ${isLarge === true ? `1.15rem` : `0.9rem`};
      height: ${isLarge === true ? `1rem` : `0.9rem`};
      margin-right: 0.6rem;
    }
  }
`;

export default SearchPageBreadcrumb;
