// Libs
import React, { useEffect, useState } from "react";
import { css } from "emotion";

// Redux
import { connect, useSelector } from "react-redux";
import { bindActionCreators } from "redux";
import { addToast, showModalPage } from "../../actions/uiActions";

// Config
import breakpoints from "../../config/breakpoints";

// Components
import Spinner from "../ui/InlineSpinner";
import Button from "../ui/Button";

// Icons
import { ArrowRightIcon, ErrorOutlineIcon } from "mdi-react";

// Utilities
import req from "../../utilities/request-utility";
import CertificateNotPassedResults from "./CertificateNotPassedResults";

// Style
import colors from "../../style/colors";
import ImageCarousel from "../ui/ImageCarousel";
import { getCertificateOverview } from "../../actions/academyActions";
import CertificatePassedResults from "./CertificatePassedResults";
import RadioGroup from "../ui/RadioGroup";
import StepBox from "../ui/StepBox";

const Certificate = (props) => {
  // Destructure the props
  const { showModalPage } = props;

  // Saves the quiz to be able to manipulate with it
  const [certificate, setCertificate] = useState(null);

  // Multi-language handling
  const lang = useSelector((state) => state.language.language);

  // The stepper is used when the user wants the next question
  const [step, setStep] = useState(0);
  const [spinner, setSpinner] = useState(false);

  // Selected answer saves the index of the selected answer
  const [selectedAnswer, setSelectedAnswer] = useState(null);

  // The users answers are saved here
  const [answers, setAnswers] = useState([]);

  // Get the whole certificate
  useEffect(() => {
    req()
      .get(`certificate/${props.certificateId}`)
      .then(({ data: certificate }) => {
        setCertificate(certificate);
        setSpinner(false);
      })
      .catch(() => {
        props.addToast({
          title: `${lang.errorGeneral}`,
          content: `${lang.errorCouldNotGet__placeholder__.replace(/{{placeholder}}/gi, lang.certificate.toLowerCase())}`,
          icon: <ErrorOutlineIcon />,
          styleType: "error",
          duration: 20000,
        });
      });
    // eslint-disable-next-line
  }, []);

  // Will add a answer to the answers-array or post the answers and get a result
  const submitAnswer = (answer) => {
    // If it's not the last question then add the answer to the answers-array
    if (step !== certificate.questions.length - 1) {
      setAnswers([...answers, answer]);
      setStep(step + 1);
      setSelectedAnswer(null);
    } else {
      // Post the answers to the backend and get results
      setSpinner(true);
      req()
        .post(`certificate/${certificate.id}/answer`, {
          userAnswers: [...answers, answer],
        })
        .then(({ data: results }) => {
          // If the user has passed the certificate-quiz then show the results
          if (results.passed) {
            showModalPage({
              title: `${lang.__placeholder__results.replace(/{{placeholder}}/gi, lang.certificate)}`,
              content: <CertificatePassedResults results={results} />,
            });
            props.getCertificateOverview();
          }
          // If the user hasn't passed the certificate-quiz then show stats of results
          if (!results.passed) {
            showModalPage({
              title: `${lang.__placeholder__results.replace(/{{placeholder}}/gi, lang.certificate)}`,
              content: <CertificateNotPassedResults results={results} />,
            });
          }
        });
    }
  };

  return (
    <div className={componentStyle(breakpoints, colors)}>
      <div className="content-wrapper">
        {spinner && <Spinner />}

        {certificate && !spinner && (
          <>
            <StepBox
              style={{ marginBottom: "0.5rem" }}
              currentStep={step + 1}
              lastStep={certificate.questions.length}
              title={lang.questions.toLowerCase()}
            />

            <p className="title">{`${certificate.questions[step].title}?`}</p>

            <ImageCarousel images={certificate.questions[step].images} />
            {/* Questions */}
            {step < certificate.questions.length && (
              <RadioGroup
                style={{ marginBottom: "2rem" }}
                options={certificate.questions[step].answers}
                onSelect={(radioId) => {
                  setSelectedAnswer(certificate.questions[step].answers.findIndex((a) => a.id === radioId));
                }}
              />
            )}

            <Button
              data-test-id={"next-question"}
              disabled={selectedAnswer === null}
              onClick={() => {
                submitAnswer({
                  answerId: certificate.questions[step].answers[selectedAnswer].id,
                  questionId: certificate.questions[step].id,
                });
              }}
              style={{ display: "flex", flexDirection: "row", alignItems: "center", justifyContent: "center" }}
            >
              <p style={{ marginRight: "0.4rem" }}>{lang.next}</p>
              <ArrowRightIcon size={20} />
            </Button>
          </>
        )}
      </div>
    </div>
  );
};

const componentStyle = (breakpoints, colors) => css`
  background-color: ${colors.white};

  height: 100%;

  div.content-wrapper {
    padding: 1rem;
    max-width: ${breakpoints.md}px;
    margin: auto;
  }

  .title {
    font-weight: bold;
    margin-bottom: 1rem;
  }

  .answer-box {
    display: flex;
    flex-direction: row;
    justify-content: flex-start;
    border: 1px solid ${colors.lightGrey};
    padding: 0.6rem;
    align-items: center;
  }

  .border-top-no {
    border-top: 0px;
  }

  .questions-progress {
    margin-bottom: 1rem;
  }
`;

const mapDispatchToProps = (dispatch) => ({
  getCertificateOverview: bindActionCreators(getCertificateOverview, dispatch),
  showModalPage: bindActionCreators(showModalPage, dispatch),
  addToast: bindActionCreators(addToast, dispatch),
});

export default connect(null, mapDispatchToProps)(Certificate);
