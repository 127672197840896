const dataTestIds = {
  phoneBookContact: "phonebook-contact",
  phoneBookGroup: "phonebook-group",
  phoneBookTitle: "phonebook-title",
  phoneBookSubTitle: "phonebook-sub-title",
  phoneBookMiddleTitle: "phonebook-middle-title",
  phoneBookContent: "phonebook-content",

  keyPersonContact: "key-person-contact",
  keyPersonGroup: "key-person-group",
  keyPersonTitle: "key-person-title",
  keyPersonSubTitle: "key-person-sub-title",
  keyPersonMiddleTitle: "phonebook-middle-title",
  keyPersonContent: "phonebook-content",
};

const dataTestTypes = {
  component: "component",
  title: "title",
  subTitle: "subTitle",
  middleTitle: "middleTitle",
  content: "content",
};

module.exports = {
  dataTestIds,
  dataTestTypes,
};
