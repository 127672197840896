import React from "react";
import { css } from "emotion";
import colors from "../../style/colors";
import breakpoints from "../../config/breakpoints";
import { ImageIcon } from "mdi-react";
import ActionWrapper from "../ui/ActionWrapper";

const EquipmentBookingListItem = ({
  title,
  subTitle,
  content,
  image,
  isAvailable,
  style,
  className,
  onClick,
  iconRight,
}) => {
  return (
    <ActionWrapper
      style={style}
      className={componentStyle({ image }) + ` enable-max-width ${className || ""}`}
      onClick={onClick}
    >
      <div className="image">
        {isAvailable ? <p className="badge available">LEDIG</p> : <p className="badge booked">UDLÅNT</p>}
        {!image && <ImageIcon className="no-image" />}
      </div>
      <div className={`content`}>
        <p className="title">{title}</p>
        <p className="sub-title">{subTitle}</p>
        <p className="content">{content}</p>
      </div>

      {iconRight && <div className="icon-right">{iconRight}</div>}
    </ActionWrapper>
  );
};

const componentStyle = ({ image }) => css`
  margin-bottom: -1px;
  background-color: ${colors.white};
  border-top: 1px solid ${colors.midGrey};
  border-bottom: 1px solid ${colors.midGrey};
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 0.65rem;
  color: ${colors.black};
  flex-shrink: 0;
  flex-grow: 0;
  width: 100%;

  &:last-child {
    margin-bottom: 0;
  }

  &.enable-max-width {
    @media screen and (min-width: ${breakpoints.md}px) {
      max-width: ${breakpoints.md}px;
      margin-left: auto !important;
      margin-right: auto !important;
      border: 1px solid ${colors.midGrey};
      border-radius: 0px;
    }

    &:first-of-type {
      border-top-left-radius: 3px;
      border-top-right-radius: 3px;
    }
    &:last-of-type {
      border-bottom-left-radius: 3px;
      border-bottom-right-radius: 3px;
    }
  }

  &:hover,
  &:focus {
    background-color: ${colors.ultraLightGrey};
    cursor: pointer;
  }
  &:active {
    background-color: ${colors.lightGrey};
  }

  .icon-right {
    margin-right: 0rem;
    flex-basis: 20px;
    flex-shrink: 0;
    display: flex;
    justify-content: center;
  }

  .title {
    font-weight: 700;
    font-size: 1rem;
    margin-bottom: 0.25rem;
  }
  .sub-title {
    font-size: 0.9rem;
    color: ${colors.darkGrey};
    margin-bottom: 0.25rem;
  }

  .content {
    flex: 1 1 100%;
    overflow: hidden;
    text-overflow: ellipsis;
    min-width: 0;
    padding-right: 0.5rem;
    font-size: 0.9rem;
  }
  .image {
    width: 66px;
    height: 66px;
    border: 1px #ccc solid;
    flex-shrink: 0;
    border-radius: 3px;
    margin-right: 0.5rem;
    position: relative;
    ${image ? `background-image: url(${image.baseURL}w_66,h_66,q_auto:best,c_fill/${image.image});` : ""}
    background-size: cover;
    background-color: ${colors.ultraLightGrey};

    svg.no-image {
      color: ${colors.midGrey};
      position: absolute;
      left: 50%;
      top: 50%;
      transform: translate3d(-50%, -50%, 0);
    }

    .badge {
      position: absolute;
      top: -1px;
      left: -1px;
      font-size: 0.65rem;
      padding: 0.05rem 0.45rem 0.05rem 0.35rem;
      color: ${colors.white};
      font-weight: 700;
      border-radius: 3px 0 6px 0;
      &.available {
        background-color: ${colors.green};
      }

      &.booked {
        background-color: ${colors.red};
      }
    }
  }
`;

export default EquipmentBookingListItem;
