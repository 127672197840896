// Libs
import React from "react";
import { css } from "emotion";
import { useSelector } from "react-redux";
import tinycolor from "tinycolor2";

// Components
import InlineSpinner from "./InlineSpinner";
import ActionWrapper from "./ActionWrapper";
import { ThumbsUpIcon, ThumbsUpOutlineIcon } from "mdi-react";

// Styles
import colors from "../../style/colors";
import { CSSTransition } from "react-transition-group";

const transitionDuration = 300;

const LikeButton = (props) => {
  const { liked = false, submittingLike = false, onLike, likesVisible, getLikeText, showComments } = props;

  const { primaryColor } = useSelector((state) => state.appConfig);

  return (
    <div className={`${componentStyle(primaryColor)} ${likesVisible ? "active" : ""} ${liked ? "liked" : "unliked"}`}>
      <ActionWrapper className="circle" onClick={onLike} data-test-id={liked ? "btn-unlike-post" : "btn-like-post"}>
        <CSSTransition
          in={!submittingLike && liked}
          timeout={transitionDuration}
          mountOnEnter={true}
          unmountOnExit={true}
          classNames="icon"
        >
          <ThumbsUpIcon style={{ marginBottom: "-2px" }} />
        </CSSTransition>

        <CSSTransition
          in={!submittingLike && !liked}
          timeout={transitionDuration}
          mountOnEnter={true}
          unmountOnExit={true}
          classNames="icon"
        >
          <ThumbsUpOutlineIcon style={{ marginBottom: "-2px" }} />
        </CSSTransition>

        {submittingLike && <InlineSpinner color={liked && colors.white} size="17" style={{ marginBottom: "-0.5rem" }} />}
      </ActionWrapper>
      <ActionWrapper data-test-id="btn-likes-toggle" onClick={() => showComments()}>
        <p className="meta">{getLikeText()}</p>
      </ActionWrapper>
    </div>
  );
};

const componentStyle = (primaryColor) => css`
  display: flex;
  align-items: center;
  text-overflow: ellipsis;
  white-space: nowrap;

  p {
    margin: 0 0.5rem;
    color: ${colors.darkGrey};
    line-height: 2.25rem;

    &:hover {
      cursor: pointer;
      color: ${primaryColor};
    }
  }

  .circle {
    display: flex;
    height: 2.25rem;
    width: 2.25rem;
    z-index: 1;
    align-items: center;
    justify-content: center;
    border-radius: 50%;
    transition: background-color 300ms ease;
    position: relative;
    overflow: hidden;

    svg {
      position: absolute;
      width: 1rem;
      height: 1rem;
    }
  }

  &.liked {
    .circle {
      background-color: ${tinycolor(primaryColor).setAlpha(1).toString()};

      &:active {
        background-color: ${tinycolor(primaryColor).setAlpha(0.8).toString()};
        transition: background-color 120ms ease;
      }

      svg {
        color: ${colors.white};
      }
    }
  }

  &.unliked {
    .circle {
      background-color: ${tinycolor(primaryColor).setAlpha(0.1).toString()};

      &:active {
        background-color: ${tinycolor(primaryColor).setAlpha(0.2).toString()};
        transition: background-color 120ms ease;
      }

      svg {
        color: ${tinycolor(primaryColor).setAlpha(1).toString()};
      }
    }
  }

  .icon-enter {
    opacity: 0;
    transform: scale(1) translate3d(0, 25px, 0);
  }
  .icon-enter-active {
    opacity: 1;
    transform: scale(1) translate3d(0, 0, 0);
    transition: transform ${transitionDuration}ms ease, opacity ${transitionDuration}ms;
    transform-origin: center center;
  }
  .icon-exit {
    opacity: 1;
    transform: scale(1) translate3d(0, 0, 0);
  }
  .icon-exit-active {
    opacity: 0;
    transform: scale(0.75) translate3d(0, -25px, 0);
    transition: transform ${transitionDuration}ms ease, opacity ${transitionDuration}ms;
    transform-origin: center center;
  }
`;

export default LikeButton;
