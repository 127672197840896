import React, { useEffect } from "react";
import { useSelector } from "react-redux";
import { css } from "emotion";
import { RadioboxBlankIcon, RadioboxMarkedIcon, ApprovalIcon } from "mdi-react";
import PropTypes from "prop-types";

//Style
import common from "../../style/common";
import colors from "../../style/colors";

/** Select list for lists where only 1 element must be selected at one time
 *
 * ```
 * Options array passed to this element should be structured:
 * [ {id: XX, title: YY}]
 * ```
 */

const RadioGroupShowCorrectAnswers = (props) => {
  const { answers, userAnswer, showIfCorrectAnswer = true } = props;
  const primaryColor = useSelector((state) => state.appConfig.primaryColor);
  const lang = useSelector((state) => state.language.language);

  /** Checks if  */
  function isCorrect(answer, userAnswer) {
    // If you have a list of correct anwers you can check that, otherwise trust what the userAnswer says itself
    if ((answer.correct && answer.id === userAnswer.answerId) || userAnswer.isCorrect) {
      return "correct";
    } else {
      return "incorrect";
    }
  }

  useEffect(() => {
    if (userAnswer && userAnswer.isCorrect && props.answerCorrectAction) props.answerCorrectAction();
    if (userAnswer && !userAnswer.isCorrect && props.answerWrongAction) props.answerWrongAction();
    // eslint-disable-next-line
  }, []);

  return (
    <div className={componentStyle(primaryColor)} style={props.style}>
      {answers.map((answer) =>
        answer.id === userAnswer.answerId ? (
          <div
            key={`answer-${answer.id}`}
            className={`radio selected ${showIfCorrectAnswer && isCorrect(answer, userAnswer)}`}
          >
            <RadioboxMarkedIcon />
            <p>{answer.title}</p>
            <div className="answer-indicator">
              <p className="user-answer">{lang.yourAnswer}</p>
              <ApprovalIcon style={{ marginLeft: "0.5rem" }} className="correct-icon" />
            </div>
          </div>
        ) : (
          <div key={`answer-${answer.id}`} className={`radio ${answer.correct ? "correct" : "incorrect"}`}>
            <RadioboxBlankIcon />
            <p>{answer.title}</p>
            <div className="answer-indicator">
              <p className="user-answer">{lang.yourAnswer}</p>
              <ApprovalIcon style={{ marginLeft: "0.5rem" }} className="correct-icon" />
            </div>
          </div>
        )
      )}
    </div>
  );
};

const componentStyle = (primaryColor) => css`
  border: 1px solid ${colors.midGrey};
  border-radius: 3px;

  .radio {
    display: flex;
    flex-direction: row;
    align-items: center;
    -webkit-appearance: none;
    border-bottom: 1px solid ${colors.midGrey};
    padding: 0.75rem 0.85rem;
    font-weight: 400;
    position: relative;
    justify-content: space-between;

    svg {
      color: ${colors.midGrey};
      flex-shrink: 0;
    }

    p {
      margin-left: 0.5rem;
      font-size: 1rem;
      font-family: ${common.fontStack};
    }

    /* Color highlight don't show whether correct or not */
    &.selected,
    &.selected svg,
    &.selected p {
      color: ${primaryColor};
      font-weight: 700;
    }

    /* Color highligt wrong answer */
    &.selected.incorrect,
    &.selected.incorrect svg,
    &.selected.incorrect p {
      color: ${colors.red};
      font-weight: 700;
    }
    &.selected.incorrect p {
      color: ${colors.red};
      border-color: ${colors.red};
    }

    /* Color highlight right answer */
    &.correct,
    &.correct svg,
    &.correct p {
      color: ${colors.green};
      font-weight: 700;
    }
    &.correct .answer-indicator p {
      color: ${colors.green};
      border-color: ${colors.green};
    }

    /* Answer indicator */
    .answer-indicator {
      transition: scale 200ms ease opacity 120ms ease;
      opacity: 0;
      transform: scale(0);
      flex-grow: 1;
      flex-shrink: 0;
      display: flex;
      justify-content: flex-end;
      margin-left: 0.5rem;

      .user-answer {
        font-weight: 400;
        font-size: 0.8rem;
        border: 1px ${colors.lightGrey} solid;
        border-radius: 4px;
        padding: 0.1rem 0.45rem;
        color: ${colors.darkGrey};
      }
    }

    &.selected .answer-indicator {
      transition: scale 200ms ease opacity 120ms ease;
      opacity: 1;
      transform: scale(1);
    }

    &.correct .answer-indicator {
      transition: scale 200ms ease opacity 120ms ease;
      opacity: 1;
      transform: scale(1);
      .user-answer {
        display: none;
      }
    }

    &.selected.correct .answer-indicator .user-answer {
      display: block;
    }
  }

  .incorrect .correct-icon {
    display: none;
  }

  div:last-of-type {
    border-bottom: none;
  }
`;

RadioGroupShowCorrectAnswers.propTypes = {
  /** Function to be executed when an option is selected */
  onSelect: PropTypes.func,
  /** Array which holds the selectable elements */
  options: PropTypes.array,
  /** Override style of component with this */
  style: PropTypes.object,
};

export default RadioGroupShowCorrectAnswers;
