import React from "react";
import { css } from "emotion";
import colors from "../../style/colors";
import PropTypes from "prop-types";

/** used for displaying items in a grid system, usecase could be: menuitems with icons, to make it easy to scan quickly.
 * ```
 *  <GridItem
                      imageLeft={getProfilePictureFromUserObject(birthdayUser)}
                      title={birthdayUser.name}
                      subTitle={birthdayUser.userGroup}
                      style={{ marginLeft: `-${boxPadding}`, marginRight: `-${boxPadding}` }}
                    />
 * ```
*/

const GridItem = (props) => (
  <div
    style={props.style}
    ref={props.scrollTopRef}
    className={componentStyle(props) + `${props.maxWidth ? " enable-max-width" : ""} ${props.className || ""}`}
    onClick={props.onClick}
    data-test-id={props.dataTestId}
  >
    {/* Number left */}
    {props.itemNumberLeft && <div className="icon-left">{props.itemNumberLeft}</div>}
    {/* Icon left */}
    {props.iconLeft && <div className="icon-left">{props.iconLeft}</div>}
    {/* Image left */}
    {props.imageLeft && <div>{props.imageLeft}</div>}

    <div className="content wrap">
      <p data-test-id={props.dataTestIdTitle} style={props.titleStyle && props.titleStyle} className="title">
        {props.title}
      </p>
      <p data-test-id={props.dataTestIdmiddleTitle} style={props.middleTitleStyle} className="middle-title">
        {props.middleTitle}
      </p>
      <p data-test-id={props.dataTestIdSubTitle} style={props.subTitleStyle} className="meta">
        {props.subTitle}
      </p>
      {props.content && props.content}
    </div>

    {props.notificationsRight && (
      <div className={`icon-right ${!props.iconLeft && `no-icon-left`} `} style={{ marginRight: "0.25rem" }}>
        {props.notificationsRight}
      </div>
    )}

    {props.contentRight && (
      <div className="content-right" style={props.contentRightStyle && props.contentRightStyle}>
        {props.contentRight}
      </div>
    )}
  </div>
);

const componentStyle = (props) => css`
  margin-bottom: -1px;
  background-color: ${colors.white};
  border-top: 1px solid ${colors.midGrey};
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  padding: 1rem 1rem;
  color: ${colors.black};
  flex: 50% 1 1;
  max-height: 220px;
  position: relative;
  word-break: break-word;
  hyphens: auto;

  &:last-child {
    margin-bottom: 0;
  }

  &.enable-max-width {
    @media screen and (min-width: ${props.maxWidth}px) {
      max-width: ${props.maxWidth}px;
      margin-left: auto !important;
      margin-right: auto !important;
    }
  }

  &:active,
  &:hover {
    ${props.clickable && `background-color: ${colors.ultraLightGrey};`}
    ${props.clickable && `cursor: pointer;`}
  }

  .icon-left {
    margin-right: ${props.imageLeft === false ? "5px" : "0.5rem"};
    margin-left: ${props.imageLeft === false ? "-5px" : "initial"};
    margin-top: 30px;
    font-size: 26px;
    margin-bottom: 12px;
  }
  .icon-right {
    position: absolute;
    top: 19%;
    left: 54%;

    &.no-icon-left {
      left: inherit;
      right: 0.5rem;
      top: 0.5rem;
    }
  }

  .icon-left,
  .icon-right {
    flex-basis: 20px;
    flex-shrink: 0;
    display: flex;
    justify-content: center;
  }
  .content {
    flex: 1 1 100%;
    overflow: hidden;
    min-width: 0;
    padding-right: 0.5rem;

    .title {
      margin-top: ${props.iconLeft ? "0px" : "30px"};
      margin-bottom: 35px;
      text-align: center;
      color: #000;
      font-size: 16px;
      padding: 0 8px;
    }

    /* Wraps content to new line if it overflows */
    &.wrap {
      .title {
        white-space: initial;
      }
      .middle-title {
        font-size: 0.9rem;
        margin-bottom: 0.125rem;
        color: ${colors.darkGrey};
      }

      .meta {
        color: ${colors.darkGrey};
        white-space: initial;
      }
    }
  }
  .image-wrapper {
    width: 30px;
    height: 30px;
    border: 1px #ccc solid;
    border-radius: 50%;
    flex: 0 0 30px;
    margin-right: 0.5rem;
  }

  :nth-child(even) {
    border-left: 1px solid ${colors.midGrey};
  }
  :nth-child(1) {
    border-top: none;
  }
  :nth-child(2) {
    border-top: none;
  }
`;

GridItem.propTypes = {
  /**  Used for displaying an icon on the left side */
  iconLeft: PropTypes.object,
  /**  Used for displaying an image on the left side */
  imageLeft: PropTypes.object,

  /**  Used for displaying an icon on the right side */
  // iconRight: PropTypes.object,

  /**  Used for displaying text-based content on the right side */
  contentRight: PropTypes.string,

  /** Content main title, for related info, use subtitle Prop */
  title: PropTypes.string,

  /** Descriptive textblock related to title prop */
  subTitle: PropTypes.string,

  /** used for deciding if content should wrap */
  wrapContent: PropTypes.bool,
  /** Used to place a component or element that has nested components */
  content: PropTypes.object,
};
export default GridItem;
