// Libs
import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import tinycolor from "tinycolor2";
import PropTypes from "prop-types";

// Components
import TextareaInput from "./TextareaInput";
import colors from "../../style/colors";
import { css } from "emotion";
import { AlertCircleIcon, PlusIcon, ReplyIcon, UploadIcon } from "mdi-react";
import ImagePreview from "./ImagePreview";
import ActionWrapper from "./ActionWrapper";

// Utilities
import req from "../../utilities/request-utility";
import breakpoints from "../../config/breakpoints";
import AnimateHeight from "react-animate-height";
import { durations } from "../../config/animations";

/** Form element related to Post component, its used giving users a way of adding a comment to the post.
 *
 * This means that the current implementation probably only is there and is like:
 *
 * ```jsx
 * {comments && commentsVisible && (
          <div className="comments-container">
              <PostComments comments={comments} primaryColor={primaryColor} boxPadding={boxPadding} user={user} />
              <PostCommentForm onComment={comment => this.props.onComment(comment)} submittingComment={submittingComment} />
          </div>
        )}
 * ```
 */

function PostCommentForm(props) {
  // Props
  const { onComment, submittingComment, showAddCommentWhenFormIsHidden = true, minHeight = "70px" } = props;

  // Dispatcher
  const dispatch = useDispatch();

  // Local state
  const [showForm, setShowForm] = useState(props.showForm || false);
  const [comment, setComment] = useState(props.comment || { content: "", image: { baseURL: null, image: null } });
  const [uploadImage, setUploadImage] = useState(null);
  const [loadingImage, setLoadingImage] = useState(false);

  useEffect(() => {
    if (props.showForm === true || props.showForm === false) setShowForm(props.showForm);
  }, [props]);

  useEffect(() => {
    if (props.onShowFormUpdate) props.onShowFormUpdate(showForm);

    // eslint-disable-next-line
  }, [showForm]);

  // Redux state
  const lang = useSelector((state) => state.language.language);
  const primaryColor = useSelector((s) => s.appConfig.primaryColor);

  // Handle comment
  const onImageUpload = (e) => {
    // TODO: Validate file type
    let file = e.target.files[0];

    // Disable the submit btn - if there is any
    setLoadingImage(true);

    // Return if no file is selected
    if (!file) return;

    // Check file size
    if (file.size > 200000000) {
      return dispatch.addToast({
        type: "error",
        title: lang.tooBig,
        content: lang.fileIsToBigDescription,
        icon: <AlertCircleIcon />,
      });
    } else if (file.size > 39999900) {
      // TO DO! Reimplement with dialog, once that is implemented
      return dispatch.addToast({
        type: "info",
        title: lang.bigFileDetected,
        content: lang.bigFileDetectedDescription,
        icon: <AlertCircleIcon />,
      });
    }

    // Construct formData with file
    let formData = new FormData();
    formData.append("file", file);

    // Start upload and set state
    uploadFile(formData);
    setUploadImage({ status: "uploading", progress: 0 });
  };

  const uploadFile = (file) => {
    req()
      .post("/images/", file)
      .then(({ data }) => data)
      .then((data) => onFileUploadSuccess(data))
      .catch((err) => onFileUploadFailure(err))
      .catch((err) => onFileUploadFailure(err));
  };

  const onFileUploadSuccess = (res) => {
    setLoadingImage(false);
    setUploadImage({
      status: "uploaded",
      baseURL: res.baseURL,
      image: res.image,
      deleteToken: res.deleteToken,
    });

    setComment({ ...comment, image: { baseURL: res.baseURL, image: res.image } });
  };

  const onFileUploadFailure = (err, index) => {
    dispatch.addToast({
      icon: <AlertCircleIcon />,
      styleType: "error",
      title: lang.error,
      content: lang.errorCouldNotUploadImage,
      duration: 10000,
    });
    setComment({ ...comment, image: { baseURL: null, image: null } });
  };

  const onFileRemove = () => {
    setComment({ ...comment, image: { baseURL: null, image: null } });
  };

  return (
    <div
      className={componentStyle(props, loadingImage, uploadImage, !!comment.image.image, primaryColor)}
      style={props.style}
    >
      {showAddCommentWhenFormIsHidden && (
        <AnimateHeight height={showForm ? 0 : "auto"} duration={durations.normal} animateOpacity={true}>
          <button
            className="show-button"
            onClick={() => setShowForm(true)}
            data-test-id="btn-show-comment-form"
            style={{ pointerEvents: "initial" }}
          >
            <PlusIcon />
            {lang.addComment}
          </button>
        </AnimateHeight>
      )}
      <AnimateHeight height={showForm ? "auto" : 0} duration={durations.normal} animateOpacity={true}>
        <form
          className="comment-container"
          onSubmit={(e) => {
            e.preventDefault();
            onComment(comment);
          }}
        >
          <div style={{ display: "flex", flexDirection: "column", justifyContent: "space-between" }}>
            {/* Comment text field */}
            <TextareaInput
              className="edit-comment"
              data-test-id="post-comment-form-input"
              style={{ border: "none", padding: "0.25rem", resize: "none", fontSize: "0.85rem" }}
              minHeight={minHeight}
              placeholder={lang.enterComment}
              value={comment.content}
              onChange={(e) => setComment({ ...comment, content: e.target.value })}
            />
            {/* Comment buttons */}
            <div className="comment-buttons-container">
              {!loadingImage && !comment.image.image && (
                <label htmlFor={props.imageInputId} className="comment-btn image">
                  <UploadIcon />
                  <p>{lang.picture}</p>
                  <input
                    onChange={(e) => onImageUpload(e)}
                    style={{ display: "none" }}
                    id={props.imageInputId}
                    accept="image/*"
                    type="file"
                  />
                </label>
              )}

              <ActionWrapper
                active={submittingComment}
                disabled={loadingImage || (!comment.content && !comment.image.baseURL) ? "disabled" : ""}
                onClick={() => {
                  onComment(comment);
                  setComment({ content: "", image: { baseURL: null, image: null } });
                  setShowForm(false);
                }}
                className={`comment-btn submit`}
              >
                <ReplyIcon />
                <p>{props.comment ? lang.update : lang.send}</p>
              </ActionWrapper>
            </div>
          </div>
          {/* photo */}
          {(loadingImage || !!comment.image.image) && (
            <ImagePreview
              onFileRemove={onFileRemove}
              loadingImage={loadingImage}
              urlPrefix={true}
              baseURL={comment.image.baseURL && comment.image.baseURL}
              image={comment.image.image && comment.image.image}
            />
          )}
        </form>
      </AnimateHeight>
    </div>
  );
}

const componentStyle = (props, loadingImage, image, stateImage, primaryColor) => css`
  max-width: ${breakpoints.md}px;
  margin: auto;

  .comment-container {
    display: flex;
    border: 1px solid ${colors.lightGrey};
    border-radius: 5px;
    padding: 0.25rem;
    height: auto;
    background-color: ${colors.white};

    &:focus-within {
      border: 1px ${primaryColor} solid;
    }

    div:first-of-type {
      flex: 1 0 80%;
      padding-right: ${loadingImage || image || stateImage ? 0.125 : 0}rem;

      div.comment-buttons-container {
        display: flex;
        justify-content: flex-end;
        max-height: 1.5rem;

        .comment-btn {
          display: flex;
          align-items: center;
          border-radius: 50px;
          padding: 0 1.25rem;

          &.submit {
            background-color: ${tinycolor(primaryColor).setAlpha(0.1).toString()};
            margin-right: ${loadingImage || image ? 0.25 : 0}rem;
          }

          &:hover {
            background-color: ${tinycolor(primaryColor).setAlpha(0.06).toString()};
            cursor: pointer;
          }

          &:active {
            background-color: ${tinycolor(primaryColor).setAlpha(0.2).toString()};
          }

          &.image {
            background-color: unset;
            margin-right: 0.25rem;
            border: 1px solid ${colors.lightGrey};

            &:hover {
              background-color: ${tinycolor(colors.lightGrey).setAlpha(0.1).toString()};
            }

            &:active {
              background-color: ${tinycolor(colors.lightGrey).setAlpha(0.3).toString()};
            }
          }

          &:disabled {
            background-color: ${colors.lightGrey};
            cursor: not-allowed;

            p,
            svg {
              color: ${colors.darkGrey};
            }
          }

          p {
            color: ${primaryColor};
            font-size: 0.85rem;
          }

          svg {
            color: ${primaryColor};
            width: 1rem;
            margin-right: 0.25rem;
          }
        }
      }
    }

    div:last-of-type {
      flex: 1 1 20%;
    }
  }

  .show-button {
    appearance: none;
    border: 0;
    background-color: ${tinycolor(primaryColor).setAlpha(0.1).toString()};
    backdrop-filter: blur(20px);
    color: ${primaryColor};
    font-size: 1rem;
    text-align: center;
    display: flex;
    align-items: center;
    line-height: 2.5rem;
    margin: 0.65rem auto 0;
    padding: 0 1.5rem;
    border-radius: 1.25rem;
    cursor: pointer;
    outline: none;

    &:hover {
      background-color: ${tinycolor(primaryColor).setAlpha(0.05).toString()};
    }

    &:focus {
      background-color: ${tinycolor(primaryColor).setAlpha(0.15).toString()};
    }

    &:active {
      background-color: ${tinycolor(primaryColor).setAlpha(0.15).toString()};
    }

    svg {
      width: 1.25rem;
      height: 1.25rem;
      margin-right: 0.25rem;
    }
  }
`;

PostCommentForm.propTypes = {
  /** Function to be executed when a comment is submitted by the user */
  onComment: PropTypes.func,
  /** Bool value to show a current comment being submitted */
  submittingComment: PropTypes.bool,
};

export default PostCommentForm;
