// Libs
import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";

// Components
import ContextMenuButton from "../ui/ContextMenuButton";
import ListItem from "../../components/ui/ListItem";
import Notification from "../ui/Notification";
import Page from "../ui/Page";
import ScrollView from "../ui/ScrollView";
import SkeletonLine from "../ui/skeletons/SkeletonLine";
import StatusBox from "../ui/StatusBox";
import TopBar from "../../components/ui/TopBar";

// Utilities
import getAppName from "../../utilities/get-app-name";
import getPageFromId from "../../utilities/get-page-from-id";
import iconConverter from "../../utilities/icon-converter";
import linkPathCreator from "../../utilities/get-link-path-from-page-object";
import pageNavigatorV2, { pageTransitions } from "../../utilities/page-navigator-v2";
import req from "../../utilities/request-utility";

// Style
import breakpoints from "../../config/breakpoints";
import colors from "../../style/colors";

// Icons
import { AccountMultipleIcon, ChevronRightIcon, FeatureSearchOutlineIcon } from "mdi-react";

// Redux
import { showContextMenu } from "../../actions/uiActions";

function ChatNavigationList(props) {
  const pages = useSelector((state) => state.pages.pages);
  const lang = useSelector((state) => state.language.language);
  const [page] = useState(getPageFromId(pages, props.match.params.pageId));
  const [membersCount, setMembersCounts] = useState([]);
  const [loadingMembersCount, setLoadingMembersCount] = useState(true);
  const dispatch = useDispatch();

  useEffect(() => {
    getNumberOfMembersInChatGroups();
  }, []);

  function getNumberOfMembersInChatGroups() {
    let ids;
    if (page.pages && page.pages.length) {
      ids = page.pages
        // Only include for social and chat pages
        .filter((page) => ["social", "chat"].includes(page.module.toLowerCase()))
        // Only return the id
        .map((page) => page.dataId);

      if (ids.length > 0) {
        req()(`social/chat-group-members-count?ids=${ids.join(",")}`)
          .then(({ data }) => {
            setMembersCounts(data);
            setLoadingMembersCount(false);
          })
          .catch(() => {
            setLoadingMembersCount(false);
          });
      }
    }
  }

  function getMembersText({ currentMemberCountObject }) {
    // If result isn't present in the list its because there are no active users in the group which effectively
    // means that no users can access the group
    if (!currentMemberCountObject) return null;
    // if (!currentMemberCountObject) return `${lang.none} ${lang.members}`;

    if (currentMemberCountObject.targetGroup === false) return `${lang.all} ${lang.users.toLowerCase()}`;
    return `${currentMemberCountObject.members} ${lang.members}`;
  }

  function onContextMenuClick(e, pageId) {
    e.stopPropagation();
    dispatch(
      showContextMenu({
        actions: [
          {
            icon: <AccountMultipleIcon />,
            title: `${lang.see} ${lang.members}`,
            callback: () =>
              pageNavigatorV2({
                path: `/${getAppName()}/chat/${pageId}/users`,
                direction: pageTransitions.forward,
              }),
          },
        ],
      })
    );
  }

  return (
    <Page>
      <TopBar title={page.title} useDefaultBackButton={true} />
      <ScrollView style={{ padding: "1rem 0", paddingTop: "1rem" }}>
        {page.pages && page.pages.length === 0 && (
          <StatusBox icon={<FeatureSearchOutlineIcon />} title={lang.noContentTitle} content={lang.noContentContent} />
        )}

        {page.pages &&
          page.pages.length !== 0 &&
          page.pages.map((subPage, index) => {
            let currentMemberCountObject = membersCount.find((d) => d.id === subPage.id);

            return (
              <ListItem
                maxWidth={breakpoints.lg}
                clickable={true}
                onClick={() =>
                  pageNavigatorV2({
                    path: linkPathCreator(subPage, "absolute"),
                    direction: "forward",
                    pageId: subPage.id,
                  })
                }
                className="list-item"
                title={subPage.title}
                subTitle={
                  <div className="sub-title">
                    {loadingMembersCount && (
                      <SkeletonLine style={{ marginTop: "0.45rem", marginBottom: "0.18rem" }} animationDelay={index * 50} />
                    )}
                    {!loadingMembersCount && membersCount.length > 0 && getMembersText({ currentMemberCountObject })}
                  </div>
                }
                iconLeft={subPage.icon ? iconConverter(subPage.icon, { color: colors.darkGrey }) : null}
                notificationsRight={subPage.notifications > 0 && <Notification notifications={subPage.notifications} />}
                iconRight={
                  <>
                    {currentMemberCountObject && currentMemberCountObject.targetGroup && (
                      <ContextMenuButton onClick={(e) => onContextMenuClick(e, subPage.id)} />
                    )}
                    <ChevronRightIcon />
                  </>
                }
                key={subPage.id}
              />
            );
          })}
      </ScrollView>
    </Page>
  );
}
export default ChatNavigationList;
