import {
  END_OF_REGISTRATIONS_POSTS,
  GET_REGISTRATION_POSTS,
  GET_REGISTRATION_POSTS_FAILURE,
  GET_REGISTRATION_POSTS_STATISTICS_SUCCESS,
  GET_REGISTRATION_POSTS_SUCCESS,
  LIKE_REGISTRATION_POST,
  LIKE_REGISTRATION_POST_FAILURE,
  READ_REGISTRATION_POST,
  READ_REGISTRATION_POST_FAILURE,
  REFETCH_REGISTRATION_POST_FAILURE,
  REFETCH_REGISTRATION_POST_LIKES_FAILURE,
  REFETCH_REGISTRATION_POST_LIKES_SUCCESS,
  REFETCH_REGISTRATION_POST_SUCCESS,
  RESET_REGISTRATION,
  RESET_REGISTRATION_FEED,
  UNLIKE_REGISTRATION_POST,
  UNLIKE_REGISTRATION_POST_FAILURE,
} from "../actions/actionTypes";

const initialState = {
  all: {
    posts: [],
    loading: false,
    endOfFeed: false,
    error: false,
  },
  mostLiked: {
    posts: [],
    loading: false,
    endOfFeed: false,
    error: false,
  },
  mine: {
    posts: [],
    loading: false,
    endOfFeed: false,
    error: false,
  },
  toMe: {
    posts: [],
    loading: false,
    endOfFeed: false,
    error: false,
  },
  postsWithLoadingSwipes: [],
  postsWithLoadingLikes: [],
  statistics: {
    posts: {
      all: {
        sent: null,
      },
      mine: {
        sent: null,
        received: null,
      },
    },
  },
};

export default function (state = initialState, action) {
  switch (action.type) {
    case GET_REGISTRATION_POSTS:
      return {
        ...state,
        [action.payload.feedType]: {
          ...state[action.payload.feedType],
          loading: true,
          error: false,
        },
      };

    case GET_REGISTRATION_POSTS_SUCCESS:
      return {
        ...state,
        [action.payload.feedType]: {
          ...state[action.payload.feedType],
          loading: false,
          error: false,
          posts: [...state[action.payload.feedType].posts, ...action.payload.posts],
        },
      };

    case GET_REGISTRATION_POSTS_FAILURE:
      return {
        ...state,
        [action.payload.feedType]: {
          ...state[action.payload.feedType],
          loading: false,
          error: true,
        },
      };

    case END_OF_REGISTRATIONS_POSTS:
      return {
        ...state,
        [action.payload.feedType]: {
          ...state[action.payload.feedType],
          loading: false,
          error: false,
          endOfFeed: true,
        },
      };

    case READ_REGISTRATION_POST:
      return {
        ...state,
        postsWithLoadingSwipes: [...state.postsWithLoadingSwipes, action.payload],
      };

    case UNLIKE_REGISTRATION_POST:
    case LIKE_REGISTRATION_POST: {
      return {
        ...state,
        postsWithLoadingLikes: [...state.postsWithLoadingLikes, action.payload],
      };
    }

    case REFETCH_REGISTRATION_POST_SUCCESS: {
      const { feedType, post } = action.payload;
      return {
        ...state,
        postsWithLoadingSwipes: [...state.postsWithLoadingSwipes.map((postId) => postId !== post.id)],
        [feedType]: {
          ...state[feedType],
          posts: [...state[feedType].posts.map((p) => (p.id === post.id ? post : p))],
        },
      };
    }

    case REFETCH_REGISTRATION_POST_LIKES_SUCCESS: {
      const { feedType, postId, likes } = action.payload;
      return {
        ...state,
        [feedType]: {
          ...state[feedType],
          posts: [...state[feedType].posts.map((p) => (p.id === postId ? { ...p, likes } : p))],
        },
        postsWithLoadingLikes: [...state.postsWithLoadingLikes.filter((pId) => pId !== postId)],
      };
    }

    case UNLIKE_REGISTRATION_POST_FAILURE:
    case LIKE_REGISTRATION_POST_FAILURE:
    case REFETCH_REGISTRATION_POST_LIKES_FAILURE: {
      return {
        ...state,
        postsWithLoadingLikes: [...state.postsWithLoadingLikes.map((postId) => postId !== action.payload)],
        ...state[action.payload.feedType],
        error: true,
      };
    }

    case GET_REGISTRATION_POSTS_STATISTICS_SUCCESS:
      return {
        ...state,
        statistics: {
          ...state.statistics,
          posts: {
            ...state.statistics.posts,
            all: action.payload.all,
            mine: action.payload.mine,
          },
        },
      };

    case READ_REGISTRATION_POST_FAILURE:
    case REFETCH_REGISTRATION_POST_FAILURE:
      return {
        ...state,
        postsWithLoadingSwipes: [...state.postsWithLoadingSwipes.map((postId) => postId !== action.payload)],
        [action.payload.feedType]: {
          ...state[action.payload.feedType],
          error: true,
        },
      };

    case RESET_REGISTRATION_FEED: {
      return {
        ...state,
        [action.payload]: {
          ...state[action.payload],
          posts: [],
          error: false,
          endOfFeed: false,
          loading: false,
        },
      };
    }

    case RESET_REGISTRATION: {
      return {
        ...initialState,
      };
    }

    default:
      return state;
  }
}
