// Libs
import React, { useEffect, useState } from "react";
import { css } from "emotion";
import { useSelector } from "react-redux";
import * as queryString from "query-string";

// Components
import Spinner from "../ui/InlineSpinner";
import { InfoOutlineIcon } from "mdi-react";
import StatusBox from "../ui/StatusBox";
import Page from "../ui/Page";
import TopBar from "../ui/TopBar";

// Utilities
import req from "../../utilities/request-utility";

// Styles
import colors from "../../style/colors";

const IFrame = (props) => {
  // Local state
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(false);
  const [iframe, setIframe] = useState(null);

  // Redux state
  const { language: lang } = useSelector((state) => state.language);

  // Get the iFrameId
  let { dataId: iFrameId } = queryString.parse(window.location.search);

  // Get the iFrame URL
  useEffect(() => {
    try {
      req()(`iframe/${iFrameId}`).then(({ data: iframe }) => {
        setIframe(iframe);
        setLoading(false);
      });
    } catch (err) {
      setError(true);
      setLoading(false);
    }
    // eslint-disable-next-line
  }, []);

  return (
    <Page>
      <TopBar useDefaultBackButton={true} title={iframe ? iframe.headline : ""} />
      <div className={componentStyle()} style={props.style}>
        {loading && !error && (
          <div className="loading-container">
            <Spinner />
            <p>{lang.loadingPage}</p>
          </div>
        )}
        {!loading && error && (
          <StatusBox
            style={{ marginTop: "1rem" }}
            icon={<InfoOutlineIcon />}
            title={lang.somethingWentWrong}
            content={lang.tryAgainOrContactSupport}
          />
        )}
        {!loading && !error && <iframe src={iframe && iframe.URL} title={(iframe && iframe.headline) || "iframe"} />}
      </div>
    </Page>
  );
};

const componentStyle = () => css`
  display: flex;
  justify-content: center;
  height: 100%;
  width: 100%;
  background-color: ${colors.white};

  iframe {
    width: 100%;
    height: 100%;
    border: none;
  }

  div.loading-container {
    margin-top: 6rem;
    display: flex;
    flex-direction: column;
    align-items: center;

    p {
      margin-top: 0.125rem;
      color: ${colors.darkGrey};
    }
  }
`;

export default IFrame;
