import store from "../../../store";
import pageConfig from "../../../config/pageConfig";

export function getTitlesForTabBar({ config }) {
  const lang = store.getState().language.language;
  const { PHONEBOOK_LANG_OVERWRITE, KEYPERSONS_LANG_OVERWRITE, HIDE_PHONEBOOK_TAB, HIDE_KEYPERSONS_TAB } = pageConfig;

  let tabs = [];

  if (
    (config && config[HIDE_PHONEBOOK_TAB] && !config[HIDE_KEYPERSONS_TAB]) ||
    (config && !config[HIDE_PHONEBOOK_TAB] && config[HIDE_KEYPERSONS_TAB])
  ) {
    return [];
  }

  // Will apply the language-variable from config to the tabBar title for contact
  if (config && config[PHONEBOOK_LANG_OVERWRITE] && !config[HIDE_PHONEBOOK_TAB]) {
    const title = lang[config[PHONEBOOK_LANG_OVERWRITE]];
    if (title) tabs.push(title);
  }

  // Will apply default language-variable to the tabBar title for contact
  if (!config || (!config[PHONEBOOK_LANG_OVERWRITE] && !config[HIDE_PHONEBOOK_TAB])) {
    tabs.push(lang.phonebook);
  }

  // Will apply the language-variable from config to the tabBar title for keyPersons
  if (config && config[KEYPERSONS_LANG_OVERWRITE] && !config[HIDE_KEYPERSONS_TAB]) {
    const title = lang[config[KEYPERSONS_LANG_OVERWRITE]];
    if (title) tabs.push(title);
  }

  // Will apply default language-variable to the tabBar title for keyPersons
  if (!config || (!config[KEYPERSONS_LANG_OVERWRITE] && !config[HIDE_KEYPERSONS_TAB])) {
    tabs.push(lang.keyPersons);
  }

  return tabs;
}
