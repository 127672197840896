// Libs
import React, { useEffect, useState } from "react";
import { useSelector } from "react-redux";
import tinycolor from "tinycolor2";
import { css } from "emotion";

// Components
import TopBar from "../components/ui/TopBar";
import ListItem from "../components/ui/ListItem";
import GridItem from "../components/ui/GridItem";
import Page from "./ui/Page";
import ScrollView from "./ui/ScrollView";
import Notification from "./ui/Notification";
import ActionWrapper from "./ui/ActionWrapper";
import StatusBox from "./ui/StatusBox";

// Utilities
import pageNavigatorV2 from "../utilities/page-navigator-v2";
import markdownParser from "../utilities/markdown-parser";
import linkPathCreator from "../utilities/get-link-path-from-page-object";
import getPageFromId from "../utilities/get-page-from-id";
import iconConverter from "../utilities/icon-converter";

// Style
import colors from "../style/colors";
import breakpoints from "../config/breakpoints";

// Icons
import { ArrowLeftIcon, ChevronRightIcon, FeatureSearchOutlineIcon } from "mdi-react";
import FloatingSearchInput from "./ui/FloatingSearchInput";

function NavigationList(props) {
  const pages = useSelector((state) => state.pages.pages);
  const lang = useSelector((state) => state.language.language);
  const primaryColor = useSelector((s) => s.appConfig.primaryColor);

  const [page] = useState(getPageFromId(pages, props.match.params.pageId));
  const [filteredSubpages, setFilteredSubpages] = useState(page.pages);
  const [searchTerm, setSearchTerm] = useState("");
  const [hasCoverImage] = useState(page.coverImage && page.coverImage.image && page.coverImage.baseURL ? true : false);

  // We only enable search if there's more than 10 menuitems in the list, and if the navigation type is indeed list and not grid.
  const enableSearch = page.pages && page.pages.length > 10 && page.module === "navigationList";

  // When the search term changes filter the list elements.
  useEffect(() => {
    filterListElements();
    // eslint-disable-next-line
  }, [searchTerm]);

  const filterListElements = () => {
    // Prevents app from crashing if page has no subpages
    if (!page.pages) {
      setFilteredSubpages([]);
      return;
    }

    let filteredSubpages = page.pages;

    filteredSubpages = filteredSubpages.filter(
      (subpage) => subpage.title.toLowerCase().search(searchTerm.toLowerCase()) !== -1
    );
    setFilteredSubpages(filteredSubpages);
  };

  function getNavigationContent(maxWidth = false, color = colors.darkGrey) {
    if (page.module === "navigationGrid") {
      return (
        <div className={gridStyle()}>
          {filteredSubpages.length > 0 &&
            filteredSubpages.map((subPage) => {
              return (
                <>
                  <GridItem
                    maxWidth={maxWidth}
                    clickable={true}
                    onClick={() =>
                      pageNavigatorV2({
                        path: linkPathCreator(subPage, "absolute"),
                        direction: "forward",
                        pageId: subPage.id,
                      })
                    }
                    className="list-item"
                    title={subPage.title}
                    iconLeft={subPage.icon ? iconConverter(subPage.icon, { color }) : null}
                    notificationsRight={subPage.notifications > 0 && <Notification notifications={subPage.notifications} />}
                    key={subPage.id}
                  />
                </>
              );
            })}
        </div>
      );
    }
    return (
      <>
        {filteredSubpages.length === 0 && enableSearch && (
          <StatusBox
            icon={<FeatureSearchOutlineIcon />}
            title={lang.noResults}
            content={lang.trySearchingForSomethingElse}
          />
        )}

        {filteredSubpages.length > 0 &&
          filteredSubpages.map((subPage) => (
            <ListItem
              dataTestId={`navigation-list-item-${subPage.title}`}
              maxWidth={maxWidth}
              clickable={true}
              onClick={() =>
                pageNavigatorV2({
                  path: linkPathCreator(subPage, "absolute"),
                  direction: "forward",
                  pageId: subPage.id,
                })
              }
              className="list-item"
              title={subPage.title}
              iconLeft={subPage.icon ? iconConverter(subPage.icon, { color }) : null}
              notificationsRight={subPage.notifications > 0 && <Notification notifications={subPage.notifications} />}
              iconRight={<ChevronRightIcon />}
              key={subPage.id}
            />
          ))}

        {enableSearch && (
          <FloatingSearchInput
            className="search-input"
            onChange={(e) => setSearchTerm(e.target.value)}
            value={searchTerm}
            placeholder={lang.search}
            onClearSearch={() => {
              setSearchTerm("");
            }}
          />
        )}
      </>
    );
  }

  // If the page has a description or a cover image, then return a navigationlist with cover image
  if (page.description || page.coverImage) {
    return (
      <Page backgroundColor={colors.white} className={componentStyles({ primaryColor, coverImage: page.coverImage })}>
        <ActionWrapper className="floating-back-button" onClick={() => pageNavigatorV2({ mode: "pop" })}>
          <ArrowLeftIcon></ArrowLeftIcon>
        </ActionWrapper>

        <ScrollView className="navigation-list-scroll-view">
          {hasCoverImage && <div className="cover-image"></div>}
          <div className={`content-container ${hasCoverImage ? "with-cover-image" : "without-cover-image"}`}>
            <div className="content-wrapper">
              <h2 className="with-cover-image-page-title">
                {iconConverter(page.icon, { marginBottom: "-3px" })} {page.title}
              </h2>
              {page.description && (
                <div className="markdown-content" dangerouslySetInnerHTML={markdownParser(page.description)}></div>
              )}

              {page.pages && page.pages.length === 0 && (
                <StatusBox icon={<FeatureSearchOutlineIcon />} title={lang.noContentTitle} content={lang.noContentContent} />
              )}
              {page.pages && page.pages.length !== 0 && getNavigationContent(false, primaryColor)}
            </div>
          </div>
        </ScrollView>
      </Page>
    );
  }

  // Else return the normal navigation list
  else {
    return (
      <Page className={componentStyles({ primaryColor })}>
        <TopBar title={page.title} useDefaultBackButton={true} />
        <ScrollView style={{ padding: "1rem 0", paddingTop: enableSearch ? "4.35rem" : "1rem" }}>
          {page.pages && page.pages.length === 0 && (
            <StatusBox icon={<FeatureSearchOutlineIcon />} title={lang.noContentTitle} content={lang.noContentContent} />
          )}

          {page.pages && page.pages.length !== 0 && getNavigationContent(breakpoints.lg, colors.darkGrey)}
        </ScrollView>
      </Page>
    );
  }
}

const IMAGE_HEIGHT = 175;
const CONTAINER_BORDER_RADIUS = 10;
const componentStyles = ({ primaryColor, coverImage = {} }) => css`
  .with-cover-image-page-title {
    color: ${primaryColor};
    margin-bottom: 0.65rem;
    font-size: 1.35rem;

    svg {
      width: 1.5rem;
    }
  }

  .floating-back-button {
    svg {
      position: fixed;
      top: 1rem;
      left: 1rem;
      width: 2.5rem;
      height: 2.5rem;
      padding: 0.35rem;
      border-radius: 50%;
      background-color: ${primaryColor};
      color: ${colors.white};
      z-index: 5;
      filter: drop-shadow(0px 4px 6px rgba(0, 0, 0, 0.15));
      /* box-shadow: 0 3px 4px rgba(0, 0, 0, 0.2), 0 3px 20px rgba(0, 0, 0, 0.1); */

      &:hover {
        background-color: ${tinycolor(primaryColor).lighten(4).toString()};
      }

      &:active {
        background-color: ${tinycolor(primaryColor).darken(4).toString()};
      }
    }
  }

  .cover-image {
    top: 0;
    width: 100%;
    position: absolute;
    height: ${IMAGE_HEIGHT + 50}px;
    background-size: cover;
    background-position: center;

    @media screen and (min-width: 1000px) {
      background-image: url(${coverImage.baseURL}${`q_auto,f_auto,w_1400/`}${coverImage.image});
    }
    @media screen and (min-width: 500px) and (max-width: 999px) {
      background-image: url(${coverImage.baseURL}${`q_auto,f_auto,w_1000/`}${coverImage.image});
    }
    @media screen and (max-width: 499px) {
      background-image: url(${coverImage.baseURL}${`q_auto,f_auto,w_500/`}${coverImage.image});
    }

    &:after {
      content: "";
      display: block;
      top: 100%;
      width: 100%;
      height: 50px;
      margin-top: ${IMAGE_HEIGHT}px;
      background: linear-gradient(
        0deg,
        rgba(255, 255, 255, 1) 0%,
        rgba(255, 255, 255, 0.7) 63%,
        rgba(255, 255, 255, 0) 100%
      );
      z-index: 10000;
    }
  }

  .navigation-list-scroll-view {
    .content-container {
      position: relative;
      background-color: ${colors.white};
      position: relative;
      width: 100%;
      padding: 1rem 1rem 4rem 1rem;
      background-color: ${colors.white};

      &.with-cover-image {
        margin-top: ${IMAGE_HEIGHT - CONTAINER_BORDER_RADIUS}px;
        min-height: calc(100% - ${IMAGE_HEIGHT - CONTAINER_BORDER_RADIUS}px);
        box-shadow: 0px -9px 11px 0px rgba(0, 0, 0, 0.06), 0px -24px 40px 0px rgba(0, 0, 0, 0.1);
        border-radius: ${CONTAINER_BORDER_RADIUS}px ${CONTAINER_BORDER_RADIUS}px 0 0;
      }

      &.without-cover-image {
        padding-top: 60px;
        min-height: 100vh;
      }

      .content-wrapper {
        max-width: ${breakpoints.md}px;
        margin-left: auto;
        margin-right: auto;

        div.markdown-content {
          margin-bottom: 1.35rem;
          line-height: 1.5;
          color: ${colors.black};
          user-select: text;

          *::selection {
            background: ${primaryColor};
            color: ${colors.white};
            border-radius: 3px;
          }

          a {
            color: ${primaryColor};
            text-decoration: underline;
          }

          p {
            white-space: break-spaces;
          }

          p,
          ul,
          li {
            margin-bottom: 0.5rem;
          }

          h1,
          h2,
          h3,
          h4,
          h5 {
            margin-top: 1.5rem;
            margin-bottom: 0.65rem;
            &:first-child {
              margin-top: 0rem;
            }
          }

          ul {
            list-style-type: disc;
            margin-left: 1.5rem;
          }

          ol {
            margin-left: 1.5rem;
          }
        }

        .list-item {
          border: 1px ${colors.midGrey} solid;

          :nth-child(3) {
            border-radius: 3px 3px 0 0;
          }
          &:last-of-type {
            border-radius: 0 0 3px 3px;
          }
        }
      }
    }

    @media screen and (max-width: ${breakpoints.xs}px) {
      .content-container.with-cover-image {
        margin-top: ${IMAGE_HEIGHT * 0.8 - CONTAINER_BORDER_RADIUS}px;
        min-height: calc(100% - ${IMAGE_HEIGHT * 0.8 - CONTAINER_BORDER_RADIUS}px);
      }
      .cover-image {
        height: ${IMAGE_HEIGHT * 0.8 + 50}px;
        &:after {
          margin-top: ${IMAGE_HEIGHT * 0.8}px;
        }
      }
    }

    @media screen and (min-width: ${breakpoints.lg}px) {
      .content-container.with-cover-image {
        margin-top: ${IMAGE_HEIGHT * 1.5 - CONTAINER_BORDER_RADIUS}px;
        min-height: calc(100% - ${IMAGE_HEIGHT * 1.5 - CONTAINER_BORDER_RADIUS}px);

        padding-top: 2rem;
      }
      .cover-image {
        height: ${IMAGE_HEIGHT * 1.5 + 50}px;
        &:after {
          margin-top: ${IMAGE_HEIGHT * 1.5}px;
        }
      }
      h2 {
        margin-bottom: 1rem;
        font-size: 1.85rem;
      }

      div.markdown-content {
        margin-bottom: 1.55rem;
      }
    }
  }
`;

const gridStyle = () => css`
  max-width: ${breakpoints.md}px;
  border: 1px solid ${colors.midGrey};
  margin: 0 auto;
  flex-wrap: wrap;
  display: flex;
  border-radius: 3px;
  overflow: hidden;
`;
export default NavigationList;
