import React, { useState, useEffect } from "react";
import { css } from "emotion";
import { useDispatch, useSelector } from "react-redux";
import breakpoints from "../../config/breakpoints";
import colors from "../../style/colors";
import { format, parse } from "date-fns/esm";
import ButtonRounded from "../ui/ButtonRounded";
import ActionWrapper from "../ui/ActionWrapper";
import {
  AccountCircleIcon,
  CheckboxMarkedCircleIcon,
  CheckIcon,
  ClockOutlineIcon,
  CommentOutlineIcon,
  ContentSaveIcon,
  FileIcon,
  FileOutlineIcon,
  InformationOutlineIcon,
  PencilIcon,
  PlusIcon,
  TrashCanOutlineIcon,
} from "mdi-react";
import AnimateHeight from "react-animate-height";
import { durations } from "../../config/animations";
import TextareaInput from "../ui/TextareaInput";
import TextInput from "../ui/TextInput";
import ContextMenuButton from "../ui/ContextMenuButton";
import req from "../../utilities/request-utility";
import { addToast, showContextMenu, showDialog } from "../../actions/uiActions";

const taskStates = {
  done: "done",
  overdue: "overdue",
  default: "default",
};

function getTaskState(task) {
  let currentTime = format(new Date(), "yyyyMMddHHmm");

  if (task.checked) {
    return taskStates.done;
  } else if (task.time && `${task.date}${task.time}` < currentTime) {
    return taskStates.overdue;
  } else {
    return taskStates.default;
  }
}

function ScheduledChecklistTask({ task = {}, checklist = {}, refreshChecklistTasks }) {
  const taskState = getTaskState(task);
  const lang = useSelector((s) => s.language.language);
  const primaryColor = useSelector((s) => s.appConfig.primaryColor);
  const authedUserId = useSelector((s) => s.auth.user.id);
  const [isExpanded, setIsExpanded] = useState(false);
  const [comment, setComment] = useState(task.comment || "");
  const [initials, setInitials] = useState(task.checkedBy.initials || "");
  const [active, setActive] = useState(false);
  const [editMode, setEditMode] = useState(false);
  const dispatch = useDispatch();

  async function markTaskAsCompleted() {
    try {
      if (initials === "") {
        dispatch(
          addToast({
            title: lang.initialsMissing,
            content: lang.initialsMissingContent,
            icon: <InformationOutlineIcon />,
            styleType: "warning",
            duration: 6000,
          })
        );
        return;
      }

      setActive(true);
      await req().post(`scheduled-checklists/${checklist.id}/tasks/${task.id}/completion`, {
        comment,
        initials,
      });
      await refreshChecklistTasks();
      setActive(false);
    } catch (err) {
      setActive(false);
      dispatch(addToast({ template: "error" }));
    }
  }
  async function updateTask() {
    try {
      setActive(true);
      await req().put(`scheduled-checklists/${checklist.id}/tasks/${task.id}/completion`, {
        comment,
        initials,
      });
      await refreshChecklistTasks();
      setActive(false);
      setEditMode(false);
    } catch (err) {
      setActive(false);
      dispatch(addToast({ template: "error" }));
    }
  }

  async function deleteTaskCompletion() {
    try {
      await req().delete(`scheduled-checklists/${checklist.id}/tasks/${task.id}/completion`);
      await refreshChecklistTasks();
      setComment("");
      setInitials("");
      setIsExpanded(false);
    } catch {
      dispatch(addToast({ template: "error" }));
    }
  }

  function handleContextMenuClick(e) {
    e.stopPropagation();
    dispatch(
      showContextMenu({
        actions: [
          {
            icon: <PencilIcon />,
            title: lang.edit,
            callback: () => {
              setIsExpanded(true);
              setEditMode(true);
            },
          },
          {
            icon: <TrashCanOutlineIcon />,
            title: lang.delete,
            callback: () =>
              dispatch(
                showDialog({
                  title: lang.delete,
                  styleType: "warning",
                  content: lang.deleteRegistrationWarning,
                  icon: <TrashCanOutlineIcon />,
                  primaryActionTitle: lang.yesDeleteMyRegistration,
                  primaryAction: deleteTaskCompletion,
                })
              ),
          },
        ],
      })
    );
  }

  return (
    <div className={`${componentStyles(primaryColor)} ${taskState} ${isExpanded ? "expanded" : "collapsed"}`}>
      <ActionWrapper disableFocusStyle={true} className="title-bar" onClick={() => setIsExpanded(!isExpanded)}>
        {task.time && <p className="time-indicator">{`${task.time.substr(0, 2)}:${task.time.substr(2)}`}</p>}
        <p className="title">{task.title}</p>

        {taskState === taskStates.done && parseInt(task.checkedBy.id) === parseInt(authedUserId) && (
          <ContextMenuButton
            onClick={handleContextMenuClick}
            className="context-menu-button"
            style={{ margin: "-0.25rem 0.25rem -0.25rem 0" }}
          />
        )}
        {taskState === taskStates.done && <CheckboxMarkedCircleIcon className="done-icon" />}
        {taskState === taskStates.overdue && <ClockOutlineIcon className="overdue-icon" />}

        <PlusIcon className="expand-collapse-indicator" />
      </ActionWrapper>
      <AnimateHeight height={isExpanded ? "auto" : 0} duration={durations.normal} animateOpacity={true}>
        <div className="task-content-wrapper">
          {task.description && <p className="description">{task.description}</p>}

          {task.file && task.file.file && task.file.baseURL && (
            <a className="file" target="blank" rel="noreferrer noopener" href={`${task.file.baseURL}${task.file.file}`}>
              <FileOutlineIcon /> {task.file.file}
            </a>
          )}

          {/* Task is done */}
          {taskState === taskStates.done && !editMode && (
            <>
              <div className="meta-info">
                <p style={{ marginRight: "1.5rem" }}>
                  <CheckIcon /> {task.checkedAt.slice(-4).slice(0, 2)}:{task.checkedAt.slice(-4).slice(2)}
                </p>
                <p>
                  <AccountCircleIcon /> {task.checkedBy.initials}
                </p>
              </div>
              <p className="comment-label">
                <CommentOutlineIcon /> {lang.comment}
              </p>
              {task.comment && <p className="comment-content">{task.comment}</p>}
              {!task.comment && (
                <p className="comment-content meta">
                  <InformationOutlineIcon /> {lang.noComment}
                </p>
              )}
            </>
          )}

          {/* Task is not done */}
          {(taskState !== taskStates.done || editMode) && (
            <>
              {/* Comment */}
              <label htmlFor="comment">{lang.comment}</label>
              <TextareaInput className="input" name="comment" value={comment} onChange={(e) => setComment(e.target.value)} />

              {/* Initials */}
              <label htmlFor="initials">{lang.initials}</label>
              <TextInput className="input" name="initials" value={initials} onChange={(e) => setInitials(e.target.value)} />

              {/* Button */}
              {!editMode && (
                <ButtonRounded active={active} style={{ width: "100%" }} onClick={markTaskAsCompleted}>
                  <CheckIcon /> {lang.markAsDone}
                </ButtonRounded>
              )}
              {editMode && (
                <>
                  <ButtonRounded active={active} style={{ width: "100%", marginBottom: "0.5rem" }} onClick={updateTask}>
                    <ContentSaveIcon /> {lang.saveChanges}
                  </ButtonRounded>
                  <ButtonRounded secondary={true} style={{ width: "100%" }} onClick={() => setEditMode(false)}>
                    {lang.cancel}
                  </ButtonRounded>
                </>
              )}
            </>
          )}
        </div>
      </AnimateHeight>
    </div>
  );
}

const basePadding = 1;
const componentStyles = (primaryColor) => css`
  background-color: ${colors.white};
  border-top: 1px ${colors.midGrey} solid;
  border-bottom: 1px ${colors.midGrey} solid;
  margin-bottom: -1px;
  position: relative;
  transition: margin ${durations.normal}ms ease;
  overflow: hidden;

  &:after {
    position: absolute;
    left: 0;
    top: 0;
    height: 100%;
    width: 3px;
    content: "";
    display: block;
    background-color: ${primaryColor};
    opacity: 0;
    transition: opacity ${durations.normal}ms ease;
    border-radius: 3px 0 0 3px;
  }

  @media screen and (min-width: ${breakpoints.lg}px) {
    border: 1px ${colors.midGrey} solid;

    &:first-of-type {
      border-radius: 3px 3px 0 0;
    }
    &:last-of-type {
      border-radius: 0 0 3px 3px;
    }
  }

  &.overdue {
    .time-indicator {
      color: ${colors.red};
    }
  }

  &.done {
    .time-indicator {
      color: ${colors.midDarkGrey};
    }

    .title-bar .title {
      color: ${colors.midDarkGrey};
      text-decoration: line-through;
    }
  }

  &.expanded {
    margin: 0.75rem 0;
    border-radius: 3px;

    .title-bar {
      .time-indicator {
        color: ${primaryColor};
      }

      .title {
        font-weight: 700;
        color: ${primaryColor};
      }
    }

    &:after {
      opacity: 1;
    }

    .expand-collapse-indicator {
      transform: rotate(45deg);
      fill: ${primaryColor};
    }
  }

  .title-bar {
    border-radius: 0px;
    padding: ${basePadding}rem;
    display: flex;
    width: 100%;
    justify-content: space-between;
    align-items: flex-start;

    .time-indicator {
      font-weight: 700;
      font-size: 0.9rem;
      margin: 0 0.5rem 0 0;
      transform: translateY(1px);
    }

    .title {
      flex-grow: 1;
      color: ${colors.black};
    }

    svg.context-menu-button {
      width: 2rem;
      height: 2rem;
    }

    .done-icon,
    .overdue-icon {
      flex-shrink: 0;
      width: 1.15rem;
      height: 1.15rem;
      transform: translateY(3px);
    }

    .expand-collapse-indicator {
      flex-shrink: 0;
      color: ${colors.darkGrey};
      width: 1.5rem;
      height: 1.5rem;
      transition: transform ${durations.normal}ms ease;
    }

    .done-icon {
      color: ${colors.green};
      margin-right: 0.3rem;
    }
    .overdue-icon {
      color: ${colors.red};
      margin-right: 0.3rem;
    }
  }

  .task-content-wrapper {
    padding: 0 ${basePadding}rem ${basePadding}rem ${basePadding}rem;

    .description {
      margin-bottom: 1rem;
    }

    .file {
      border: 1px ${colors.midGrey} solid;
      border-radius: 3px;
      padding: 0.45rem 0.7rem;
      margin-top: -0.5rem;
      margin-bottom: 1rem;
      display: inline-block;
      font-size: 0.9rem;
      color: ${colors.darkGrey};

      svg {
        width: 1rem;
        height: 1rem;
        margin-bottom: -2px;
        margin-right: 0.25rem;
        margin-left: 0.05rem;
      }
    }

    label {
      color: ${colors.darkGrey};
      margin: 0 0 0.5rem 0;
      display: block;
    }

    .input {
      margin: 0 0 1rem 0;
    }

    .meta-info {
      display: flex;
      align-items: center;
      margin-bottom: 1rem;

      svg {
        width: 1rem;
        height: 1rem;
        margin-bottom: -3px;
        margin-right: 0.15rem;
      }

      p {
        color: ${colors.darkGrey};
        font-size: 0.9rem;
      }
    }

    .comment-label {
      margin-bottom: 0.45rem;
      color: ${colors.black};

      svg {
        width: 1rem;
        height: 1rem;
        margin-bottom: -3px;
        margin-right: 0.15rem;
      }
    }

    .comment-content {
      color: ${colors.darkGrey};
      white-space: break-spaces;
      font-size: 1rem;

      svg {
        width: 1rem;
        height: 1rem;
        margin-bottom: -3px;
      }
    }
  }
`;

export default ScheduledChecklistTask;
