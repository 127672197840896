import React from "react";
import { AlertDecagramIcon, HourglassEmptyIcon } from "mdi-react";
import { v4 as uuidv4 } from "uuid";
import { durations } from "../config/animations";

import {
  SHOW_CONTEXT_MENU,
  HIDE_CONTEXT_MENU,
  SHOW_DIALOG,
  HIDE_DIALOG,
  CLEAR_DIALOG,
  SHOW_LIGHT_BOX,
  HIDE_LIGHT_BOX,
  SHOW_MODAL_PAGE,
  UPDATE_MODAL_PAGE_CONTENT,
  HIDE_MODAL_PAGE,
  ADD_TOAST,
  SHOW_NEXT_TOAST,
  CLEAR_CONTEXT_MENU_ACTIONS,
  DEACTIVATE_CURRENT_TOAST,
  REPLACE_IDENTICAL_TOAST,
} from "./actionTypes";

/** ContextMenu
 * @param {Object} payload
 * @param {Object[]} payload.actions - The contextMenu's actions
 * @param {React.ReactElement} payload.actions[].icon - The icon of the individual action
 * @param {String} payload.actions[].title - The title of the individual action
 * @param {Function} payload.actions[].callback - The callback of the individual action
 * @param {Function} payload.closeOnActionCallback - A callback to be invoked when the menu closes
 * @param {String} payload.defaultActionTitle - Change the title of the default action. The default
 *                                                  is "Annuller"/"Cancel" or equivalent in the users
 *                                                  current language
 */
export function showContextMenu(payload) {
  return {
    type: SHOW_CONTEXT_MENU,
    payload,
  };
}
export function hideContextMenu() {
  return function (dispatch, getState) {
    dispatch({
      type: HIDE_CONTEXT_MENU,
    });

    setTimeout(
      () =>
        dispatch({
          type: CLEAR_CONTEXT_MENU_ACTIONS,
        }),
      durations.normal
    );
  };
}

/** Creates a new dialog
 * @param {Object} dialog
 * @param {String} dialog.title
 * @param {ReactElement|String|Function} dialog.content
 * @param {ReactElement} dialog.icon
 * @param {String} dialog.primaryActionTitle
 * @param {Function} dialog.primaryAction
 * @param {String} dialog.secondaryActionTitle
 * @param {Function} dialog.secondaryAction
 * @param {Boolean} dialog.allowClosing - disable/enable the ability to close the dialog without choosing something. Defaults to true
 */
export function showDialog(dialog) {
  return {
    type: SHOW_DIALOG,
    payload: dialog,
  };
}
export function hideDialog() {
  return function (dispatch, getState) {
    dispatch({
      type: HIDE_DIALOG,
    });

    setTimeout(
      () =>
        dispatch({
          type: CLEAR_DIALOG,
        }),
      durations.normal + 50
    );
  };
}

// Lightbox
export function showLightBox(payload) {
  return {
    type: SHOW_LIGHT_BOX,
    payload: payload,
  };
}

export function hideLightBox(payload) {
  return {
    type: HIDE_LIGHT_BOX,
  };
}

// Modal page

/** Shows a modal page
 * @param {Object} payload
 * @param {String} payload.title - The title of the modal page
 * @param {ReactComponent} payload.actionRight - An action to use as the main right action in top right of top bar in the modal page
 * @param {ReactComponent} payload.content - The main content to show in the modalpage
 * @param {Function} payload.closeCallback - A callback function to be called when closing the modalpage. If passed the modal page will not
 * @param {Boolean} payload.useScrollView - Decides if the ModalPage should auto-insert a ScrollView or not
 * @param {Object} payload.pageStyle - A style object to attach to the main page in the modal page
 */
export function showModalPage(payload) {
  return {
    type: SHOW_MODAL_PAGE,
    payload: { ...payload, id: uuidv4() },
  };
}

/**
 * @param {Object} payload
 * @param {Boolean} payload.active - Displays a spinner instead of action right
 * @param {String} payload.title - The title that is displayed in the TopBar
 * @param {ReactComponent} payload.content - The content in the ModalPage (For scrollable contant, wrap it in a ScrollView)
 * @param {function} payload.closeCallback - Function which is invoked before closing the modalpage. If the function is specified
 * the modalPage will not close itself. You'll have to manually import the hideModalPage action and call it. This feature is
 * usefull for prompting users before exiting a form with unsaved changes.
 * actionRight: null
 * @param {ReactComponent} payload.actionRight - The component that handles the primary modalPage action
 * `<ActionWrapper onClick={{onClick}}><p>Click Me</p><ActionWrapper/>`
 *
 */
export function updateModalPage(payload) {
  return {
    type: UPDATE_MODAL_PAGE_CONTENT,
    payload: payload,
  };
}
export function hideModalPage() {
  return function (dispatch, getState) {
    dispatch({
      type: HIDE_MODAL_PAGE,
    });
  };
}

/** Displays a toast.
 * @param {string} toast.title - title of the toast
 * @param {string} toast.content -  content of the toast
 * @param {mdi-icon} toast.icon - icon of the toast
 * @param {string} [toast.styleType="neutral"] - styleType (see styleTypeNormaliser). eg. "error", "success", "neutral"
 * @param {number} [toast.duration=5000] - ms for the toast to be shown
 * @param {string} [template] - A optional template. eg. "error" displays a generic error-toast
 *
 */
export function addToast(toast) {
  // Sets a default duration of 3000 if nothing is specified
  if (typeof toast.duration === "undefined") {
    toast.duration = 5000;
  }
  return (dispatch, getState) => {
    /** Error template */
    if (toast.template === "error") {
      let lang = getState().language.language;
      toast.title = lang.error;
      toast.content = `${lang.errorGeneral} ${lang.tryAgainOrContactSupport}`;
      toast.styleType = "warning";
      toast.duration = 10000;
      toast.icon = <AlertDecagramIcon />;
    }

    /** Error template */
    if (toast.template === "tooManyRequests") {
      let lang = getState().language.language;
      toast.title = lang.error;
      toast.content = `${lang.errorTooManyRequests} ${lang.tryAgainOrContactSupport}`;
      toast.styleType = "neutral";
      toast.duration = 10000;
      toast.icon = <HourglassEmptyIcon />;
    }

    /** Check if toast matches previous added toast -> Overwrite it if it does **/
    if (getState().ui.toasts.length > 0) {
      const previousToast = getState().ui.toasts[0];
      if (
        previousToast.title === toast.title &&
        previousToast.content === toast.content &&
        previousToast.styleType === toast.styleType
      ) {
        replaceToast(toast)(dispatch, getState);
        return;
      }
    }

    dispatch({
      type: ADD_TOAST,
      payload: toast,
    });

    if (getState().ui.toasts.length === 1) runQueue();

    function runQueue() {
      setTimeout(() => {
        // The queue isn't modified until showNextToasts so the length should be 2 to have
        // one element remaining (since it will be set to 1 in the next line)
        if (getState().ui.toasts.length > 1) runQueue();

        // Cancels queue if user manually triggered showNextToast action
        if (getState().ui.toasts.length === 0) return;

        dispatch({ type: DEACTIVATE_CURRENT_TOAST });
        setTimeout(() => showNextToast()(dispatch, getState), durations.normal);
      }, getState().ui.toasts[0].duration);
    }
  };
}

export function showNextToast() {
  return (dispatch, getState) => {
    if (getState().ui.toasts.length === 0) return;
    dispatch({ type: DEACTIVATE_CURRENT_TOAST });
    setTimeout(() => dispatch({ type: SHOW_NEXT_TOAST }), durations.normal);
  };
}

export function replaceToast(toast) {
  return (dispatch, getState) => {
    if (getState().ui.toasts.length === 0) return;
    dispatch({ type: REPLACE_IDENTICAL_TOAST, payload: toast });
    dispatch({ type: DEACTIVATE_CURRENT_TOAST });
    setTimeout(() => dispatch({ type: SHOW_NEXT_TOAST }), durations.normal);
  };
}
