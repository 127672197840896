import React, { useEffect, useState } from "react";
import { connect, useSelector } from "react-redux";
import { bindActionCreators } from "redux";

// Redux actions
import { getPosts, resetFeeds } from "../../actions/newsActions";
import { showModalPage } from "../../actions/uiActions";

// Utilities
import getPageFromId from "../../utilities/get-page-from-id";

// Components
import Page from "../ui/Page";
import TopBar from "../ui/TopBar";
import InlineSpinner from "../ui/InlineSpinner";
import StatusBox from "../ui/StatusBox";
import ScrollView from "../ui/ScrollView";
import colors from "../../style/colors";
import { InfoOutlineIcon, DownloadIcon, FeatureSearchOutlineIcon } from "mdi-react";

import breakpoints from "../../config/breakpoints";
import ListItem from "../ui/ListItem";
import FloatingSearchInput from "../ui/FloatingSearchInput";
import highlightMatch from "../../utilities/highlight-match";
import req from "../../utilities/request-utility";

function LinkCollectionOverview(props) {
  // page setup
  const { match } = props;
  const { primaryColor } = useSelector((state) => state.appConfig);
  const { pages } = useSelector((state) => state.pages);
  const lang = useSelector((state) => state.language.language);
  const [page] = useState(getPageFromId(pages, match.params.pageId));

  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(false);
  const [searchterm, setSearchterm] = useState("");
  const [filteredPosts, setFilteredPosts] = useState([]);
  const [documents, setDocuments] = useState([]);

  // Data
  useEffect(() => {
    req()(`/link-collections/${page.dataId}`).then(({ data }) => {
      setDocuments(data);
      setLoading(false);
      setError(false);
    });
  }, []);

  useEffect(() => {
    setFilteredPosts(
      documents.filter((document) => {
        if (document.title.toLowerCase().indexOf(searchterm.toLocaleLowerCase()) !== -1) {
          return true;
        } else {
          return false;
        }
      })
    );
  }, [searchterm, documents]);

  return (
    <Page>
      <TopBar useDefaultBackButton={true} title={page.title} />

      <FloatingSearchInput
        onClearSearch={() => setSearchterm("")}
        onChange={(e) => setSearchterm(e.target.value)}
        value={searchterm}
      />

      <ScrollView style={{ paddingTop: "4.45rem", paddingBottom: "5rem" }}>
        {/* Loading */}
        {loading && !error && <InlineSpinner style={{ margin: "2rem 0" }} />}

        {/* End of feed without posts */}
        {documents.length === 0 && !loading && !error && (
          <StatusBox
            style={{ marginBottom: "2rem" }}
            icon={<InfoOutlineIcon />}
            title={lang.noPostsHere}
            content={lang.comeBackSoon}
          />
        )}

        {filteredPosts.length === 0 && documents.length > 0 && searchterm && (
          <StatusBox
            icon={<FeatureSearchOutlineIcon />}
            title={lang.noResults}
            content={lang.trySearchingForSomethingElse}
            style={{ margin: "1rem auto" }}
          />
        )}

        {/* Content */}
        {filteredPosts.map((file) => (
          <ListItem
            key={file.id}
            onClick={() => window.open(`${file.baseURL}${file.file}`)}
            title={
              <span style={{ color: primaryColor, fontWeight: 700 }}>
                {highlightMatch(file.title, searchterm, "JSX", {
                  display: "inline-block",
                  borderBottom: `1px ${colors.darkGrey} dashed`,
                  backgroundColor: colors.yellowLight,
                  borderRadius: "3px",
                })}
              </span>
            }
            subTitle={file.file}
            maxWidth={breakpoints.lg}
            clickable={true}
            iconLeft={<DownloadIcon style={{ color: primaryColor }} />}
          />
        ))}
      </ScrollView>
    </Page>
  );
}

export default LinkCollectionOverview;
