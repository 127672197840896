// Libs
import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useDebouncedCallback } from "use-debounce/lib";
import { CSSTransition } from "react-transition-group";
import { css } from "emotion";

// Actions
import { clearContacts, getContactGroupNames, getContacts } from "../../../actions/contactsActions";

// Components
import ScrollView from "../../ui/ScrollView";
import ListItem from "../../ui/ListItem";
import { ChevronRightIcon } from "mdi-react";
import ContactsMenuOptions from "./ContactsMenuOptions";
import { ContactsListItemSkeletonGroup } from "./skeleton/ContactsListItemSkeleton";
import FloatingSearchInput from "../../ui/FloatingSearchInput";
import StatusBox from "../../ui/StatusBox";

// Utilities
import getProfilePicture from "../../../utilities/get-profile-picture-from-user-object";
import { getMiddleTitle } from "../utilities/getMiddleTitle";
import { getSubTitle } from "../utilities/getSubTitle";
import highlightMatch from "../../../utilities/highlight-match";
import getSkills from "../utilities/getSkills";
import getLayoutType from "../utilities/getLayoutType";
import { getDataTestIdForListItem } from "../utilities/getDataTestId";

// Config
import layoutTypes from "../config/layoutTypes";
import groupTypes from "../config/groupTypes";
import contactsTypes from "../config/contactsTypes";
import breakpoints from "../../../config/breakpoints";
import contactTypes from "../config/contactsTypes";
import { durations } from "../../../config/animations";
import { dataTestTypes } from "../config/dataTestIds";

// Styles
import colors from "../../../style/colors";

// Hooks
import useAuthorModal from "../../../hooks/useAuthorModal";
import usePostRenderEffect from "../../../hooks/usePostRenderEffect";

/**
 * @param {Object} props
 * @param {Boolean} props.isMain - Helps deactivate the animation on the searchBar when the component acts as a overview of a specific group of contacts
 * @param {Object} props.mainPage - Provides the config and id and the mainPage.
 * The mainPage.config helps determine what information should be visible of users (ListItem-level)
 * The mainPage.id helps hiding information about the users before they reach the app (API-level)
 * @param {Function} props.navigateToContactsGroup - Callback function that will navigate to an overview of the selected groups contacts
 * @param {String} props.contactType - Determines what kind of contacts should be fetched -> (phoneBook or keyPersons)
 * @param {String, Integer} props.groupId - Determines from what group should contacts be fetched
 * @param {String} props.groupType - What are the groupType -> all, masterGroup, useGroup, groups (KeyPersons).
 * This also determines the layout:
 *  all -> all contacts
 *  masterGroup, userGroup and group -> group titles
 * @param {boolean} props.tabBarIsIncluded - Helps position the searchBar height by the tabBars position
 */

export function ContactsOverview({
  isMain = true,
  mainPage,
  navigateToContactsGroup = null,
  contactType = contactsTypes.phoneBook,
  groupId = null,
  groupBy = groupTypes.all,
  groupType = null,
  tabBarIsIncluded = false,
} = props) {
  const dispatch = useDispatch();
  const authorModal = useAuthorModal();

  const { groupNames: groups, contacts, loading, error } = useSelector((state) => state.contact[contactType]);
  const { activeTab, filters } = useSelector((state) => state.contact);
  const { language: lang } = useSelector((state) => state.language);
  const [searchTerm, setSearchTerm] = useState("");

  const [debouncedGetContacts] = useDebouncedCallback(
    ({ searchTerm }) =>
      dispatch(
        getContacts({
          contactType: contactsTypes[contactType],
          searchTerm,
          contactGroupId: groupId,
          groupType,
        })
      ),
    250
  );

  function showAuthorModal(contact) {
    if (contactType === contactsTypes.phoneBook) authorModal(contact.id);
    if (contactType === contactTypes.keyPerson) authorModal(contact.id, `contacts/key-persons/${contact.id}`);
  }

  function getGroups() {
    dispatch(getContactGroupNames({ contactType: contactsTypes[contactType], groupBy }));
  }

  function getInitialContacts() {
    dispatch(clearContacts({ contactType }));

    dispatch(
      getContacts({
        contactType: contactsTypes[contactType],
        searchTerm,
        contactGroupId: groupId,
        groupType,
      })
    );
  }

  useEffect(() => {
    if (contactType === contactTypes.phoneBook && isMain === true) return;

    if (getLayoutType({ groupBy }) === layoutTypes.groups) getGroups();
    else if (getLayoutType({ groupBy }) === layoutTypes.contacts) getInitialContacts();
  }, []);

  useEffect(() => {
    if (
      contactType === contactTypes.keyPerson ||
      filters.selectedAppId === null ||
      (contactType === contactTypes.phoneBook && isMain === false)
    ) {
      return;
    }

    if (getLayoutType({ groupBy }) === layoutTypes.groups) getGroups();
    else if (getLayoutType({ groupBy }) === layoutTypes.contacts) getInitialContacts();
  }, [filters.selectedAppId]);

  usePostRenderEffect(() => {
    if (getLayoutType({ groupBy }) === layoutTypes.contacts) {
      dispatch(clearContacts({ contactType }));
      debouncedGetContacts({ searchTerm });
    }
  }, [searchTerm]);

  usePostRenderEffect(() => {
    if (contacts.length === 0) return;

    let currentFeed = document.querySelector(".contact-container");
    if (currentFeed && currentFeed.scrollHeight <= currentFeed.clientHeight) {
      debouncedGetContacts({ searchTerm });
    }
  }, [contacts]);

  if (error) {
    return (
      <div style={{ marginTop: "0.5rem" }}>
        <StatusBox />
      </div>
    );
  } else if (getLayoutType({ groupBy }) === layoutTypes.contacts) {
    return (
      <div className={allStyle(activeTab, isMain)}>
        <CSSTransition
          in={activeTab === 0 || activeTab === 1}
          timeout={900}
          mountOnEnter={true}
          unmountOnExit={true}
          classNames="floating-search-input"
        >
          <FloatingSearchInput
            style={{ top: `${tabBarIsIncluded ? 10 : 60}px` }}
            className="search-contacts-input"
            dataTestId={"inputField"}
            onChange={(e) => setSearchTerm(e.target.value)}
            value={searchTerm}
            placeholder={lang.searchForContacts}
            onClearSearch={() => {
              dispatch(clearContacts({ contactType: contactTypes.phoneBook }));
              setSearchTerm("");
            }}
          />
        </CSSTransition>
        <ScrollView
          className="contact-container"
          onScrollEnd={() =>
            dispatch(
              getContacts({
                contactType: contactsTypes[contactType],
                searchTerm,
                contactGroupId: groupId,
                groupType,
              })
            )
          }
        >
          {contacts.map((contact) => (
            <ListItem
              dataTestId={getDataTestIdForListItem({ groupBy, contactType })}
              className="list-item"
              style={{ padding: "0.625rem" }}
              maxWidth={breakpoints.lg}
              key={contact.id}
              clickable={true}
              onClick={() => showAuthorModal(contact)}
              middleTitleStyle={{ fontSize: "0.8125rem", color: colors.black }}
              imageLeft={getProfilePicture(contact, 36, { marginRight: "0.5rem", marginBottom: "-3px" })}
              title={highlightMatch(contact.name, searchTerm)}
              dataTestIdTitle={getDataTestIdForListItem({ groupBy, contactType, type: dataTestTypes.title })}
              middleTitle={highlightMatch(
                getMiddleTitle({ contact, config: mainPage ? mainPage.config : null }),
                searchTerm
              )}
              dataTestIdmiddleTitle={getDataTestIdForListItem({ groupBy, contactType, type: dataTestTypes.middleTitle })}
              subTitle={highlightMatch(getSubTitle({ contact, config: mainPage ? mainPage.config : null }), searchTerm)}
              dataTestIdSubTitle={getDataTestIdForListItem({ groupBy, contactType, type: dataTestTypes.subTitle })}
              content={
                <p
                  data-test-id={getDataTestIdForListItem({ groupBy, contactType, type: dataTestTypes.content })}
                  className="skills"
                >
                  {highlightMatch(getSkills({ contact, config: mainPage ? mainPage.config : null }))}
                </p>
              }
              iconRight={<ContactsMenuOptions {...contact} />}
            />
          ))}
          {loading && <ContactsListItemSkeletonGroup />}
        </ScrollView>
      </div>
    );
  }

  if (getLayoutType({ groupBy }) === layoutTypes.groups) {
    return (
      <ScrollView className={groupStyle({ groupBy, contactType })}>
        {groups.length > 0 && (
          <ListItem
            maxWidth={breakpoints.md}
            clickable={true}
            onClick={navigateToContactsGroup ? () => navigateToContactsGroup({ contactType }) : null}
            title={lang.showAll}
            iconRight={<ChevronRightIcon />}
          />
        )}
        {groups.map((group) => (
          <ListItem
            dataTestId={getDataTestIdForListItem({ groupBy, contactType })}
            maxWidth={breakpoints.md}
            clickable={true}
            onClick={navigateToContactsGroup ? () => navigateToContactsGroup({ group, contactType }) : null}
            title={group.title}
            iconRight={<ChevronRightIcon />}
            key={group.id}
          />
        ))}
      </ScrollView>
    );
  }

  return <></>;
}

const allStyle = (tab, isMain) => css`
  height: 100%;
  overflow-x: auto;

  .search-contacts-input {
    position: absolute;
    top: 10px;
    z-index: 3;
  }

  .list-item {
    margin-bottom: -2px;

    &:last-child {
      margin-bottom: 0.5rem;
    }
  }

  .skills {
    font-size: 0.8125rem;
    color: ${colors.darkGrey};
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
  }

  .floating-search-input-enter {
    opacity: 0;
    transform: scale(1) ${tab === 0 || isMain === false ? `translateX(200px)` : "translateX(-200px)"};
  }
  .floating-search-input-enter-active {
    opacity: 1;
    transform: scale(1) translateX(0px);
    transition: opacity ${durations.normal}ms ease, transform ${durations.normal}ms ease;
  }
  .floating-search-input-exit {
    opacity: 1;
    transform: scale(1);
  }
  .floating-search-input-exit-active {
    opacity: 0;
    transform: scale(1) ${tab === 0 || isMain === false ? `translateX(200px)` : "translateX(-200px)"};
    transition: opacity ${durations.normal}ms ease, transform ${durations.normal}ms ease;
  }

  .contact-container {
    height: 100%;
    padding-top: 4.35rem;
  }
`;

const groupStyle = () => css`
  height: 100%;
  padding-top: 1rem;
`;
