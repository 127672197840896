// Libs
import React, { useState, useRef, useEffect } from "react";
import { connect, useDispatch, useSelector } from "react-redux";
import { bindActionCreators } from "redux";
import { parse, format } from "date-fns";

// Utilities
import deletedUser from "../../utilities/deleted-user";

// Redux actions
import {
  addToast,
  showContextMenu,
  showModalPage,
  updateModalPage,
  showDialog,
  hideModalPage,
} from "../../actions/uiActions";
import {
  readPost,
  likePost,
  unlikePost,
  addComment,
  resetFeed,
  getPosts,
  refreshPostComments,
  removePostFromFeed,
  refreshPostCommentLikes,
} from "../../actions/newsActions";

// Components
import Post from "../ui/Post";
import CheckQuestionsModal from "./CheckQuestionsModal";
import { AlertOctagramIcon, EditIcon, RunFastIcon, TrashCanOutlineIcon, TrashCircleIcon } from "mdi-react";
import NewsCommentModal from "./NewsCommentModal";
import UserProfile from "../profile/UserProfile";
import NewsPostModal from "./NewsPostModal";

// Utils and config
import req from "../../utilities/request-utility";
import getUserLocale from "../../utilities/get-user-locale";
import { feedTypes } from "./config";
import translatorLanguages from "../../config/translatorLanguages";
import getAppName from "../../utilities/get-app-name";
import isValidTranslatorLanguage from "../../utilities/is-valid-translator-language";

/**
 * NewsPost is a proxy-component that adds news-specific functionality to the Post component
 * It sits between `<NewsFeedTabs/>` and `<Post/>` component.
 *
 * @example
 * ```jsx
 * {posts.map(post => <NewsPost post={post} key={`news-post-${post.id}`} />)}
 * ```
 *
 */
function NewsPost(props) {
  const dispatch = useDispatch();

  // actions
  const {
    addToast,
    lang,
    refreshPostComments,
    removePostFromFeed,
    showContextMenu,
    showDialog,
    showModalPage,
    getPosts,
    updateModalPage,
    addComment,
    resetFeed,
    user,
    likePost,
    readPost,
    readActionLangOverwrite,
    subTypeId,
    feedType,
    unlikePost,
    disableSwipe = false,
  } = props;

  const { postsWithLoadingLikes, postsWithLoadingComments, postsWithCommentsBeingAdded, deletedPosts } = useSelector(
    (state) => state.news
  );

  // Reference for comment container element (used for auto scroll when adding comments)
  const commentsContainer = useRef();

  //props
  const { disableInteractionsPreview, style } = props;

  // Post content
  const {
    author,
    comments,
    content,
    date,
    documentOwner,
    approvedBy,
    files,
    id,
    hideAuthorInApp,
    images,
    links,
    likes,
    points,
    questions,
    read,
    readDate,
    title,
    video,
    youtubeEmbed,
  } = props.post;

  const [titleState, setTitleState] = useState(title);
  const [contentState, setContentState] = useState(content);
  const [isTranslating, setIsTranslating] = useState(false);
  const [isTranslated, setIsTranslated] = useState(false);
  const [currentTranslationLanguage, setCurrentTranslationLanguage] = useState(props.user.language);
  const [isPlayingAudio, setIsPlayingAudio] = useState(false);
  const [isLoadingAudio, setIsLoadingAudio] = useState(false);
  const audioElement = useRef();

  async function translateContent({ chosenTranslateLanguage = false, explicitlyChangeLanguage = false } = {}) {
    /* Three outcomes
      1) No language is chosen and user has no previous language OR
         User implcitly requested to change the langauge 
         -> Language prompt
      2) No language is chosen but user has a previously used language
         -> Translate with previously used language
      3) A language is chosen
         -> Translate with chosen language regardless of previous settings
    */

    /** Previously used language will be stored here */
    let previouslyUsedLanguage = localStorage.getItem(`${getAppName()}-translator-language`);

    // if previously used language for some reason is set to users own language, don't just auto translate to it
    // this can happen either as a bug or because user changed language since last translation
    if (props.user.language === previouslyUsedLanguage) previouslyUsedLanguage = "";

    // 1)
    if ((!chosenTranslateLanguage && !previouslyUsedLanguage) || explicitlyChangeLanguage === true) {
      // Display prompt
      dispatch(
        showContextMenu([
          {
            title: translatorLanguages.da.title,
            callback: () => translateContent({ chosenTranslateLanguage: translatorLanguages.da.identifier }),
          },
          {
            title: translatorLanguages.en.title,
            callback: () => translateContent({ chosenTranslateLanguage: translatorLanguages.en.identifier }),
          },
          {
            title: translatorLanguages.pl.title,
            callback: () => translateContent({ chosenTranslateLanguage: translatorLanguages.pl.identifier }),
          },
        ])
      );
    }
    // 2)
    else if (!chosenTranslateLanguage && previouslyUsedLanguage) {
      // Validate language
      // If its valid -> continue as normal
      if (isValidTranslatorLanguage(previouslyUsedLanguage)) {
        translateContent({ chosenTranslateLanguage: previouslyUsedLanguage });
        // For invalid - prompt user to reselect langauge
      } else {
        translateContent({ explicitlyChangeLanguage: true });
      }
      // 3
    } else {
      setIsTranslating(true);
      setCurrentTranslationLanguage(chosenTranslateLanguage);
      req()(`news/${subTypeId}/${id}/translations/${chosenTranslateLanguage}`)
        .then(({ data }) => {
          // In case user went back to native language, don't set a value in localstorage and set isTranslated to false
          if (props.user.language !== chosenTranslateLanguage) {
            setIsTranslated(true);
            localStorage.setItem(`${getAppName()}-translator-language`, chosenTranslateLanguage);
          } else {
            setIsTranslated(false);
          }

          setIsTranslating(false);
          setContentState(data.content);
          setTitleState(data.title);
        })
        .catch((err) => {
          setIsTranslating(false);
          addToast({ template: "error" });
        });
    }

    // If user has chosen a language
    // a: Language is not original language
    //    -> Run the translation as normal
    // b: Language is original language
    //    -> Go back to the orignal content and title

    // If user has not chosen a language:
    // -> Get last used langauge:
    //    a: There is no last used language
    //       -> Prompt user with language selector and call this function with the chosen language
    //    b: There is a last used language
    //       -> call this function with the last chosen language
  }

  function readContent() {
    if (isLoadingAudio) return;

    if (!isPlayingAudio) {
      setIsLoadingAudio(true);
      audioElement.current = new Audio();
      req()(`news/${subTypeId}/${id}/audio/${currentTranslationLanguage}`)
        .then(({ data }) => {
          audioElement.current.setAttribute("src", data.file);
          audioElement.current.load();
          audioElement.current.play();
          audioElement.current.addEventListener("canplay", () => {
            audioElement.current.play();
            setIsLoadingAudio(false);
            setIsPlayingAudio(true);
          });
        })
        .catch(() => {
          setIsLoadingAudio(false);
          setIsPlayingAudio(false);
          addToast({ template: "error" });
        });
    } else {
      audioElement.current.pause();
      setIsPlayingAudio(false);
    }
  }

  useEffect(() => {
    return () => {
      if (audioElement && audioElement.current) {
        try {
          audioElement.current.pause();
          audioElement.current.remove();
          audioElement.current = undefined;
        } catch {}
      }
    };
  }, []);

  const isLiked = getIsLiked({ user: props.user, likes });

  /**************** Generel  ****************/
  const [isAuthor] = useState(() => {
    if (!author) {
      return false;
    } else if (author.id === user.id || author.id === user.adminId) {
      return true;
    } else {
      return false;
    }
  });

  /**************** Handle swipe  ****************/
  const [swipeCompleted, setSwipeCompleted] = useState(false);
  function handleSwipeEnd(questions) {
    setSwipeCompleted(true);
    // If questions, show the check-questions modal
    if (questions) {
      readPost({ subTypeId, feedType, postId: id });
      openCheckQuestionsModal(props.post);

      // If no questions just read the post
    } else {
      readPost({ subTypeId, feedType, postId: id, callback });

      function callback() {
        // If the feedType is unread, fetch readposts again
        if (feedType === feedTypes.unread) {
          resetFeed(feedTypes.read);
          // Wait 500ms before getting posts. This offsets the request so it fires after
          // the request to update the current post
          setTimeout(() => getPosts({ subTypeId, feedType: feedTypes.read }), 4000);
        }
      }
    }
  }

  /**************** Likes  ****************/
  function getIsLiked({ user, likes }) {
    if (!likes || likes.length === 0) return false;

    if (likes.some((like) => like && like.id === user.id)) {
      return true;
    } else {
      return false;
    }
  }
  /**************** Comment Likes  ****************/
  const [submittingLikedOnCommentId, setSubmittingLikeOnCommentId] = useState(false);
  async function toggleCommentLike(comment) {
    let timer = setTimeout(() => setSubmittingLikeOnCommentId(comment.id), 300);
    if (isCommentLiked(comment)) {
      await req().delete(`news/${subTypeId}/news/${id}/comments/${comment.id}/like`);
    } else {
      await req().put(`news/${subTypeId}/news/${id}/comments/${comment.id}/like`);
    }

    props.refreshPostCommentLikes({
      subTypeId: subTypeId,
      newsId: id,
      feedType: feedType,
      commentId: comment.id,
      callback: () => {
        clearTimeout(timer);
        setSubmittingLikeOnCommentId(false);
      },
    });
  }

  function isCommentLiked(comment) {
    // l && l.id is a null-check. If a user is deleted they are represented as NULL in the likes array
    if (comment.likes && comment.likes.filter((l) => l && l.id === user.id).length === 0) {
      return false;
    } else {
      return true;
    }
  }

  /**************** Check-Question  ****************/
  function openCheckQuestionsModal(post) {
    showModalPage({
      title: "Tjekspørgsmål",
      content: <CheckQuestionsModal postId={post.id} questions={post.questions} subTypeId={subTypeId} />,
      closeCallback: () =>
        showDialog({
          title: lang.heyThere,
          content: lang.dialogCloseCheckQuestionsContent,
          primaryActionTitle: lang.dialogCloseCheckQuestionsPrimary,
          primaryAction: props.hideModalPage,
          secondaryActionTitle: lang.dialogCloseCheckQuestionsSecondary,
          styleType: "error",
          icon: <AlertOctagramIcon />,
        }),
      useScrollView: false,
    });
  }

  /**************** Comments  ****************/
  function onCommentContextMenu({ comment, postId, subTypeId, feedType }) {
    showContextMenu([
      {
        icon: <EditIcon />,
        title: lang.edit,
        callback: () =>
          showModalPage({
            title: `${lang.edit} ${lang.comment.toLowerCase()}`,
            content: <NewsCommentModal comment={comment} postId={postId} subTypeId={subTypeId} feedType={feedType} />,
            useScrollView: false,
          }),
      },
      {
        icon: <TrashCanOutlineIcon />,
        title: lang.delete,
        callback: () =>
          showDialog({
            title: `${lang.delete} ${lang.comment.toLowerCase()}`,
            content: lang.deleteCommentInfo,
            primaryActionTitle: lang.deleteCommentConfirm,
            primaryAction: () => deleteComment({ comment, postId, subTypeId, feedType }),
            secondaryActionTitle: lang.deleteCommentCancel,
            styleType: "error",
            icon: <AlertOctagramIcon />,
          }),
      },
    ]);
  }

  function deleteComment({ comment, postId, subTypeId, feedType }) {
    req()
      .delete(`news/${subTypeId}/${postId}/comments/${comment.id}`)
      .then(() => refreshPostComments({ subTypeId, postId, feedType }))
      .catch((err) => addToast({ template: "error" }));
  }

  // Auto refresh comments
  const [autoRefreshComments, setAutoRefreshComments] = useState(false);

  // Register side-effect to refetch commetns every 15 seconds
  useEffect(() => {
    let interval;

    // If autorefreshCommetns is set to true, call refreshPostComments every 15 seconds
    if (autoRefreshComments) {
      refreshPostComments({ subTypeId, postId: id, feedType });
      interval = setInterval(() => void refreshPostComments({ subTypeId, postId: id, feedType }), 15000);
    } else {
      // If autoRefreshComments is set to false clear the interval
      clearInterval(interval);
    }

    // Cleanup function to remove interval if component unmounts
    return () => {
      clearInterval(interval);
    };
    // eslint-disable-next-line
  }, [autoRefreshComments, id]);

  async function showAuthorModal() {
    if (!author) return;

    showModalPage({
      content: <UserProfile userDataLoading={true} userDataError={false} />,
    });
    let { data: userData } = await req()(`users/${author.id}`);
    updateModalPage({
      content: <UserProfile userDataLoading={false} userDataError={false} userData={userData} />,
    });
  }

  function onPostContextMenu({ postId, subTypeId, feedType }) {
    showContextMenu([
      {
        icon: <EditIcon />,
        title: lang.edit,
        "data-test-id": "context-menu__edit",
        callback: () =>
          showModalPage({
            useScrollView: false,
            title: `${lang.edit} ${lang.post}`,
            content: <NewsPostModal postId={postId} subTypeId={subTypeId} feedType={feedType} />,
          }),
      },
      {
        icon: <TrashCanOutlineIcon />,
        title: lang.delete,
        "data-test-id": "context-menu__delete",
        callback: () =>
          showDialog({
            title: `${lang.delete} ${lang.post}`,
            content: lang.deletePostInfo,
            primaryActionTitle: lang.deletePostConfirm,
            primaryAction: () => deletePost({ postId, subTypeId, feedType }),
            secondaryActionTitle: lang.deletePostCancel,
            styleType: "error",
            icon: <AlertOctagramIcon />,
          }),
      },
    ]);
  }

  function deletePost({ postId, subTypeId, feedType }) {
    req()
      .delete(`news/${subTypeId}/${postId}`)
      .then(() => {
        removePostFromFeed({ postId, subTypeId, feedType });
        addToast({
          title: lang.yourPostIsDeleted,
          icon: <TrashCircleIcon />,
          styleType: "neutral",
          duration: 5000,
        });
      })
      .catch(() => {
        addToast({ template: "error" });
      });
  }

  return (
    <Post
      // Content
      id={id}
      data-test-id="news-post"
      date={format(parse(date, "yyyyMMdd", 0), "do MMMM yyyy", getUserLocale(user))}
      title={titleState}
      // Content and translation
      content={contentState}
      enableMarkdownContent={true}
      isTranslating={isTranslating}
      isTranslated={isTranslated}
      enableTranslation={props.appConfig.enableNewsTranslation}
      translateContent={translateContent}
      readContent={readContent}
      isPlayingAudio={isPlayingAudio}
      isLoadingAudio={isLoadingAudio}
      // Misc
      author={author || deletedUser}
      images={images}
      hideAuthorInApp={hideAuthorInApp}
      likes={likes}
      links={links}
      documentOwner={documentOwner}
      approvedBy={approvedBy}
      readActionLangOverwrite={readActionLangOverwrite}
      comments={
        !comments
          ? null
          : comments.map(({ id, comment: content, author, date, image, likes, comments, parentCommentId }) => ({
              id,
              content,
              author,
              date,
              image,
              likes,
              parentCommentId,
              comments:
                comments.length > 0
                  ? comments.map(({ id, comment: content, author, date, image, likes, comments, parentCommentId }) => ({
                      id,
                      content,
                      author,
                      date,
                      image,
                      likes,
                      comments,
                      parentCommentId,
                    }))
                  : [],
            }))
      }
      points={points}
      documents={files}
      video={video}
      youtubeEmbed={youtubeEmbed}
      read={read}
      readDate={readDate}
      // Config
      className="news-post"
      key={`news-post-${id}`}
      showContextMenuToggle={isAuthor}
      disableInteractionsPreview={disableInteractionsPreview}
      // Likes
      onLike={
        isLiked ? () => unlikePost({ subTypeId, feedType, postId: id }) : () => likePost({ subTypeId, feedType, postId: id })
      }
      liked={isLiked}
      onCommentLike={toggleCommentLike}
      submittingLike={postsWithLoadingLikes.indexOf(id) !== -1}
      isCommentLiked={isCommentLiked}
      submittingLikedOnCommentId={submittingLikedOnCommentId}
      // Read status
      disableSwipe={disableSwipe}
      onSwipeEnd={() => handleSwipeEnd(questions)}
      swipeCompleted={swipeCompleted}
      animateOut={deletedPosts.indexOf(id) !== -1}
      onComment={(comment, parentCommentId = null) =>
        addComment({ subTypeId, feedType, postId: id, comment, commentsContainer, parentCommentId })
      }
      onAuthorClick={showAuthorModal}
      contextMenuToggleCallback={() => onPostContextMenu({ postId: id, subTypeId, feedType })}
      onCommentContextMenu={(comment) => onCommentContextMenu({ subTypeId, feedType, postId: id, comment })}
      // State and loading-stuff
      submittingComment={postsWithCommentsBeingAdded.indexOf(id) !== -1}
      loadingComments={postsWithLoadingComments.indexOf(id) !== -1}
      commentsContainerRef={commentsContainer}
      //style
      style={style}
      onCommentShow={() => setAutoRefreshComments(true)}
      onCommentHide={() => setAutoRefreshComments(false)}
    />
  );
}

NewsPost.defaultProps = {
  disableInteractionsPreview: false,
  style: {},
};

const mapStateToProps = (state) => ({
  user: state.auth.user,
  appConfig: state.appConfig,
  lang: state.language.language,
});

const mapDispatchToProps = (dispatch) => ({
  addToast: bindActionCreators(addToast, dispatch),
  showContextMenu: bindActionCreators(showContextMenu, dispatch),
  showModalPage: bindActionCreators(showModalPage, dispatch),
  updateModalPage: bindActionCreators(updateModalPage, dispatch),
  hideModalPage: bindActionCreators(hideModalPage, dispatch),
  showDialog: bindActionCreators(showDialog, dispatch),
  likePost: bindActionCreators(likePost, dispatch),
  readPost: bindActionCreators(readPost, dispatch),
  unlikePost: bindActionCreators(unlikePost, dispatch),
  addComment: bindActionCreators(addComment, dispatch),
  removePostFromFeed: bindActionCreators(removePostFromFeed, dispatch),
  refreshPostComments: bindActionCreators(refreshPostComments, dispatch),
  resetFeed: bindActionCreators(resetFeed, dispatch),
  getPosts: bindActionCreators(getPosts, dispatch),
  refreshPostCommentLikes: bindActionCreators(refreshPostCommentLikes, dispatch),
});

export default connect(mapStateToProps, mapDispatchToProps, null, { forwardRef: true })(NewsPost);
