// Libs
import React from "react";
import { css } from "emotion";

// Styles
import colors from "../../style/colors";

const TrafficLightLabel = (props) => {
  const determineLabelColor = (labelValue) => {
    if (labelValue === 1) return "red-label";
    if (labelValue === 2) return "yellow-label";
    if (labelValue === 3) return "green-label";
  };

  return (
    <label
      className={`${componentStyle(props)} ${determineLabelColor(props.value)} ${props.className || ""}`}
      style={props.style && props.style}
    />
  );
};

const componentStyle = () => css`
  position: relative;
  min-height: 2rem;
  margin: 0.2rem 0;
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  -webkit-appearance: none;
  border-width: 0 1px 1px 0;
  font-weight: 400;
  flex: 1;
  padding: 0.5rem 1rem;
  border-style: solid;
  border-color: ${colors.midGrey};

  &.green-label {
    background-color: ${colors.green};
  }

  &.red-label {
    background-color: ${colors.redMedium};
  }

  &.yellow-label {
    background-color: ${colors.yellow};
  }
`;

export default TrafficLightLabel;
