// Libs
import React, { useEffect } from "react";
import { css } from "emotion";
import { connect, useSelector } from "react-redux";
import { bindActionCreators } from "redux";

// Components
import StatusBox from "../ui/StatusBox";
import Page from "../ui/Page";
import TopBar from "../ui/TopBar";
import ScrollView from "../ui/ScrollView";
import ListItem from "../ui/ListItem";
import InlineSpinner from "../ui/InlineSpinner";

// Actions
import { getAnniversaries, resetAll } from "../../actions/anniversaryActions";

// Styles
import { InfoOutlineIcon } from "mdi-react";

// Hooks
import useAuthorModal from "../../hooks/useAuthorModal";

// Utilities and config
import breakpoints from "../../config/breakpoints";
import getProfilePictureFromUserObject from "../../utilities/get-profile-picture-from-user-object";

const Anniversary = (props) => {
  // Anniversary state from redux
  const { loading, error, anniversaries: outerAnniversaries, endOfFeed } = useSelector((state) => state.anniversary);
  const { language: lang } = useSelector((state) => state.language);

  // Used for showing the user modal
  const authorModal = useAuthorModal();

  // ComponentDidMount - initial load of component
  useEffect(() => {
    props.resetAll();
    requestAnimationFrame(() => props.getAnniversaries());

    // eslint-disable-next-line
  }, []);

  // Adds emoji depending on anniversary type
  const addEmoji = (text = "") => {
    let type = text.toLowerCase();
    if (type.indexOf("jubilæum") > -1) {
      // eslint-disable-next-line
      return `🇩🇰 \u00A0\ ${text}`;
    } else if (type.indexOf("udlært") > -1) {
      // eslint-disable-next-line
      return `🎉 \u00A0\ ${text}`;
    } else {
      return text;
    }
  };

  return (
    <Page>
      <TopBar title={lang.anniversaries} useDefaultBackButton={true} />
      <ScrollView onScrollEnd={() => props.getAnniversaries()}>
        <div className={componentStyle()}>
          {outerAnniversaries.map(({ anniversaries, title }) => (
            <div className="anniversary-container">
              <p className="date-title">{title}</p>
              {anniversaries.map((anniversary) => (
                <ListItem
                  maxWidth={breakpoints.md}
                  clickable={true}
                  onClick={() => authorModal(anniversary.user.id)}
                  title={anniversary.user && anniversary.user.name}
                  middleTitle={addEmoji(anniversary.anniversaryText)}
                  subTitle={anniversary.userGroup && anniversary.userGroup.title}
                  imageLeft={getProfilePictureFromUserObject(anniversary.user, undefined, { marginRight: "0.6rem" })}
                />
              ))}
            </div>
          ))}

          {/* Loading */}
          {loading && !error && !endOfFeed && (
            <InlineSpinner style={{ margin: "2rem 0" }} title={`${lang.loading} ${lang.posts}...`} />
          )}

          {/* End of feed with anniversaries */}
          {outerAnniversaries.length > 0 && endOfFeed && (
            <StatusBox
              style={{ paddingBottom: "5rem" }}
              icon={<InfoOutlineIcon />}
              title={lang.noMoreAnniversaries}
              content={lang.reachedEndOfFeed}
            />
          )}

          {/* End of feed without anniversaries */}
          {outerAnniversaries.length === 0 && endOfFeed && (
            <StatusBox
              style={{ marginBottom: "2rem" }}
              icon={<InfoOutlineIcon />}
              title={lang.thereIsNoAnniversariesHere}
              content={lang.comeBackSoon}
            />
          )}
        </div>
      </ScrollView>
    </Page>
  );
};

const componentStyle = () => css`
  width: 100%;
  height: 100%;
  margin: auto;
  padding-bottom: 5rem;
  max-width: ${breakpoints.md}px;

  div.anniversary-container {
    margin-bottom: 2rem;
    p.date-title {
      padding: 0.5rem 1rem;
    }
  }
`;

const mapDispatchToProps = (dispatch) => ({
  getAnniversaries: bindActionCreators(getAnniversaries, dispatch),
  resetAll: bindActionCreators(resetAll, dispatch),
});

export default connect(null, mapDispatchToProps)(Anniversary);
