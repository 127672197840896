import React from "react";
import PropTypes from "prop-types";
import { css } from "emotion";
import colors from "../../style/colors";
import { CloseIcon } from "mdi-react";
import styleTypeNormalizer from "../../utilities/style-type-normalizer";
import { durations } from "../../config/animations";

/** Implemented through redux */
const Toast = (props) => {
  let { styleType, icon, title, content, disableClose, closeCallback, hostRef } = props;
  return (
    <div
      ref={hostRef}
      className={componentStyles(props) + ` ${styleTypeNormalizer(styleType) || "neutral"}`}
      data-test-id="toast"
    >
      {}
      {title && (
        <p className="title">
          {icon} {title}
        </p>
      )}
      {content && (
        <p className="content">
          {!title && icon} {content}
        </p>
      )}
      {disableClose !== true && (
        <CloseIcon onClick={closeCallback} className="close-icon" data-test-id="toast__close-icon" />
      )}
    </div>
  );
};

const animationOffset = "0";

const componentStyles = (props) => css`
  z-index: 40;
  position: absolute;
  top: -${animationOffset}rem;
  left: 0;
  width: 100%;
  padding: ${animationOffset}.75rem 0.75rem 0.75rem 1rem;
  z-index: 40;
  border-bottom: 1px ${colors.midGrey} solid;
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
 

  &.toast-enter {
    opacity: 0;
    transform: translate3d(0, -100%, 0);
  }
  &.toast-enter-active {
    transform: translate3d(0, 0%, 0);
    opacity: 1;
    transition: transform 190ms ease, opacity ${durations.fast}ms;
  }
  &.toast-exit {
    opacity: 1;
    transform: scale(1);
  }
  &.toast-exit-active {
    opacity: 0;
    transform: translate3d(0, -100%, 0);
    transition: transform ${durations.normal}ms ease, opacity ${durations.fast}ms;
  }

  /* Element styles */
  .title {
    font-weight: 700;
    margin-bottom: ${props.content ? "0.5rem" : "0"};
  }

  .title,
  .content {
    padding-right: 2rem;
  }

  .content svg,
  .title svg {
    vertical-align: middle;
    width: 1.3rem;
    height: 1.3rem;
    margin-top: -3px;
    margin-right: 0.25rem;
  }

  .close-icon {
    position: absolute;
    top: ${animationOffset}rem;
    right: 0;
    padding: 0.5rem;
    width: 2.5rem;
    height: 2.5rem;
  }

  /* styleTypes */
  &.neutral,
  &.default {
    background-color: ${colors.blueLight};
    color: ${colors.blue};
    border-left: 3px ${colors.blue} solid;
  }

  &.negative,
  &.error,
  &.warning,
  &.failure {
    background-color: ${colors.redLight};
    color: ${colors.red};
    border-left: 3px ${colors.red} solid;
  }

  &.positive,
  &.success,
  &.ok {
    background-color: ${colors.greenLight};
    color: ${colors.green};
    border-left: 3px ${colors.green} solid;
  }
`;

Toast.propTypes = {
  title: PropTypes.string,
  content: PropTypes.string,
  icon: PropTypes.element,
  disableClose: PropTypes.bool,
  closeCallback: PropTypes.func,
};

export default Toast;
